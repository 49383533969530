import { useCallback, useEffect } from 'react';
import { useSaveForm } from 'utils/form/useSaveForm';
import { notifications } from 'designSystem/toastNotifications/toastNotificationEmitter';
import { projectBaseUrl } from 'utils/network/endpoints';

export const useSubmitProject = () : {
  submitProject: (id: number) => void;
  isSaved: boolean;
  loading: boolean;
  error: string;
} => {
  const {
    error, loading, isSaved, save,
  } = useSaveForm();

  useEffect(() => {
    if (error) {
      notifications.error(error);
    }
  }, [error]);

  const submitProject = useCallback(async (id: number) => {
    const url = `${projectBaseUrl}/${id}/approve`;
    await save(url, { }, 'patch');
  }, [save]);
  return {
    submitProject,
    isSaved,
    loading,
    error,
  };
};
