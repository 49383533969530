import { Button } from 'designSystem';
import { CardDescription } from 'domains/accountSetup/onboarding/styles';
import HomeownerContractForm from 'domains/project/addProject/contract/homeowner/form/homeownerContractForm';
import lang from 'lang/en';
import { useState } from 'react';
import AddContractorButton from '../addContractorButton';
import { UserDetailsHeader } from '../styles';
import { HomeownerContractsProps } from '../types';
import { useGetHomeownerData } from '../useGetHomeownerData';

const AddHomeownerContract = ({
  owner,
  projectData,
  triggerDataUpdate,
}: HomeownerContractsProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const {
    homeOwnerId: homeownerId,
  } = projectData?.details || {};
  const { homeowner } = useGetHomeownerData(homeownerId as string);
  const isHomeowner = !!homeowner;
  const homeownerFullName = `${homeowner?.firstName} ${homeowner?.lastName}`;

  const cardText = isHomeowner
    ? (
      <UserDetailsHeader>
        {homeownerFullName}
      </UserDetailsHeader>
    )
    : (
      <CardDescription>
        {lang.contract.homeowner.noHomeownerDescriptionText}
      </CardDescription>
    );

  return (
    <>
      <HomeownerContractForm
        triggerDataUpdate={triggerDataUpdate}
        projectData={projectData}
        owner={owner}
        preloadedData={{ homeownerFullName }}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      />
      {cardText}
      <Button
        type="button"
        variant="primary"
        customRender={<AddContractorButton onClick={() => setIsOpen(true)} />}
        state={isHomeowner ? 'active' : 'disabled'}
      />
    </>
  );
};

export default AddHomeownerContract;
