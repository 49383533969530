import { Confirmation } from 'common/confirmation';
import lang from 'lang/en';
import { useLocation, useNavigate } from 'react-router-dom';
import { TextSection, PlainText } from 'domains/project/addProject/styles';

const Success = () => {
  const { state } : any = useLocation();
  // const navigate = useNavigate();
  const handleRedirection = () => {
    if (state && state.path) {
      window.location.href = state.path;
    }
  };
  return (
    <Confirmation
      confirmationText={(
        <TextSection>
          <PlainText>{lang.milestones.approvalSuccessMsg}</PlainText>
        </TextSection>
      )}
      handleOkClick={handleRedirection}
    />
  );
};

export default Success;
