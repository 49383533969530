import {
  CardDescription, CardRow,
} from 'domains/accountSetup/onboarding/styles';
import lang from 'lang/en';
import { ReactComponent as SearchIcon } from 'assets/images/search-icon.svg';
import { getButtonState } from 'utils/helpers';
import { useEffect, useRef, useState } from 'react';
import { emailValidationSchema } from './validationSchema';
import { useGetUserData } from './useGetUserData';
import { UserSearchCardProps } from './types';
import { CustomButton, CustomInputField } from './styles';

const UserSearch = ({
  onUserFound, onUserNotFound, userId, description, userType, userNotFoundMessage,
}: UserSearchCardProps) => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const {
    getUserDataByMail,
    getUserDataById,
    loading,
  } = useGetUserData(userType);
  useEffect(() => {
    const validateEmail = async () => {
      setError('');
      try {
        await emailValidationSchema.validate({ email });
      } catch (validationError) {
        setError((validationError as any)?.message);
      }
    };
    if (email) {
      validateEmail();
    }
  }, [email]);

  useEffect(() => {
    const searchUser = async () => {
      const requestResult = await getUserDataById(userId as string);
      if (requestResult) {
        onUserFound(requestResult);
        return;
      }
      onUserNotFound();
    };
    if (userId) {
      searchUser();
    }
  }, [userId]);

  const field = {
    onChange: (event: any) => setEmail(event.target.value),
    onBlur: () => {},
    value: email,
    name: 'email',
    ref: useRef(null).current as any,
  };

  const handleSearch = async () => {
    const requestResult = await getUserDataByMail(email);
    if (requestResult) {
      onUserFound(requestResult);
      return;
    }
    setError(userNotFoundMessage);
    onUserNotFound();
  };

  return (
    <>
      <CardDescription>
        {description}
      </CardDescription>
      <CardRow>
        <CustomInputField field={field} iconPrefix={<SearchIcon />} placeholder={lang.searchByEmailPlaceholder} errorMessage={error} />
      </CardRow>
      <CustomButton
        data-testid="search-button"
        width="full"
        onClick={handleSearch}
        type="button"
        variant="primary"
        label="Search"
        state={getButtonState(!!email, !error, loading)}
      />
    </>

  );
};

export default UserSearch;
