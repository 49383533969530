import Logo from 'assets/images/logo.svg';
import lang from 'lang/en';
import { useWindowDimensions } from 'hooks/useWindowDimensions';
import { LogoImg, LogoSection, MainHeading } from './styles';

type HeaderWithLogoProps = {
  text?: string;
  fontSize?: string;
  lineHeight?: string;
}

const HeaderWithLogo = ({ text, fontSize, lineHeight }: HeaderWithLogoProps) => {
  const { screenSize } = useWindowDimensions();

  return (
    <>
      <LogoSection screenSize={screenSize}>
        <LogoImg src={Logo} alt={lang.tradesmenLogoAltText} />
      </LogoSection>
      {text && <MainHeading lineHeight={lineHeight} fontSize={fontSize}>{text}</MainHeading>}
    </>
  );
};

export default HeaderWithLogo;
