import { RadioButton } from 'designSystem/input/radioBox/radioButton';
import lang from 'lang/en';
import {
  RadioSection, CompletedText, ApprovalSection,
} from '../styles';
import type { ApprovalProps } from '../types';

const Approval = ({
  name,
  milestoneId,
  labelText,
  approvalStatus,
}: ApprovalProps) => {
  return (
    <ApprovalSection margin="30px 0 23px 0" key={milestoneId}>
      <CompletedText>{labelText}</CompletedText>
      <RadioSection>
        <RadioButton
          name={name}
          labelText={lang.subcontractorMilestones.milestones.completed.yesOption}
          id={`${name}-yes`}
          isChecked={approvalStatus}
          isDisabled
        />
        <RadioButton
          name={name}
          labelText={lang.subcontractorMilestones.milestones.completed.noOption}
          id={`${name}-no`}
          isChecked={!approvalStatus}
          isDisabled
        />
      </RadioSection>
    </ApprovalSection>
  );
};

export default Approval;
