import { ControlledInputField } from 'designSystem/input';
import {
  LabelWithMargin, RadioContainer, ErrorMessage, ErrorText,
} from 'domains/project/addProject/contract/styles';
import { SelectPropsWithControl } from 'domains/project/addProject/contract/subcontractor/form/types';
import { NarrowControlledNumberMaskedInput } from 'domains/project/addProject/styles';
import lang from 'lang/en';
import { useEffect } from 'react';
import { useWatch } from 'react-hook-form';
import { useWindowDimensions } from 'hooks/useWindowDimensions';
import { getTextareaIconLeftPosition } from '../helpers';

const formStrings = lang.contract.subcontractors.modal.form;

const PaymentAmountOptionSelect = ({
  registrant,
  control,
  setValue,
  errorMsg,
}: SelectPropsWithControl) => {
  const optionValue = useWatch({
    control,
    name: registrant.name,
  });
  const { screenSize } = useWindowDimensions();
  useEffect(() => {
    if (optionValue !== 'totalAmount' && setValue) {
      setValue('paymentAmountTotal', null);
    }
    if (optionValue !== 'hourlyRate' && setValue) {
      setValue('paymentAmountHourly', null);
    }
    if (optionValue !== 'other' && setValue) {
      setValue('paymentAmountOther', '');
    }
  }, [optionValue]);

  return (
    <RadioContainer>
      <LabelWithMargin htmlFor="paymentAmountTotalOption">
        <input
          {...registrant}
          type="radio"
          value="totalAmount"
          id="paymentAmountTotalOption"
        />
        {formStrings.paymentAmountOptions.totalAmountOption}
      </LabelWithMargin>
      <NarrowControlledNumberMaskedInput
        numberPrefix="$"
        placeholder="$ 0.00"
        labelText={formStrings.paymentAmountOptions.totalAmountFieldLabel}
        control={control}
        name="paymentAmountTotal"
        disabled={optionValue !== 'totalAmount'}
        errorIconPosition="206px"
      />
      <LabelWithMargin htmlFor="paymentAmountHourlyOption">
        <input
          {...registrant}
          type="radio"
          value="hourlyRate"
          id="paymentAmountHourlyOption"
        />
        {formStrings.paymentAmountOptions.hourlyRateOption}
      </LabelWithMargin>
      <NarrowControlledNumberMaskedInput
        numberPrefix="$"
        placeholder="$ 0.00"
        labelText={formStrings.paymentAmountOptions.hourlyRateFieldLabel}
        control={control}
        name="paymentAmountHourly"
        disabled={optionValue !== 'hourlyRate'}
        errorIconPosition="206px"
      />
      <LabelWithMargin htmlFor="paymentAmountOtherOption">
        <input
          {...registrant}
          type="radio"
          value="other"
          id="paymentAmountOtherOption"
        />
        {formStrings.paymentAmountOptions.otherOption}
      </LabelWithMargin>
      <ControlledInputField
        type="textarea"
        control={control}
        name="paymentAmountOther"
        labelText={formStrings.paymentAmountOptions.otherDescriptionFieldLabel}
        disabled={optionValue !== 'other'}
        errorIconTopPosition="68px"
        errorIconPosition={getTextareaIconLeftPosition(screenSize)}
      />
      <ErrorMessage><ErrorText>{errorMsg}</ErrorText></ErrorMessage>
    </RadioContainer>
  );
};

PaymentAmountOptionSelect.defaultProps = {
  errorMsg: '',
};

export default PaymentAmountOptionSelect;
