import { TableBodyPropGetter, TableBodyProps, Row } from 'react-table';
import {
  TableRow, TableCell,
} from './styles';

type TableBodyPropsType = {
  bodyPadding?: string;
  getTableBodyProps: (propGetter?: TableBodyPropGetter<TableBodyProps>) => TableBodyProps;
  prepareRow: (row: Row<TableBodyProps>) => void;
  rows: Array<Row<TableBodyProps>>;
}

export const TableBodySection = ({
  getTableBodyProps, prepareRow, bodyPadding, rows,
} : TableBodyPropsType) => {
  return (
    <tbody {...getTableBodyProps()}>
      {rows.map((row) => {
        prepareRow(row);
        return (
          <TableRow {...row.getRowProps()}>
            {row.cells.map((cell) => {
              return <TableCell bodyPadding={bodyPadding} {...cell.getCellProps()}>{cell.render('Cell')}</TableCell>;
            })}
          </TableRow>
        );
      })}
    </tbody>
  );
};
