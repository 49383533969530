import { Button } from 'designSystem';
import { useState } from 'react';
import AddContractorButton from '../addContractorButton';
import { UserDetailsHeader } from '../styles';
import { SubcontractorContractProps } from '../types';
import SubcontractorContractForm from './form/subcontractorContractForm';

const SubcontractorContract = ({
  subcontractorDetails,
  owner,
  projectData,
  triggerDataUpdate,
}: SubcontractorContractProps) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <SubcontractorContractForm
        triggerDataUpdate={triggerDataUpdate}
        projectData={projectData}
        owner={owner}
        subcontractorDetails={subcontractorDetails}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      />
      <UserDetailsHeader>
        {`${subcontractorDetails?.firstName} ${subcontractorDetails?.lastName}`}
      </UserDetailsHeader>
      <Button
        type="button"
        variant="primary"
        customRender={<AddContractorButton onClick={() => setIsOpen(true)} />}
        state="active"
      />
    </>
  );
};

export default SubcontractorContract;
