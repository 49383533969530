import styled from 'styled-components';
import {
  gray300, blue700, white,
} from 'designSystem/colors';

export const CheckboxContainer = styled.label`
  display: flex;
  position: relative;
  cursor: pointer;
`;

export const CheckSpan = styled.span`
  width: 16px;
  height: 16px;
  left: 0;
  position: absolute;
  border: 1px solid ${gray300};
  border-radius: 4px;
  &:after {
    content: "";
    position: absolute;
    display: none;
  }
`;

export const Checkbox = styled.input`
  &:checked + ${CheckSpan} {
    background-color: ${blue700};
    border: none;
  }
  &:checked + ${CheckSpan}:after {
    display: block;
    left: 6px;
    top: 2px;
    width: 3px;
    height: 7px;
    border: solid ${white};
    border-width: 0 2px 3px 0;
    transform: rotate(45deg);
  }
  position: absolute;
  opacity: 0;
  cursor: pointer;
`;
