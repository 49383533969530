import { PreloadedDataType } from 'domains/project/addProject/types';
import { useCallback, useState } from 'react';
import { getAuthInfo, clearAuthInfo } from 'utils/helpers';
import { useNavigate } from 'react-router-dom';
import lang from 'lang/en';
import {
  saveChangeOrderSignature, deleteChangeOrderContract, getChangeOrderContract,
  sendChangeOrderContractByHomeowner, sendChangeOrderContractByContractor,
} from 'utils/network/endpoints';

import { apiClient } from 'utils/network/apiClient';
import { notifications } from 'designSystem/toastNotifications/toastNotificationEmitter';
import { ChangeOrderData } from '../types';

export const useChangeOrderActions = (projectData?: PreloadedDataType) => {
  const { accountType } = getAuthInfo();
  const navigate = useNavigate();
  const [signatureLoading, setSignatureLoading] = useState(false);
  const [sendContractLoading, setSendContractLoading] = useState(false);
  const [isContractSend, setContractSend] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [downloadLoading, setdownloadLoading] = useState(false);

  const getTotalSalesPrice = useCallback(() => {
    let price = '';
    if (projectData?.details?.cost) {
      const {
        cost,
        /*escrow,*/
      } = projectData?.details || {};
      const parsedCost = typeof cost !== 'string' ? cost : parseFloat(cost);
      price = parsedCost.toString();
    }
    return price;
  }, [projectData]);

  const getContractorFullName = useCallback(() => {
    const contractorFirstName = projectData?.user?.company?.owners[0].firstName || '';
    const contractorLastName = projectData?.user?.company?.owners[0].lastName || '';
    return `${contractorFirstName} ${contractorLastName}`;
  }, [projectData]);

  const getAddressDetails = useCallback(() => {
    const {
      project: projectAddressDetails,
    } = projectData?.details || {};
    return {
      streetAddress: projectAddressDetails?.streetAddress || '',
      city: projectAddressDetails?.city || '',
      state: projectAddressDetails?.state || '',
      zip: projectAddressDetails?.zip || '',
    };
  }, [projectData]);

  const getHomeownerFullName = useCallback(() => {
    return `${projectData?.homeOwner?.firstName} ${projectData?.homeOwner?.lastName}`;
  }, [projectData]);

  const getSignatureData = useCallback((changeOrderDetails?: ChangeOrderData) => {
    if (changeOrderDetails) {
      const signatureUrl = accountType === 'homeowner' ? changeOrderDetails?.homeOwnerSignatureUrl : changeOrderDetails?.contractorSignatureUrl;
      return signatureUrl;
    }
  }, []);

  const addContractSignature = useCallback(async (contractId?: number, signatureUrl?: string) => {
    try {
      if (contractId) {
        setSignatureLoading(true);
        const url = saveChangeOrderSignature.replace('%contractId%', contractId.toString());
        const signatureType = accountType === 'homeowner' ? 'homeOwnerSignature' : 'contractorSignature';
        await apiClient.patch(url, {
          signatureType,
          signatureUrl,
        });
      }
    } catch (error: any) {
      console.error(error);
    } finally {
      setSignatureLoading(false);
    }
  }, []);

  const sendChangeOrderContract = useCallback(async (contractId?: number) => {
    try {
      if (contractId) {
        setSendContractLoading(true);
        const apiUrl = accountType === 'homeowner' ? sendChangeOrderContractByHomeowner : sendChangeOrderContractByContractor;
        const url = apiUrl.replace('%contractId%', contractId.toString());
        await apiClient.post(url);
        setContractSend(true);
      }
    } catch (error: any) {
      console.error(error);
    } finally {
      setContractSend(false);
      setSendContractLoading(false);
    }
  }, []);

  const deleteContract = useCallback(async (contractId?: number) => {
    try {
      if (contractId) {
        setDeleteLoading(true);
        const url = deleteChangeOrderContract.replace('%contractId%', contractId.toString());
        await apiClient.delete(url);
      }
    } catch (error: any) {
      console.error(error);
    } finally {
      setDeleteLoading(false);
    }
  }, []);

  const getContract = useCallback(async (contractId?: number) => {
    if (contractId) {
      setdownloadLoading(true);
      const url = getChangeOrderContract.replace('%contractId%', contractId.toString());
      try {
        const callResponse = await apiClient.get(url);
        setdownloadLoading(false);
        if (callResponse.status === 200) {
          return callResponse.data.data;
        } else {
          if ((callResponse as any).isNotAuthenticated) {
            clearAuthInfo();
            notifications.error(lang.sessionExpiredMessage);
            navigate('/signin');
            return;
          }
          notifications.error(lang.unknownErrorTryLater);
        }
      } catch (callError: unknown) {
        notifications.error((callError as Error)?.message || lang.accountActivationFailedMessage);
      }

      setdownloadLoading(false);
    }
  }, [apiClient]);

  return {
    getTotalSalesPrice,
    getContractorFullName,
    getAddressDetails,
    getHomeownerFullName,
    getSignatureData,
    addContractSignature,
    sendChangeOrderContract,
    isContractSend,
    sendContractLoading,
    signatureLoading,
    deleteContract,
    deleteLoading,
    getContract,
    downloadLoading,
  };
};
