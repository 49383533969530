import { ReactComponent as DownloadIcon } from 'assets/images/downloadIcon.svg';
import lang from 'lang/en';
import runtimeEnv from '@mars/heroku-js-runtime-env';
import {
  FileRow, FilesContainer, ListFile, DownloadLink,
  DownloadText,
} from './styles';
import type { ListFilesProps } from './types';

export const ListFiles = ({ documents }: ListFilesProps) => {
  const { REACT_APP_IMAGE_URL } = runtimeEnv();
  return (
    <FilesContainer>
      {documents.map((file) => (
        <FileRow>
          <ListFile>
            <p>
              {file.fileName}
            </p>
            <a href={`${REACT_APP_IMAGE_URL}/${file.documentUrl}`} rel="noreferrer" download>
              <DownloadLink>
                <DownloadIcon />
                <DownloadText>
                  {lang.downloadText}
                </DownloadText>
              </DownloadLink>
            </a>
          </ListFile>
        </FileRow>
      ))}
    </FilesContainer>
  );
};
