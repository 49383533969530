import { ReactElement } from 'react';
import { ReactComponent as TickIcon } from 'assets/images/tick.svg';
import { ReactComponent as RedLineIcon } from 'assets/images/line.svg';
import type { PasswordValidationRulesProps } from './types';
import {
  RulesSection, Rules, ValidationMessage, Symbol,
} from '../styles';

const PasswordValidationRules = ({ messages }: PasswordValidationRulesProps): ReactElement => {
  const getCurrentState = (value: string, valid: boolean) => {
    let colorState = 'default';
    let symbol: string | ReactElement = '•';
    if (value.length > 0) {
      const isValid = value.length > 0 && valid;
      colorState = isValid ? 'valid' : 'invalid';
      symbol = isValid ? <TickIcon /> : <RedLineIcon width="12px" height="8px" />;
    }
    return {
      colorState,
      symbol,
    };
  };
  return (
    <RulesSection>
      {messages && messages.map(({
        id, valid, message, value,
      }) => {
        return (
          <Rules key={id}>
            <ValidationMessage colorState={getCurrentState(value, valid).colorState}>
              <Symbol>{getCurrentState(value, valid).symbol}</Symbol>
              {message}
            </ValidationMessage>
          </Rules>
        );
      })}
    </RulesSection>

  );
};

export default PasswordValidationRules;
