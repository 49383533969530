import {
  LabelWithMargin, RadioContainer, ErrorMessage, ErrorText,
} from 'domains/project/addProject/contract/styles';
import { SimpleSelectProps } from 'domains/project/addProject/contract/subcontractor/form/types';
import lang from 'lang/en';

const formStrings = lang.contract.subcontractors.modal.form;

const AssignmentRightOptionSelect = ({
  registrant,
  errorMsg,
}: SimpleSelectProps) => {
  return (
    <RadioContainer>
      <LabelWithMargin htmlFor="hasRightsToAssignOption">
        <input
          {...registrant}
          type="radio"
          value="hasRight"
          id="hasRightsToAssignOption"
        />
        {formStrings.assignmentRightOptions.rightOption}
      </LabelWithMargin>
      <LabelWithMargin htmlFor="hasNoRightsToAssignOption">
        <input
          {...registrant}
          type="radio"
          value="hasNoRights"
          id="hasNoRightsToAssignOption"
        />
        {formStrings.assignmentRightOptions.noRightOption}
      </LabelWithMargin>
      <ErrorMessage><ErrorText>{errorMsg}</ErrorText></ErrorMessage>
    </RadioContainer>
  );
};

AssignmentRightOptionSelect.defaultProps = {
  errorMsg: '',
};

export default AssignmentRightOptionSelect;
