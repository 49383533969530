import { colors, typings } from 'designSystem';
import ReactSelect from 'react-select';
import styled from 'styled-components';

export const StyledSelect = styled(ReactSelect)`
  ${typings.textSm};
`;

export const OptionWithSeparator = styled.div`
  border-bottom: 1px solid ${colors.gray100}
`;

export const SelectContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
