import runtimeEnv from '@mars/heroku-js-runtime-env';
import { Modal } from 'common/modal';
import { CardDescription, CardTitle } from 'domains/accountSetup/onboarding/styles';
import ContractTile from 'domains/project/addProject/contract/homeowner/contractTile';
import SendContractModal from 'domains/project/addProject/contract/homeowner/sendContractModal';
import Signature from 'domains/project/addProject/contract/homeowner/signature/signature';
import { useUpdateSubcontractorContract } from 'domains/project/addProject/contract/homeowner/signature/useUpdateSubcontractorContract';
import { Section, WideButton } from 'domains/project/addProject/contract/homeowner/styles';
import { SignatureState } from 'domains/project/addProject/contract/homeowner/types';
import { useDownloadContractPreview } from 'domains/project/addProject/contract/subcontractor/useDownloadContractPreview';
import { UserDetailsHeader } from 'domains/project/addProject/contract/styles';
import { useSendSubcontractorContractAsSubcontractor } from 'domains/project/addProject/contract/subcontractor/useSendSubcontractorContractAsSubcontractor';
import { SubcontractorContractProps } from 'domains/project/addProject/contract/types';
import lang from 'lang/en';
import {
  useEffect, useState, useMemo, useRef,
} from 'react';
import { isEmpty } from 'lodash';

const signFlowStrings = lang.contract.homeowner.signFlow;

const SubcontractorContractView = ({
  projectData,
  triggerDataUpdate,
  subcontractorDetails,
  subcontractorData,
}: SubcontractorContractProps) => {
  const [isSendContractOpen, setIsSendContractOpen] = useState(false);
  const [signatureState, setSignatureState] = useState<SignatureState>('empty');
  const subcontractorContract = subcontractorData;
  const subcontractorFullName = `${subcontractorDetails?.firstName} ${subcontractorDetails?.lastName}`;
  const { REACT_APP_IMAGE_URL } = runtimeEnv();
  const {
    loading: updateContractLoading,
    updateSubcontractorContract,
  } = useUpdateSubcontractorContract();

  const {
    loading,
    downloadContractPreview,
  } = useDownloadContractPreview();

  const {
    loading: sendContractLoading,
    isSend,
    sendSubcontractorContractAsSubcontractor,
  } = useSendSubcontractorContractAsSubcontractor();
  const signatureRef = useRef<any>();

  const handleSignatureUploadFinished = async (signatureUrl: string) => {
    await updateSubcontractorContract(subcontractorContract.id, 'subcontractorSignature', signatureUrl);
    setSignatureState('submitted');
  };

  const handleSendContract = async () => {
    await sendSubcontractorContractAsSubcontractor(subcontractorContract?.id);
    triggerDataUpdate();
  };

  useEffect(() => {
    if (isSend && !loading) {
      setIsSendContractOpen(false);
    }
  }, [isSend]);

  const isContractSentByContractor = !!subcontractorContract?.contractorSentAt;

  useEffect(() => {
    if (isEmpty(subcontractorContract?.subcontractorSignatureUrl)) {
      setSignatureState('empty');
      signatureRef?.current?.click();
    }
  }, [subcontractorContract]);

  const preloadedImageUrl = useMemo(() => {
    if (!isEmpty(subcontractorContract?.subcontractorSignatureUrl)) {
      return `${REACT_APP_IMAGE_URL}/${subcontractorContract?.subcontractorSignatureUrl}`;
    }
    return '';
  }, [subcontractorContract]);

  return (
    <>
      <UserDetailsHeader>
        {subcontractorFullName}
      </UserDetailsHeader>
      {isContractSentByContractor && (
        <>
          <Section>
            <CardTitle marginBottom="0">
              {signFlowStrings.step1.header}
            </CardTitle>
            <CardDescription>
              {signFlowStrings.step1.description}
            </CardDescription>
            <ContractTile
              contractFileName={subcontractorContract?.fileName}
              downloadLoading={loading}
              onDownloadClick={() => downloadContractPreview(subcontractorContract?.id as string)}
            />
          </Section>
          <Section>
            <CardTitle marginBottom="0">
              {signFlowStrings.step2.header}
            </CardTitle>
            <CardDescription>
              {signFlowStrings.step2.description}
            </CardDescription>
            <Signature
              signatureState={signatureState}
              setSignatureState={setSignatureState}
              onSignatureUploadFinished={handleSignatureUploadFinished}
              preloadedImageUrl={preloadedImageUrl}
              id={projectData?.projectContract.id}
              ref={signatureRef}
            />
          </Section>
          <Section>
            <CardTitle marginBottom="0">
              {signFlowStrings.step3.header}
            </CardTitle>
            <CardDescription>
              {signFlowStrings.step3.description}
            </CardDescription>
            <WideButton
              width="full"
              type="button"
              variant="primary"
              label={signFlowStrings.step3.sendContractButtonLabel}
              onClick={() => setIsSendContractOpen(true)}
              state={(signatureState === 'submitted' || signatureState === 'preloaded') ? 'active' : 'disabled'}
            />
          </Section>
          <Modal isOpen={isSendContractOpen}>
            <SendContractModal
              sendContractLoading={sendContractLoading}
              handleSend={handleSendContract}
              handleClose={() => setIsSendContractOpen(false)}
            />
          </Modal>
        </>
      )}
    </>
  );
};

export default SubcontractorContractView;
