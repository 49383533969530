import styled from 'styled-components';
import { colors, typings } from 'designSystem';

export const Container = styled.div`
  margin-top: 12px;
`;

export const Label = styled.label`
  position: relative;
  display: inline-flex;
  width: 36px;
  height: 16px;
`;

export const SpanSection = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${colors.gray200};
  transition: .4s;
  border-radius: 34px;

  &:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: -1px;
    bottom: -2px;
    background-color: ${colors.white};
    transition: .4s;
    border-radius: 50%;
    box-shadow: 0px 1px 6px 3px rgba(29, 78, 216, 0.1);
  }
`;

export const Input = styled.input`
  opacity: 0;
  width: 0;
  height: 0;

  &:checked + ${SpanSection} {
    background-color: ${colors.blue700};
    
    &:before {
      transform: translateX(26px);
      left: -7px;
      bottom: -2px;
      box-shadow: 0px 1px 4px 3px rgba(29, 78, 216, 0.1);
    }
  }

  &:checked {
    &:disabled  + ${SpanSection} {
      background-color: ${colors.blueGrey};
    }   
  }
`;

export const Text = styled.p`
  ${typings.textSm}
  line-height: 20px;
  color: ${colors.gray500};
  font-weight: 500;
`;
