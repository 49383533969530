import { useCallback, useEffect } from 'react';
import { useSaveForm } from 'utils/form/useSaveForm';
import { uploadHeaderFileUrl } from 'utils/network/endpoints';
import { notifications } from 'designSystem/toastNotifications/toastNotificationEmitter';
import lang from 'lang/en';
import type { File } from 'designSystem/fileUpload/types';
import type { SaveHeaderLogo } from '../types';

export const useHeaderUploadFile = (): {
  saveHeaderLogo: (data: Array<File>) => Promise<void>;
  loading: boolean;
  isSaved: boolean;
  error: string;
} => {
  const {
    loading, error, save, isSaved,
  } = useSaveForm<SaveHeaderLogo>();

  useEffect(() => {
    if (isSaved && !error) {
      notifications.success(lang.user.fileUploadSuccess);
    }
  }, [error, isSaved]);

  useEffect(() => {
    if (error) {
      notifications.error(error);
    }
  }, [error]);

  const saveHeaderLogo = useCallback(
    async (data: Array<File>) => {
      const payload = {
        profileLogo: {
          fileName: data?.[0]?.fileName || null,
          documentUrl: data?.[0]?.documentUrl || null,
        },
      };
      await save(uploadHeaderFileUrl, payload, 'patch');
    },
    [save],
  );

  return {
    loading,
    error,
    saveHeaderLogo,
    isSaved,
  };
};
