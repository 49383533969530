import { ContractorMiletones as CreateMilestones } from 'domains/project/addProject/milestones/contractor/contractor';
import { Header, NoDataFoundText, TitleHeading } from 'domains/project/addProject/styles';
import { useWindowDimensions } from 'hooks/useWindowDimensions';
import lang from 'lang/en';
import { isProjectPendingState, projectSubmittedByContractor } from 'utils/helpers';
import type { ContractorMilestoneActionProps } from '../types';
import { ViewMilestones } from './viewMilestones';

export const ContractorMilestones = ({
  setPreviousStep, onFormSubmitted, setProjectId, handleSubmitProjectValidation,
  milestoneData,
} :
ContractorMilestoneActionProps) => {
  const {
    projectStatus, contractorId, isEscrow, preloadedData, projectId,
  } = milestoneData;
  const { screenSize } = useWindowDimensions();

  if (projectSubmittedByContractor(projectStatus, contractorId)) {
    return (
      <CreateMilestones
        setPreviousStep={setPreviousStep}
        onFormSubmitted={onFormSubmitted}
        setProjectId={setProjectId}
        preloadedData={preloadedData}
        handleSubmitProjectValidation={handleSubmitProjectValidation}
        isEscrow={isEscrow}
      />
    );
  }

  return (
    <>
      <TitleHeading><Header screenSize={screenSize}>{lang.contractorMilestones}</Header></TitleHeading>
      {
        preloadedData?.milestones?.length === 0
          ? <NoDataFoundText marginTop="60px">{lang.milestoneUpload.noDataFound}</NoDataFoundText>
          : (
            <ViewMilestones
              preloadedData={preloadedData}
              isEscrow={isEscrow}
              contractorId={contractorId}
              projectId={projectId}
              isPendingProject={isProjectPendingState(projectStatus)}
            />
          )

      }
    </>
  );
};
