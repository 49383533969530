import { ReactComponent as DownloadIcon } from 'assets/images/downloadIcon.svg';
import { ReactComponent as EditIcon } from 'assets/images/edit-icon.svg';
import { ReactComponent as TrashIcon } from 'assets/images/trashIcon.svg';
import {
  DeleteAction, DownloadAction, EditAction, StyledLoading,
} from './styles';
import { ActionProps } from './types';

const Action = ({
  type,
  onClick,
  label,
  name,
  state = 'active',
}: ActionProps) => {
  switch (type) {
    case 'delete':
      return (
        <DeleteAction name={name} onClick={onClick} type="button">
          <TrashIcon />
          <p>{label}</p>
        </DeleteAction>
      );
    case 'edit':
      return (
        <EditAction name={name} onClick={onClick} type="button">
          <EditIcon />
          <p>{label}</p>
        </EditAction>
      );
    case 'download':
      return (
        <DownloadAction name={name} onClick={onClick} type="button">
          {state === 'loading'
            ? <StyledLoading />
            : <DownloadIcon />}
          <p>{label}</p>
        </DownloadAction>
      );
    default:
      return <div />;
  }
};

export default Action;
