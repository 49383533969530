import { Button } from 'designSystem';
import { useWindowDimensions } from 'hooks/useWindowDimensions';
import { initializeDrawing } from 'domains/project/addProject/contract/homeowner/signature/drawingHelper';
import { useSaveSignature } from 'domains/project/addProject/contract/homeowner/signature/useSaveSignature';
import { SignatureButtonsRow, StyledCanvas } from 'domains/project/addProject/contract/homeowner/styles';
import { SignatureProps, SignatureState } from 'domains/project/addProject/contract/homeowner/types';
import lang from 'lang/en';
import {
  forwardRef, useEffect, useImperativeHandle, useRef,
} from 'react';
import { isEmpty } from 'lodash';

const signFlowStrings = lang.contract.homeowner.signFlow;

const getButtonState = (loading: boolean, signatureState: SignatureState) => {
  if (loading) {
    return 'loading';
  }
  switch (signatureState) {
    case 'empty':
    case 'submitted':
    case 'preloaded':
      return 'disabled';
    case 'present':
      return 'active';
    default:
      return 'active';
  }
};

const Signature = forwardRef(({
  onSignatureUploadFinished,
  preloadedImageUrl,
  signatureState,
  id,
  setSignatureState,
}: SignatureProps, ref) => {
  const { screenSize } = useWindowDimensions();
  const canvasId = `signature-canvas-${id}`;
  const submitButtonName = `submit-button-${id}`;
  const clearButtonName = `clear-button-${id}`;
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const {
    loading,
    saveSignature,
  } = useSaveSignature();

  useImperativeHandle(ref, () => ({
    click() {
      handleClear();
    },
  }));

  const handleUpdateSignatureState = (newState: SignatureState) => {
    if (signatureState !== newState) {
      setSignatureState(newState);
    }
  };

  useEffect(() => {
    if (isEmpty(preloadedImageUrl)) {
      handleClear();
    }
  }, [preloadedImageUrl]);

  useEffect(() => {
    initializeDrawing(canvasId, handleUpdateSignatureState, preloadedImageUrl);
  }, []);

  const handleSubmit = () => {
    if (canvasRef && canvasRef.current) {
      canvasRef.current.toBlob(async (blob: any) => {
        const fileKey = await saveSignature(blob, 'signature.png');
        onSignatureUploadFinished(fileKey);
      }, 'png');
    }
  };

  const handleClear = () => {
    if (canvasRef && canvasRef.current) {
      // eslint-disable-next-line no-self-assign
      canvasRef.current.width = canvasRef.current.width;
      setSignatureState('empty');
    }
  };

  return (
    <>
      <StyledCanvas
        id={canvasId}
        ref={canvasRef}
        width="560px"
        height="168px"
        screenSize={screenSize}
      >
        This browser is not supported
      </StyledCanvas>
      <SignatureButtonsRow>
        <Button
          onClick={handleSubmit}
          name={submitButtonName}
          type="button"
          variant="primary"
          label={signFlowStrings.step2.submitSignatureButtonLabel}
          state={getButtonState(loading, signatureState)}
        />
        <Button
          onClick={handleClear}
          name={clearButtonName}
          type="button"
          variant="secondary"
          label={signFlowStrings.step2.clearSignatureButtonLabel}
        />
      </SignatureButtonsRow>
    </>
  );
});

export default Signature;
