import { Button } from 'designSystem';
import { ControlledInputField } from 'designSystem/input';
import lang from 'lang/en';
import { Control, FieldValues } from 'react-hook-form';
import { ReactComponent as PlusIcon } from 'assets/images/plus-icon.svg';
import { ButtonWithIconContainer } from 'designSystem/button/styles';
import { useWindowDimensions } from 'hooks/useWindowDimensions';
import Action from 'designSystem/action/action';
import {
  Card, CardRow, CardTitle, CustomFieldSized, CustomMaskedField, AddressNote,
} from '../../../styles';

export type CompanyOwnerProps = {
  control: Control<FieldValues>;
  index: number;
  lastElement: boolean;
  firstElement: boolean;
  handleShowModal: (index: number) => void;
  handleAddOwner: () => void;
}

const CompanyOwner = ({
  control, index, lastElement, firstElement, handleShowModal, handleAddOwner,
}: CompanyOwnerProps) => {
  const { screenSize } = useWindowDimensions();
  const emailLabel = firstElement ? lang.primaryEmailLabel : lang.emailLabel;
  const isEmailFieldDisabled = firstElement ? true : false;

  return (
    <Card screenSize={screenSize}>
      <CardTitle>
        {`${lang.companyOwner} ${index + 1}`}
        { !firstElement && (
          <Action name="deleteAction" label={lang.deleteButtonLabel} type="delete" onClick={() => handleShowModal(index)} />
        )}
      </CardTitle>
      <CardRow>
        <ControlledInputField labelText={lang.firstNameLabel} control={control} name={`owners[${index}].firstName`} />
        <ControlledInputField labelText={lang.lastNameLabel} control={control} name={`owners[${index}].lastName`} />
      </CardRow>
      <CardRow>
        <CustomFieldSized disabled={isEmailFieldDisabled} maxWidth="504px" labelText={emailLabel} control={control} name={`owners[${index}].email`} />
      </CardRow>
      <CardRow>
        <AddressNote>{lang.addressNote}</AddressNote>
      </CardRow>
      <CardRow>
        <CustomFieldSized disabled maxWidth="612px" labelText={lang.countryLabel} control={control} name={`owners[${index}].country`} />
      </CardRow>
      <CardRow>
        <ControlledInputField labelText={lang.addressLabel} control={control} name={`owners[${index}].streetAddress`} />
      </CardRow>
      <CardRow customFlexBasis="230px">
        <ControlledInputField labelText={lang.cityLabel} control={control} name={`owners[${index}].city`} />
        <ControlledInputField labelText={lang.stateLabel} control={control} name={`owners[${index}].state`} />
        <CustomFieldSized maxWidth="240px" labelText={lang.zipLabel} control={control} name={`owners[${index}].zip`} />
      </CardRow>
      <CardRow>
        <CustomMaskedField
          maxWidth="240px"
          placeholder="+1(000) 000-00-00"
          maskFormat="+1(###) ###-##-##"
          labelText={lang.phoneLabel}
          control={control}
          name={`owners[${index}].phone`}
        />
      </CardRow>
      { lastElement && (
        <CardRow>
          <Button
            customRender={(
              <ButtonWithIconContainer>
                <PlusIcon />
                {lang.addCompanyOwnerLabel}
              </ButtonWithIconContainer>
            )}
            name="addOwner"
            type="button"
            variant="primary"
            onClick={handleAddOwner}
          />
        </CardRow>
      )}
    </Card>
  );
};

export default CompanyOwner;
