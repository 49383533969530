import { CompanyOwnerDTO } from 'domains/accountSetup/onboarding/company/types';
import ContractorContractView from 'domains/project/addProject/contract/subcontractor/contractView/contractorContractView';
import SendContractView from 'domains/project/addProject/contract/subcontractor/contractView/sendContractView';
import SubcontractorContractView from 'domains/project/addProject/contract/subcontractor/contractView/subcontractorContractView';
import { UserDataDTO } from 'domains/project/addProject/details/userSearchAndDisplayCard/types';
import { PreloadedDataType, SubcontractorDTO } from 'domains/project/addProject/types';
import { getAuthInfo } from 'utils/helpers';

type ContractViewProps = {
  subcontractorData: SubcontractorDTO,
  subcontractorDetails: UserDataDTO;
  triggerDataUpdate: () => void;
  projectData: PreloadedDataType;
}

const ContractView = ({
  subcontractorData,
  triggerDataUpdate,
  subcontractorDetails,
  projectData,
}: ContractViewProps) => {
  const {
    owner,
    userId,
  } = getAuthInfo();
  const isSubcontractor = userId !== projectData?.contractorId;
  const subcontractorContract = (subcontractorData?.subcontractorContract?.[0] as any);
  const isContractSentByContractor = !!subcontractorContract?.contractorSentAt;
  const isContractSentBySubcontractor = !!subcontractorContract?.subcontractorSentAt;

  if ((isContractSentByContractor && !isSubcontractor) || isContractSentBySubcontractor) {
    return (
      <SendContractView
        subcontractorDetails={subcontractorDetails}
        subcontractorData={subcontractorContract}
        owner={owner as CompanyOwnerDTO}
        projectData={projectData}
        triggerDataUpdate={triggerDataUpdate}
      />
    );
  }
  if (isSubcontractor) {
    return (
      <SubcontractorContractView
        triggerDataUpdate={triggerDataUpdate}
        owner={owner as CompanyOwnerDTO}
        subcontractorData={subcontractorContract}
        subcontractorDetails={subcontractorDetails}
      />
    );
  }
  return (
    <ContractorContractView
      subcontractorDetails={subcontractorDetails}
      owner={owner as CompanyOwnerDTO}
      projectData={projectData}
      subcontractorData={subcontractorContract}
      triggerDataUpdate={triggerDataUpdate}
    />
  );
};

export default ContractView;
