import * as Yup from 'yup';
import lang from 'lang/en';
import { notAllowSpecialCharcters } from 'utils/regex';
import { ownerValidationSchema } from '../validationSchema';

export const companyDetailsValidationSchema = Yup.object().shape({
  name: Yup.string()
    .nullable()
    .typeError(lang.missingInformationLabel)
    .required(lang.missingInformationLabel),
  registrationNumber: Yup.number()
    .nullable()
    .typeError(lang.missingInformationLabel)
    .required(lang.missingInformationLabel),
  ein: Yup.number()
    .nullable()
    .typeError(lang.missingInformationLabel)
    .required(lang.missingInformationLabel),
  typeOfContractor: Yup.string()
    .nullable(),
});

export const companyDataValidationSchema = Yup.object().shape({
  details: companyDetailsValidationSchema,
  owners: Yup.array().of(ownerValidationSchema),
});

export const subscriptionValidation = Yup.object().shape({
  name: Yup.string()
    .required(lang.fieldRequired)
    .matches(notAllowSpecialCharcters, lang.incorrectCharactersText).nullable(),
  planType: Yup.string().required(lang.subscriptionPlanError).nullable(),
  refferalCode: Yup.string().max(10).matches(notAllowSpecialCharcters, lang.incorrectCharactersText).nullable(),
});

export const licenseValidation = Yup.object().shape({
  licenseNumber: Yup.string().required(lang.fieldRequired).matches(notAllowSpecialCharcters, lang.incorrectCharactersText).nullable(),
  expiryDate: Yup.string().required(lang.fieldRequired).nullable(),
});
