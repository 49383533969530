/* eslint-disable react/jsx-closing-tag-location */
import { ReactComponent as PlusIcon } from 'assets/images/plus-icon.svg';
import lang from 'lang/en';
import { NavLink, useNavigate } from 'react-router-dom';
import { DataTable } from 'common/dataTable';
import { colors } from 'designSystem';
import { Line } from 'rc-progress';
import { getTrimmedString, isContractor } from 'utils/helpers';
import { CircleButton, Button } from 'designSystem/button';
import { ListingEmptyState } from './listingEmptyState';
import { mobileViewColumns } from './data';
import {
  MobileCreateProject, ProjectTitle, MobileListing, ProjectSection, ProjectName, ProjectStatus,
  StatuSpan, StatusText, ProgressLine, ProgressPercentage, UpdateCTA,
} from '../styles';
import type { ProjectListDataType } from '../types';

export const MoblieListing = ({ projectListData }: ProjectListDataType) => {
  const navigate = useNavigate();
  const { linkProjects, updateText } = lang;

  const getListing = () => {
    if (projectListData) {
      const listing = projectListData.map(({
        status, name, progress, id,
      }: any) => {
        return {
          projectData: <ProjectSection>
            <ProjectStatus>
              <NavLink to={`/projects/view/details/${id}`}><ProjectName>{getTrimmedString(name, 12)}</ProjectName></NavLink>
              <StatuSpan status={status}><StatusText status={status}>{status}</StatusText></StatuSpan>
            </ProjectStatus>
            <ProgressLine>
              <Line
                percent={progress}
                strokeWidth={3}
                strokeColor={colors.green500}
                trailColor={colors.blue100}
                trailWidth={3}
              />
              <ProgressPercentage>
                {progress}
                                &nbsp;%
              </ProgressPercentage>
            </ProgressLine>
          </ProjectSection>,
          milestoneAction: <UpdateCTA>
            <Button
              type="button"
              label={updateText}
              variant="secondary"
            />
          </UpdateCTA>,
        };
      });
      return listing;
    }
  };
  return (
    <>
      <MobileCreateProject>
        {isContractor()
           && (
             <>
               <ProjectTitle>{linkProjects}</ProjectTitle>
               <CircleButton icon={<PlusIcon />} size="medium" onClick={() => navigate('/projects/add')} />
             </>
           )}
      </MobileCreateProject>
      {projectListData !== null && projectListData.length === 0
            && <ListingEmptyState />}
      {projectListData !== null && projectListData.length > 0
            && (
              <MobileListing>
                <DataTable columns={mobileViewColumns} data={getListing() || []} isTransparentBg bodyPadding="12px 0" />
              </MobileListing>
            )}
    </>
  );
};
