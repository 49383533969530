import { useCallback, useState } from 'react';
import { apiClient } from 'utils/network/apiClient';
import { notifications } from 'designSystem/toastNotifications/toastNotificationEmitter';
import lang from 'lang/en';
import { clearAuthInfo } from 'utils/helpers';
import { useNavigate } from 'react-router-dom';
import type { GetResponseType } from './types';

export const useGetData = <DataResponse>(): GetResponseType<DataResponse> => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [response, setResponse] = useState<DataResponse | null>(null);

  const getData = useCallback(async (url: string) => {
    setLoading(true);
    setError('');
    setResponse(null);
    try {
      const callResponse = await apiClient.get(url);
      setLoading(false);
      if (callResponse.status === 200) {
        setResponse(callResponse.data);
      } else {
        if ((callResponse as any).isNotAuthenticated) {
          clearAuthInfo();
          notifications.error(lang.sessionExpiredMessage);
          navigate('/signin');
          return;
        }
        notifications.error(lang.unknownErrorTryLater);
      }
    } catch (callError: unknown) {
      setError((callError as Error)?.message || lang.accountActivationFailedMessage);
    }

    setLoading(false);
  }, []);
  return {
    getData,
    response,
    loading,
    error,
  };
};
