import { useCallback, useEffect } from 'react';
import { notifications } from 'designSystem/toastNotifications/toastNotificationEmitter';
import { projectBaseUrl } from 'utils/network/endpoints';
import { useGetData } from 'utils/form/useGetData';
import type { ListingResponseType } from '../types';

export const useGetProjectListing = () : {
  response: ListingResponseType | null
  getProjectListing: () => void;
} => {
  const {
    getData,
    error,
    response,
  } = useGetData<ListingResponseType>();

  useEffect(() => {
    if (error) {
      notifications.error(error);
    }
  }, [error]);

  const getProjectListing = useCallback(async () => {
    const url = `${projectBaseUrl}?limit=300`;
    await getData(url);
  }, []);
  return {
    response,
    getProjectListing,
  };
};
