import { Card } from 'domains/accountSetup/onboarding/styles';
import { ListFiles } from 'designSystem/fileUpload/ListFiles';
import lang from 'lang/en';
import { useWindowDimensions } from 'hooks/useWindowDimensions';
import { CustomCardTitle } from 'domains/project/addProject/details/userSearchAndDisplayCard/styles';
import { NoDataFoundText } from 'domains/project/addProject/styles';

export const ListBlueprints = ({ documents } : {
  documents: Array<{fileName: string; documentUrl: string}>
}) => {
  const { screenSize } = useWindowDimensions();
  return (
    <Card screenSize={screenSize}>
      { documents.length !== 0
        ? (
          <>
            <CustomCardTitle>
              {`${lang.blueprint.titleLabel}`}
            </CustomCardTitle>
            {' '}
            <ListFiles documents={documents} />
          </>
        )
        : <NoDataFoundText>{lang.noBlueprintsFound}</NoDataFoundText>}
    </Card>
  );
};
