import { useCallback, useState } from 'react';
import { projectBaseUrl } from 'utils/network/endpoints';
import { notifications } from 'designSystem/toastNotifications/toastNotificationEmitter';
import { apiClient } from 'utils/network/apiClient';
import lang from 'lang/en';
import { clearAuthInfo } from 'utils/helpers';
import { useNavigate } from 'react-router-dom';
import { ProjectDetailsDTO } from './types';

export const useGetProjectData = (): {
  getProjectData: (projectId: number) => Promise<ProjectDetailsDTO>;
  loading: boolean
} => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const getProjectData = useCallback(
    async (projectId: number) => {
      setLoading(true);
      const url = `${projectBaseUrl}/${projectId}`;
      try {
        const callResponse = await apiClient.get(url);
        setLoading(false);
        if (callResponse.status === 200) {
          return callResponse.data.data;
        } else if (callResponse.status === 404) {
          notifications.error(lang.projectNotFound);
          navigate('/projects');
        } else {
          if ((callResponse as any).isNotAuthenticated) {
            clearAuthInfo();
            notifications.error(lang.sessionExpiredMessage);
            navigate('/signin');
            return;
          }
          notifications.error(lang.unknownErrorTryLater);
        }
      } catch (callError: unknown) {
        notifications.error((callError as Error)?.message || lang.accountActivationFailedMessage);
      }

      setLoading(false);
    },
    [apiClient],
  );

  return {
    getProjectData,
    loading,
  };
};
