import { ReactComponent as Logo } from 'assets/images/logo.svg';
import { Button } from 'designSystem';
import { CardSection } from 'designSystem/card/styles';
import lang from 'lang/en';
import { useNavigate } from 'react-router-dom';
import {
  Container, Heading, PlainText, TextSection, ButtonSpacer,
} from './styles';

const AccountConfirmation = () => {
  const {
    thankyou, okay, accountConfirmationFirstText, accountConfirmationSecondText,
  } = lang;
  const navigate = useNavigate();

  const handleOkClick = () => {
    navigate('/signin');
  };

  return (
    <Container>
      <Logo height="72px" />
      <Heading>{thankyou}</Heading>
      <CardSection maxWidth="448px">
        <TextSection>
          <PlainText>{accountConfirmationFirstText}</PlainText>
          <PlainText>{accountConfirmationSecondText}</PlainText>
        </TextSection>
      </CardSection>
      <ButtonSpacer />
      <Button variant="primary" label={okay} type="button" onClick={handleOkClick} />
    </Container>
  );
};

export default AccountConfirmation;
