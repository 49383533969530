import { Controller } from 'react-hook-form';
import { ReactElement } from 'react';
import RadioButton from './radioButton';
import type { ControlledRadioButtonProps } from './types';

export const ControlledRadioButton = ({
  name, labelText, control, ...propsToPass
}: ControlledRadioButtonProps):ReactElement => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => {
        const { error } = fieldState;
        return <RadioButton {...propsToPass} field={field} labelText={labelText} errorMessage={error?.message} name={name} />;
      }}
    />
  );
};

export default ControlledRadioButton;
