import { css } from 'styled-components';

export const textXSm = css`
  font-size: 12px;
  line-height: 16px;
  font-style: normal;
  font-weight: 500;
`;

export const textSm = css`
  font-size: 14px;
  line-height: 16px;
  font-style: normal;
  font-weight: 400;
`;

export const textBase = css`
  font-size: 16px;
  line-height: 24px;
  font-style: normal;
  font-weight: 500;
`;

export const textBaseNormal = css`
  font-size: 16px;
  line-height: 24px;
  font-style: normal;
  font-weight: 400;
`;

export const textLg = css`
  font-size: 18px;
  line-height: 24px;
  font-weight: 700;
`;

export const textSLg = css`
  font-size: 20px;
  line-height: 28px;
  font-weight: 700;
`;

export const textXLg = css`
  font-size: 1.875rem;
  line-height: 2.5rem;
  font-weight: 700;
`;

const typings = {
  textXSm,
  textSm,
  textBase,
  textLg,
  textXLg,
  textBaseNormal,
  textSLg,
};

export default typings;
