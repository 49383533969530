import { CardNumberElement, CardExpiryElement, CardCvcElement } from '@stripe/react-stripe-js';
import {
  CardInput, PaymentContainer, InputLabel, IconSpan, ErrorMessage, ErrorText,
} from 'designSystem/input/styles';
import { ReactComponent as CrossIcon } from 'assets/images/errorIcon.svg';
import { ReactElement } from 'react';
import type { LabelledPaymentFieldProps } from './types';

export const PaymentField = ({
  labelText, field, onChange, errorMessage = '',
}: LabelledPaymentFieldProps) => {
  const getPaymentElement = (name: string): ReactElement => {
    switch (name) {
      case 'cardNumber':
        return <CardNumberElement onChange={onChange} />;
      case 'cardExpiry':
        return <CardExpiryElement onChange={onChange} />;
      case 'cardCvc':
        return <CardCvcElement onChange={onChange} />;
      default:
        return <CardNumberElement onChange={onChange} />;
    }
  };
  return (
    <PaymentContainer>
      <InputLabel htmlFor={field.name}>{labelText}</InputLabel>
      <CardInput $isError={errorMessage.length > 0} data-testid="test-input">
        {getPaymentElement(field.name)}
      </CardInput>
      {errorMessage.length > 0 && <IconSpan><CrossIcon /></IconSpan>}
      {errorMessage !== ''
      && <ErrorMessage><ErrorText>{errorMessage}</ErrorText></ErrorMessage>}
    </PaymentContainer>
  );
};
