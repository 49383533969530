import { Button } from 'designSystem';
import { useWindowDimensions } from 'hooks/useWindowDimensions';
import { ControlledInputField, ControlledDatepicker } from 'designSystem/input';
import lang from 'lang/en';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import {
  dateStringFormat, defaultDateFormat, defaultDateFormatString, dateFormatStartWithYear,
} from 'utils/dateFormats';
import { getButtonState } from 'utils/helpers';
import { useEffect, useState } from 'react';
import { useSaveLicenseData } from './useSaveLicenseData';
import { licenseValidation } from '../validationSchema';
import type { CompanyDataDTO } from '../types';
import {
  ActionButtonsContainer, Card, CardContainer, CardTitle,
} from '../styles';
import { FieldCol, FormSection } from './styles';

type FormDataType = {
  licenseNumber: string;
  expiryDate: string;
}

export const License = ({ onFormSubmitted, setPreviousStep, onboardingDetails } : {
  onFormSubmitted: () => void;
  setPreviousStep: () => void;
  onboardingDetails?: CompanyDataDTO;
}) => {
  const [selectedDate, setSelectedDate] = useState<any>(null);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const resolver = { resolver: yupResolver(licenseValidation) };
  const {
    loading, saveLicense, error, isSaved,
  } = useSaveLicenseData();

  const {
    handleSubmit, control, setValue, formState, reset, trigger,
  } = useForm({ ...resolver, mode: 'onChange', defaultValues: { licenseNumber: '', expiryDate: '' } });
  const { screenSize } = useWindowDimensions();
  const {
    licenseTitle, licenseNumber, licenseDatePlaceholder, licenseDateLabel,
  } = lang;

  const { isDirty, isValid } = formState;

  useEffect(() => {
    if (isSaved && !error) {
      onFormSubmitted();
    }
  }, [isSaved, error]);

  useEffect(() => {
    if (onboardingDetails && onboardingDetails.license.length > 0) {
      reset({ licenseNumber: onboardingDetails.license[0].licenseNumber, expiryDate: dateStringFormat(onboardingDetails.license[0].expiryDate, defaultDateFormatString) });
      setIsDataLoaded(true);
      setSelectedDate(defaultDateFormat(onboardingDetails.license[0].expiryDate));
      trigger();
    }
  }, [onboardingDetails]);

  const submitForm = (data: FormDataType) => {
    if (onboardingDetails) {
      const { id = '' } = onboardingDetails;
      saveLicense({ licenseNumber: data.licenseNumber, expiryDate: dateStringFormat(selectedDate, dateFormatStartWithYear) }, id);
    }
  };

  const handleExpiryDate = (date: Date | string) => {
    if (date) {
      setSelectedDate(defaultDateFormat(date));
      setValue('expiryDate', dateStringFormat(date, defaultDateFormatString), { shouldValidate: true });
    }
  };

  return (
    <Card screenSize={screenSize}>
      <FormSection onSubmit={handleSubmit(submitForm)}>
        <CardContainer screenSize={screenSize}>
          <CardTitle>{licenseTitle}</CardTitle>
          <FieldCol>
            <ControlledInputField
              control={control}
              labelText={licenseNumber}
              name="licenseNumber"
            />
            <ControlledDatepicker
              selectedDate={selectedDate}
              control={control}
              name="expiryDate"
              labelText={licenseDateLabel}
              placeholderText={licenseDatePlaceholder}
              setFieldValue={(date) => { handleExpiryDate(date); }}
            />
          </FieldCol>
        </CardContainer>
        <ActionButtonsContainer>
          <Button type="button" variant="secondary" label={lang.backLabel} state="active" onClick={setPreviousStep} />
          <Button type="submit" variant="primary" label={lang.nextLabel} state={getButtonState(isDirty || isDataLoaded, isValid, loading)} />
        </ActionButtonsContainer>
      </FormSection>
    </Card>
  );
};
