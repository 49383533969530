import { useCallback, useEffect } from 'react';
import { useSaveForm } from 'utils/form/useSaveForm';
import { projectBaseUrl } from 'utils/network/endpoints';
import { notifications } from 'designSystem/toastNotifications/toastNotificationEmitter';
import { ProjectDetails, ProjectDetailsCreateDTO } from './types';

export const useSaveProjectDetailsData = (): {
  saveProjectDetailsData: (data: ProjectDetails) => Promise<void>;
  updateProjectDetailsData: (data: ProjectDetails) => Promise<void>;
  loading: boolean;
  isSaved: boolean;
  error: string;
  response: any;
} => {
  const {
    loading, error, save, isSaved, response,
  } = useSaveForm<ProjectDetailsCreateDTO>();

  useEffect(() => {
    if (error) {
      notifications.error(error);
    }
  }, [error]);

  const saveProjectDetailsData = useCallback(
    async (data: ProjectDetails) => {
      const url = projectBaseUrl;
      const {
        project, cost, escrow, ...rest
      } = data;

      const flattenData = {
        ...project, cost, escrow, ...rest,
      } as ProjectDetailsCreateDTO;
      await save(url, flattenData, 'post');
    },
    [save],
  );

  const updateProjectDetailsData = useCallback(
    async (data: ProjectDetails) => {
      const url = `${projectBaseUrl}/${data.id}`;
      const {
        project, cost, escrow, ...rest
      } = data;

      const flattenData = {
        ...project, cost, escrow, ...rest,
      } as ProjectDetailsCreateDTO;
      await save(url, flattenData, 'patch');
    },
    [save],
  );

  return {
    loading,
    error,
    saveProjectDetailsData,
    updateProjectDetailsData,
    isSaved,
    response: response?.data?.data,
  };
};
