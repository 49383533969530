import styled from 'styled-components';
import type { CircleButtonSize, ButtonState } from './types';
import { colors, typings } from '..';

type CircleStyledButtonProps = {
  state: ButtonState;
  size: CircleButtonSize;
}

type StyledButtonProps = {
  state: ButtonState;
  width?: 'auto' | 'full';
}

const getSize = (size: CircleButtonSize) => {
  switch (size) {
    case 'small':
      return '30px';
    case 'large':
      return '50px';
    case 'medium':
    default:
      return '42px';
  }
};

const getIconSize = (size: CircleButtonSize) => {
  switch (size) {
    case 'small':
      return '10px';
    case 'medium':
    case 'large':
    default:
      return '12px';
  }
};

const backgroundColorForState = (state: ButtonState) => {
  switch (state) {
    case 'active':
      return colors.blue700;
    case 'disabled':
      return colors.blueGrey;
    case 'loading':
      return colors.blue300;
    default:
      return colors.blue700;
  }
};

const textColorForState = (state: ButtonState) => {
  switch (state) {
    case 'active':
      return colors.blue700;
    case 'disabled':
      return colors.blueGrey;
    case 'loading':
      return colors.blue400;
    default:
      return colors.blue700;
  }
};

export const LoaderButtonInnerComponent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  svg {
    margin-right: 9px;
    width: 16px;
    height: 16px;
  }
`;

const BaseButton = styled.button<StyledButtonProps>`
  ${typings.textSm}
  line-height: 20px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 9px 17px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border: 0;
  border-radius: 6px;

  height: 38px;
  width: ${(props) => props.width === 'full' ? '100%' : 'auto'};
`;

export const PrimaryButton = styled(BaseButton)<StyledButtonProps>`
  background: ${(props) => backgroundColorForState(props.state)};
  color: ${colors.white};

  ${LoaderButtonInnerComponent} {
    svg {
      stroke: ${colors.white};
    }
  }

  &:hover {
    background-color: ${colors.blue800};
  }

  &:active {
    background-color: ${colors.blue900};
  }

  &:disabled {
    background-color: ${colors.blueGrey};
  }
`;

export const SecondaryButton = styled(BaseButton)<StyledButtonProps>`
  background: ${colors.white};
  color: ${(props) => textColorForState(props.state)};

  border: 1px solid ${(props) => textColorForState(props.state)};

  ${LoaderButtonInnerComponent} {
    svg {
      stroke: ${colors.blue400};
    }
  }

  &:hover {
    background-color: ${colors.blue50};
  }

  &:active {
    background-color: ${colors.blue200};
  }

  &:disabled {
    background-color: ${colors.white};
  }
`;

export const DangerButton = styled(BaseButton)<StyledButtonProps>`
  background: ${colors.red500};
  color: ${colors.white};

  border: 1px solid ${colors.red500};

  ${LoaderButtonInnerComponent} {
    svg {
      stroke: ${colors.blue400};
    }
  }

  &:hover {
    background-color: ${colors.red700};
  }

  &:active {
    background-color: ${colors.red900};
  }

  &:disabled {
    background-color: ${colors.white};
  }
`;

export const CircleButton = styled(PrimaryButton)<CircleStyledButtonProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  
  width: ${(props) => getSize(props.size)};
  height: ${(props) => getSize(props.size)};
  padding: 0;
  border-radius: 50%;
  background: ${(props) => backgroundColorForState(props.state)};
  
  img {
    width: ${(props) => getIconSize(props.size)};
    height: ${(props) => getIconSize(props.size)};
  }
`;

export const ButtonWithIconContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  
  & > svg {
    margin-right: 9px;
  }
`;
