import lang from 'lang/en';
import { ErrorBoundary } from 'utils/errorBoundary';
import { Link } from 'react-router-dom';
import { useForm, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ControlledInputField, ControlledCheckbox, ControlledPasswordField } from 'designSystem/input';
import { Button } from 'designSystem';
import { getButtonState } from 'utils/helpers';
import HeaderWithLogo from 'designSystem/header/headerWithLogo';
import { CardSection } from 'designSystem/card/styles';
import { useWindowDimensions } from 'hooks/useWindowDimensions';
import {
  Centerlized, Wrapper,
  FormContainer, BottomHeading, BottomSection,
  LinkText, Form, CheckBoxContent, TermsAndCondition,
  ButtonSpacer,
} from '../styles';
import type { RegisterFormData } from '../types';
import { signupValidationSchema } from '../validationSchema';
import useSignup from './useSignup';

const Signup = () => {
  const resolver = { resolver: yupResolver(signupValidationSchema) };
  const { handleSubmit, control, formState: { isDirty, isValid } } = useForm({ ...resolver, mode: 'onChange' });

  const {
    loading,
    signUp,
  } = useSignup();
  const {
    signupHeading, signupBottomHeading, signInText,
    email, password, agreeText, termsAndConditionsText, andText, privacyPolicy,
  } = lang;
  const { screenSize } = useWindowDimensions();

  const onSubmit: SubmitHandler<RegisterFormData> = (data) => {
    signUp(data);
  };

  return (
    <Centerlized>
      <ErrorBoundary>
        <Wrapper>
          <HeaderWithLogo text={signupHeading} />
          <FormContainer>
            <CardSection screenSize={screenSize} maxWidth="448px" minHeight="400px">
              <Form screenSize={screenSize} onSubmit={handleSubmit(onSubmit)}>
                <ControlledInputField
                  name="email"
                  control={control}
                  type="text"
                  labelText={email}
                  errorIconPosition={screenSize === 'mobile' ? '91%' : '296px'}
                />
                <ControlledPasswordField
                  name="password"
                  control={control}
                  labelText={password}
                  showValidationSchema
                />
                <ControlledCheckbox
                  name="termsAndConditions"
                  control={control}
                  labelText={(
                    <CheckBoxContent>
                      <TermsAndCondition>
                        {agreeText}
                       &nbsp;
                        <a href="https://www.thetradesmens.com/terms-and-conditions" target="_blank"><LinkText>{termsAndConditionsText}</LinkText></a>
                      &nbsp;
                        {andText}
                      &nbsp;
                        <a href="https://www.thetradesmens.com/privacy-policy" target="_blank"><LinkText>{privacyPolicy}</LinkText></a>
                      </TermsAndCondition>
                    </CheckBoxContent>
                  )}
                />
                <ButtonSpacer />
                <Button
                  variant="primary"
                  state={getButtonState(isDirty, isValid, loading)}
                  label={lang.createAccountLabel}
                  type="submit"
                />
              </Form>
            </CardSection>
          </FormContainer>
          <BottomSection>
            <BottomHeading>{signupBottomHeading}</BottomHeading>
            <Link to="/signin">
              <LinkText>
                {signInText}
              </LinkText>
            </Link>
          </BottomSection>
        </Wrapper>
      </ErrorBoundary>
    </Centerlized>
  );
};

export default Signup;
