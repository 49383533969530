import { AxiosResponse } from 'axios';
import { notifications } from 'designSystem/toastNotifications/toastNotificationEmitter';
import lang from 'lang/en';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSaveForm } from 'utils/form/useSaveForm';
import { clearAuthInfo, setAuthInfo } from 'utils/helpers';
import { signInUrl } from 'utils/network/endpoints';
import { UserFormDTO } from '../types';

const useSignin = (): {
  signIn: (data: UserFormDTO) => Promise<void>;
  loading: boolean;
  isSaved: boolean;
  error: string;
} => {
  const {
    loading,
    error,
    save,
    isSaved,
    response,
  } = useSaveForm<UserFormDTO>();
  const [localError, setLocalError] = useState(error);
  const navigate = useNavigate();

  useEffect(() => {
    setLocalError(error);
  }, [error]);

  useEffect(() => {
    if (localError) {
      notifications.error(localError);
    }
  }, [localError]);

  useEffect(() => {
    if (response && !error) {
      if (response.data.user.accountStatus === 'notConfirmed') {
        setLocalError(lang.confirmEmailFirstText);
        clearAuthInfo();
        return;
      }

      const authUser = getAuthUserData(response);
      setAuthInfo(authUser);
      authUser.accountStatus === 'active'
        ? navigate('/projects')
        : navigate('/account-setup');

      notifications.success('Logged in successfully');
    }
  }, [response]);

  const signIn = useCallback(
    async (data: UserFormDTO) => {
      const url = signInUrl;

      setLocalError('');
      await save(url, data, 'post');
    },
    [save],
  );

  return {
    loading,
    error,
    signIn,
    isSaved,
  };
};

export default useSignin;

const getAuthUserData = (response: AxiosResponse) => {
  return {
    userId: response.data.user.id,
    user: response.data.user.name,
    ...response?.data.tokens,
    accountStatus: response.data.user.accountStatus,
    accountType: response.data.user.type,
    owner: response.data.user?.company?.owners[0],
  };
};
