import * as Yup from 'yup';
import { otherStateSchema, defaultSchema } from './manageValidationSchema';

export const hicValidationSchema = (stateName?: string) => {
  let schema = { ...defaultSchema };
  if (stateName && otherStateSchema[stateName]) {
    schema = { ...schema, ...otherStateSchema[stateName] };
  }
  return Yup.object().shape({ ...schema });
};
