import axios from 'axios';
import handleErrors from './apiErrorHandler';
import {
  RequestHandlerType, ResponseHandlerType,
} from './types';

export const defaultHeaders = {};

export const apiClient = axios.create({
  headers: defaultHeaders,
  withCredentials: true,
});

const requestHandler: RequestHandlerType = (request) => {
  return request;
};

const responseHandler: ResponseHandlerType = (response) => {
  return response;
};

apiClient.interceptors.request.use(requestHandler, handleErrors);
apiClient.interceptors.response.use(responseHandler, handleErrors);
