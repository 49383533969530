import { Control } from 'react-hook-form';
import lang from 'lang/en';
import { useWindowDimensions } from 'hooks/useWindowDimensions';
import {
  CardRow, CardTitle, Card,
} from 'domains/accountSetup/onboarding/styles';
import { NarrowControlledNumberMaskedInput } from 'domains/project/addProject/styles';

export const TotalMilestoneCost = ({ control }: {
  control: Control;
}) => {
  const { screenSize } = useWindowDimensions();
  return (
    <Card screenSize={screenSize}>
      <CardTitle>{lang.milestones.contractorTotalMilestone}</CardTitle>
      <CardRow>
        <NarrowControlledNumberMaskedInput
          numberPrefix="$"
          placeholder="$ 0.00"
          labelText={lang.milestones.contractorTotalCost}
          control={control}
          name="totalCost"
        />
      </CardRow>
    </Card>
  );
};
