import styled from 'styled-components';
import {
  red300, gray300, blue700, white,
  red500, gray100, black, disabledColor,
} from 'designSystem/colors';
import { textXSm } from 'designSystem/typings';
import type { InputStyleProps } from '../types';

export const RadioBoxContainer = styled.div``;

export const RadioBoxLabel = styled.label`
  width: 216px;
  background: ${white};
  border: none;
  text-align: center;
  display: flex;
  flex-direction: column;
`;

export const RadioBoxBtnSection = styled.div<InputStyleProps>`
  display: flex;
  width: inherit;
  height: 48px;
  background: ${gray100};
  justify-content: center;
  align-items: center;
  border: ${({ $isError }) => $isError ? `1px solid ${red500}` : 'none'};
`;

export const RadioBoxContentSection = styled.div<InputStyleProps>`
  padding: 13px;
  border: ${({ $isError }) => $isError ? `1px solid ${red300}` : `1px solid ${gray300}`};
  width: inherit;
  height: 297px;
`;

export const RadioBoxSpan = styled.span<InputStyleProps>`
  height: 18px;
  width: 18px;
  background-color: ${white};
  border-radius: 50%;
  border: ${({ $isError }) => $isError ? `1px solid ${red500}` : `1px solid ${gray300}`};
  &:after {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: ${white};
    margin: 4px;
    content: "";
    position: absolute;
    display: none;
}
`;

export const RadioBoxInput = styled.input<InputStyleProps>`
 display: none;
  &:not(:disabled) ~ label {
    cursor: pointer;
  }
  &:checked + ${RadioBoxLabel} { 
    border: 1px solid ${blue700};
  }

  &:checked + ${RadioBoxLabel} ${RadioBoxBtnSection} {    
    background: ${blue700};
  }

  &:checked + ${RadioBoxLabel} ${RadioBoxSpan} {
    background-color: ${blue700};
    border: 2px solid ${white};
  }

  :checked + ${RadioBoxLabel} ${RadioBoxSpan}:after {
    display: block;
  }
}
`;

export const RadioButtonContainer = styled.div`
`;

export const RadioButtonText = styled.p`
 ${textXSm}
 color: ${black};
 font-weight: 400;
 margin-bottom: 8px;
`;

export const RadioButtonLabel = styled.label`
  display: flex;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
`;

export const RadioButtonSpan = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  height: 16px;
  width: 16px;
  background-color: ${white};
  border: 1px solid ${gray300};
  border-radius: 50%;

  &:after {
    content: "";
    position: absolute;
    display: none;
    top: 5px;
    left: 5px;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: ${white};
  }
`;

export const RadioButtonInput = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  &:checked + ${RadioButtonSpan} {
    background-color: ${blue700};
    border: none;

    &:after {
      display: block;
    }
  }

  &:checked {
    &:disabled  + ${RadioButtonSpan} {
      background-color: ${disabledColor};
    }   
  }
`;
