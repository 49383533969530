import lang from 'lang/en';
import { useWindowDimensions } from 'hooks/useWindowDimensions';
import { Card } from 'domains/accountSetup/onboarding/styles';
import { MaxWidthContainer } from 'domains/project/addProject/contract/styles';
import { RadioOptionFields } from '../../../common/radioOptionFields';
import { HomeSolicitation } from './homesolicitation';
import { JobExceeds } from './jobExceeds';
import type { FloridaStateTypes } from './types';

export const FloridaStateForm = ({
  control, setValue, getValues, errors, clearErrors,
} : FloridaStateTypes) => {
  const { screenSize } = useWindowDimensions();
  const { contract: { homeowner: { modal: { form: { floridaFields } } } } } = lang;

  return (
    <>
      <Card screenSize={screenSize}>
        <RadioOptionFields
          control={control}
          fieldName="extraData.workOnThePrincipalResidence"
          fieldText={floridaFields.principleResidence}
          setValue={setValue}
          errorMsg={errors?.extraData?.workOnThePrincipalResidence?.message || ''}
          clearErrors={clearErrors}
          getValues={getValues}
        />
      </Card>
      <Card screenSize={screenSize}>
        <RadioOptionFields
          control={control}
          fieldName="extraData.threeDayRight"
          fieldText={floridaFields.rightToRescind}
          setValue={setValue}
          errorMsg={errors?.extraData?.threeDayRight?.message || ''}
          clearErrors={clearErrors}
          getValues={getValues}
        />
      </Card>
      <Card screenSize={screenSize}>
        <RadioOptionFields
          control={control}
          fieldName="extraData.miamiDadeCountry"
          fieldText={floridaFields.miamiWorkDone}
          setValue={setValue}
          errorMsg={errors?.extraData?.miamiDadeCountry?.message || ''}
          clearErrors={clearErrors}
          getValues={getValues}
        />
      </Card>
      <Card screenSize={screenSize}>
        <JobExceeds control={control} setValue={setValue} errors={errors} clearErrors={clearErrors} getValues={getValues} />
      </Card>
      <Card screenSize={screenSize}>
        <HomeSolicitation control={control} setValue={setValue} errors={errors} clearErrors={clearErrors} getValues={getValues} />
      </Card>
      <Card screenSize={screenSize}>
        <RadioOptionFields
          control={control}
          fieldName="extraData.isAmountGreaterThan2500"
          fieldText={floridaFields.contractAmount}
          setValue={setValue}
          errorMsg={errors?.extraData?.isAmountGreaterThan2500?.message || ''}
          clearErrors={clearErrors}
          getValues={getValues}
        />
      </Card>
      <Card screenSize={screenSize}>
        <MaxWidthContainer>
          <RadioOptionFields
            control={control}
            fieldName="extraData.isContractForConstructionRapairAndImprovement"
            fieldText={floridaFields.contractForConstructionRapairAndImprovement}
            setValue={setValue}
            errorMsg={errors?.extraData?.isContractForConstructionRapairAndImprovement?.message || ''}
            clearErrors={clearErrors}
            getValues={getValues}
          />
        </MaxWidthContainer>
      </Card>
      <Card screenSize={screenSize}>
        <RadioOptionFields
          control={control}
          fieldName="extraData.isContractForRepairRestorationAndImprovement"
          fieldText={floridaFields.contractForRepairRestorationAndImprovement}
          setValue={setValue}
          errorMsg={errors?.extraData?.isContractForRepairRestorationAndImprovement?.message || ''}
          clearErrors={clearErrors}
          getValues={getValues}
        />
      </Card>
      <Card screenSize={screenSize}>
        <RadioOptionFields
          control={control}
          fieldName="extraData.isContractWaivedFloridaNoticeAndRight"
          fieldText={floridaFields.contractWaivedFloridaNoticeAndRight}
          setValue={setValue}
          errorMsg={errors?.extraData?.isContractWaivedFloridaNoticeAndRight?.message || ''}
          clearErrors={clearErrors}
          getValues={getValues}
        />
      </Card>
    </>
  );
};
