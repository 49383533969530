import lang from 'lang/en';
import * as Yup from 'yup';

export const ChangeOrderValidationSchema = Yup.object().shape({
  additionalHomeOwner: Yup.string().nullable(),
  descriptionOfWork: Yup.string().nullable().required(lang.missingInformationLabel),
  additionalTime: Yup.string().nullable().required(lang.missingInformationLabel),
  priceOfWork: Yup.number().transform((v, o) => o === '' ? null : v).nullable().required(lang.missingInformationLabel),
  netChange: Yup.number().transform((v, o) => o === '' ? null : v).nullable().required(lang.missingInformationLabel),
  grossContractTotalPrior: Yup.number().transform((v, o) => o === '' ? null : v).nullable().required(lang.missingInformationLabel),
  grossContractTotal: Yup.number().transform((v, o) => o === '' ? null : v).nullable().required(lang.missingInformationLabel),
  fileName: Yup.string().nullable().required(lang.missingInformationLabel),
});
