import lang from 'lang/en';
import * as Yup from 'yup';

export const schema = {
  workOnThePrincipalResidence: Yup.boolean().nullable().required(lang.missingInformationLabel),
  threeDayRight: Yup.boolean().nullable().required(lang.missingInformationLabel),
};

export const delawareStateFieldSchema = {
  extraData: Yup.object().shape(schema),
};
