import * as Yup from 'yup';
import lang from 'lang/en';

const maxCharactersAllowed = 50;

export const ownerValidationSchema = Yup.object().shape({
  firstName: Yup.string()
    .max(maxCharactersAllowed, `${lang.maxCharactersAllowedIs} ${maxCharactersAllowed}`)
    .nullable()
    .required(lang.missingInformationLabel),
  lastName: Yup.string()
    .max(maxCharactersAllowed, `${lang.maxCharactersAllowedIs} ${maxCharactersAllowed}`)
    .nullable()
    .required(lang.missingInformationLabel),
  email: Yup.string()
    .email(lang.emailFormat)
    .nullable()
    .required(lang.missingInformationLabel),
  country: Yup.string()
    .nullable()
    .required(lang.missingInformationLabel),
  streetAddress: Yup.string()
    .nullable()
    .required(lang.missingInformationLabel),
  city: Yup.string()
    .nullable()
    .required(lang.missingInformationLabel),
  state: Yup.string()
    .nullable()
    .required(lang.missingInformationLabel),
  zip: Yup.string()
    .nullable()
    .required(lang.missingInformationLabel),
  phone: Yup.number()
    .test('length', lang.validPhoneNumber, (val?: number) : boolean => {
      if (val) {
        const dataLength = val.toString();
        return dataLength.length === 10;
      }
      return false;
    })
    .nullable()
    .typeError(lang.missingInformationLabel)
    .required(lang.missingInformationLabel),
});
