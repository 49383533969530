import { useCallback, useState } from 'react';
import { setUserUpdateUrl } from 'utils/network/endpoints';
import { notifications } from 'designSystem/toastNotifications/toastNotificationEmitter';
import { apiClient } from 'utils/network/apiClient';
import Lang from 'lang/en';
import { getAuthInfo, setAuthInfo } from 'utils/helpers';

export const useSetUserType = (): {
  setUserType: (userId: string, userType: string) => Promise<void>;
  loading: boolean
} => {
  const [loading, setLoading] = useState(false);

  const verifyEmail = useCallback(
    async (userId: string, userType: string) => {
      setLoading(true);
      const url = setUserUpdateUrl.replace('%userId%', userId);
      try {
        const callResponse = await apiClient.put(url, { type: userType });
        if (callResponse.status === 200) {
          const currentAuthInfo = getAuthInfo();
          setAuthInfo({ ...currentAuthInfo, accountType: callResponse.data.data.type });
          notifications.success(Lang.accountSetTypeSuccessfullMessage);
        } else {
          notifications.error(Lang.unknownErrorTryLater);
        }
      } catch (callError: unknown) {
        notifications.error((callError as Error)?.message || Lang.accountActivationFailedMessage);
      }
      setLoading(false);
    },
    [apiClient],
  );

  return {
    setUserType: verifyEmail,
    loading,
  };
};
