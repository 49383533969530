import { useEffect, useState } from 'react';
import { SubcontractorDataDTO, UserDataDTO } from '../details/userSearchAndDisplayCard/types';
import { useGetUserData } from '../details/userSearchAndDisplayCard/useGetUserData';
import { SubcontractorDTO } from '../types';

export const useGetSubcontractorData = (subContractors: Array<SubcontractorDTO>) => {
  const [subcontractorsDetails, setSubcontractorsDetails] = useState<Array<UserDataDTO>>([]);
  const { getUserDataById: getContractorById } = useGetUserData('contractor');

  useEffect(() => {
    const getSubcontractors = async () => {
      const subcontractorDetailsData: Array<SubcontractorDataDTO> = [];
      if (subContractors) {
        const promises = subContractors.map(async (subcontractor: SubcontractorDTO) => {
          if (!subcontractorDetailsData.some((element) => element.id === subcontractor.subContractorId)) {
            const contractorDetails = await getContractorById(subcontractor.subContractorId);
            subcontractorDetailsData.push({
              ...contractorDetails,
              subcontractorId: subcontractor.id,
              company: subcontractor?.subcontractor?.company,
            });
          }
        });
        return Promise.all(promises).then(() => {
          return subcontractorDetailsData;
        });
      }
    };

    getSubcontractors().then((users) => {
      if (users) {
        setSubcontractorsDetails(users);
      }
    });
  }, [subContractors]);

  return {
    subcontractorsDetails,
  };
};
