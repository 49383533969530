import { Card } from 'domains/accountSetup/onboarding/styles';
import { Button } from 'designSystem';
import FileUpload from 'designSystem/fileUpload/fileUpload';
import { useEffect, useState } from 'react';
import { useWindowDimensions } from 'hooks/useWindowDimensions';
import { useGetS3PresignedUrl } from 'designSystem/fileUpload/useGetS3PresignedUrl';
import type { File } from 'designSystem/fileUpload/types';
import { userHeaderFilePresignedUrl } from 'utils/network/endpoints';
import lang from 'lang/en';
import {
  HeaderLogoContainer, HeaderLogoFormSection, LogoButtonSection,
  LogoHeadingText,
} from '../styles';
import { useHeaderUploadFile } from './useHeaderUploadFile';
import { HeaderLogoProps } from '../types';

const maxFileSize = 10000000;

export const UploadHeaderLogo = ({ profileLogo } : HeaderLogoProps) => {
  const filetypeDefinition = {
    label: lang.milestoneUpload.fileTypesLabel,
    sizeLabel: lang.milestoneUpload.fileSizeLabel,
    accept: 'image/png, image/jpeg, image/jpg',
  };
  const { screenSize } = useWindowDimensions();
  const { saveHeaderLogo, loading } = useHeaderUploadFile();
  const [documents, setDocuments] = useState<Array<File>>([]);
  const { getSignedUrl } = useGetS3PresignedUrl(userHeaderFilePresignedUrl);

  useEffect(() => {
    if (profileLogo?.fileName && profileLogo?.documentUrl) {
      setDocuments([profileLogo]);
    }
  }, [profileLogo]);

  const updateDocuments = (newDocuments: Array<File>) => {
    setDocuments(newDocuments);
  };

  const onSubmit = () => {
    saveHeaderLogo(documents);
  };

  return (
    <HeaderLogoContainer screenSize={screenSize}>
      <Card screenSize={screenSize}>
        <HeaderLogoFormSection>
          <LogoHeadingText>{lang.user.headerFileTitle}</LogoHeadingText>
          <FileUpload
            uploadedFiles={documents}
            updateUploadedFiles={updateDocuments}
            getSignedUrlHandler={getSignedUrl}
            title={lang.milestoneUpload.uploadTitle}
            filetypeDefinition={filetypeDefinition}
            maxFileSize={maxFileSize}
            deleteItemHeading={lang.milestoneUpload.delete.heading}
            deleteItemSubtext={lang.milestoneUpload.delete.subText}
            variant="secondary"
            showAddMore={false}
            showDownload
          />
          <LogoButtonSection>
            <Button
              label={lang.user.saveHeaderFile}
              state={loading ? 'loading' : 'active'}
              width="auto"
              type="button"
              onClick={onSubmit}
            />
          </LogoButtonSection>
        </HeaderLogoFormSection>
      </Card>
    </HeaderLogoContainer>
  );
};
