import {
  useState, useMemo, useEffect, useCallback,
} from 'react';
import {
  Control, FieldValues, UseFormSetValue, UseFormGetValues, UseFormClearErrors, UseFormRegister,
} from 'react-hook-form';
import { manageStates } from './manageStates';

export const getFormState = (
  stateName: string,
  control: Control<FieldValues>,
  setValue: UseFormSetValue<FieldValues>,
  getValues:UseFormGetValues<FieldValues>,
  errors: any,
  clearErrors: UseFormClearErrors<FieldValues>,
  register: UseFormRegister<FieldValues>,
) => {
  const [state, setState] = useState<string | null>(null);

  useEffect(() => {
    if (stateName) {
      setState(stateName);
    }
  }, [stateName]);

  const renderStateForm = useCallback(() => {
    if (state && manageStates[state]) {
      const { component } = manageStates[state];
      const Component = component;
      if (Component) {
        return <Component control={control} register={register} setValue={setValue} getValues={getValues} errors={errors} clearErrors={clearErrors} />;
      }
    }
  }, [state, manageStates, setValue, clearErrors, getValues, errors]);
  return {
    renderStateForm,
  };
};
