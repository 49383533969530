import { ReactElement } from 'react';
import { useWindowDimensions } from 'hooks/useWindowDimensions';
import StepComponent from './step';
import { StepsContainer } from './styles';
import { Step } from './types';

type StepsProps = {
  steps: Array<Step>;
  setCurrentStep?: (step: number) => void;
}

export const Steps = ({
  steps,
  setCurrentStep,
}: StepsProps): ReactElement => {
  const { screenSize } = useWindowDimensions();
  return (
    <StepsContainer>
      {steps.map((step, index) => (
        <StepComponent
          setCurrentStep={() => {
            if (setCurrentStep) {
              setCurrentStep(index);
            }
          }}
          key={step.label}
          {...step}
          screenSize={screenSize}
        />
      ))}
    </StepsContainer>
  );
};

export default Steps;
