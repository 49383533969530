import { useState, useEffect } from 'react';

export type ScreenSize = 'mobile' | 'smallTablet' | 'tablet' | 'desktop';

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

const dimensionToSize = (dimension: number) => {
  if (dimension <= 576) {
    return 'mobile';
  }
  if (dimension <= 768) {
    return 'smallTablet';
  }
  if (dimension <= 1024) {
    return 'tablet';
  }
  return 'desktop';
};

type WindowDimensions = {
  dimensions: {
    width: number;
    height: number;
  };
  screenSize: ScreenSize;
};

export const useWindowDimensions = (): WindowDimensions => {
  const [dimensions, setDimensions] = useState(
    getWindowDimensions(),
  );
  const [screenSize, setScreenSize] = useState<ScreenSize>(dimensionToSize(dimensions.width));

  useEffect(() => {
    function handleResize() {
      window.requestAnimationFrame(() => {
        const currentDimensions = getWindowDimensions();
        setDimensions(currentDimensions);
        setScreenSize(dimensionToSize(currentDimensions.width));
      });
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return { dimensions, screenSize };
};
