import {
  CardRow, CustomFieldSized, CustomMaskedField, CardTitle, CardDescription, Card,
} from 'domains/accountSetup/onboarding/styles';
import lang from 'lang/en';
import { Button } from 'designSystem';
import { useWindowDimensions } from 'hooks/useWindowDimensions';
import { ReactComponent as PlusIcon } from 'assets/images/plus-icon.svg';
import { ButtonWithIconContainer } from 'designSystem/button/styles';
import Action from 'designSystem/action/action';
import { useMemo } from 'react';
import { PercentageText } from '../../styles';
import type { MilestoneProps } from '../types';

export const MilestoneFields = ({
  control, index, lastElement, firstElement,
  handleAddMilestone, handleShowModal, isPercentageError, isEscrow,
}: MilestoneProps) => {
  const { screenSize } = useWindowDimensions();
  const addMoreCTA = useMemo(() => {
    if (lastElement && !isEscrow) {
      return (
        <CardRow>
          <Button
            customRender={(
              <ButtonWithIconContainer>
                <PlusIcon />
                {lang.addMileStoneText}
              </ButtonWithIconContainer>
            )}
            name="addMilestone"
            type="button"
            variant="primary"
            onClick={handleAddMilestone}
          />
        </CardRow>
      );
    }
  }, [lastElement, isEscrow]);

  const deleteAction = useMemo(() => {
    if (index > 0 && !isEscrow) {
      return (
        <Action name="deleteAction" label={lang.deleteButtonLabel} type="delete" onClick={() => handleShowModal(index)} />
      );
    }
  }, [isEscrow]);

  return (
    <Card screenSize={screenSize}>
      {firstElement && <CardDescription>{lang.contractorMileStoneDesc}</CardDescription> }
      <CardTitle>
        {lang.mileStone}
          &nbsp;
        {index + 1}
        {deleteAction}
      </CardTitle>
      <CardRow>
        <CustomFieldSized maxWidth="504px" labelText={`${lang.milestoneLabel} ${index + 1}`} control={control} name={`milestones.${index}.name`} />
      </CardRow>
      <CardRow>
        <CustomMaskedField
          maxWidth="320px"
          control={control}
          name={`milestones.${index}.percentageOverall`}
          labelText={lang.percentageCostOfProject}
          iconPostfix={<PercentageText>%</PercentageText>}
          postfixAlignment={{ top: '32px', left: isPercentageError ? '41px' : '40px' }}
          errorIconAlignment={{ top: '32px', left: isPercentageError ? '58px' : '64px' }}
          inputpadding="7px 12px"
          inputwidth={isPercentageError ? '88px' : '59px'}
          keepPostfix
          disabled={isEscrow}
        />
      </CardRow>
      {addMoreCTA}
    </Card>
  );
};
