import lang from 'lang/en';
import * as Yup from 'yup';
import { notAllowSpecialCharcters } from 'utils/regex';

export const schema = {
  workOnThePrincipalResidence: Yup.boolean().nullable().required(lang.missingInformationLabel),
  threeDayRight: Yup.boolean().nullable().required(lang.missingInformationLabel),
  miamiDadeCountry: Yup.boolean().nullable().required(lang.missingInformationLabel),
  jobExceeds5000: Yup.boolean().nullable().required(lang.missingInformationLabel),
  publicLiabilityInsPolicyNum: Yup.string().nullable().when('jobExceeds5000', (jobExceeds5000: boolean) => {
    return jobExceeds5000 ? Yup.string().nullable().required(lang.missingInformationLabel) : Yup.string().nullable();
  }),
  publicLiabilityInsExpirationDate: Yup.string().when('jobExceeds5000', (jobExceeds5000: boolean) => {
    return jobExceeds5000 ? Yup.string().nullable().required(lang.missingInformationLabel) : Yup.string().nullable();
  }),
  publicLiabilityInsLiabilityLimit: Yup.number().transform((v, o) => o === '' ? null : v).when('jobExceeds5000', (jobExceeds5000: boolean) => {
    return jobExceeds5000 ? Yup.number()
      .transform((v, o) => o === '' ? null : v)
      .nullable().required(lang.missingInformationLabel) : Yup.number().transform((v, o) => o === '' ? null : v).nullable();
  }),
  workerCompensationInsPolicyNum: Yup.string().nullable().when('jobExceeds5000', (jobExceeds5000: boolean) => {
    return jobExceeds5000 ? Yup.string().nullable().required(lang.missingInformationLabel) : Yup.string().nullable();
  }),
  workerCompensationInsExpirationDate: Yup.string().when('jobExceeds5000', (jobExceeds5000: boolean) => {
    return jobExceeds5000 ? Yup.string().nullable().required(lang.missingInformationLabel) : Yup.string().nullable();
  }),
  workerCompensationInsLiabilityLimit: Yup.number().transform((v, o) => o === '' ? null : v).when('jobExceeds5000', (jobExceeds5000: boolean) => {
    return jobExceeds5000 ? Yup.number()
      .transform((v, o) => o === '' ? null : v).nullable().required(lang.missingInformationLabel)
      : Yup.number().transform((v, o) => o === '' ? null : v).nullable();
  }),
  placeOfBusinessOfContractor: Yup.boolean().nullable().required(lang.missingInformationLabel),
  stateFairOrCommercialExhibit: Yup.boolean().nullable().required(lang.missingInformationLabel),
  residenceOfOwner: Yup.boolean().nullable().required(lang.missingInformationLabel),
  isAmountGreaterThan2500: Yup.boolean().nullable().required(lang.missingInformationLabel),
  isContractForConstructionRapairAndImprovement: Yup.boolean().nullable().required(lang.missingInformationLabel),
  isContractForRepairRestorationAndImprovement: Yup.boolean().nullable().required(lang.missingInformationLabel),
  isContractWaivedFloridaNoticeAndRight: Yup.boolean().nullable().required(lang.missingInformationLabel),
};

export const floridaStateFieldSchema = {
  extraData: Yup.object().shape(schema),
};
