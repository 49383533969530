import { configureStore } from '@reduxjs/toolkit';
import appReducer from '../appSlice';

export const combinedReducer = {
  app: appReducer,
};

export const store = configureStore({
  reducer: combinedReducer,
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
