import {
  FormHeader, CloseButtonRow,
} from 'domains/project/addProject/contract/styles';
import { ReactComponent as CloseIcon } from 'assets/images/close-icon.svg';
import { CircleButton } from 'designSystem/button';
import {
  CardTitle,
} from 'domains/accountSetup/onboarding/styles';
import { useWindowDimensions } from 'hooks/useWindowDimensions';

export const ModalInfoSection = ({
  setOpenModal,
  headerText,
  subHeaderText,
  titleText,
}: {
  setOpenModal: (value: boolean) => void;
  headerText: string;
  subHeaderText: string;
  titleText?: string;
}) => {
  const { screenSize } = useWindowDimensions();

  return (
    <FormHeader screenSize={screenSize}>
      <CloseButtonRow>
        <CircleButton size="medium" icon={<CloseIcon />} onClick={() => setOpenModal(false)} />
      </CloseButtonRow>
      <CardTitle>{headerText}</CardTitle>
      <CardTitle>{subHeaderText}</CardTitle>
      <CardTitle>{titleText}</CardTitle>
    </FormHeader>
  );
};

ModalInfoSection.defaultProps = {
  titleText: '',
};
