import { CardRow, CustomMaskedField } from 'domains/accountSetup/onboarding/styles';
import lang from 'lang/en';
import {
  MediumControlledInputField, CustomControlledDatepicker, MediumControlledInputFieldSection, MarginTop,
} from 'domains/project/addProject/contract/styles';
import { useWindowDimensions } from 'hooks/useWindowDimensions';
import { useState } from 'react';
import { dateStringFormat, defaultDateFormat, defaultDateFormatString } from 'utils/dateFormats';
import { ContractorDetailsSection } from '../../styles';
import { ComponentCommonTypes } from '../types';
import { RadioStringOptions } from './radioStringOptions';
import { iconPositionStyle } from './content';

export const ContractorDetailsField = ({
  control, setValue, getValues, clearErrors, errors,
}: ComponentCommonTypes) => {
  const { contract: { homeowner: { modal: { form: { massachusettsFields: { jobProjectStatements } } } } } } = lang;
  const { customField, mediumField, smallField } = iconPositionStyle;
  const { screenSize } = useWindowDimensions();
  const [selectedDate, setSelectedDate] = useState({
    selectedContractorHICExpiryDate: null,
  });

  const handleDateChange = (date: Date | string, dateField: string, selectedDateKey: string) => {
    if (date) {
      setValue(dateField, dateStringFormat(date, defaultDateFormatString), { shouldValidate: true });
      setSelectedDate({
        ...selectedDate,
        [selectedDateKey]: defaultDateFormat(date),
      });
    }
  };

  return (
    <ContractorDetailsSection>
      <CardRow>
        <CustomMaskedField
          maxWidth="250px"
          placeholder="+1(000) 000-00-00"
          maskFormat="+1(###) ###-##-##"
          labelText={jobProjectStatements.contractorDaytimePhnNo}
          control={control}
          name="extraData.contractorDaytimePhoneNo"
          errorIconPosition={screenSize === 'mobile' ? `${customField.left.mobile}` : `${customField.left.desktop}`}
        />
      </CardRow>
      <CardRow>
        <CustomMaskedField
          maxWidth="250px"
          placeholder="+1(000) 000-00-00"
          maskFormat="+1(###) ###-##-##"
          labelText={jobProjectStatements.contractorEveningPhnNo}
          control={control}
          name="extraData.contractorEveningPhoneNo"
          errorIconPosition={screenSize === 'mobile' ? `${customField.left.mobile}` : `${customField.left.desktop}`}
        />
      </CardRow>
      <CardRow>
        <MediumControlledInputField
          control={control}
          labelText={jobProjectStatements.contractorEmployerIdOrSocialSecurityNo}
          name="extraData.contractorEmployerIdOrSocialSecurityNo"
          errorIconPosition={screenSize === 'mobile' ? `${mediumField.left.mobile}` : `${mediumField.left.desktop}`}
          errorIconTopPosition={screenSize === 'mobile' ? `${mediumField.top.mobile}` : `${mediumField.top.desktop}`}
        />
      </CardRow>
      <CardRow>
        <MediumControlledInputField
          control={control}
          labelText={jobProjectStatements.contractorHICRegisterationNo}
          name="extraData.contractorHICRegisterationNo"
          errorIconPosition={screenSize === 'mobile' ? `${mediumField.left.mobile}` : `${mediumField.left.desktop}`}
          errorIconTopPosition={screenSize === 'mobile' ? `${mediumField.top.mobile}` : `${mediumField.top.desktop}`}
        />
      </CardRow>
      <CardRow>
        <CustomControlledDatepicker
          selectedDate={null}
          control={control}
          name="extraData.contractorHICExpiryDate"
          labelText={jobProjectStatements.contractorHICExpiryDate}
          placeholderText={jobProjectStatements.expiryDatePlaceholderText}
          setFieldValue={(date) => { handleDateChange(date, 'extraData.contractorHICExpiryDate', 'selectedContractorHICExpiryDate'); }}
          errorIconPosition={screenSize === 'mobile' ? `${mediumField.left.mobile}` : `${mediumField.left.desktop}`}
          errorIconTopPosition={screenSize === 'mobile' ? `${mediumField.top.mobile}` : `${mediumField.top.desktop}`}
        />
      </CardRow>
      <CardRow>
        <MediumControlledInputFieldSection
          maxWidth="280px"
          control={control}
          labelText={jobProjectStatements.salesperson}
          name="extraData.salesperson"
          errorIconPosition={screenSize === 'mobile' ? `${smallField.left.mobile}` : `${smallField.left.desktop}`}
          errorIconTopPosition={screenSize === 'mobile' ? `${smallField.top.mobile}` : `${smallField.top.desktop}`}
        />
      </CardRow>
      <MarginTop />
      <CardRow>
        <RadioStringOptions
          control={control}
          fieldName="extraData.responsibleForBuildingPermit"
          fieldText={jobProjectStatements.responsibleForBuildingPermit}
          setValue={setValue}
          errorMsg={errors?.extraData?.responsibleForBuildingPermit?.message || ''}
          clearErrors={clearErrors}
          getValues={getValues}
          value1="contractor"
          value2="owner"
        />
      </CardRow>
    </ContractorDetailsSection>
  );
};
