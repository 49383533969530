import { Controller } from 'react-hook-form';
import React, { ReactElement } from 'react';
import type { ControlledPaymentFieldProps } from './types';
import { PaymentField } from './paymentField';

export const ControlledPaymentField = ({
  name, control, ...propsToPass
}: ControlledPaymentFieldProps): ReactElement => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => {
        const { error } = fieldState;
        return <PaymentField {...propsToPass} field={field} errorMessage={error?.message} />;
      }}
    />
  );
};
