import { ReactElement } from 'react';
import { ReactComponent as SortAscending } from 'assets/images/sortingAsc.svg';
import { ReactComponent as SortDescending } from 'assets/images/sortingDesc.svg';
import { ReactComponent as DefautSortIcon } from 'assets/images/defaultSort.svg';
import { HeaderGroup } from 'react-table';
import {
  TableRow, TableThead, MainHead, SortIcon,
} from './styles';

type TableHeadSectionType = {
  isTransparentBg?: boolean;
  headerGroups: Array<HeaderGroup>;
}

export const TableHeadSection = ({ isTransparentBg, headerGroups } : TableHeadSectionType) => {
  const getSortingState = (isSorted: boolean, isSortedDesc?: boolean): ReactElement => {
    if (isSorted) {
      const sortingState = isSortedDesc ? <SortDescending width={16} height={14} /> : <SortAscending width={16} height={14} />;
      return sortingState;
    } else {
      return <DefautSortIcon width={16} height={14} />;
    }
  };
  return (
    <MainHead isTransparentBg={isTransparentBg}>
      {headerGroups.map((headerGroup) => (
        <TableRow {...headerGroup.getHeaderGroupProps()}>
          {headerGroup.headers.map((column) => {
            const {
              getSortByToggleProps, render, isSorted, isSortedDesc, disableSortBy,
            } = column;
            const headerTitle = render('Header') !== '' ? render('Header') : null;
            if (!disableSortBy) {
              return (
                <TableThead
                  {...column.getHeaderProps(
                    getSortByToggleProps(),
                  )}
                >
                  {headerTitle}
                  <SortIcon>
                    {getSortingState(isSorted, isSortedDesc)}
                  </SortIcon>
                </TableThead>
              );
            }
            return (
              <TableThead
                {...column.getHeaderProps()}
              >
                {headerTitle}
              </TableThead>
            );
          })}
        </TableRow>
      ))}
    </MainHead>
  );
};
