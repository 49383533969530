import lang from 'lang/en';
import * as Yup from 'yup';

export const subcontractorContractSchema = Yup.object().shape({
  state: Yup.object().shape({ value: Yup.string(), label: Yup.string() }).required(lang.missingInformationLabel).nullable(),
  additionalHomeOwnerName: Yup.string().nullable(),
  contractorAuthorizedRepresentative: Yup.string().nullable(),
  homeOwnerAuthorizedRepresentative: Yup.string().nullable(),
  contractorInsuranceOption: Yup.string().nullable().required(lang.missingInformationLabel),
  contractorInsuranceProviderName: Yup.string().when('contractorInsuranceOption', (contractorInsuranceOption: string) => {
    return contractorInsuranceOption === 'commercialLiabilityInsurance' ? Yup.string().nullable().required(lang.missingInformationLabel) : Yup.string().nullable();
  }),
  workerCompensationInsuranceOption: Yup.string().nullable().required(lang.missingInformationLabel),
  workerCompensationInsuranceLimit: Yup.string().when('workerCompensationInsuranceOption', (workerCompensationInsuranceOption: string) => {
    return workerCompensationInsuranceOption === 'hasCompensationInsurance' ? Yup.string().nullable().required(lang.missingInformationLabel) : Yup.string().nullable();
  }),
  descriptionOfServices: Yup.string().nullable().required(lang.missingInformationLabel),
  subcontractorResponsibilities: Yup.array().of(Yup.string())
    .min(1, lang.missingInformationLabel),
  otherSubcontractorResponsibilities: Yup.string().when('subcontractorResponsibilities', (subcontractorResponsibilities: string) => {
    return subcontractorResponsibilities?.includes('other') ? Yup.string().nullable().required(lang.missingInformationLabel) : Yup.string().nullable();
  }),
  commencementDate: Yup.string().nullable().required(lang.missingInformationLabel),

  completionDateOptions: Yup.string().nullable().required(lang.missingInformationLabel),
  specificCompletionDate: Yup.string().when('completionDateOptions', (completionDateOptions: string) => {
    return completionDateOptions === 'specificDate' ? Yup.string().nullable().required(lang.missingInformationLabel) : Yup.string().nullable();
  }),
  otherCompletionDate: Yup.string().when('completionDateOptions', (completionDateOptions: string) => {
    return completionDateOptions === 'other' ? Yup.string().nullable().required(lang.missingInformationLabel) : Yup.string().nullable();
  }),
  substantialCompletionDateOptions: Yup.string().nullable().required(lang.missingInformationLabel),
  specificSubstantialCompletionDate: Yup.string().when('substantialCompletionDateOptions', (substantialCompletionDateOptions: string) => {
    return substantialCompletionDateOptions === 'specificDate' ? Yup.string().nullable().required(lang.missingInformationLabel) : Yup.string().nullable();
  }),
  otherSubstantialCompletionDate: Yup.string().when('substantialCompletionDateOptions', (substantialCompletionDateOptions: string) => {
    return substantialCompletionDateOptions === 'other' ? Yup.string().nullable().required(lang.missingInformationLabel) : Yup.string().nullable();
  }),

  paymentAmountOptions: Yup.string().required(lang.missingInformationLabel).nullable(),
  paymentAmountTotal: Yup.string().when('paymentAmountOptions', (paymentAmountOptions: string) => {
    return paymentAmountOptions === 'totalAmount' ? Yup.string().nullable().required(lang.missingInformationLabel) : Yup.string().nullable();
  }),
  paymentAmountHourly: Yup.string().when('paymentAmountOptions', (paymentAmountOptions: string) => {
    return paymentAmountOptions === 'hourlyRate' ? Yup.string().nullable().required(lang.missingInformationLabel) : Yup.string().nullable();
  }),
  paymentAmountOther: Yup.string().when('paymentAmountOptions', (paymentAmountOptions: string) => {
    return paymentAmountOptions === 'other' ? Yup.string().nullable().required(lang.missingInformationLabel) : Yup.string().nullable();
  }),
  paymentMethodOptions: Yup.string().required(lang.missingInformationLabel).nullable(),
  paymentMethodOther: Yup.string().when('paymentMethodOptions', (paymentMethodOptions: string) => {
    return paymentMethodOptions === 'other' ? Yup.string().nullable().required(lang.missingInformationLabel) : Yup.string().nullable();
  }),
  paymentFrequencyOptions: Yup.string().required(lang.missingInformationLabel).nullable(),
  paymentFrequencyOther: Yup.string().when('paymentFrequencyOptions', (paymentFrequencyOptions: string) => {
    return paymentFrequencyOptions === 'other' ? Yup.string().nullable().required(lang.missingInformationLabel) : Yup.string().nullable();
  }),

  rightToSubcontractingOptions: Yup.string().required(lang.missingInformationLabel).nullable(),
  assignmentRightOptions: Yup.string().required(lang.missingInformationLabel).nullable(),

  subcontractorInsuranceOption: Yup.string().nullable().required(lang.missingInformationLabel),
  subcontractorInsuranceProviderName: Yup.string().when('subcontractorInsuranceOption', (subcontractorInsuranceOption: string) => {
    return subcontractorInsuranceOption === 'commercialLiabilityInsurance' ? Yup.string().nullable().required(lang.missingInformationLabel) : Yup.string().nullable();
  }),
  subcontractorWorkerCompensationInsuranceOptions: Yup.string().nullable().required(lang.missingInformationLabel),
  subcontractorWorkerCompensationInsuranceLimit: Yup.string().when('subcontractorWorkerCompensationInsuranceOptions', (subcontractorWorkerCompensationInsuranceOptions: string) => {
    return subcontractorWorkerCompensationInsuranceOptions === 'hasCompensationInsurance'
      ? Yup.string().nullable().required(lang.missingInformationLabel) : Yup.string().nullable();
  }),
  additionalProvisions: Yup.string().nullable(),
  fileName: Yup.string().nullable().required(lang.missingInformationLabel),
});
