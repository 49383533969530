import styled from 'styled-components';
import { typings, colors } from 'designSystem';

export const Table = styled.table`
width: 100%;
border-spacing: 0;
border-collapse: collapse;
overflow: hidden;
border-top: 1px solid ${colors.blue100};
border-top-left-radius: 14px;
border-top-right-radius: 14px;
`;

export const MainHead = styled.thead<{ isTransparentBg?: boolean}>`
background: ${({ isTransparentBg }) => isTransparentBg ? 'transparent' : colors.blue50};
width: 800px;
`;

export const TableRow = styled.tr`
`;

export const MainBody = styled.tbody`
`;

export const TableThead = styled.th`
${typings.textXSm}
color: ${colors.blue700};
text-align: left;
text-transform: uppercase;
padding: 12px 72px;
`;

export const TableCell = styled.td<{bodyPadding?: string}>` 
padding: ${({ bodyPadding }) => bodyPadding};
border-top: 1px solid ${colors.blue100};
`;

export const SortIcon = styled.span`
margin-left: 28px;
`;
