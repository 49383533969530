import { yupResolver } from '@hookform/resolvers/yup';
import { useWindowDimensions } from 'hooks/useWindowDimensions';
import { Modal } from 'common/modal';
import lang from 'lang/en';
import moment from 'moment';
import { Button } from 'designSystem';
import { ControlledInputField } from 'designSystem/input';
import { ControlledToggleButton } from 'designSystem/input/toggleInput';
import { Card, CardDescription, CardTitle } from 'domains/accountSetup/onboarding/styles';
import {
  ButtonRow,
  CustomControlledDatepicker,
  ErrorMessage,
  ErrorText,
  GrayContainer,
  MediumControlledInputField,
  NarrowControlledInputField,
  NarrowControlledSelect,
  PrefilledSection,
} from 'domains/project/addProject/contract/styles';
import {
  getTextareaIconLeftPosition, getTopPosition,
} from 'domains/project/addProject/contract/subcontractor/form/helpers';
import { useSaveProjectDetailsData } from 'domains/project/addProject/useSaveProjectDetailsData';
import { useEffect, useMemo, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { dateStringFormat, defaultDateFormat, defaultDateFormatString } from 'utils/dateFormats';
import { states } from 'utils/constants';
import { useChangeOrderActions } from 'domains/project/addProject/contract/changerOder/useChangeOrderActions';
import { isEmpty } from 'lodash';
import { TotalSalesPrice } from '../../common/totalSalesPrice';
import { ProjectAddress } from '../../common/projectAddress';
import { ContractorDetails } from '../../common/contractorDetails';
import { ModalInfoSection } from '../../common/modalInfoSection';
import {
  getStateOptions, getHicDefaultValues,
} from '../staticData';
import { HICContractPayload, HomeownerContractFormProps, HICDateType } from '../types';
import { hicValidationSchema } from '../validationSchema';
import ContractorInsuranceSelect from './contractorInsuranceSelect';
import ProjectCostModelSelect from './projectCostModelSelect';
import WorkersCompensationInsuranceSelect from './workersCompensationInsuranceSelect';
import { getFormState } from './getFormState';
import { HomeownerDetails } from '../../common/homeownerDetails';

const modalStrings = lang.contract.homeowner.modal;

const HomeownerContractForm = ({
  isOpen,
  setIsOpen,
  projectData,
  triggerDataUpdate,
}: HomeownerContractFormProps) => {
  const {
    loading,
    isSaved,
    updateProjectDetailsData,
  } = useSaveProjectDetailsData();
  const { screenSize } = useWindowDimensions();
  const [validationSchema, setValidationSchema] = useState(hicValidationSchema());
  const [formDefaultValues, setFormDefaultValues] = useState(getHicDefaultValues());
  const resolver = { resolver: yupResolver(validationSchema) };
  const [initialValuesSet, setInitialValuesSet] = useState(false);
  const [selectedDate, setSelectedDate] = useState<HICDateType>({
    selectedStartDate: null,
    selectedCompletionDate: null,
    selectedSubstantialDate: null,
  });

  const {
    getTotalSalesPrice, getContractorFullName, getAddressDetails, getHomeownerFullName,
  } = useChangeOrderActions(projectData);

  const {
    control,
    reset,
    getValues,
    setValue,
    register,
    clearErrors,
    formState,
    handleSubmit,

  } = useForm({
    ...resolver,
    mode: 'onChange',
    defaultValues: {
      ...formDefaultValues,
    },
  });

  const {
    escrow,
  } = projectData?.details || {};

  const stateValue = useWatch({
    control,
    name: 'state',
  });

  const contractorInsuranceOptionValue = useWatch({
    control,
    name: 'contractorInsuranceOption',
  });
  const workerCompensationInsuranceLimitValue = useWatch({
    control,
    name: 'workerCompensationInsuranceOption',
  });

  const mappedPreloadedData = useMemo(() => {
   if (!isEmpty(projectData) && isEmpty(projectData?.projectContract)) {
  return false;
}
    if (projectData && projectData.projectContract) {
      const { projectContract } = projectData;
      setSelectedDate({
        selectedStartDate: defaultDateFormat(projectContract.startDate) || null,
        selectedCompletionDate: defaultDateFormat(projectContract.completionDate) || null,
        selectedSubstantialDate: defaultDateFormat(projectContract.substantialCompletionDate) || null,
      });
      return {
        ...projectContract,
        state: getStateOptions().find((stateOption) => stateOption.value === (stateValue?.value ? stateValue?.value : projectContract.state)),
        startDate: dateStringFormat(projectContract.startDate, defaultDateFormatString),
        completionDate: dateStringFormat(projectContract.completionDate, defaultDateFormatString),
        substantialCompletionDate: dateStringFormat(projectContract.substantialCompletionDate, defaultDateFormatString),
        workerCompensationInsuranceLimit: projectContract.workerCompensationInsuranceLimit?.toString(),
        contractorInsuranceOption: projectContract.contractorInsuranceOption,
        extraData: stateValue?.value === projectContract.state ? projectContract?.extraData : null,
      };
    }
  }, [projectData, stateValue?.value]);

  useEffect(() => {
    if (stateValue?.value) {
      const currentSchema = hicValidationSchema(stateValue?.value);
      const defaultValues = getHicDefaultValues(stateValue?.value);
      const stateObj = getStateOptions()
        .find((stateOption) => stateOption.value === stateValue.value);
      setFormDefaultValues({ ...defaultValues, state: stateObj });
      reset({ ...defaultValues, state: stateObj });
      setSelectedDate({
        selectedStartDate: null,
        selectedCompletionDate: null,
        selectedSubstantialDate: null,
      });
      setInitialValuesSet(false);
      setValidationSchema(currentSchema);
    }
  }, [stateValue?.value]);

  const {
    errors,
  } = formState;

  useEffect(() => {
    if (!isOpen) {
      setInitialValuesSet(false);
    }
  }, [isOpen]);

  useEffect(() => {
    if (!initialValuesSet && !!projectData) {
      const {
        escrow: isEscrow,
      } = projectData?.details || {};

      reset({
        ...formDefaultValues,
        ...mappedPreloadedData,
        escrow: isEscrow,
      });
      setInitialValuesSet(true);
    }
  }, [projectData, formDefaultValues, stateValue?.value]);

  const onSubmit = async (data: HICContractPayload) => {
    const mappedData = {
      id: projectData?.details.id,
      projectContract: {
        ...data,
        state: data.state.value,
        startDate: data.startDate,
        completionDate: data.completionDate,
        substantialCompletionDate: data.substantialCompletionDate,
        workerCompensationInsuranceLimit: parseFloat(data.workerCompensationInsuranceLimit),
        totalSalesPrice: parseFloat(getTotalSalesPrice()),
      },
    };
    await updateProjectDetailsData(mappedData);
    triggerDataUpdate();
  };

  useEffect(() => {
    if (!loading && isSaved) {
      setIsOpen(false);
    }
  }, [loading, isSaved]);

  const costModelSelectRegistrant = register('projectCostModel');
  const contractorInsuranceRegistrant = register('contractorInsuranceOption');
  const workerCompensationInsuranceRegistrant = register('workerCompensationInsuranceOption');

  const { renderStateForm } = getFormState(stateValue?.value, control, setValue, getValues, errors, clearErrors, register);

  const handleDateChange = (date: Date | string, dateField: string, selectedDateKey: string) => {
    if (date) {
      setValue(dateField, dateStringFormat(date, defaultDateFormatString), { shouldValidate: true });
      setSelectedDate({
        ...selectedDate,
        [selectedDateKey]: defaultDateFormat(date),
      });
    }
  };

  const getLoadingState = () => {
    return loading ? 'loading' : 'active';
  };

  return (
    <Modal
      isOpen={isOpen}
    >
      <GrayContainer>
        <ModalInfoSection
          setOpenModal={setIsOpen}
          headerText={modalStrings.header}
          subHeaderText={modalStrings.subheader}
        />
        <form onSubmit={handleSubmit(onSubmit)}>
          <Card screenSize={screenSize}>
            <CardTitle>{modalStrings.form.contractType.header}</CardTitle>
            <NarrowControlledSelect
              labelText={modalStrings.form.contractType.stateLabel}
              options={getStateOptions()}
              control={control}
              name="state"
            />
            <ErrorMessage margin="13px 0 0 12px"><ErrorText>{errors?.state?.message || ''}</ErrorText></ErrorMessage>
          </Card>
          <PrefilledSection screenSize={screenSize}>
            <HomeownerDetails
              fullName={getHomeownerFullName()}
              subText={modalStrings.form.homeownerDetails.description}
              showAdditionalHomeowner
              additionalHomeownerLabel={modalStrings.form.homeownerDetails.additionalHomeownerNameLabel}
              control={control}
            />
            <ContractorDetails fullName={getContractorFullName()} />
            <ProjectAddress
              addressDetails={getAddressDetails()}
            />
          </PrefilledSection>
          <Card screenSize={screenSize}>
            <CardTitle>
              {modalStrings.form.dates.header}
            </CardTitle>
            <CustomControlledDatepicker
              placeholder={modalStrings.form.dates.datePlaceholder}
              control={control}
              name="startDate"
              labelText={modalStrings.form.dates.startDateLabel}
              setFieldValue={(date) => { handleDateChange(date, 'startDate', 'selectedStartDate'); }}
              selectedDate={selectedDate.selectedStartDate}
              errorIconPosition={screenSize === 'mobile' ? '91%' : '343px'}
              maxDate={moment(selectedDate.selectedSubstantialDate).toDate()}
              minDate={null}
            />
            <CustomControlledDatepicker
              disabled={!getValues('startDate')}
              minDate={moment(selectedDate.selectedStartDate).toDate()}
              maxDate={moment(selectedDate.selectedCompletionDate).toDate()}
              placeholder={modalStrings.form.dates.datePlaceholder}
              control={control}
              name="substantialCompletionDate"
              labelText={modalStrings.form.dates.substantialDateLabel}
              setFieldValue={(date) => { handleDateChange(date, 'substantialCompletionDate', 'selectedSubstantialDate'); }}
              selectedDate={selectedDate.selectedSubstantialDate}
              errorIconPosition={screenSize === 'mobile' ? '91%' : '343px'}
            />
            <CustomControlledDatepicker
              disabled={!getValues('substantialCompletionDate')}
              minDate={moment(selectedDate.selectedSubstantialDate).toDate()}
              placeholder={modalStrings.form.dates.datePlaceholder}
              control={control}
              name="completionDate"
              labelText={modalStrings.form.dates.completionDateLabel}
              setFieldValue={(date) => { handleDateChange(date, 'completionDate', 'selectedCompletionDate'); }}
              selectedDate={selectedDate.selectedCompletionDate}
              errorIconPosition={screenSize === 'mobile' ? '91%' : '343px'}
            />
          </Card>
          {stateValue?.value === states.connecticut && (
            renderStateForm()
          )}
          <Card screenSize={screenSize}>
            <CardTitle marginBottom="0">
              {modalStrings.form.projectCostModel.header}
            </CardTitle>
            <CardDescription>
              {modalStrings.form.projectCostModel.description}
            </CardDescription>
            <ProjectCostModelSelect
              registrant={costModelSelectRegistrant}
              fieldName="projectCostModel"
              errorMsg={errors?.projectCostModel?.message || ''}
            />
          </Card>
          <TotalSalesPrice salesPrice={getTotalSalesPrice()} />
          <Card screenSize={screenSize}>
            <CardTitle>
              {modalStrings.form.allowance.header}
            </CardTitle>
            <ControlledInputField control={control} name="totalAllowance" labelText={modalStrings.form.allowance.totalAllowanceLabel} />
          </Card>
          <Card screenSize={screenSize}>
            <CardTitle>
              {modalStrings.form.descriptionOfWork.header}
            </CardTitle>
            <ControlledInputField
              control={control}
              name="workDescription"
              labelText={modalStrings.form.descriptionOfWork.descriptionOfWorkLabel}
              type="textarea"
              errorIconPosition={getTextareaIconLeftPosition(screenSize)}
              errorIconTopPosition="68px"
            />
          </Card>
          {!escrow && (
            <Card screenSize={screenSize}>
              <CardTitle>
                {modalStrings.form.paymentSchedule.header}
              </CardTitle>
              <ControlledInputField
                control={control}
                name="paymentSchedule"
                labelText={modalStrings.form.paymentSchedule.paymentScheduleLabel}
                type="textarea"
                errorIconPosition={getTextareaIconLeftPosition(screenSize)}
                errorIconTopPosition="68px"
              />
            </Card>
          )}
          <Card screenSize={screenSize}>
            <CardTitle>
              {modalStrings.form.escrowService.header}
            </CardTitle>
            <ControlledToggleButton control={control} name="escrow" labelText={modalStrings.form.escrowService.escrowLabel} id="escrow" disabled />
          </Card>
          <Card screenSize={screenSize}>
            <CardTitle>
              {modalStrings.form.authorizedRepresentatives.header}
            </CardTitle>
            <MediumControlledInputField
              control={control}
              type="text"
              name="contractorAuthorizedRepresentative"
              labelText={modalStrings.form.authorizedRepresentatives.contractorLabel}
            />
            <MediumControlledInputField
              control={control}
              type="text"
              name="homeOwnerAuthorizedRepresentative"
              labelText={modalStrings.form.authorizedRepresentatives.homeownerLabel}
            />
          </Card>
          <Card screenSize={screenSize}>
            <CardTitle marginBottom="0">
              {modalStrings.form.contractorsInsurance.header}
            </CardTitle>
            <CardDescription>
              {modalStrings.form.contractorsInsurance.description}
            </CardDescription>
            <ContractorInsuranceSelect
              contractorInsuranceValue={getValues('contractorInsuranceOption')}
              setValue={setValue}
              registrant={contractorInsuranceRegistrant}
              control={control}
              errorMsg={errors?.contractorInsuranceOption?.message || ''}
            />
          </Card>
          <Card screenSize={screenSize}>
            <CardTitle marginBottom="0">
              {modalStrings.form.workersCompensationInsurance.header}
            </CardTitle>
            <CardDescription>
              {modalStrings.form.workersCompensationInsurance.description}
            </CardDescription>
            <WorkersCompensationInsuranceSelect
              workerCompensationInsuranceLimitValue={getValues('workerCompensationInsuranceOption')}
              registrant={workerCompensationInsuranceRegistrant}
              control={control}
              setValue={setValue}
              errorMsg={errors?.workerCompensationInsuranceOption?.message || ''}
            />
          </Card>
          {stateValue?.value !== states.connecticut && (
            renderStateForm()
          )}
          <Card screenSize={screenSize}>
            <CardTitle>
              {modalStrings.form.contractsFileName.header}
            </CardTitle>
            <NarrowControlledInputField
              control={control}
              name="contractFileName"
              labelText={modalStrings.form.contractsFileName.fileNameLabel}
              errorIconPosition="206px"
              errorIconTopPosition={getTopPosition(screenSize)}
            />
          </Card>
          <ButtonRow>
            <Button
              type="submit"
              variant="primary"
              label={modalStrings.form.createContractButtonLabel}
              state={getLoadingState()}
            />
          </ButtonRow>
        </form>
      </GrayContainer>
    </Modal>
  );
};

export default HomeownerContractForm;
