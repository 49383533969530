import { Controller } from 'react-hook-form';
import { ReactElement } from 'react';
import InputField from './inputField';
import type { ControlledInputFieldProps } from './types';

const ControlledInputField = ({
  name, control, ...propsToPass
}: ControlledInputFieldProps): ReactElement => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => {
        const { error } = fieldState;
        return <InputField {...propsToPass} field={field} errorMessage={error?.message} />;
      }}
    />
  );
};

export default ControlledInputField;
