import {
  CustomControlledDatepicker, LabelWithMargin, RadioContainer, ErrorMessage, ErrorText,
} from 'domains/project/addProject/contract/styles';
import { CompletionDateOptionSelectProps } from 'domains/project/addProject/contract/subcontractor/form/types';
import lang from 'lang/en';
import { useWatch } from 'react-hook-form';
import { useEffect } from 'react';
import { useWindowDimensions } from 'hooks/useWindowDimensions';

const formStrings = lang.contract.subcontractors.modal.form;

const CompletionDateOptionSelect = ({
  registrant,
  control,
  handleDateChange,
  getValues,
  setValue,
  specificDate,
  otherDate,
  errorMsg,
}: CompletionDateOptionSelectProps) => {
  const { screenSize } = useWindowDimensions();

  const optionValue = useWatch({
    control,
    name: registrant.name,
  });

  useEffect(() => {
    if (optionValue !== 'specificDate' && setValue) {
      setValue('specificCompletionDate', null);
    }
    if (optionValue !== 'other' && setValue) {
      setValue('otherCompletionDate', null);
    }
  }, [optionValue]);

  return (
    <RadioContainer>
      <LabelWithMargin htmlFor="specificCompletionDateOption">
        <input
          {...registrant}
          type="radio"
          value="specificDate"
          id="specificCompletionDateOption"
        />
        {formStrings.completionDateOptions.specificDateOption}
      </LabelWithMargin>
      <CustomControlledDatepicker
        placeholder={formStrings.pickDatePlaceholder}
        control={control}
        name="specificCompletionDate"
        labelText={formStrings.completionDateOptions.completionDateSpecificFieldLabel}
        setFieldValue={handleDateChange('specificCompletionDate', 'selectedCompletionDateSpecific')}
        selectedDate={specificDate}
        disabled={optionValue !== 'specificDate'}
        errorIconPosition={screenSize === 'mobile' ? '91%' : '343px'}
      />
      <LabelWithMargin htmlFor="industryStandardCompletionDateOption">
        <input
          {...registrant}
          type="radio"
          value="industryStandard"
          id="industryStandardCompletionDateOption"
        />
        {formStrings.completionDateOptions.industryStandardsOption}
      </LabelWithMargin>
      <LabelWithMargin htmlFor="otherCompletionDateOption">
        <input
          {...registrant}
          type="radio"
          value="other"
          id="otherCompletionDateOption"
        />
        {formStrings.completionDateOptions.otherOption}
      </LabelWithMargin>
      <CustomControlledDatepicker
        placeholder={formStrings.pickDatePlaceholder}
        control={control}
        name="otherCompletionDate"
        labelText={formStrings.completionDateOptions.otherCompletionDateFieldLabel}
        setFieldValue={handleDateChange('otherCompletionDate', 'selectedCompletionDateOther')}
        selectedDate={otherDate}
        disabled={optionValue !== 'other'}
        errorIconPosition={screenSize === 'mobile' ? '91%' : '343px'}
      />
      <ErrorMessage><ErrorText>{errorMsg}</ErrorText></ErrorMessage>
    </RadioContainer>
  );
};
CompletionDateOptionSelect.defaultProps = {
  errorMsg: '',
};

export default CompletionDateOptionSelect;
