import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import './globalStyles/global.css';
import { apiClient } from 'utils/network/apiClient';
import { getAuthInfo } from 'utils/helpers';
import Modal from 'react-modal';
import runtimeEnv from '@mars/heroku-js-runtime-env';
import App from './app';
import reportWebVitals from './reportWebVitals';
import { store } from './store/store';

Modal.setAppElement('#root');
const { REACT_APP_STRIPE_PUBLIC_KEY } = runtimeEnv();
const stripePubKey = REACT_APP_STRIPE_PUBLIC_KEY || 'mockedKey';
apiClient.defaults.headers.common.Authorization = getAuthInfo().accessToken;

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Elements stripe={loadStripe(stripePubKey)}>
        <App />
      </Elements>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
