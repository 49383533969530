import React from 'react';
import {
  components, MenuListProps, OptionsOrGroups, GroupBase,
} from 'react-select';
import { OptionWithSeparator } from './styles';

const { MenuList } = components;

const MenuListWithSeparators = (
  { children, ...props }: MenuListProps<OptionsOrGroups<unknown, GroupBase<unknown>>>,
) => {
  const mappedChildren = () => {
    if (children) {
      return React.Children.map(children, (child, index) => {
        if (React.isValidElement(child)) {
          const component = React.cloneElement(child);
          const length = React.Children.count(children);
          if (index === length - 1 || length === 1) {
            return component;
          }
          return (
            <OptionWithSeparator>
              {component}
            </OptionWithSeparator>
          );
        }
      });
    }
    return children;
  };
  return (
    <MenuList {...props}>
      {mappedChildren()}
    </MenuList>
  );
};

export default MenuListWithSeparators;
