import { Button, colors } from 'designSystem';
import { textSm } from 'designSystem/typings';
import styled, { css } from 'styled-components';
import { ScreenSize } from 'hooks/useWindowDimensions';
import { gray700 } from 'designSystem/colors';

export const Tile = styled.div`
  ${textSm};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  background-color: ${colors.white};
  padding: 30px 24px;
  box-shadow: 0 4px 6px -1px rgba(29, 78, 216, 0.1), 0px 2px 4px -1px rgba(29, 78, 216, 0.06);
  border-radius: 8px;
`;

export const Section = styled.section`
  margin-bottom: 32px;
`;

export const ActionContainer = styled.div`
  display: flex;
  justify-content: right;
  gap: 37px;
`;

export const StyledCanvas = styled.canvas<{screenSize?: ScreenSize}>`
  touch-action: none;

  width: 560px;
  height: 168px;

  border: 1px solid ${colors.gray300};
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  ${({ screenSize }) => {
    switch (screenSize) {
      case 'mobile':
        return css`
          width: 345px;
          `;
      default:
        return css``;
    }
  }};
`;

export const SignatureButtonsRow = styled.div`
  display: flex;
  justify-content: left;
  gap: 32px;
  margin-top: 32px;
`;

export const WideButton = styled(Button)`
  max-width: 320px;
`;

export const WiderNoButton = styled(Button)`
  max-width: 96px;
`;

export const SendContractButtonRow = styled.div`
  display: flex;
  justify-content: center;
  gap: 32px;
  margin-bottom: 32px;
`;

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
  width: 450px;
  max-width: 450px;
  padding: 32px;
  `;

export const FileName = styled.p<{screenSize?: ScreenSize}>`
  ${({ screenSize }) => {
    switch (screenSize) {
      case 'mobile':
        return css`
          width: 45%;
          text-overflow: ellipsis;
          overflow: hidden;
          `;
      default:
        return css``;
    }
  }};
  `;

export const ListItems = styled.p`
  ${textSm}
  line-height: 20px;
  font-weight: 500;
  color: ${gray700}
`;

export const PointsSection = styled.div`
   max-width: 779px;
   margin-left: 31px;
`;

export const ContractorDetailsSection = styled.div`
  margin-top: 40px;
  max-width: 651px;
`;

export const DownloadRef = styled.a`
  display: flex;
  align-items: center;
`;

export const DownloadText = styled.p`
${textSm};
color: ${colors.blue700};
line-height: 20px;
margin-left: 10px;
`;
