import runtimeEnv from '@mars/heroku-js-runtime-env';
import { Modal } from 'common/modal';
import { useWindowDimensions } from 'hooks/useWindowDimensions';
import { Card, CardDescription, CardTitle } from 'domains/accountSetup/onboarding/styles';
import ContractTile from 'domains/project/addProject/contract/homeowner/contractTile';
import SendContractModal from 'domains/project/addProject/contract/homeowner/sendContractModal';
import Signature from 'domains/project/addProject/contract/homeowner/signature/signature';
import { Section, WideButton } from 'domains/project/addProject/contract/homeowner/styles';
import { SignatureState } from 'domains/project/addProject/contract/homeowner/types';
import { UserDetailsHeader, SendChangerOrderSection, StyledCardTitle } from 'domains/project/addProject/contract/styles';
import { SendChangeOrderContractType } from 'domains/project/addProject/contract/types';
import { ChangeOrderForm } from 'domains/project/addProject/contract/changerOder/form/changeOrderForm';
import lang from 'lang/en';
import {
  useEffect, useState, useRef, useMemo,
} from 'react';
import { isEmpty } from 'lodash';
import { useChangeOrderActions } from '../useChangeOrderActions';

const signFlowStrings = lang.contract.homeowner.signFlow;

export const SendChangeOrderContractView = ({
  changeOrderDetails,
  projectData,
  triggerDataUpdate,
}: SendChangeOrderContractType) => {
  const { screenSize } = useWindowDimensions();
  const changeOrderFormRef = useRef<any>();
  const [signatureState, setSignatureState] = useState<SignatureState>('empty');
  const [isSendContractOpen, setIsSendContractOpen] = useState(false);
  const { REACT_APP_IMAGE_URL } = runtimeEnv();
  const {
    getHomeownerFullName, getSignatureData, addContractSignature, sendChangeOrderContract,
    isContractSend,
    sendContractLoading,
    getContract,
    downloadLoading,
  } = useChangeOrderActions(projectData);
  const {
    contract: {
      homeowner, changeOrder: {
        listing: {
          contractHeading, steps: {
            step1, step1Description, step2, step2Description, step3, step3Description,
          },
        },
      },
    },
  } = lang;
  const homewonerFullName = getHomeownerFullName();
  const signatureRef = useRef<any>();

  const handleSignatureUploadFinished = async (signatureUrl: string) => {
    addContractSignature(changeOrderDetails?.id, signatureUrl);
    setSignatureState('submitted');
  };

  useEffect(() => {
    if (isContractSend) {
      setIsSendContractOpen(false);
    }
  }, [isContractSend]);

  const handleSendContract = async () => {
    await sendChangeOrderContract(changeOrderDetails?.id);
    triggerDataUpdate();
  };

  const signatureUrl = getSignatureData(changeOrderDetails);

  useEffect(() => {
    if (isEmpty(signatureUrl)) {
      setSignatureState('empty');
      signatureRef?.current?.click();
    }
  }, [signatureUrl, changeOrderDetails]);

  const preloadedSignatureUrl = useMemo(() => {
    if (!isEmpty(signatureUrl)) {
      return `${REACT_APP_IMAGE_URL}/${signatureUrl}`;
    }
    return '';
  }, [signatureUrl, changeOrderDetails]);

  return (
    <SendChangerOrderSection>
      <Card screenSize={screenSize}>
        <StyledCardTitle screenSize={screenSize}>
          {homeowner.headerText}
        </StyledCardTitle>
        <UserDetailsHeader>
          {homewonerFullName}
        </UserDetailsHeader>
        <UserDetailsHeader>
          {contractHeading}
        </UserDetailsHeader>
        <Section>
          <CardTitle marginBottom="0">
            {step1}
          </CardTitle>
          <CardDescription>
            {step1Description}
          </CardDescription>
          <ContractTile
            contractFileName={changeOrderDetails?.fileName}
            onEditClick={() => changeOrderFormRef && changeOrderFormRef?.current?.click(true)}
            downloadLoading={downloadLoading}
            onDownloadClick={() => getContract(changeOrderDetails?.id)}
          />
        </Section>
        <Section>
          <CardTitle marginBottom="0">
            {step2}
          </CardTitle>
          <CardDescription>
            {step2Description}
          </CardDescription>
          <Signature
            signatureState={signatureState}
            setSignatureState={setSignatureState}
            onSignatureUploadFinished={handleSignatureUploadFinished}
            preloadedImageUrl={preloadedSignatureUrl}
            id={changeOrderDetails?.id}
            ref={signatureRef}
          />
        </Section>
        <Section>
          <CardTitle marginBottom="0">
            {step3}
          </CardTitle>
          <CardDescription>
            {step3Description}
          </CardDescription>
          <WideButton
            width="full"
            type="button"
            variant="primary"
            label={signFlowStrings.step3.sendContractButtonLabel}
            onClick={() => setIsSendContractOpen(true)}
            state={(signatureState === 'submitted' || signatureState === 'preloaded') ? 'active' : 'disabled'}
          />
        </Section>
        <Modal isOpen={isSendContractOpen}>
          <SendContractModal
            sendContractLoading={sendContractLoading}
            handleSend={handleSendContract}
            handleClose={() => setIsSendContractOpen(false)}
          />
        </Modal>
        <ChangeOrderForm
          projectData={projectData}
          ref={changeOrderFormRef}
          triggerDataUpdate={triggerDataUpdate}
          changeOrderDetails={changeOrderDetails}
        />
      </Card>
    </SendChangerOrderSection>
  );
};
