import { yupResolver } from '@hookform/resolvers/yup';
import { useWindowDimensions } from 'hooks/useWindowDimensions';
import { useForm, useFieldArray } from 'react-hook-form';
import lang from 'lang/en';
import { DeleteItem } from 'common/deleteItem';
import {
  useState, useEffect, useMemo,
} from 'react';
import { ActionButtonsContainer } from 'domains/accountSetup/onboarding/styles';
import { Button } from 'designSystem';
import { getButtonState } from 'utils/helpers';
import { notifications } from 'designSystem/toastNotifications/toastNotificationEmitter';
import { useParams } from 'react-router-dom';
import { MilestoneFields } from './milestoneFields';
import { contractorMilestone } from '../../validationSchema';
import { Header, TitleHeading } from '../../styles';
import type { MilestoneActionProps } from '../types';
import { useContractorMilestones } from './useContractorMilestones';
import { ContractorMilestoneData } from '../../types';
import { nonEscrowcontractorMilestones, escrowcontractorMilestones } from '../helper';
import { TotalMilestoneCost } from './totalMilestoneCost';

export const ContractorMiletones = ({
  setPreviousStep, onFormSubmitted, setProjectId, preloadedData, handleSubmitProjectValidation, isEscrow,
} :
   MilestoneActionProps & {
    handleSubmitProjectValidation: (data: Array<{[key:string] : boolean}>) => void;
   }) => {
  const [isOpen, setOpen] = useState(false);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [defaultValues, setDefaultValues] = useState({});
  const currentProjectId = parseInt(useParams<{ id: string }>().id as string, 10);
  const [deleteMilestoneIndex, setDeleteMilestoneIndex] = useState(-1);
  const resolver = { resolver: yupResolver(contractorMilestone) };
  const { screenSize } = useWindowDimensions();
  const {
    saveContractorMilestone, loading, isSaved, error,
  } = useContractorMilestones();

  const {
    control, handleSubmit, getValues, setError, clearErrors,
    formState: { errors, isDirty, isValid }, reset, trigger,
  } = useForm({
    ...resolver,
    mode: 'onChange',
    defaultValues,
  });
  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    if (preloadedData) {
      const formValues = preloadedData?.milestones?.length === 0 && isEscrow !== undefined && isEscrow ? escrowcontractorMilestones : nonEscrowcontractorMilestones;
      const defaultFormValues = { ...formValues, totalCost: preloadedData?.totalCost || null };
      setDefaultValues(defaultFormValues);
      reset(defaultFormValues);
    }
  }, [isEscrow, preloadedData]);

  useEffect(() => {
    if (id) {
      setProjectId(parseInt(id, 10));
    }
  }, [id]);

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'milestones',
  });

  useEffect(() => {
    if (isSaved && !error) {
      onFormSubmitted();
    }
  }, [isSaved, error]);

  useEffect(() => {
    if (preloadedData && preloadedData.milestones && preloadedData?.milestones?.length !== 0) {
      const milestones = preloadedData.milestones?.map(({ name, percentageOverall }) => ({ name, percentageOverall }));
      reset({ milestones, totalCost: preloadedData?.totalCost || null });
      trigger();
      setIsDataLoaded(true);
    }
  }, [preloadedData]);

  const handleAddMilestone = () => append({
    name: '',
    percentageOverall: '',
  });

  const handleShowModal = (index: number) => {
    setOpen(true);
    setDeleteMilestoneIndex(index);
  };

  const handleCloseModal = () => {
    setOpen(false);
    setDeleteMilestoneIndex(-1);
  };

  const handleDelete = (index: number) => {
    remove(index);
    handleCloseModal();
  };

  const onSubmit = (data: ContractorMilestoneData) => {
    handleSubmitProjectValidation([{
      contractorMilestones: data?.milestones ? data.milestones.length > 0 : false,
    }]);
    if (isDataLoaded && !isDirty) {
      onFormSubmitted();
      return;
    }
    const isValidMilestones = validateMilestones();
    if (isValidMilestones) {
      saveContractorMilestone(data, currentProjectId);
    }
  };

  const commulativePercentage = () => {
    const formValues = getValues().milestones;
    const sum = formValues
      .map((item: { name: string; percentageOverall: string}) => Number(item.percentageOverall))
      .reduce((prevValue : number, current: number) => prevValue + current, 0);
    return sum;
  };

  const validateMilestones = () => {
    const totalPercentage = commulativePercentage();
    const isValidSum = Number(totalPercentage) <= 100;
    if (isValidSum) {
      clearErrors('milestones');
      return isValidSum;
    }
    setError('milestones', {
      type: 'manual',
      message: lang.milestoneOverallPercentageErrorText,
    });
    notifications.error(lang.milestoneOverallPercentageErrorText);
    return isValidSum;
  };

  const milestoneFields = fields.map((field, index) => {
    return (
      <MilestoneFields
        control={control}
        key={field.id}
        index={index}
        lastElement={index === fields.length - 1}
        handleShowModal={handleShowModal}
        handleAddMilestone={handleAddMilestone}
        firstElement={index === 0}
        isPercentageError={(errors?.milestones?.index?.percentageOverall) || false}
        isEscrow={isEscrow}
      />
    );
  });

  return (
    <>
      <TitleHeading><Header screenSize={screenSize}>{lang.contractorMilestoneHeading}</Header></TitleHeading>
      <form onSubmit={handleSubmit(onSubmit)}>
        <TotalMilestoneCost control={control} />
        {milestoneFields}
        <ActionButtonsContainer>
          <Button data-testid="back-button" name="back" type="button" variant="secondary" label={lang.backLabel} state="active" onClick={setPreviousStep} />
          <Button data-testid="next-button" name="next" type="submit" variant="primary" label={lang.nextLabel} state={getButtonState(isDirty || isDataLoaded, isValid, loading)} />
        </ActionButtonsContainer>
      </form>
      <DeleteItem
        isOpen={isOpen}
        heading={lang.deleteMilestone}
        subText={lang.deleteMileStoneSubText}
        handleDelete={() => handleDelete(deleteMilestoneIndex)}
        handleCancel={handleCloseModal}
      />
    </>
  );
};
