import { useCallback } from 'react';
import { verifyEmailUrl } from 'utils/network/endpoints';
import { notifications } from 'designSystem/toastNotifications/toastNotificationEmitter';
import { apiClient } from 'utils/network/apiClient';
import Lang from 'lang/en';

const useVerifyEmail = (): {
  verifyEmail: (token: string) => Promise<void>;
} => {
  const verifyEmail = useCallback(
    async (token: string) => {
      const url = `${verifyEmailUrl}/${token}`;
      try {
        const callResponse = await apiClient.post(url);
        if (callResponse.status === 200) {
          notifications.success(Lang.accountActivationSuccessfullMessage);
        } else {
          notifications.error(Lang.accountActivationFailedMessage);
        }
      } catch (callError: unknown) {
        notifications.error((callError as Error)?.message || Lang.accountActivationFailedMessage);
      }
    },
    [apiClient],
  );

  return {
    verifyEmail,
  };
};

export default useVerifyEmail;
