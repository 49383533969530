import {
  Circle, CompletedTagContainer, Label, LightCompletedContainer, PendingTagContainer,
} from 'designSystem/tag/styles';
import { TagProps } from 'designSystem/tag/types';
import { useMemo } from 'react';

const Tag = ({
  label,
  status,
}: TagProps) => {
  const tagContent = useMemo(() => {
    return (
      <>
        <Circle />
        <Label>
          {label}
        </Label>
      </>
    );
  }, [label, status]);

  switch (status) {
    case 'completed':
      return (
        <CompletedTagContainer>
          {tagContent}
        </CompletedTagContainer>
      );
    case 'light-completed':
      return (
        <LightCompletedContainer>
          {tagContent}
        </LightCompletedContainer>
      );
    case 'pending':
    default:
      return (
        <PendingTagContainer>
          {tagContent}
        </PendingTagContainer>
      );
  }
};

export default Tag;
