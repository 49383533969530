import styled, { css } from 'styled-components';
import { white } from 'designSystem/colors';
import type { StyleProps } from './types';

export const CardSection = styled.div<StyleProps>`
  max-width: ${(props) => props.maxWidth ? props.maxWidth : 'auto'};
  width: calc(100vw - 31px);
  padding: ${({ screenSize }) => screenSize === 'mobile' ? '34px 12px' : '31px 60px'}; 

  ${(props) => {
    switch (props.screenSize) {
      case 'mobile':
        return css``;
      case 'desktop':
      default:
        return css`
          height: auto;
          min-height: ${props.minHeight ? props.minHeight : 'none'};
          background-color: ${white};
          border-radius: 8px;
          box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);        
        `;
    }
  }};
`;
