import { useNavigate } from 'react-router-dom';
import { getAuthInfo, isUserAuthourised } from 'utils/helpers';

export const useInactiveUserRedirection = (): {
  redirectInactiveUser: () => void;
  unAuthroisedForHomeowner: () => void;
} => {
  const navigate = useNavigate();
  const { accountType } = getAuthInfo();
  const redirectInactiveUser = () => {
    switch (accountType) {
      case 'contractor':
      case 'subcontractor':
        navigate('/account-setup/onboarding/company');
        break;
      case 'homeowner':
        navigate('/account-setup/onboarding/homeowner');
        break;
      default:
        navigate('/account-setup/type');
        break;
    }
  };

  const unAuthroisedForHomeowner = () => {
    const isActiveUser = isUserAuthourised();
    if (isActiveUser && accountType === 'homeowner') {
      return navigate('/projects');
    }
  };

  return {
    redirectInactiveUser,
    unAuthroisedForHomeowner,
  };
};
