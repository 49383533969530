import { UseFormRegisterReturn } from 'react-hook-form';
import { Checkbox, CheckboxContainer, CheckSpan } from '../../styles';

type RegisteredCheckboxFieldProps = {
  labelText: string;
  registrant: UseFormRegisterReturn;
  value: string;
}

export const RegisteredCheckboxField = ({
  labelText,
  registrant,
  value,
}: RegisteredCheckboxFieldProps) => {
  return (
    <CheckboxContainer>
      <Checkbox type="checkbox" {...registrant} value={value} />
      <CheckSpan />
      <p>
        {labelText}
      </p>
    </CheckboxContainer>
  );
};

export default RegisteredCheckboxField;
