import {
  checkLowerCaseLetter, checkUpperCaseLetter, checkSpecialCharacter, checkNumbers,
} from 'utils/regex';

type ValidationSchema = {
  uppercase: boolean;
  lowercase: boolean;
  number: boolean;
  min: number;
  specialCharacter: boolean
}

export const passwordValidator = (input: string, validation:ValidationSchema) => {
  const isLengthCorrect = (length: number) => input.length >= length;
  const isHasLowerCaseLetter = checkLowerCaseLetter.test(input);
  const isHasUpperCaseLetter = checkUpperCaseLetter.test(input);
  const isHasSpecialLetter = checkSpecialCharacter.test(input);
  const isNumber = checkNumbers.test(input);
  const validations = [];

  if (validation.min) {
    validations.push(
      {
        id: validations.length + 1,
        valid: isLengthCorrect(validation.min),
        message: 'min. 8 characters',
        value: input,
      },
    );
  }

  if (validation.lowercase) {
    validations.push(
      {
        id: validations.length + 1,
        valid: isHasLowerCaseLetter,
        message: '1 lowercase',
        value: input,
      },
    );
  }

  if (validation.uppercase) {
    validations.push(
      {
        id: validations.length + 1,
        valid: isHasUpperCaseLetter,
        message: '1 uppercase',
        value: input,
      },
    );
  }

  if (validation.number) {
    validations.push(
      {
        id: validations.length + 1,
        valid: isNumber,
        message: '1 number',
        value: input,
      },
    );
  }

  if (validation.specialCharacter) {
    validations.push(
      {
        id: validations.length + 1,
        valid: isHasSpecialLetter,
        message: '1 special',
        value: input,
      },
    );
  }

  return validations;
};
export default passwordValidator;
