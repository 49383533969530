import { states } from 'utils/constants';
import { FloridaStateForm } from './floridaStateForm';
import { OhioStateForm } from './ohioStateForm';
import { Connecticut } from './connecticut';
import { MassachusettsStateForm } from './MassachusettsStateForm';
import { DelawareContractFields } from './delaware';

export const manageStates = {
  [states.florida]: {
    component: FloridaStateForm,
  },
  [states.connecticut]: {
    component: Connecticut,
  },
  [states.massachusetts]: {
    component: MassachusettsStateForm,
  },
  [states.delaware]: {
    component: DelawareContractFields,
  },
  [states.ohio]: {
    component: OhioStateForm,
  },
};
