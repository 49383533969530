import Action from 'designSystem/action/action';
import { ReactComponent as DownloadIcon } from 'assets/images/downloadIcon.svg';
import lang from 'lang/en';
import runtimeEnv from '@mars/heroku-js-runtime-env';
import { useWindowDimensions } from 'hooks/useWindowDimensions';
import {
  FileRow, DownloadLink, DownloadText, ActionItems, FileName,
} from './styles';
import { UploadedFileProps } from './types';

const UploadedFile = ({
  fileName, onDeleteAction, documentUrl, showDownload,
}: UploadedFileProps) => {
  const { screenSize } = useWindowDimensions();
  const { REACT_APP_IMAGE_URL } = runtimeEnv();
  return (
    <FileRow>
      <FileName screenSize={screenSize}>
        {fileName}
      </FileName>
      <ActionItems>
        <Action type="delete" onClick={onDeleteAction} label="Delete" name="delete" />
        {
          showDownload
        && (
          <a href={`${REACT_APP_IMAGE_URL}/${documentUrl}`} rel="noreferrer" download>
            <DownloadLink>
              <DownloadIcon />
              <DownloadText>
                {lang.downloadText}
              </DownloadText>
            </DownloadLink>
          </a>
        )
        }
      </ActionItems>

    </FileRow>
  );
};

export default UploadedFile;
