import { ControlledInputField } from 'designSystem/input';
import ControlledNumberMaskedField from 'designSystem/input/maskedNumber/controlledMaskedNumberField';
import lang from 'lang/en';
import { Control, FieldValues } from 'react-hook-form';
import { useWindowDimensions } from 'hooks/useWindowDimensions';
import { Card, CardRow, CardTitle } from '../../../styles';

export type CompanyDetailsProps = {
  control: Control<FieldValues>
}

export const CompanyDetails = ({ control }: CompanyDetailsProps) => {
  const { screenSize } = useWindowDimensions();
  return (
    <Card screenSize={screenSize}>
      <CardTitle>
        {lang.companyDetails}
      </CardTitle>
      <CardRow>
        <ControlledInputField labelText={lang.nameOfCompanyLabel} control={control} name="details.name" />
        <ControlledNumberMaskedField
          maskFormat="## ### ### ### ### ###"
          labelText={lang.registrationNumberLabel}
          control={control}
          name="details.registrationNumber"
        />
      </CardRow>
      <CardRow>
        <ControlledNumberMaskedField
          maskFormat="##-#######"
          labelText={lang.einLabel}
          control={control}
          name="details.ein"
        />
        <ControlledInputField labelText={lang.typeOfContractorLabel} control={control} name="details.typeOfContractor" />
      </CardRow>
    </Card>
  );
};

export default CompanyDetails;
