import { ReactElement, useEffect } from 'react';
import { NavBar } from 'domains/navBar';
import Loader from 'designSystem/loader';
import { useWindowDimensions } from 'hooks/useWindowDimensions';
import { isUserAuthourised } from 'utils/helpers';
import { useInactiveUserRedirection } from 'hooks/useInactiveUserRedirection';
import DesktopListing from './desktopListing';
import { MoblieListing } from './mobileListing';
import { useGetProjectListing } from './useGetProjectListing';

export const Listing = (): ReactElement => {
  const { screenSize } = useWindowDimensions();
  const { response, getProjectListing } = useGetProjectListing();
  const { redirectInactiveUser } = useInactiveUserRedirection();

  useEffect(() => {
    const isActiveUser = isUserAuthourised();
    if (!isActiveUser) {
      redirectInactiveUser();
    }
    getProjectListing();
  }, []);

  if (response?.data) {
    if (screenSize === 'mobile') {
      return (
        <>
          <NavBar />
          <MoblieListing projectListData={response?.data || null} />
        </>
      );
    }
    return (
      <>
        <NavBar />
        <DesktopListing projectListData={response?.data || null} />
      </>
    );
  }
  return <Loader />;
};

export default Listing;
