import { Control } from 'react-hook-form';
import lang from 'lang/en';
import { useMemo } from 'react';
import { CardTitle } from 'domains/accountSetup/onboarding/styles';
import { InspectionSection } from './inspectionSection';
import { FormFieldType } from './types';
import {
  CustomCardRow, MidScreenDescription, MilestonesContainer, NarrowControlledNumberMaskedInput, NarrowControlledInputField,
} from '../../styles';

type MilestonesProps = {
  control: Control;
  formIndex: number;
  isEscrow?: boolean;
}

export const emptySubcontractorMilestones = {
  milestone0Name: '',
  milestone0Completed: false,
  milestone0TownInspection: false,
  milestone0TradesmanInspection: true,
  milestone1Name: '',
  milestone1Completed: false,
  milestone1TownInspection: false,
  milestone1TradesmanInspection: true,
  milestone2Name: '',
  milestone2Completed: false,
  milestone2TownInspection: false,
  milestone2TradesmanInspection: true,
};

const Milestones = ({
  control, formIndex, setValue, getValues, isEscrow,
}: MilestonesProps & FormFieldType) => {
  const milestone2TownInspection = useMemo(() => {
    if (isEscrow) {
      return (
        <InspectionSection
          control={control}
          name={`subContractors.${formIndex}.milestone1TownInspection` as const}
          labelText={lang.subcontractorMilestones.inspection.townInspection}
          labelId={`subContractors.${formIndex}-townInspection-1`}
          setValue={setValue}
          getValues={getValues}
        />
      );
    }
  }, [isEscrow]);

  const milestone3TownInspection = useMemo(() => {
    if (isEscrow) {
      return (
        <InspectionSection
          control={control}
          name={`subContractors.${formIndex}.milestone2TownInspection` as const}
          labelText={lang.subcontractorMilestones.inspection.townInspection}
          labelId={`subContractors.${formIndex}-townInspection-2`}
          setValue={setValue}
          getValues={getValues}
        />
      );
    }
  }, [isEscrow]);

  const milestone3TradesmenInspection = useMemo(() => {
    if (isEscrow) {
      return (
        <InspectionSection
          control={control}
          name={`subContractors.${formIndex}.milestone2TradesmanInspection` as const}
          labelText={lang.subcontractorMilestones.inspection.tradesmanInspection}
          labelId={`subContractors.${formIndex}-tradesmanInspection-2`}
          setValue={setValue}
          getValues={getValues}
        />
      );
    }
  }, [isEscrow]);

  return (
    <MilestonesContainer>
      <CardTitle>
        {lang.subcontractorMilestones.milestones.header}
      </CardTitle>
      <CustomCardRow>
        <NarrowControlledNumberMaskedInput
          numberPrefix="$"
          placeholder="$ 0.00"
          labelText={lang.subcontractorMilestones.milestones.contractorCost}
          control={control}
          name={`subContractors.${formIndex}.cost` as const}
        />
      </CustomCardRow>
      <MidScreenDescription>
        {lang.subcontractorMilestones.milestones.description}
      </MidScreenDescription>
      <CustomCardRow>
        <NarrowControlledInputField
          labelText={`
                ${lang.subcontractorMilestones.milestones.milestone.startText} 
                1
                ${lang.subcontractorMilestones.milestones.milestone.endText} *
              `}
          control={control}
          name={`subContractors.${formIndex}.milestone0Name` as const}
        />
      </CustomCardRow>
      <CustomCardRow>
        <NarrowControlledInputField
          labelText={`
                ${lang.subcontractorMilestones.milestones.milestone.startText} 
                2
                ${lang.subcontractorMilestones.milestones.milestone.endText} *
              `}
          control={control}
          name={`subContractors.${formIndex}.milestone1Name` as const}
        />
      </CustomCardRow>
      {milestone2TownInspection}
      <CustomCardRow>
        <NarrowControlledInputField
          labelText={`
                ${lang.subcontractorMilestones.milestones.milestone.startText} 
                3
                ${lang.subcontractorMilestones.milestones.milestone.endText} *
              `}
          control={control}
          name={`subContractors.${formIndex}.milestone2Name` as const}
        />
      </CustomCardRow>
      {milestone3TownInspection}
      {milestone3TradesmenInspection}
    </MilestonesContainer>
  );
};

export default Milestones;
