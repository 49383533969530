import projectClip from 'assets/images/paper-clip.svg';
//import ProfileIcon from 'assets/images/user.svg';
import SettingsIcon from 'assets/images/settings.svg';
import lang from 'lang/en';

export const pageLinks = [
  {
    id: 1,
    name: lang.linkProjects,
    icon: projectClip,
    link: '/projects',
  },
];

export const otherLinks = [
  {
    id: 2,
    name: lang.linkSettings,
    icon: SettingsIcon,
    link: '/settings',
  },
];

export const desktopOtherLinks = [
  {
    id: 1,
    name: lang.linkSettings,
    icon: SettingsIcon,
    link: '/settings',
  },
];
