import {
  RadioButtonContainer, RadioButtonInput, RadioButtonLabel, RadioButtonSpan, RadioButtonText,
} from './styles';
import type { LabelledRadioButtonProps } from './types';

export const RadioButton = ({
  /*field,*/ name, isChecked, labelText, id, isDisabled, onChange,
}: LabelledRadioButtonProps) => {
  return (
    <RadioButtonContainer>
      <RadioButtonText>{labelText}</RadioButtonText>
      <RadioButtonLabel htmlFor={id}>
        <RadioButtonInput
          type="radio"
          name={name}
          id={id}
          defaultChecked={isChecked}
          disabled={isDisabled}
          onChange={onChange}
        />
        <RadioButtonSpan></RadioButtonSpan>
      </RadioButtonLabel>
    </RadioButtonContainer>
  );
};

export default RadioButton;
