import { colors } from 'designSystem';
import Lang from 'lang/en';
//import { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { StylesConfig, Theme } from 'react-select';
import { InputLabel } from '../input/styles';
import MenuListWithSeparators from './menuListWithSeparators';
import { SelectContainer, StyledSelect } from './styles';
import { ControlledSelectProps, SelectProps } from './types';

const customStyles: StylesConfig<unknown, false> = {
  option: (provided) => {
    return {
      ...provided,
      color: colors.gray900,
      margin: '4px 0px',
    };
  },
};

export const Select = ({
  field,
  placeholder = Lang.selectOptionDefaultPlaceholderText,
  disabled = false,
  options,
  labelText,
  className,
}: SelectProps) => {
  return (
    <SelectContainer className={className}>
      {labelText && <InputLabel htmlFor={field.name}>{labelText}</InputLabel>}
      <StyledSelect
        id={`select-${field.name}`}
        {...field}
        components={{
          IndicatorSeparator: () => null,
          MenuList: MenuListWithSeparators as any,
        }}
        styles={customStyles}
        placeholder={placeholder}
        isDisabled={disabled}
        options={options}
        theme={(theme: Theme) => ({
          ...theme,
          borderRadius: '6px',
          colors: {
            ...theme.colors,
            primary25: colors.gray100,
            primary50: colors.blue100,
            primary: colors.blue100,
          },
        })}
      />
    </SelectContainer>
  );
};

const ControlledSelect = ({
  name,
  control,
  ...propsToPass
}: ControlledSelectProps) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        return <Select {...propsToPass} field={field} />;
      }}
    />
  );
};

export default ControlledSelect;
