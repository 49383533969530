import { useState, useEffect } from 'react';
import { Step, StepState } from 'designSystem/steps/types';

const updateStepsState = (currentStep: number): (step: Step, index: number) => Step => {
  return (stepState, index) => {
    if (index < currentStep) {
      return {
        ...stepState,
        stepState: 'completed' as StepState,
      };
    }
    if (index === currentStep) {
      return {
        ...stepState,
        stepState: 'started' as StepState,
      };
    }
    return stepState;
  };
};

export const useStepsState = (initialSteps: Array<Step>) => {
  const [stepsState, setStepsState] = useState(initialSteps);
  const [currentStep, setCurrentStep] = useState(0);

  useEffect(() => {
    const newStepsState = initialSteps.map(updateStepsState(currentStep));
    setStepsState(newStepsState);
  }, [currentStep, initialSteps]);

  return {
    stepsState,
    currentStep,
    setCurrentStep,
  };
};
