import lang from 'lang/en';
import * as Yup from 'yup';

import { floridaStateFieldSchema } from 'domains/project/addProject/contract/homeowner/form/floridaStateForm/validationSchema';
import { states } from 'utils/constants';
import { massachusettsStateFieldSchema } from './form/MassachusettsStateForm/validationSchema';
import { delawareStateFieldSchema } from './form/delaware/validationSchema';

export const defaultSchema = {
  state: Yup.object().shape({ value: Yup.string(), label: Yup.string() }).required(lang.missingInformationLabel).nullable(),
  additionalHomeOwnerName: Yup.string().nullable(),
  startDate: Yup.string().nullable().required(lang.missingInformationLabel),
  completionDate: Yup.string().nullable().required(lang.missingInformationLabel),
  substantialCompletionDate: Yup.string().nullable().required(lang.missingInformationLabel),
  projectCostModel: Yup.string().nullable().required(lang.missingInformationLabel),
  previousCorporation: Yup.string().nullable(),
  totalAllowance: Yup.string().nullable(),
  workDescription: Yup.string().nullable().required(lang.missingInformationLabel),
  paymentSchedule: Yup.string().when('escrow', (escrow: string) => {
    return escrow ? Yup.string().nullable() : Yup.string().nullable().required(lang.missingInformationLabel);
  }),
  contractorAuthorizedRepresentative: Yup.string().nullable(),
  homeOwnerAuthorizedRepresentative: Yup.string().nullable(),
  contractorInsuranceOption: Yup.string().nullable().required(lang.missingInformationLabel),
  contractorInsuranceProviderName: Yup.string().when('contractorInsuranceOption', (contractorInsuranceOption: string) => {
    return contractorInsuranceOption === 'commercialLiabilityInsurance' ? Yup.string().nullable().required(lang.missingInformationLabel) : Yup.string().nullable();
  }),
  workerCompensationInsuranceOption: Yup.string().nullable().required(lang.missingInformationLabel),
  workerCompensationInsuranceLimit: Yup.string().when('workerCompensationInsuranceOption', (workerCompensationInsuranceOption: string) => {
    return workerCompensationInsuranceOption === 'hasCompensationInsurance' ? Yup.string().nullable().required(lang.missingInformationLabel) : Yup.string().nullable();
  }),
  contractFileName: Yup.string().nullable().required(lang.missingInformationLabel),
};

export const otherStateSchema = {
  [states.florida]: floridaStateFieldSchema,
  [states.massachusetts]: massachusettsStateFieldSchema,
  [states.delaware]: delawareStateFieldSchema,
};
