import { Controller } from 'react-hook-form';
import { ReactElement } from 'react';
import type { MaskedControlledInputFieldProps } from './types';
import NumberMaskedField from './maskedNumberField';

const ControlledNumberMaskedField = ({
  name, control, ...propsToPass
}: MaskedControlledInputFieldProps): ReactElement => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => {
        const { error } = fieldState;
        return <NumberMaskedField {...propsToPass} field={field} errorMessage={error?.message} />;
      }}
    />
  );
};

export default ControlledNumberMaskedField;
