import * as Yup from 'yup';
import lang from 'lang/en';

const {
  emailFormat, emailRequired, passwordRequired, passwordValidationCheck, consent,
  passwordValidationText,
} = lang;

export const signupValidationSchema = Yup.object().shape({
  email: Yup.string().email(emailFormat)
    .required(emailRequired).nullable(),
  password: Yup.string().required(passwordRequired)
    .min(8, passwordValidationCheck)
    .matches(/[A-Z]/, passwordValidationCheck)
    .matches(/[a-z]/, passwordValidationCheck)
    .matches(/[#?!@$%^&*-]/, passwordValidationCheck)
    .matches(/[0-9]/, passwordValidationCheck)
    .nullable(),
  termsAndConditions: Yup.boolean().oneOf([true], consent).required(consent),
});

export const signinValidationSchema = Yup.object().shape({
  email: Yup.string().email(emailFormat)
    .required(emailRequired).nullable(),
  password: Yup.string().required(passwordRequired)
    .min(8, passwordValidationText)
    .matches(/[A-Z]/, passwordValidationText)
    .matches(/[a-z]/, passwordValidationText)
    .matches(/[#?!@$%^&*-]/, passwordValidationText)
    .matches(/[0-9]/, passwordValidationText)
    .nullable(),
});
