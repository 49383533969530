import Loader from 'designSystem/loader';
import Steps from 'designSystem/steps/steps';
import { NavBar } from 'domains/navBar';
import { PreloadedDataType, ValidationType } from 'domains/project/addProject/types';
import { useFillExistingData } from 'domains/project/useFillExistingData';
import { useSubmitProjectValidation } from 'domains/project/useSubmitProjectValidation';
import { saveAddProjectValidation } from 'domains/project/addProject/data';
import { Centerlized } from 'domains/userAuth/styles';
import useScrollTop from 'hooks/scrollTop';
import { useStepsState } from 'hooks/useStepsState';
import { useBlockBrowserBackButton } from 'hooks/useBlockBrowserBackButton';
import {
  ReactElement, useEffect, useState,
} from 'react';
import {
  Route, Routes, useLocation, useNavigate,
} from 'react-router-dom';
import { getAuthInfo, isUserAuthourised } from 'utils/helpers';
import { useInactiveUserRedirection } from 'hooks/useInactiveUserRedirection';
import Contracts from './contract';
import Details from './details';
import { initialSteps, stepToRoute } from './helpers';
import { ContractorMiletones, SubcontractorMilestones } from './milestones';
import { Container, StepsContainer } from './styles';
import { SubmitProject } from './submit';
import { ProjectSuccess } from './success';

const ProjectAdd = (): ReactElement => {
  const {
    stepsState,
    currentStep,
    setCurrentStep,
  } = useStepsState(initialSteps);
  const {
    projectData,
    loader,
    setTriggerDataUpdate,
    setCurrentProjectId,
    currentProjectId,
  } = useFillExistingData(currentStep);
  const { redirectInactiveUser, unAuthroisedForHomeowner } = useInactiveUserRedirection();

  const [currentRoute, setCurrentRoute] = useState<string>(stepToRoute(currentStep, currentProjectId as number));
  const navigate = useNavigate();
  const location = useLocation();
  const { blockBackButton } = useBlockBrowserBackButton();
  const isActiveUser = isUserAuthourised();
  const isSuccessPage = location.pathname === '/projects/add/success';

  useEffect(() => {
    if (!isActiveUser) {
      redirectInactiveUser();
    }
  });

  useEffect(() => {
    unAuthroisedForHomeowner();
  }, []);

  useEffect(() => {
    blockBackButton();
  }, []);

  useEffect(() => {
    if (!isSuccessPage) {
      setCurrentRoute(stepToRoute(currentStep, currentProjectId as number));
    }
  }, [currentStep, currentProjectId]);

  useEffect(() => {
    if (!isSuccessPage) {
      const { accountType } = getAuthInfo();
      if (currentRoute && accountType !== 'homeowner') {
        useScrollTop();
        navigate(currentRoute);
      }
    }
  }, [currentRoute]);

  const {
    projectSubmitValidation,
    handleSubmitProjectValidation,
  } = useSubmitProjectValidation(projectData as PreloadedDataType, saveAddProjectValidation as ValidationType);

  return (
    <>
      {loader && <Loader />}
      <NavBar />
      <Centerlized>
        <Container>
          {!isSuccessPage && loader === false
            && (
              <StepsContainer>
                <Steps
                  steps={stepsState}
                  setCurrentStep={setCurrentStep}
                />
              </StepsContainer>
            )}

          <Routes>
            <Route path="details">
              <Route
                path=""
                element={(
                  <Details
                    setProjectId={(id) => setCurrentProjectId(id)}
                    onFormSubmitted={() => setCurrentStep(1)}
                    preloadedData={projectData?.details}
                    handleSubmitProjectValidation={handleSubmitProjectValidation}
                    projectStatus={projectData?.status}
                    contractorId={projectData?.contractorId}
                  />
                )}
              />
              <Route
                path=":id"
                element={(
                  <Details
                    setProjectId={(id) => setCurrentProjectId(id)}
                    onFormSubmitted={() => setCurrentStep(1)}
                    preloadedData={projectData?.details}
                    handleSubmitProjectValidation={handleSubmitProjectValidation}
                    projectStatus={projectData?.status}
                    contractorId={projectData?.contractorId}
                  />
                )}
              />
            </Route>
            <Route
              path="contractor-milestones/:id"
              element={(
                <ContractorMiletones
                  setProjectId={(id) => setCurrentProjectId(id)}
                  onFormSubmitted={() => setCurrentStep(2)}
                  setPreviousStep={() => setCurrentStep(0)}
                  preloadedData={{
                    milestones: projectData?.milestones,
                    totalCost: projectData?.totalCost,
                  }}
                  handleSubmitProjectValidation={handleSubmitProjectValidation}
                  isEscrow={projectData?.details?.escrow}
                />
              )}
            />
            <Route
              path="subcontractor-milestones/:id"
              element={(
                <SubcontractorMilestones
                  setProjectId={(id) => setCurrentProjectId(id)}
                  onFormSubmitted={() => setCurrentStep(3)}
                  setPreviousStep={() => setCurrentStep(1)}
                  preloadedData={{ subContractors: projectData?.subContractors }}
                  isEscrow={projectData?.details?.escrow}
                />
              )}
            />
            <Route
              path="contract/:id"
              element={(
                <Contracts
                  setProjectId={(id) => setCurrentProjectId(id)}
                  onFormSubmitted={() => setCurrentStep(4)}
                  setPreviousStep={() => setCurrentStep(2)}
                  preloadedData={{
                    contract: {},
                    projectData,
                  }}
                  triggerDataUpdate={() => setTriggerDataUpdate(true)}
                />
              )}
            />
            <Route
              path="submit-save/:id"
              element={(
                <SubmitProject
                  setProjectId={(id) => setCurrentProjectId(id)}
                  projectSubmitValidation={projectSubmitValidation}
                  updateCurrentStep={(step) => setCurrentStep(step)}
                />
              )}
            />
            <Route
              path="success"
              element={(
                <ProjectSuccess />
              )}
            />
          </Routes>
        </Container>
      </Centerlized>

    </>
  );
};

export default ProjectAdd;
