import { WorkersCompensationInsuranceSelectProps } from 'domains/project/addProject/contract/homeowner/types';
import {
  LabelWithMargin, RadioContainer, ErrorMessage, ErrorText,
} from 'domains/project/addProject/contract/styles';
import { MediumControlledNumberMaskedInput } from 'domains/project/addProject/styles';
import lang from 'lang/en';
import { useEffect } from 'react';

const SubcontractorsWorkersCompensationInsuranceSelect = ({
  registrant,
  control,
  workerCompensationInsuranceLimitValue,
  setValue,
  errorMsg,
}: WorkersCompensationInsuranceSelectProps) => {
  const modalStrings = lang.contract.subcontractors.modal;

  useEffect(() => {
    if (workerCompensationInsuranceLimitValue !== 'hasCompensationInsurance') {
      setValue('subcontractorWorkerCompensationInsuranceLimit', null);
    }
  }, [workerCompensationInsuranceLimitValue]);

  return (
    <RadioContainer>
      <LabelWithMargin htmlFor="subcontractorExemptFromInsurance">
        <input
          {...registrant}
          type="radio"
          value="exemptFromInsurance"
          id="subcontractorExemptFromInsurance"
        />
        {modalStrings.form.optionsForSubcontractorsWorkersCompensationInsurance.option1}
      </LabelWithMargin>
      <LabelWithMargin htmlFor="subcontractorHasCompensationInsurance">
        <input
          {...registrant}
          type="radio"
          value="hasCompensationInsurance"
          id="subcontractorHasCompensationInsurance"
        />
        {modalStrings.form.optionsForSubcontractorsWorkersCompensationInsurance.option2}
      </LabelWithMargin>
      <MediumControlledNumberMaskedInput
        placeholder="$ 0.00"
        numberPrefix="$"
        fixedDecimalScale
        control={control}
        name="subcontractorWorkerCompensationInsuranceLimit"
        labelText={modalStrings.form.optionsForSubcontractorsWorkersCompensationInsurance.option2InputFieldLabel}
        disabled={workerCompensationInsuranceLimitValue !== 'hasCompensationInsurance'}
        errorIconTopPosition="47px"
        errorIconPosition="286px"
      />
      <ErrorMessage margin="0 0 0 12px"><ErrorText>{errorMsg}</ErrorText></ErrorMessage>

    </RadioContainer>
  );
};

SubcontractorsWorkersCompensationInsuranceSelect.defaultProps = {
  errorMsg: '',
};

export default SubcontractorsWorkersCompensationInsuranceSelect;
