export const blue50 = '#EFF6FF';
export const blue100 = '#DBEAFE';
export const blue200 = '#BFDBFE';
export const blue300 = '#93C5FD';
export const blue400 = '#60A5FA';
export const blue600 = '#2563EB';
export const blue700 = '#1D4ED8';
export const blue800 = '#1E40AF';
export const blue900 = '#1E3A8A';
export const blueGrey = '#8DA0C1';
export const disabledColor = '#456397';

export const teal100 = '#CCFBF1';

export const gray50 = '#F9FAFB';
export const gray89 = '#E3E3E3';
export const gray100 = '#F3F4F6';
export const gray200 = '#E5E7EB';
export const gray300 = '#D1D5DB';
export const gray500 = '#6B7280';
export const gray700 = '#374151';
export const gray900 = '#111827';
export const gray5 = '#394A64';

export const green100 = '#D1FAE5';
export const green400 = '#34D399';
export const green500 = '#10B981';
export const green800 = '#065F46';

export const red50 = '#FEF2F2';
export const red100 = '#FEE2E2';
export const red300 = '#FCA5A5';
export const red500 = '#EF4444';
export const red700 = '#B91C1C';
export const red800 = '#991B1B';
export const red900 = '#7F1D1D';

export const white = '#FFFFFF';
export const black = '#000000';

export const orange100 = '#FFEDD5';
export const orange200 = '#FED7AA';
export const orange900 = '#7C2D12';

export const purple100 = '#EDE9FE';
export const purple800 = '#5B21B6';

export const pink800 = '#9D174D';

export const yellow700 = '#B45309';
export const yellow50 = '#FFFBEB';

const colors = {
  blue50,
  blue100,
  blue200,
  blue300,
  blue400,
  blue600,
  blue700,
  blue800,
  blue900,
  blueGrey,
  disabledColor,
  teal100,
  white,
  gray50,
  gray89,
  gray100,
  gray300,
  gray500,
  gray700,
  gray900,
  green400,
  green500,
  green800,
  green100,
  red50,
  red100,
  red300,
  red500,
  red700,
  red800,
  red900,
  black,
  orange100,
  orange200,
  orange900,
  purple100,
  purple800,
  pink800,
  yellow700,
  yellow50,
  gray200,
};

export default colors;
