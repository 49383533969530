import { useEffect, useCallback } from 'react';
import { useSaveForm } from 'utils/form/useSaveForm';
import { notifications } from 'designSystem/toastNotifications/toastNotificationEmitter';
import { saveProjectUrl } from 'utils/network/endpoints';
import { ContractorMilestoneData } from '../../types';

export const useContractorMilestones = () : {
  isSaved: boolean,
  saveContractorMilestone: (data: ContractorMilestoneData, id: number) => void;
  loading: boolean;
  error: string;
} => {
  const {
    loading, save, error, isSaved,
  } = useSaveForm();

  useEffect(() => {
    if (error) {
      notifications.error(error);
    }
  }, [error]);

  const saveContractorMilestone = useCallback(async (data: ContractorMilestoneData, id: number) => {
    if (id) {
      const projectId = id.toString();
      const { milestones, totalCost } = data;
      if (totalCost) {
        const payload = {
          milestones,
          totalCost: parseFloat(totalCost),
        };

        const url = saveProjectUrl.replace('%projectId%', projectId);
        await save(url, payload, 'patch');
      }
    }
  }, [save]);
  return {
    saveContractorMilestone,
    loading,
    error,
    isSaved,
  };
};
