/* eslint-disable react/jsx-closing-tag-location */
import lang from 'lang/en';
import { ReactComponent as SearchIcon } from 'assets/images/nolist.svg';
import { isContractor } from 'utils/helpers';
import { ListingEmptyStateCss } from '../styles';

export const ListingEmptyState = () => {
  return (
    <ListingEmptyStateCss>
      <SearchIcon />
      <p>
        {lang.noProjectListingContent}
        {isContractor() && lang.noProjectListingContractor}
      </p>
    </ListingEmptyStateCss>
  );
};
