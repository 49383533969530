import {
  CardRow,
} from 'domains/accountSetup/onboarding/styles';
import lang from 'lang/en';
import { InputField } from 'designSystem/input';
import { UserFoundDisplayProps } from 'domains/project/addProject/details/userSearchAndDisplayCard/types';
import { useGetField } from 'domains/project/addProject/details/userSearchAndDisplayCard/useGetField';
import { CustomInputField, CustomNumberMaskedField } from 'domains/project/addProject/details/userSearchAndDisplayCard/styles';

export const DisplayUserData = ({ userData, customRender }: UserFoundDisplayProps) => {
  const { getField } = useGetField();

  return (
    <>
      <CardRow>
        <InputField disabled field={{ ...getField('firstName', userData.firstName) }} labelText={lang.firstNameLabel} />
        <InputField disabled field={{ ...getField('lastName', userData.lastName) }} labelText={lang.lastNameLabel} />
      </CardRow>
      <CardRow>
        <CustomInputField disabled field={{ ...getField('email', userData.email) }} labelText={lang.emailLabel} />
      </CardRow>
      <CardRow>
        <CustomNumberMaskedField
          disabled
          field={{ ...getField('phone', userData.phone) }}
          labelText={lang.phoneLabel}
          maskFormat="+1(###) ###-##-##"
        />
      </CardRow>
      {customRender}
    </>
  );
};
