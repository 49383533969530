import { useCallback, useEffect } from 'react';
import { useSaveForm } from 'utils/form/useSaveForm';
import { homeownerBaseUrl } from 'utils/network/endpoints';
import { notifications } from 'designSystem/toastNotifications/toastNotificationEmitter';
import { HomeownerData } from './types';

export const useSaveHomeownerData = (): {
  saveHomeownerData: (data: HomeownerData) => Promise<void>;
  loading: boolean;
  isSaved: boolean;
  error: string;
} => {
  const {
    loading, error, save, isSaved,
  } = useSaveForm<HomeownerData>();

  useEffect(() => {
    if (error) {
      notifications.error(error);
    }
  }, [error]);

  const saveHomeownerData = useCallback(
    async (data: HomeownerData) => {
      const url = homeownerBaseUrl;

      await save(url, data.homeowner, 'post');
    },
    [save],
  );

  return {
    loading,
    error,
    saveHomeownerData,
    isSaved,
  };
};
