import moment from 'moment';

export const dateStringFormat = (date?: string | Date, formatType?: string) => {
  return moment(date).format(formatType);
};

export const defaultDateFormat = (date: string | Date) => {
  if (date) {
    return new Date(date);
  }
};

export const defaultDateFormatString = 'MM-DD-YY';
export const dateFormatStartWithYear = 'yy-MM-DD';
