const LoaderIcon = () => {
  return (
    <svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.16667 10.0001C1.16667 14.6026 4.89751 18.3334 9.50001 18.3334C14.1025 18.3334 17.8333 14.6026 17.8333 10.0001C17.8333 5.39758 14.1025 1.66675 9.50001 1.66675"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <animateTransform
          attributeName="transform"
          attributeType="XML"
          type="rotate"
          dur="1s"
          from="0 10 10"
          to="360 10 10"
          repeatCount="indefinite"
        />
      </path>
    </svg>
  );
};

export default LoaderIcon;
