import { textLg, textBaseNormal } from 'designSystem/typings';
import { black } from 'designSystem/colors';
import styled from 'styled-components';

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 48px 23px;
`;

export const Heading = styled.h1`
  ${textLg}
  color: ${black}
  text-transform: uppercase;
  margin-top: 40px;
  margin-bottom: 32px;
`;

export const TextSection = styled.div`
  padding: 32px 31px;
  & p:nth-child(2) {
    margin-top: 23px;
  }
`;

export const PlainText = styled.p`
  ${textBaseNormal}
  color: ${black};
  text-align: center;
`;

export const ButtonSpacer = styled.div`
  margin-top: 32px;
`;
