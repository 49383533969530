import { apiClient } from 'utils/network/apiClient';
import { s3PresignedUrl } from 'utils/network/endpoints';
import { Callback, SigningFile } from './types';

export const useGetS3PresignedUrl = (apiUrl?: string) => {
  const getSignedUrl = async (file: SigningFile, callback: Callback) => {
    try {
      const presignedUrl = apiUrl || s3PresignedUrl;
      const requestUrl = `${presignedUrl}?file=${file.name}`;
      const { data } = await apiClient.get(requestUrl);
      const url = data.data.presignedUrl;
      callback({ signedUrl: url, fileKey: data.data.key });
    } catch (requestError: any) {
      console.error(requestError);
    }
  };

  return {
    getSignedUrl,
  };
};
