import { Subcontractor, SubcontractorDTO, Subcontractors } from '../../types';

export function prepareDataToSubmit(data: Subcontractors) {
  return {
    subContractors: data.subContractors
      ?.filter((subcontractor: Subcontractor) => subcontractor.subcontractorId !== '')
      .map((subcontractor: Subcontractor) => {
        const {
          subcontractorId,
          id,
          cost,
          ...flatMilestones
        } = subcontractor;

        const mappedMilestones = [
          {
            id: flatMilestones?.milestone0id,
            name: flatMilestones.milestone0Name,
            townInspection: false,
            tradesmanInspection: false,
          },
          {
            id: flatMilestones?.milestone1id,
            name: flatMilestones.milestone1Name,
            townInspection: flatMilestones.milestone1TownInspection,
            tradesmanInspection: false,
          },
          {
            id: flatMilestones?.milestone2id,
            name: flatMilestones.milestone2Name,
            townInspection: flatMilestones.milestone2TownInspection,
            tradesmanInspection: flatMilestones.milestone2TradesmanInspection,
          },
        ];
        return {
          subContractorId: subcontractorId,
          totalCost: parseFloat(cost),
          milestones: mappedMilestones,
          id,
        };
      }),
  };
}

export function prepareDataToPreload(subContractors: Array<SubcontractorDTO> | undefined) {
  if (!subContractors) {
    return [];
  }
  return subContractors
    .filter((subcontractor: SubcontractorDTO) => subcontractor.subContractorId && subcontractor.subContractorId !== '')
    .map((subcontractor: SubcontractorDTO) => {
      const {
        subContractorId,
        totalCost,
        milestones,
        id,
      } = subcontractor;
      let mappedMilestones;
      if (milestones) {
        mappedMilestones = {
          milestone0id: milestones[0]?.id,
          milestone0Name: milestones[0]?.name,
          milestone0TownInspection: milestones[0]?.townInspection,
          milestone0TradesmanInspection: milestones[0]?.tradesmanInspection,
          milestone1id: milestones[1]?.id,
          milestone1Name: milestones[1]?.name,
          milestone1TownInspection: milestones[1]?.townInspection,
          milestone1TradesmanInspection: milestones[1]?.tradesmanInspection,
          milestone2id: milestones[2]?.id,
          milestone2Name: milestones[2]?.name,
          milestone2TownInspection: milestones[2]?.townInspection,
          milestone2TradesmanInspection: milestones[2]?.tradesmanInspection,
        };
      }
      return {
        subcontractorId: subContractorId,
        id,
        cost: totalCost,
        ...mappedMilestones,
      };
    });
}
