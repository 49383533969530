import Action from 'designSystem/action/action';
import lang from 'lang/en';
import { useWindowDimensions } from 'hooks/useWindowDimensions';
import { byteArrayToBase64, downloadPDFfile, getAuthInfo } from 'utils/helpers';
import { useEffect, useMemo, useState } from 'react';
import { ReactComponent as DownloadIcon } from 'assets/images/downloadIcon.svg';
import runtimeEnv from '@mars/heroku-js-runtime-env';
import {
  ActionContainer, Tile, FileName, DownloadRef, DownloadText,
} from './styles';
import { ContractTileProps } from './types';

const signFlowStrings = lang.contract.homeowner.signFlow;

const ContractTile = ({
  contractFileName,
  onDownloadClick,
  downloadLoading,
  onEditClick,
  onDeleteClick,
  validateDownloadRequest,
  contractDocumentUrl,
}: ContractTileProps) => {
  const { screenSize } = useWindowDimensions();
  const { accountType } = getAuthInfo();
  const { REACT_APP_IMAGE_URL } = runtimeEnv();

  const [fileName, setFileName] = useState(contractFileName);

  useEffect(() => {
    setFileName(contractFileName);
  }, [contractFileName]);

  const handleDownloadClick = async () => {
    if (onDownloadClick) {
      const responseData = await onDownloadClick();
      const base64data = await byteArrayToBase64(responseData.data);
      downloadPDFfile(base64data, `${fileName}.pdf`);
    }
  };
  const isMobile = screenSize === 'mobile';
  const downloadLabel = !isMobile ? signFlowStrings.step1.downloadButtonLabel : '';
  const editLabel = !isMobile ? signFlowStrings.step1.editButtonLabel : '';
  const deleteLabel = !isMobile ? signFlowStrings.deleteContract.deleteButtonLabel : '';

  const manageDownloading = useMemo(() => {
    if (validateDownloadRequest) {
      return (
        onDownloadClick && (
          <Action state={downloadLoading ? 'loading' : 'active'} type="download" label={downloadLabel} onClick={handleDownloadClick} />
        )
      );
    }
    if (!validateDownloadRequest && contractDocumentUrl) {
      return (
        <DownloadRef href={`${REACT_APP_IMAGE_URL}/${contractDocumentUrl}`} rel="noreferrer" download>
          <DownloadIcon />
          {!isMobile && <DownloadText>{lang.downloadText}</DownloadText> }
        </DownloadRef>
      );
    }
  }, [validateDownloadRequest, contractDocumentUrl, isMobile, downloadLoading, downloadLabel, fileName]);

  return (
    <Tile>
      <FileName screenSize={screenSize}>{contractFileName}</FileName>
      <ActionContainer>
        {manageDownloading}
        {onEditClick && accountType !== 'homeowner' && <Action type="edit" label={editLabel} onClick={onEditClick} />}
        {onDeleteClick && <Action type="delete" label={deleteLabel} onClick={onDeleteClick} />}
      </ActionContainer>
    </Tile>
  );
};

ContractTile.defaultProps = {
  validateDownloadRequest: true,
  contractDocumentUrl: null,
};

export default ContractTile;
