import { RadioButton } from 'designSystem/input/radioBox/radioButton';
import lang from 'lang/en';
import { subcontractorUrl } from 'utils/network/endpoints';
import {
  RadioSection, CompletedText, InspectionSection,
} from '../styles';
import { useInspectionSaveData } from './useInspectionSaveData';
import { InspectionProps } from '../types';

export const Inspection = ({
  labelText, milestoneId, inspectionRequiredStatus, fieldName,
  setInspectionRequired,
}:InspectionProps) => {
  const { saveInspectionData } = useInspectionSaveData(`${subcontractorUrl}`);
  const updateInspectionStatus = (value: boolean) => {
    setInspectionRequired(value);
    if (milestoneId) saveInspectionData(fieldName, value, milestoneId);
  };

  return (
    <InspectionSection margin="30px 0 23px 0" key={milestoneId}>
      <CompletedText>{labelText}</CompletedText>
      <RadioSection>
        <RadioButton
          name={`${fieldName}-${milestoneId}`}
          labelText={lang.subcontractorMilestones.milestones.completed.yesOption}
          id={`inspection-yes-${milestoneId}`}
          isChecked={inspectionRequiredStatus !== undefined && inspectionRequiredStatus}
          onChange={() => updateInspectionStatus(true)}
          isDisabled
        />
        <RadioButton
          name={`${fieldName}-${milestoneId}`}
          labelText={lang.subcontractorMilestones.milestones.completed.noOption}
          id={`inspection-no-${milestoneId}`}
          onChange={() => updateInspectionStatus(false)}
          isChecked={inspectionRequiredStatus !== undefined && !inspectionRequiredStatus}
          isDisabled
        />
      </RadioSection>
    </InspectionSection>
  );
};

export default Inspection;
