import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from 'designSystem';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import {
  clearAuthInfo, getAuthInfo, getButtonState,
} from 'utils/helpers';
import lang from 'lang/en';
import { companyDataValidationSchema } from '../validationSchema';
import CompanyDetails from './details/companyDetails';
import CompanyOwners from './owners/companyOwners';
import { ActionButtonsContainer } from '../styles';
import { CompanyData } from '../types';
import { useSaveCompanyData } from './useSaveCompanyData';
import type { CompanyDataDTO } from '../types';

type CompanyProps = {
  onFormSubmitted: () => void;
  onboardingDetails?: CompanyDataDTO;
}

const DetailsOwners = ({ onFormSubmitted, onboardingDetails }: CompanyProps) => {
  const {
    loading,
    saveCompanyData,
    isSaved,
  } = useSaveCompanyData();
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const navigate = useNavigate();
  const { user } = getAuthInfo();
  const resolver = { resolver: yupResolver(companyDataValidationSchema) };
  const {
    handleSubmit, control, formState, reset, trigger,
  } = useForm(
    {
      ...resolver,
      mode: 'onChange',
      defaultValues: {
        details: {
          name: '', registrationNumber: null, ein: null, typeOfContractor: '',
        },
        owners: [{
          firstName: '',
          lastName: '',
          email: user,
          country: 'United States',
          streetAddress: '',
          city: '',
          state: '',
          zip: '',
          phone: null,
        }],
      },
    },
  );

  const { isDirty, isValid } = formState;

  const onSubmit = async (data: CompanyData) => {
    if (isDirty) {
      await saveCompanyData(data);
    } else {
      onFormSubmitted();
    }
  };

  useEffect(() => {
    if (onboardingDetails) {
      const { owners, ...details } = onboardingDetails;
      const ownersData = owners.map((owner) => {
        const { id: ownerId, ...rest } = owner;
        return { ...rest };
      });
      const { id, ...detailsWithoutId } = details;
      reset({ details: { ...detailsWithoutId }, owners: [...ownersData] });
      setIsDataLoaded(true);
      trigger();
    }
  }, [onboardingDetails]);

  useEffect(() => {
    if (isSaved) {
      onFormSubmitted();
    }
  }, [isSaved]);

  const handleBackToSignin = () => {
    clearAuthInfo();
    navigate('/signin');
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <CompanyDetails control={control} />
      <CompanyOwners control={control} />
      <ActionButtonsContainer>
        <Button name="back" type="button" variant="secondary" label={lang.backLabel} state="active" onClick={handleBackToSignin} />
        <Button name="next" type="submit" variant="primary" label={lang.nextLabel} state={getButtonState(isDirty || isDataLoaded, isValid, loading)} />
      </ActionButtonsContainer>
    </form>
  );
};

export default DetailsOwners;
