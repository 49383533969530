import AddProjectSubcontractorMilestones from 'domains/project/addProject/milestones/subcontractor';
import {
  Header, NoDataFoundText, TitleHeadingCol, HeaderDesc,
} from 'domains/project/addProject/styles';
import { useWindowDimensions } from 'hooks/useWindowDimensions';
import lang from 'lang/en';
import { projectSubmittedByContractor } from 'utils/helpers';
import type { SubContractorMilestoneDataTypes } from '../types';
import { ViewMilestones } from './viewMilestones';

export const SubcontractorMilestones = ({
  setPreviousStep, onFormSubmitted, setProjectId, milestoneData,
} : SubContractorMilestoneDataTypes) => {
  const { screenSize } = useWindowDimensions();
  const {
    preloadedData, isEscrow, projectStatus, contractorId, projectId,
  } = milestoneData;

  if (projectSubmittedByContractor(projectStatus, contractorId)) {
    return (
      <AddProjectSubcontractorMilestones
        setPreviousStep={setPreviousStep}
        onFormSubmitted={onFormSubmitted}
        setProjectId={setProjectId}
        preloadedData={preloadedData}
        isEscrow={isEscrow}
      />
    );
  }

  return (
    <>
      <TitleHeadingCol>
        <Header screenSize={screenSize}>{lang.subcontractorMilestones.header}</Header>
        <HeaderDesc screenSize={screenSize}>{lang.subcontractorMilestones.headerDesc}</HeaderDesc>
      </TitleHeadingCol>
      {
        preloadedData?.subContractors?.length === 0
          ? <NoDataFoundText marginTop="60px">{lang.milestoneUpload.noDataFound}</NoDataFoundText>
          : preloadedData && preloadedData.subContractors && preloadedData.subContractors.map((item, index) => {
            return (
              <ViewMilestones
                subContractorMilestone={item}
                index={index}
                projectStatus={projectStatus}
                contractorId={contractorId}
                isEscrow={isEscrow}
                projectId={projectId}
              />
            );
          })
      }
    </>
  );
};
