import { ButtonState } from 'designSystem/button/types';
import { CompanyOwnerDTO } from '../domains/accountSetup/onboarding/company/types';
import { projectStatus, userRole } from './constants';
import { apiClient } from './network/apiClient';

export type AccountStatus =
  | 'active'
  | 'disabled'
  | 'notConfirmed'
  | 'notOnboarded'
  | 'missingAccountType';

export type AccountType =
  | 'contractor'
  | 'subcontractor'
  | 'homeowner'

export type AuthUser = {
  userId: string;
  user: string;
  accessToken: string;
  refreshToken: string;
  accountStatus: AccountStatus;
  accountType: AccountType;
  owner?: CompanyOwnerDTO;
}

export const setAuthInfo = (authUser: AuthUser) => {
  apiClient.defaults.headers.common.Authorization = `${authUser.accessToken}`;

  localStorage.setItem('authUser', JSON.stringify(authUser));
};

export const updateAuthToken = () => {
  const authUserInfo = getAuthInfo();
  apiClient.defaults.headers.common.Authorization = `${authUserInfo.accessToken}`;
};

export const getAuthInfo = (): AuthUser => {
  const user = localStorage.getItem('authUser');
  if (user) {
    return JSON.parse(user);
  }
  return {
    userId: '',
    user: '',
    accountType: '' as AccountType,
    accessToken: '',
    refreshToken: '',
    accountStatus: 'disabled',
  };
};

export const clearAuthInfo = () => {
  apiClient.defaults.headers.common.Authorization = '';
  localStorage.removeItem('authUser');
};

export const checkAuth = (): boolean => {
  const { accessToken } = getAuthInfo();
  if (accessToken) {
    return true;
  }
  return false;
};

export const getButtonState = (isDirty: boolean, isValid: boolean, loading?: boolean): ButtonState => {
  if (isDirty && isValid) {
    return loading ? 'loading' : 'active';
  }

  return 'disabled';
};

export const accountTypeToString = (accountType: AccountType): string => {
  switch (accountType) {
    case 'contractor':
      return 'Contractor';
    case 'subcontractor':
      return 'Sub contractor';
    case 'homeowner':
      return 'Homeowner';
    default:
      return '';
  }
};

export const getTrimmedString = (text: string, length: number) => {
  const trimmedString = text.length > length
    ? `${text.substring(0, length)}...`
    : text;
  return trimmedString;
};

export const isContractor = () => {
  const { accountType } = getAuthInfo();
  return accountType === 'contractor';
};

export const isEditableByContractor = (status?: number, contractorId?: string) => {
  const { accountType, userId } = getAuthInfo();
  if (status && contractorId) {
    return !(status === projectStatus.draft && accountType === userRole.contractor && contractorId === userId);
  }
  return false;
};

export const isReadOnlyProjectSectionBySubcontractorAndHomeowner = (contractorId?: string) => {
  const { accountType, userId } = getAuthInfo();
  if (contractorId) {
    return (accountType === userRole.contractor && contractorId !== userId) || accountType === userRole.homeowner;
  }
  return false;
};

export const isHomeowner = () => {
  const { accountType } = getAuthInfo();
  return accountType === userRole.homeowner;
};

export const isSubcontractorNotAuthorized = (contractorId?: string) => {
  const { accountType, userId } = getAuthInfo();
  if (contractorId) {
    return (accountType === userRole.contractor && contractorId !== userId);
  }
  return false;
};

export const isVisibleProjectSectionByContractor = (status?: number, contractorId?: string) => {
  if (status && contractorId) {
    const { accountType, userId } = getAuthInfo();
    return (status !== projectStatus.draft && accountType === userRole.contractor && contractorId === userId);
  }
  return false;
};

export const isReadOnlyProjectSectionByHomeowner = (contractorId?: string) => {
  const { accountType } = getAuthInfo();
  if (contractorId) {
    return accountType === userRole.homeowner;
  }
  return false;
};

export const projectSubmittedByContractor = (status?: number, contractorId?: string) => {
  if (status && contractorId) {
    const { userId } = getAuthInfo();
    return status === projectStatus.draft && contractorId === userId;
  }
  return true;
};

export const downloadPDF = (pdf: string, filename: string) => {
  const linkSource = `data:application/pdf;base64,${pdf}`;
  const downloadLink = document.createElement('a');
  const fileName = filename;
  downloadLink.href = linkSource;
  downloadLink.download = fileName;
  downloadLink.click();
};
export const downloadPDFfile = async (pdf: string, filename: string) => {
  const blob = b64toBlob(pdf, 'application/pdf');
  const blobUrl = URL.createObjectURL(blob);
  const downloadLink = document.createElement('a');
  downloadLink.href = blobUrl;
  downloadLink.download = filename;
  downloadLink.style.cursor = 'pointer';
  await document.body.appendChild(downloadLink);
  downloadLink.click();
};

const b64toBlob = (b64Data: string, contentType = '', sliceSize = 512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);
    const byteNumbers = new Array(slice.length);
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

export const byteArrayToBase64 = (array: Array<number>) => {
  return btoa(
    new Uint8Array(array)
      .reduce((data, byte) => data + String.fromCharCode(byte), ''),
  );
};

export const isInspector = () => {
  const { accountType } = getAuthInfo();
  return accountType === userRole.inspector;
};

export const isProjectPendingState = (status?: number) => {
  return status === projectStatus.pending;
};

export const getUserType = () => {
  if (isInspector()) {
    return 'inspector';
  }
  return isHomeowner() ? 'homeOwner' : 'contractor';
};

export const showSettings = () => {
  if (isContractor()) {
    return true;
  }
  return false;
};

export const isUserAuthourised = () => {
  const { accountStatus } = getAuthInfo();
  return accountStatus === 'active';
};
