import { SignatureType } from 'domains/project/addProject/contract/homeowner/types';
import { useState } from 'react';
import { apiClient } from 'utils/network/apiClient';
import { contractUpdateUrl } from 'utils/network/endpoints';

export const useUpdateContract = (): {
  loading: boolean,
  updateContract: (contractId: number, signatureType: SignatureType, signatureUrl: string) => unknown
} => {
  const [loading, setLoading] = useState(false);

  const updateContract = async (contractId: number, signatureType: string, signatureUrl: string) => {
    try {
      setLoading(true);
      const url = contractUpdateUrl.replace('%contractId%', contractId.toString());
      const result = await apiClient.patch(url, {
        signatureType,
        signatureUrl,
      });
    } catch (error: any) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    updateContract,
  };
};
