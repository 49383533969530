import { useEffect, useCallback } from 'react';
import { useSaveForm } from 'utils/form/useSaveForm';
import { notifications } from 'designSystem/toastNotifications/toastNotificationEmitter';
import { licenseDataUrl } from 'utils/network/endpoints';

type FormDataType = {
  licenseNumber: string;
  expiryDate: string;
}

export const useSaveLicenseData = () : {
  isSaved: boolean,
  saveLicense: (data: FormDataType, id: number | string) => void;
  loading: boolean;
  error: string;
} => {
  const {
    loading, save, error, isSaved,
  } = useSaveForm();

  useEffect(() => {
    if (error) {
      notifications.error(error);
    }
  }, [error]);

  const saveLicense = useCallback(async (data: FormDataType, id: string | number) => {
    const userCompanyId = id.toString();

    const { licenseNumber, expiryDate } = data;
    const url = licenseDataUrl.replace('%userCompanyId%', userCompanyId);
    await save(url, { licenseNumber, expiryDate }, 'patch');
  }, [save]);
  return {
    saveLicense,
    loading,
    error,
    isSaved,
  };
};
