import { getAuthInfo } from 'utils/helpers';
import lang from 'lang/en';

export const draft = 'Draft';
export const live = 'Live';
export const underReview = 'UnderReview';
export const pending = 'Pending';
export const completed = 'Completed';
export const cancelled = 'Cancelled';

export const defaultColumns = [
  {
    Header: lang.statusText,
    accessor: 'status',
    id: 'Status',
    width: 800,
    disableSortBy: true,
  },
  {
    Header: lang.projectName,
    accessor: 'projectName',
    id: 'projectName',
    width: 800,
    disableSortBy: true,
  },
  {
    Header: lang.homeOwner,
    accessor: 'homeownerName',
    id: 'homeownerName',
    width: 800,
    disableSortBy: true,
  },
  {
    Header: lang.contractor,
    accessor: 'contractorName',
    id: 'contractorName',
    width: 800,
    disableSortBy: true,
  },
  {
    Header: lang.progress,
    accessor: 'progress',
    id: 'progress',
    width: 800,
    disableSortBy: true,
  },
];

export const contractorColumns = [
  {
    Header: 'Status',
    accessor: 'status',
    id: 'Status',
    width: 800,
    disableSortBy: true,
  },
  {
    Header: 'Project Name',
    accessor: 'projectName',
    id: 'projectName',
    width: 800,
    disableSortBy: true,
  },
  {
    Header: 'Homeowner',
    accessor: 'homeownerName',
    id: 'homeownerName',
    width: 800,
    disableSortBy: true,
  },
  {
    Header: 'Progress',
    accessor: 'progress',
    id: 'progress',
    width: 800,
    disableSortBy: true,
  },
];

export const homeOwnerColumns = [
  {
    Header: 'Status',
    accessor: 'status',
    id: 'Status',
    width: 800,
    disableSortBy: true,
  },
  {
    Header: 'Project Name',
    accessor: 'projectName',
    id: 'projectName',
    width: 800,
    disableSortBy: true,
  },
  {
    Header: 'Contractor',
    accessor: 'contractorName',
    id: 'contractorName',
    width: 800,
    disableSortBy: true,
  },
  {
    Header: 'Progress',
    accessor: 'progress',
    id: 'progress',
    width: 800,
    disableSortBy: true,
  },
];

export const getRequiredColumn = () => {
  const { accountType } = getAuthInfo();
  switch (accountType) {
    case 'contractor':
      return contractorColumns;
    case 'homeowner':
      return homeOwnerColumns;
    default:
      return defaultColumns;
  }
};

export const mobileViewColumns = [
  {
    Header: '',
    accessor: 'projectData',
    id: 'projectData',
    width: 800,
    disableSortBy: true,
  },
];
