import { useState } from 'react';
import { apiClient } from 'utils/network/apiClient';
import { sendSubcontractorContractAsSubcontractorUrl } from 'utils/network/endpoints';

export const useSendSubcontractorContractAsSubcontractor = () => {
  const [loading, setLoading] = useState(false);
  const [isSend, setIsSend] = useState(false);

  const sendSubcontractorContractAsSubcontractor = async (contractId: number) => {
    try {
      setLoading(true);
      const url = sendSubcontractorContractAsSubcontractorUrl.replace('%contractId%', contractId.toString());
      await apiClient.post(url);
      setIsSend(true);
    } catch (error: any) {
      console.error(error);
    } finally {
      setIsSend(false);
      setLoading(false);
    }
  };

  return {
    sendSubcontractorContractAsSubcontractor,
    loading,
    isSend,
  };
};
