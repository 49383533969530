import { ControlledInputField } from 'designSystem/input';
import lang from 'lang/en';
import { Control, FieldValues } from 'react-hook-form';
import { useWindowDimensions } from 'hooks/useWindowDimensions';
import {
  Card, CardRow, CardTitle, CustomFieldSized, CustomMaskedField,
} from '../styles';

export type CompanyOwnerProps = {
  control: Control<FieldValues>
}

const HomeownerForm = ({ control }: CompanyOwnerProps) => {
  const { screenSize } = useWindowDimensions();
  return (
    <Card screenSize={screenSize}>
      <CardTitle>
        {`${lang.homeownerDetails}`}
      </CardTitle>
      <CardRow>
        <ControlledInputField labelText={lang.firstNameLabel} control={control} name="homeowner.firstName" />
        <ControlledInputField labelText={lang.lastNameLabel} control={control} name="homeowner.lastName" />
      </CardRow>
      <CardRow>
        <CustomFieldSized disabled maxWidth="504px" labelText={lang.emailLabel} control={control} name="homeowner.email" />
      </CardRow>
      <CardRow>
        <CustomFieldSized disabled maxWidth="612px" labelText={lang.countryLabel} control={control} name="homeowner.country" />
      </CardRow>
      <CardRow>
        <ControlledInputField labelText={lang.addressLabel} control={control} name="homeowner.streetAddress" />
      </CardRow>
      <CardRow customFlexBasis="230px">
        <ControlledInputField labelText={lang.cityLabel} control={control} name="homeowner.city" />
        <ControlledInputField labelText={lang.stateLabel} control={control} name="homeowner.state" />
        <CustomFieldSized maxWidth="240px" labelText={lang.zipLabel} control={control} name="homeowner.zip" />
      </CardRow>
      <CardRow>
        <CustomMaskedField
          maxWidth="240px"
          placeholder="+1(000) 000-00-00"
          maskFormat="+1(###) ###-##-##"
          labelText={lang.phoneLabel}
          control={control}
          name="homeowner.phone"
        />
      </CardRow>
    </Card>
  );
};

export default HomeownerForm;
