import { ControllerRenderProps } from 'react-hook-form';
import { useCallback, useRef } from 'react';

export const useGetField = (): {
  getField: (name: string, value: unknown, onChange?: () => void) => ControllerRenderProps
} => {
  const emptyFunction = useCallback(() => {}, []);
  const getField = (name: string, value: unknown, onChange?: () => void) => {
    const ref = useRef(null).current as unknown;
    return {
      onChange: onChange || emptyFunction,
      onBlur: emptyFunction,
      value,
      name,
      ref,
    } as ControllerRenderProps;
  };

  return { getField };
};
