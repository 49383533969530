import { Control, FieldValues, useFieldArray } from 'react-hook-form';
import { useState } from 'react';
import { DeleteItem } from 'common/deleteItem';
import lang from 'lang/en';
import CompanyOwner from './companyOwner';

export type CompanyOwnerProps = {
  control: Control<FieldValues>
}

const CompanyOwners = ({ control }: CompanyOwnerProps) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'owners',
  });

  const [isOpen, setIsOpen] = useState(false);
  const [deleteUserIndex, setDeleteUserIndex] = useState(-1);

  const handleShowModal = (index: number) => {
    setIsOpen(true);
    setDeleteUserIndex(index);
  };

  const handleCloseModal = () => {
    setIsOpen(false);
    setDeleteUserIndex(-1);
  };

  const handleDelete = (index: number) => {
    remove(index);
    handleCloseModal();
  };

  const handleAddOwner = () => append({
    firstName: '',
    lastName: '',
    email: '',
    country: 'United States',
    streetAddress: '',
    city: '',
    state: '',
    zip: '',
    phone: '',
  });

  const mappedFields = fields.map((field, index) => {
    return (
      <CompanyOwner
        key={field.id}
        control={control}
        index={index}
        lastElement={index === fields.length - 1}
        firstElement={index === 0}
        handleShowModal={handleShowModal}
        handleAddOwner={handleAddOwner}
      />
    );
  });

  return (
    <>
      <DeleteItem
        isOpen={isOpen}
        heading={lang.deleteCompanyOwnerHeader}
        subText={lang.deleteCompanyOwnerContent}
        handleDelete={() => handleDelete(deleteUserIndex)}
        handleCancel={handleCloseModal}
      />
      {mappedFields}
    </>
  );
};

export default CompanyOwners;
