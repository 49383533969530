import { useWindowDimensions } from 'hooks/useWindowDimensions';
import { useNavigate } from 'react-router-dom';
import { DesktopNavigation } from './desktopNavigation';
import { MobileNavigation } from './mobileNavigation';
import type { LinkType } from './types';

export const Navigation = ({ pageLinks } : LinkType) => {
  const { screenSize } = useWindowDimensions();
  const navigate = useNavigate();
  const handleLogout = () => {
    localStorage.clear();
    navigate('/signin');
  };

  if (screenSize === 'mobile') {
    return (<MobileNavigation pageLinks={pageLinks} handleLogout={handleLogout} />);
  }

  return (<DesktopNavigation pageLinks={pageLinks} handleLogout={handleLogout} />);
};
