import { Card } from 'domains/accountSetup/onboarding/styles';
import { useWindowDimensions } from 'hooks/useWindowDimensions';
import { useState } from 'react';
import lang from 'lang/en';
import { CustomCardTitle } from './styles';
import { UserDataDTO, UserSearchAndDisplayCardProps } from './types';
import UserFoundDisplay from './userFoundDisplay';
import UserSearch from './userSearch';

const UserSearchAndDisplayCard = ({
  onUserFound, onUserNotFound, userId, header, description, userType, userNotFoundMessage, customRender, title,
}: UserSearchAndDisplayCardProps) => {
  const { screenSize } = useWindowDimensions();
  const [userData, setUserData] = useState<UserDataDTO>();
  const handleUserFound = (user: any) => {
    setUserData(user);
    onUserFound(user);
  };

  return (
    <Card screenSize={screenSize}>
      <CustomCardTitle>
        {header}
      </CustomCardTitle>
      {userData
        ? <UserFoundDisplay customRender={customRender} userData={userData} title={title} />
        : (
          <UserSearch
            userNotFoundMessage={userNotFoundMessage}
            userType={userType}
            description={description}
            userId={userId}
            onUserFound={handleUserFound}
            onUserNotFound={onUserNotFound}
          />
        )}
    </Card>
  );
};

UserSearchAndDisplayCard.defaultProps = {
  title: lang.homeownerDetailsFilledDescription,
};

export default UserSearchAndDisplayCard;
