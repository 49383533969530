import { ReactElement } from 'react';
import ReactModal, { Styles } from 'react-modal';

const reactModalStyleOverrides = {
  overlay: {
    background: '#000000c6',
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '8px',
    padding: '0',
  },
};

export const Modal = ({ isOpen, children }: {
  isOpen: boolean,
  children: ReactElement
}) => {
  return (
    <ReactModal
      style={reactModalStyleOverrides as Styles}
      isOpen={isOpen}
    >
      {children}
    </ReactModal>
  );
};
