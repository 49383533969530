import { colors } from 'designSystem';
import { textSm } from 'designSystem/typings';
import styled from 'styled-components';

export const UnorderedList = styled.ul`
  ${textSm};
  list-style-type: disc;
  padding-left: 18px;
  margin-bottom: 32px;
`;

export const ListItem = styled.li`
  margin-top: 4px;
  color: ${colors.gray500};
`;

export const ChangeOrderAction = styled.div`
  width: 320px;
  display: flex;
`;
