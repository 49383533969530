/* eslint-disable max-len */
export type Lang = {
  [key: string]: string;
}

export const lang = {
  projectTitle: 'tradesmen',
  signupHeading: 'Create an account',
  email: 'Email',
  password: 'Password',
  passwordValidationMinCharacters: 'min. 8 characters',
  passwordValidationLowercase: '1 lowercase',
  passwordValidationUppercase: '1 uppercase',
  passwordValidationNumber: '1 number',
  passwordValidationSpecialChar: '1 special',
  termsAndConditionsText: 'Terms & Conditions',
  privacyPolicy: 'Privacy Policy',
  showPassword: 'Show',
  backLabel: 'Back',
  nextLabel: 'Save & Next',
  saveDraftLabel: 'Save Draft',
  submitLabel: 'Submit',
  tradesmenLogoAltText: 'tradesmen-logo',
  signupBottomHeading: 'Already have an account?',
  signInText: 'Sign in',
  createAccountLabel: 'Create account',
  agreeText: 'I agree to the',
  andText: 'and',
  unknownError: 'Unknown error',
  unknownErrorTryLater: 'Unknown error occured. Please try again later.',
  emailFormat: 'This is not a correct email format',
  emailRequired: 'Add an email address to continue.',
  passwordRequired: 'Please enter password',
  fieldRequired: 'This field is required',
  validPhoneNumber: 'One or more digits are missing. Try again.',
  passwordValidationCheck: 'Please check validation',
  consent: 'You must agree to Privacy Policy to proceed',
  hide: 'Hide',
  show: 'Show',
  thankyou: 'Thank you!',
  accountConfirmationFirstText: 'We’ve sent a message to your email address.',
  accountConfirmationSecondText: 'Please click the button in that email to activate your account.',
  okay: 'Ok',
  signinHeading: 'Sign in',
  signinBottomHeading: 'New to Tradesmen?',
  createAccountText: 'Create an account',
  forgotPassword: 'Forgot your password?',
  passwordValidationText: 'Your password is invalid. Try again.',
  confirm: 'Confirm',
  selectUserHeading: 'Welcome to the Tradesmen’s Collective!',
  introduceText: 'Can you introduce yourself?',
  goBackToSignin: 'Go back to the sign in screen',
  selectUser: 'Please select user type',
  accountActivationSuccessfullMessage: 'Activation of your account was successful.',
  accountActivationFailedMessage: 'We were unable to activate your account. Please try again later.',
  selectOptionDefaultPlaceholderText: 'Select option',
  confirmEmailFirstText: 'Your account has not been activated. Check your email to activate your account.',
  accountSetTypeSuccessfullMessage: 'Your account type has been set successfully.',
  sessionExpiredMessage: 'Your session has expired. Please sign in again.',
  projectNotFound: 'Project not found',

  tooManyCharactersLabel: 'Too many characters',
  maxCharactersAllowedIs: 'Max characters allowed is',
  missingInformationLabel: 'Fill in the information to continue.',
  incorrectCharactersText: 'Incorrect characters provided',

  stepCompanyDetailsText: 'Company details & company owner/s',
  stepLicenseText: 'License',
  stepSubscriptionText: 'Subscription & payment',
  companyDetails: 'Company details',

  nameOfCompanyLabel: 'Name of company *',
  registrationNumberLabel: 'Registration/License number *',
  einLabel: 'Employer Identification Number (EIN) *',
  typeOfContractorLabel: 'Type of contractor',

  homeownerDetails: 'Homeowner details',

  addCompanyOwnerLabel: 'Add company owner',
  companyOwner: 'Company owner',
  firstNameLabel: 'First name *',
  lastNameLabel: 'Last name *',
  primaryEmailLabel: 'Email address (primary for communication) *',
  emailLabel: 'Email address *',
  countryLabel: 'Country',
  addressLabel: 'Address *',
  cityLabel: 'City *',
  stateLabel: 'State *',
  zipLabel: 'Zip *',
  phoneLabel: 'Phone *',
  addressNote: 'The address you fill in below must not be a PO Box or Mail Drop address.',

  deleteCompanyOwnerHeader: 'Company owner deletion',
  deleteCompanyOwnerContent: 'Are you sure you want to delete this company owner?',
  deleteButtonLabel: 'Delete',
  cancelButtonLabel: 'Cancel',

  subscriptionTitle: 'Subscription plan',
  subscriptionPlanLabel: 'Choose the best option for you.',
  subscriptionAnnually: 'Annually',
  subscriptionMonthly: 'Monthly',
  subscriptionYear: 'yr',
  subscriptionMonthlyLabelOne: 'First up front payment of',
  subscriptionMonthlyLabelTwo: 'followed by 11 monthly payments of',
  subscriptionMonth: 'mo',
  subscriptionPayment: 'Payment',
  subscriptionPaymentNote: 'Enter details of your debit or credit card. ',
  referalCode: 'Referral Code',
  referalCodePlaceHolder: 'If you have a referral code please enter here !',
  paymentNameLabel: 'First and last name *',
  paymentCardLabel: 'Card number *',
  paymentExpiryLabel: 'Expiry date *',
  paymentCvcLabel: 'CVC *',
  securePayment: 'Secure payments',
  subscriptionPlanError: 'Please choose preferred subscription plan.',

  licenseTitle: 'License',
  licenseNumber: 'License number *',
  licenseDatePlaceholder: 'Pick your date',
  licenseDateLabel: 'Expiry date of license *',

  companyOnboardingConfirmationText: 'You’ll receive an email with a confirmation of your payment shortly.',

  homeownerOnboardingConfirmationMainText: 'That’s all we need! ',
  homeownerOnboardingConfirmationSubText: 'We hope we’ll make your transactions more secure and better.',

  linkPayments: 'Payments',
  linkProjects: 'Projects',

  linkProfile: 'Your profile',
  linkSettings: 'Settings',
  linkSignOut: 'Sign out',

  projectDetailsStepLabel: 'Project details',
  projectMilestonesStepLabel: 'Project milestones',
  projectContractorMilestonesStepLabel: 'Subcontractor milestones',
  projectContractStepLabel: 'Contract',
  projectSubmitOrSaveStepLabel: 'Submit or save',
  projectNameAndAddressLabel: 'Project name and address',
  projectNameLabel: 'Project name *',
  updateText: 'Update',

  homeownerDetailsLabel: 'Homeowner details',
  homeownerDetailsDescription: 'Search for a homeowner in our database using their email address. The homeowner must already have a Tradesmen account. Adding homeowner details now is not compulsory as it can be added to the project later.',
  searchByEmailPlaceholder: 'Search by email',
  searchButtonLabel: 'Search',
  homeownerNotFound: 'Homeowner record not found. Please ensure the Homeowner has signed up to Tradesmen.',
  homeownerDetailsFilledDescription: 'These details are prefilled and can be edited only by a homeowner.',

  fileUpload: {
    label: 'Upload a file',
    incorrectExtensionText: 'Incorrect extension of the file. Please make sure file is',
    incorrectSizeText: 'Incorrect size of the file. Make sure file is',
  },
  blueprint: {
    titleLabel: 'Blueprint',
    subtitleDescription: 'If available, please upload any blueprints or documents related to this project',
    uploadTitle: 'Upload a blueprint',
    fileTypesLabel: 'PNG, JPG, PDF, DOC or DOCX',
    fileSizeLabel: 'up to 10MB',
    addButtonLabel: 'Add blueprint',
    delete: {
      heading: 'Blueprint deletion',
      subText: 'Are you sure you want to delete this blueprint?',
    },
  },

  milestoneUpload: {
    titleLabel: 'Photo',
    subtitleDescription: 'Uploading a photo is necessary to prove a completion of a milestone.',
    uploadTitle: 'Upload a photo',
    fileTypesLabel: 'PNG, JPG, JPEG',
    fileSizeLabel: 'up to 10MB',
    addButtonLabel: 'Add photo',
    delete: {
      heading: 'Photo deletion',
      subText: 'Are you sure you want to delete this photo?',
    },
    requireFile: 'please upload atleast 1 file',
    noDataFound: 'No milestones data found',
  },

  statusText: 'Status',
  projectName: 'Project Name',
  homeOwner: 'Homeowner',
  contractor: 'Contractor',
  progress: 'Progress',
  mileStone: 'Milestone',
  cost: 'Cost',
  projectCostLabel: 'Cost of project *',
  projectCostDesc: 'Please enter the total cost of the project. Escrow payment is set my default, but you can change it',
  currencyUsd: 'USD',
  escrowText: 'Escrow',
  projectCostTitle: 'Cost of project',

  onlyPositiveNumberAllowed: 'Only positive value is allowed',
  noProjectListingContent: 'No projects here yet.',
  noProjectListingContractor: ' Add your first one using Plus button above.',
  contractorMilestoneHeading: 'MY MILESTONES',
  contractorMileStoneDesc: 'Please indicate names of your milestones.',
  milestoneText: 'Milestone',
  milestoneLabel: 'Name of Milestone',
  nameText: 'name',
  percentageCostOfProject: 'Percentage of your fee',
  addMileStoneText: 'Add milestone',
  deleteMilestone: 'Milestone deletion',
  deleteMileStoneSubText: 'Are you sure you want to delete this milestone?',
  maxAllowedHundred: 'Max allowed number is 100',
  milestoneOverallPercentageErrorText: 'Incorrect percentages for milestones. The percentages for milestones should altogether sum up to 100% and not exceed it. Fill in the right numbers.',
  contractorMilestones: 'CONTRACTOR MILESTONES',

  subcontractorMilestones: {
    header: 'Subcontractor Milestones (optional)',
    headerDesc: 'Subcontractor database for General Contractors',
    subHeaderStartText: 'Subcontractor',
    subHeaderEndText: 'details',
    description: 'Search for a subcontractor in our database using their primary email address used to register a Tradesmen account.',
    subcontractorNotFoundText: 'Subcontractor record not found. Please ensure the Subcontractor has signed up to Tradesmen.',
    subcontractorDetailsFilledDescription: 'These details are prefilled and can be edited only by the subcontractor.',
    milestones: {
      header: 'Milestones',
      contractorCost: 'Total Subcontractor cost (if known)',
      description: 'Please indicate names for your milestones.',
      completed: {
        title: 'Completed',
        yesOption: 'Yes',
        noOption: 'No',
      },
      milestone: {
        startText: 'Name of Milestone',
        endText: '',
      },
      addSubcontractorButtonText: 'Add subcontractor',
      removeSubcontractorButtonText: 'Delete',
      removeSubcontractorModal: {
        title: 'Subcontractor deletion',
        description: 'Are you sure you want to delete this subcontractor?',
      },
    },
    noSubcontractorDetailsFound: 'No subcontractor details found',
    selectInspection: 'Please select inspection type',
    inspection: {
      title: 'Request a Tradesmen Inspection',
      subTitle: 'Tradesmen inspection',
      description: 'By requesting an inspection you are confirming that any necessary inspections from the relevant bodies have been completed.',
      townInspection: 'Town inspection waived',
      tradesmanInspection: 'Tradesman inspection required',
      none: 'None',
      start: 'Start',
      rough: 'Rough',
      final: 'Final',
      submitRequest: 'Submit request',
      uploadFileTitle: 'Upload your inspection documentation',
      delete: {
        heading: 'Document deletion',
        subText: 'Are you sure you want to delete this document?',
      },
    },
  },
  projectSubmitSave: {
    header: 'Submit or save',
    subcontractorNotFoundText: '',
    subHeaderStartText: 'Submit for approval or save as draft',
    description: 'You can submit your project for approval if you\'ve entered all the required information or save your project as a draft and complete the missing data later. You\'ll find your draft in the project list.',
    subHeaderEndText: '',
    homeOwnerDetailsMissing: 'Home Owner details are missing',
    costMissing: 'Cost is missing',
    milestoneMissing: 'My milestones details are missing',
    blueprintMissing: 'Blueprint is missing',
    subcontractorMilestone: 'Subcontractor milestones are missing',
    contract: 'Contracts not completed',
    contractSuccess: 'Contracts completed',
    successText: 'You\'ve successfully submitted your project. You\'ll be updated about its status.',
  },
  noHomeownerDetailsFound: 'No homeowner details found',
  noBlueprintsFound: 'No blueprints are available',
  downloadText: 'Download',
  completed: 'Completed',
  detailsText: 'Details',
  approveCTA: 'Approve',
  milestones: {
    confirmationText: 'People concerned will be notified about your approval.',
    homeownerApproval: 'Approved by Homeowner',
    contractorApproval: 'Approved by Contractor',
    contractorInspectionSuccess: 'Your request has been submitted successfully',
    contractorTotalCost: 'Total cost',
    contractorTotalMilestone: 'Total cost (i.e. your part of the total project cost)',
    approvalSuccessMsg: 'People concerned will be notified about your approval.',
    milestoneUpdateSuccessMsg: 'Milestone updated successfully',
    InspectorApproval: 'Approved by Inspector',
  },
  contract: {
    headerText: 'Contract',
    addContractButtonLabel: 'Add contract',
    homeowner: {
      headerText: 'Homeowner Contract',
      noHomeownerDescriptionText: 'No homeowner added to the project yet. You need to link the homeowner with the project before adding a contract.',
      modal: {
        header: 'ADD HOMEOWNER CONTRACT',
        subheader: 'Home Improvement Contract',
        form: {
          contractType: {
            header: 'Contract type',
            stateLabel: 'Governing Law State *',
            confirmButtonLabel: 'Confirm',
          },
          homeownerDetails: {
            header: 'Homeowner details',
            description: 'Most details are already prefilled by a homeowner. You can add additional homeowner name if you wish.',
            firstLastNameLabel: 'First and last name',
            additionalHomeownerNameLabel: 'Additional Homeowner Name',
          },
          contractorDetails: {
            header: 'Contractor details',
            description: 'All necessary details are already prefilled by you.',
            firstLastNameLabel: 'First and last name',
          },
          projectAddress: {
            header: 'Project address',
            description: 'All necessary details are already prefilled by you.',
            streetAddressLabel: 'Street address',
            cityLabel: 'City',
            stateLabel: 'State',
            zipLabel: 'Zip',
          },
          dates: {
            header: 'Dates',
            startDateLabel: 'Start date*',
            completionDateLabel: 'Completion date*',
            substantialDateLabel: 'Substantial completion date*',
            datePlaceholder: 'Pick your date',
          },
          previousCorporations: {
            header: 'Previous corporations',
            label: 'Have you, in the last 5 years, been a shareholder, member, partner or owner in any corporation, limited liability company, partnership, sole proprietorship or other legal entity, which is or has been a home improvement contractor or a new home construction contractor? Is so, please list them all here:',
          },
          projectCostModel: {
            header: 'Project Cost Model *',
            description: 'Choose one option.',
            option1: 'Total Cost of the Project excluding any allowances agreed to between the parties',
            option2: 'Cost of the Work plus the Contractor’s Fee',
            option3: 'The work to be performed at the Construction Site / Project Site will be based upon time and materials',
          },
          totalSalesPrice: {
            header: 'Total Sales Price *',
            totalSalesPriceLabel: 'Total Sales Price *',
          },
          allowance: {
            header: 'Allowances',
            totalAllowanceLabel: 'Total allowances',
          },
          descriptionOfWork: {
            header: 'Description of work*',
            descriptionOfWorkLabel: 'Description of work*',
          },
          paymentSchedule: {
            header: 'Payment schedule *',
            paymentScheduleLabel: 'Payment schedule (inc. down payment & deposit) *',
          },
          escrowService: {
            header: 'Escrow Service',
            escrowLabel: 'Escrow',
          },
          authorizedRepresentatives: {
            header: 'Authorized Representatives',
            contractorLabel: 'Contractor Authorized Representative First and Last Name',
            homeownerLabel: 'Homeowner Authorized Representative First and Last Name',
          },
          emailReadReceipt: {
            header: 'Email read receipt *',
            contractorLabel: 'Contractor email read receipt requested * ',
            homeownerLabel: 'Homeowner email read receipt requested *',
          },
          contractorsInsurance: {
            header: 'Contractor’s Insurance *',
            description: 'Choose one option.',
            option1: 'Carries commercial general liability insurance',
            option1InputFieldLabel: 'Insurance Provider Name:',
            option2: 'The Contractor does not carry commercial general liability insurance',
            option3: 'The Contractor is self-insured',
          },
          workersCompensationInsurance: {
            header: 'Worker\'s Compensation Insurance *',
            description: 'Choose one option.',
            option1: 'Contractor has no employees and is exempt from Workers’ Compensation Laws or Requirements or Insurance Regulations',
            option2: 'Contractor carries Workers’ Compensation Insurance for all employees',
            option2InputFieldLabel: 'Worker\'s Compensation Insurance Limit *',
          },
          contractsFileName: {
            header: 'Document name *',
            fileNameLabel: 'Please indicate what you would like the contract’s PDF file name to be. This name will be shared with all signing parties *',
          },
          createContractButtonLabel: 'Create contract',
          floridaFields: {
            principleResidence: {
              mainHeading: 'Does this contract include work on the principal residence of the owner? *',
              description: 'Choose one option.',
              option1: 'Yes',
              option2: 'No',
              additionalText: 'Add additional text',
            },
            rightToRescind: {
              mainHeading: 'Does the Owner give up the 3-day right to rescind because there is a true emergency? *',
              description: 'Choose one option.',
              option1: 'Yes. This contract will include a form to be completed in handwriting by Owner.',
              option2: 'No. Contractor will delay the start of work until three business days have passed.',
              additionalText: 'Add additional text',
            },
            miamiWorkDone: {
              mainHeading: 'Will work be done in Miami-Dade County? *',
              description: 'Choose one option.',
              option1: 'Yes',
              option2: 'No',
              additionalText: 'Add additional text',
            },
            jobExceeds: {
              mainHeading: 'Please confirm if the value of the job exceeds $5000? *',
              description: 'Choose one option.',
              option1: 'Yes',
              option2: 'No',
              additionalText: 'Add additional text',
              publicLiabilityInsurancePolicy: 'Public liability insurance: Policy Number *',
              publicLiabilityInsuranceExpiryDate: 'Public liability insurance: Expiration Date *',
              publicLiabilityInsuranceLimit: 'Public liability insurance: Liability Limit * ',
              workerCompensationInsurancePolicy: 'Workers\' compensation insurance: Policy Number *',
              workerCompensationInsuranceExpiryDate: 'Workers\' compensation insurance: Expiration Date *',
              workerCompensationInsuranceLiabilityLimit: 'Workers\' compensation insurance: Liability Limit* ',
              expiryDatePlaceholderText: 'Pick your date',
            },
            homesolicitation: {
              heading1: 'Florida Home Solicitation Sale – Right of Owner to Cancel',
              heading2: ' Answer the following questions to determine if the Florida Home Solicitation Sale Act applies:',
              contractorBusinessSales: {
                mainHeading: 'Was this sale made at the place of business of contractor? *',
                description: 'Choose one option.',
                option1: 'Yes',
                option2: 'No',
                additionalText: 'Add additional text',
              },
              commericalExhibit: {
                mainHeading: 'Was this sale made at a state fair or commercial exhibit? *',
                description: 'Choose one option.',
                option1: 'Yes',
                option2: 'No',
                additionalText: 'Add additional text',
              },
              residenceVisitInvitation: {
                mainHeading: 'Was this sale made at the residence of Owner after an express invitation to visit that residence? *',
                description: 'Choose one option.',
                option1: 'Yes',
                option2: 'No',
                additionalText: 'Add additional text',
              },
            },
            contractAmount: {
              mainHeading: 'Is the contract amount greater than $2500? *',
              description: 'Choose one option.',
              option1: 'Yes',
              option2: 'No',
              additionalText: 'Add additional text',
            },
            contractForConstructionRapairAndImprovement: {
              mainHeading: 'Is this a contract for new construction, repair or improvement of a home with four living units or less? *',
              description: 'Choose one option.',
              option1: 'Yes',
              option2: 'No',
              additionalText: 'Add additional text',
            },
            contractForRepairRestorationAndImprovement: {
              mainHeading: 'Is this a contract for repair, restoration or improvement of a residence? *',
              description: 'Choose one option.',
              option1: 'Yes',
              option2: 'No',
              additionalText: 'Add additional text',
            },
            contractWaivedFloridaNoticeAndRight: {
              mainHeading: 'Should this contract waive Florida’s Notice and Right to Cure Act? *',
              description: 'Choose one option.',
              option1: 'Yes',
              option2: 'No',
              additionalText: 'Add additional text',
            },
          },
          ohioFields: {
            contractForConstruction: {
              mainHeading: 'Is this a contract for construction or substantial rehabilitation of a one, two, or three family detached residence? *',
              description: 'Choose one option.',
              option1: 'Yes',
              option2: 'No',
            },
            siteLocated: {
              mainHeading: '  Is the construction site located in one of these cities? *',
              description: 'Choose one option.',
              option1: 'Cincinnati',
              option2: 'Dayton',
              option3: 'None of these',
            },
            contractNegotiated: {
              mainHeading: 'Where was this contract negotiated? *',
              description: 'Choose one option.',
              option1: 'This contract was negotiated at the retail store of the Contractor',
              option2: 'This contract was negotiated at some place other than the retail store of the Contractor',
            },
            jobExceeds: {
              mainHeading: 'Please confirm if the value of the job exceeds $5000? *',
              description: 'Choose one option.',
              option1: 'Yes',
              option2: 'No',
              additionalText: 'Add additional text',
              publicLiabilityInsurancePolicy: 'Public liability insurance: Policy Number *',
              publicLiabilityInsuranceExpiryDate: 'Public liability insurance: Expiration Date *',
              publicLiabilityInsuranceLimit: 'Public liability insurance: Liability Limit * ',
              workerCompensationInsurancePolicy: 'Workers\' compensation insurance: Policy Number *',
              workerCompensationInsuranceExpiryDate: 'Workers\' compensation insurance: Expiration Date *',
              workerCompensationInsuranceLiabilityLimit: 'Workers\' compensation insurance: Liability Limit* ',
              expiryDatePlaceholderText: 'Pick your date',
            },
            ohioStatuteLimitations: {
              heading1: '',
              heading2: '',
              contractWorkOnPrincipal: {
                mainHeading: 'Does this contract include work on the principal residence of the owner?  *',
                description: 'Choose one option.',
                option1: 'Yes',
                option2: 'No',
                additionalText: 'Add additional text',
              },
              rightToRescind: {
                mainHeading: 'Does the Owner give up the 3-day right to rescind because there is a true emergency? *',
                description: 'Choose one option.',
                option1: 'Yes',
                option2: 'No',
                additionalText: 'Add additional text',
              }
          },
        },
          massachusettsFields: {
            rightToInitiateArbitration: {
              mainHeading: 'Will Contractor have the right to initiate arbitration? *',
              description: 'Choose one option.',
              option1: 'Yes, Contractor has the right to initiate arbitration.',
              option2: 'No, Contractor will not have the right to arbitrate.',
            },
            workOnPrincipleResidence: {
              mainHeading: 'Does this contract include work on the principal residence of the owner? *',
              description: 'Choose one option.',
              option1: 'Yes',
              option2: 'No',
            },
            rightToRescind: {
              mainHeading: 'Does the Owner give up the 3-day right to rescind because there is a true emergency? *',
              description: 'Choose one option.',
              option1: 'Yes. This contract will include a form to be completed in handwriting by Owner.',
              option2: 'No. Contractor will delay the start of work until three business days have passed.',
            },
            jobProjectStatements: {
              mainHeading: 'Check the statements that are true about this job/project *',
              description: 'You can choose multiple options.',
              statement1: 'The value of the work exceeds $1,000',
              statement2: 'Work will be done on an existing owner-occupied residence with from 1 to 4 units',
              statement3: 'The work under this agreement is primarily for one of the following:',
              points: {
                point1: 'Installation of HVAC systems and energy-conservation devices,',
                point2: 'Landscaping,',
                point3: 'Interior painting and wall covering,',
                point4: 'Finished floor covering (carpet, vinyl or tile),',
                point5: 'Fencing',
                point6: 'Freestanding masonry walls,',
                point7: 'Above-ground swimming pools,',
                point8: 'Shutters or awnings,',
                point9: 'Ground level patios,',
                point10: 'Asphalt and driveway installation or maintenance,',
                point11: 'Conservation services conducted by or on behalf of a public utility under a program approved by the Department of Telecommunications and Energy, and',
                point12: 'The practice of a trade such as plumbing or wiring for which a license is required.',
              },
              contractorDaytimePhnNo: 'Contractor Daytime phone number *',
              contractorEveningPhnNo: 'Contractor Evening phone number *',
              contractorEmployerIdOrSocialSecurityNo: 'Contractor Federal Employer ID or Social Security Number: *',
              publicLiabilityInsurancePolicyNo: 'Public liability insurance: Policy Number *',
              contractorHICRegisterationNo: 'Contractor Massachusetts Home Improvement Contractor Registration Number *',
              contractorHICExpiryDate: 'Contractor Massachusetts Home Improvement Contractor Registration Expiry Date *',
              salesperson: 'Salesperson who solicited this contract *',
              responsibleForBuildingPermit: {
                mainHeading: 'Who is responsible for getting the required building permits? *',
                description: 'Choose one option.',
                option1: 'Contractor accepts responsibility for securing building permits for the Project.',
                option2: 'Owner is responsible for getting the required building permits.',
              },
              expiryDatePlaceholderText: 'Pick your date',
            },
          },
          delaware: {
            workOnPrincipleResidence: {
              mainHeading: 'Does this contract include work on the principal residence of the owner? *',
              description: 'Choose one option.',
              option1: 'Yes',
              option2: 'No',
            },
            rightToRescind: {
              mainHeading: 'Does the Owner give up the 3-day right to rescind because there is a true emergency? *',
              description: 'Choose one option.',
              option1: 'Yes. This contract will include a form to be completed in handwriting by Owner.',
              option2: 'No. Contractor will delay the start of work until three business days have passed.',
            },
          },
        },
      },
      signFlow: {
        step1: {
          header: '1. Preview contract',
          description: 'Read the full text of the contract before signing it. After signing it you can still come back to editing it.',
          downloadButtonLabel: 'Download',
          editButtonLabel: 'Edit',
          confirmButtonLabel: 'Confirm',
        },
        step2: {
          header: '2. Sign',
          description: 'Enter your signature in the box below and submit it to sign your contract.',
          submitSignatureButtonLabel: 'Submit signature',
          clearSignatureButtonLabel: 'Clear signature',
        },
        step3: {
          header: '3. Send',
          description: 'If the contract is ready send it to the other Party.',
          sendContractButtonLabel: 'Send contract',
          modal: {
            header: 'Sending contract',
            description: 'Have you previewed the signed contract before sending?',
            noButtonLabel: 'No',
            yesButtonLabel: 'Yes, send contract',
          },
        },
        deleteContract: {
          deleteButtonLabel: 'Delete',
          header: 'Delete contract',
          description: 'Are you sure you want to delete the contract you’ve already sent to the other Party?',
        },
      },
      sendToHomeowner: {
        header: 'Home Improvement Contract',
        description: 'You\'ve signed and sent the contract to the other Party.',
      },
      awaitingHomeownersSignatureLabel: 'Awaiting Homeowner’s signature',
      signedAndSentByBothPartiesLabel: 'Signed and sent by both Parties',
      signedByBothPartiesLabel: 'Awaiting sending contract by Homeowner',
    },
    subcontractors: {
      headerText: 'Subcontractors Contracts',
      noSubcontractorsDescriptionText: 'No Subcontractor/s added to the project yet. You need to link the Subcontractor/s with the project before adding a contract.',
      modal: {
        header: 'ADD SUBCONTRACTOR CONTRACT',
        description: '',
        form: {
          contractType: {
            header: 'Contract type',
            stateLabel: 'Governing Law State *',
            confirmButtonLabel: 'Confirm',
          },
          homeownerDetails: {
            header: 'Homeowner details',
            description: 'Most details are already prefilled by a homeowner. You can add additional homeowner name if you wish.',
            firstLastNameLabel: 'First and last name',
            additionalHomeownerNameLabel: 'Additional Homeowner Name',
          },
          contractorDetails: {
            header: 'Contractor details',
            description: 'All necessary details are already prefilled by you.',
            firstLastNameLabel: 'First and last name',
            contractorCompanyName: 'Contractor Company Name',
          },
          subContractorDetails: {
            header: 'Subcontractor details',
            description: 'All necessary details are already prefilled by Subcontractor.',
            firstLastNameLabel: 'First and last name',
            contractorCompanyName: 'Contractor Company Name',
          },
          projectAddress: {
            header: 'Project address',
            description: 'All necessary details are already prefilled by you.',
            streetAddressLabel: 'Street address',
            cityLabel: 'City',
            stateLabel: 'State',
            zipLabel: 'Zip',
          },
          authorizedRepresentatives: {
            header: 'Authorized Representatives',
            contractorLabel: 'Contractor Authorized Representative First and Last Name',
            subcontractorLabel: 'Subcontractor Authorized Representative First and Last Name ',
          },
          contractorsInsurance: {
            header: 'Contractor\'s Insurance *',
            description: 'Choose one option.',
            option1: 'Carries commercial general liability insurance',
            option1InputFieldLabel: 'Insurance Provider Name:',
            option2: 'The Contractor does not carry commercial general liability insurance',
            option3: 'The Contractor is self-insured',
          },
          workersCompensationInsurance: {
            header: 'Worker\'s Compensation Insurance *',
            description: 'Choose one option.',
            option1: 'Contractor has no employees and is exempt from Workers’ Compensation Laws or Requirements or Insurance Regulations',
            option2: 'Contractor carries Workers’ Compensation Insurance for all employees',
            option2InputFieldLabel: 'Worker\'s Compensation Insurance Limit *',
          },
          descriptionOfServices: {
            header: 'Description of services *',
            label: 'Description of Services to Be Provided By Subcontractor *',
          },
          subcontractorResponsibilities: {
            header: 'Subcontractor Responsibilities *',
            description: 'You can select multiple options.',
            laborOption: 'Labor. Including, but not limited to, employees, subcontractors and any other individuals or agents.',
            materialsOption: 'Materials. Including, but not limited to, all supplies and products.',
            equipmentOption: 'Equipment. Including, but not limited to, machinery, accessories, or devices.',
            travelOption: 'Travel. Including, but not limited to, ensuring that the above-mentioned Responsibilities are provided at the Location mentioned in Section V.',
            otherOption: 'Other',
            fieldLabel: 'Description of Other Responsibilities *',
            validationMsg: 'please select subcontractor responsibilities',
          },
          commencementDate: {
            header: 'Subcontractor Start Date*',
            fieldLabel: 'Subcontractor Start Date*',
          },
          completionDateOptions: {
            header: 'Completion Date Options *',
            description: 'Choose one option.',
            specificDateOption: 'By the specific date of',
            completionDateSpecificFieldLabel: 'Completion Date - Specific *',
            industryStandardsOption: 'In accordance with industry standards',
            otherOption: 'Other',
            otherCompletionDateFieldLabel: 'Completion Date - Other *',
          },
          substantialCompletionDateOptions: {
            header: 'Substantial Completion Date Options *',
            description: 'Choose one option.',
            specificDateOption: 'By the specific date of',
            completionDateSpecificFieldLabel: 'Substantial Completion Date - Specific *',
            industryStandardsOption: 'In accordance with industry standards',
            otherOption: 'Other',
            otherCompletionDateFieldLabel: 'Substantial Completion Date - Other *',
          },
          escrowService: {
            header: 'Escrow Service',
            escrowLabel: 'Escrow',
          },
          paymentAmountOptions: {
            header: 'Payment Amount Options *',
            description: 'Choose one option.',
            totalAmountOption: 'Total Amount',
            totalAmountFieldLabel: 'Payment Amount - Total Amount *',
            hourlyRateOption: 'Hourly Rate',
            hourlyRateFieldLabel: 'Payment Amount - Hourly Rate *',
            otherOption: 'Other',
            otherDescriptionFieldLabel: 'Payment Amount - Other Description *',
          },
          paymentMethodOptions: {
            header: 'Payment Method Options *',
            description: 'Choose one option.',
            immediatelyOption: 'Immediately upon completion of the Services to the satisfaction of the Contractor.',
            within7daysOption: 'Within (7) business days after completion of the Services to the satisfaction of the Contractor.',
            regularPaymentOption: 'Weekly/monthly/quarterly/other regular payment',
            otherOption: 'Other',
            otherFieldLabel: 'Payment Method Option - Other *',
          },
          paymentFrequencyOptions: {
            header: 'Payment Frequency Options *',
            description: 'Choose one option.',
            weeklyOption: 'Weekly',
            monthlyOption: 'Monthly',
            quarterlyOption: 'Quarterly',
            otherOption: 'Other',
            otherFieldLabel: 'Payment Frequency Option - Other *',
          },
          rightToSubcontractingOptions: {
            header: 'Right to Subcontracting Options *',
            description: 'Choose one option.',
            rightOption: 'Right to Subcontracting',
            noRightOption: 'No Right to Subcontracting',
          },
          assignmentRightOptions: {
            header: 'Assignment Right Options *',
            description: 'Choose one option.',
            rightOption: 'Subcontractor shall have the right to assign any rights under this Agreement or any part of the Services issued herin',
            noRightOption: 'Subcontractor shall not have the right to assign any rights under this Agreement or any part of the Services issued herin',
          },
          optionsForSubcontractorsInsurance: {
            header: 'Options for Subcontractor’s Insurance *',
            description: 'Choose one option.',
            option1: 'Carries commercial general liability insurance',
            option1InputFieldLabel: 'Insurance Provider Name:',
            option2: 'The Subcontractor does not carry commercial general liability insurance',
            option3: 'The Subcontractor is self-insured',
          },
          optionsForSubcontractorsWorkersCompensationInsurance: {
            header: 'Options for Subcontractor’s Worker\'s Compensation Insurance *',
            description: 'Choose one option.',
            option1: 'Subcontractor has no employees and is exempt from Workers’ Compensation Laws or Requirements or Insurance Regulations',
            option2: 'Subcontractor carries Workers’ Compensation Insurance for all employees in the following amounts',
            option2InputFieldLabel: 'Subcontractor\'s Worker\'s Compensation Insurance Limit *',
          },
          emailReadReceipt: {
            header: 'Email read receipt *',
            contractorLabel: 'Contractor email read receipt requested * ',
            subcontractorLabel: 'Subcontractor email read receipt requested *',
          },
          additionalProvisions: {
            header: 'Additional Provisions',
            fieldLabel: 'Additional Provisions',
          },
          contractsFileName: {
            header: 'Document name *',
            fileNameLabel: 'Please indicate what you would like the contract’s PDF file name to be. This name will be shared with all signing parties *',
          },
          createContractButtonLabel: 'Create contract',
          pickDatePlaceholder: 'Pick your date',
        },
      },
      signFlow: {
        step1: {
          header: '1. Preview contract',
          description: 'Read the full text of the contract before signing it. After signing it you can still come back to editing it.',
          downloadButtonLabel: 'Download',
          editButtonLabel: 'Edit',
          confirmButtonLabel: 'Confirm',
        },
        step2: {
          header: '2. Sign',
          description: 'Enter your signature in the box below and submit it to sign your contract.',
          submitSignatureButtonLabel: 'Submit signature',
          clearSignatureButtonLabel: 'Clear signature',
        },
        step3: {
          header: '3. Send',
          description: 'If the contract is ready send it to the other Party.',
          sendContractButtonLabel: 'Send contract',
          modal: {
            header: 'Sending contract',
            description: 'Have you previewed the signed contract before sending?',
            noButtonLabel: 'No',
            yesButtonLabel: 'Yes, send contract',
          },
        },
        deleteContract: {
          deleteButtonLabel: 'Delete',
          header: 'Delete contract',
          description: 'Are you sure you want to delete the contract you’ve already sent to the other Party?',
        },
      },
      sendToSubcontractor: {
        header: 'Subcontractor Contract',
        description: 'You\'ve signed and sent the contract to the other Party. Until it\'s signed by the other Party you can edit or delete it beneath. Taking any of these actions will mean you need to repeat the flow of signing and sending the contract.',
      },
    },
    changeOrder: {
      buttonActionText: 'Change order',
      createContractButtonLabel: 'Create contract',
      modal: {
        headerText: 'HOMEOWNER CONTRACT',
        subHeaderText: 'Home Improvement Contract',
        titleText: 'Change Order',
      },
      homeowner: {
        title: 'Homeowner details',
        subText: 'All necessary details are already prefilled.',
        inputLabel: 'First and last name',
        additionalHomeowner: 'Additional Homeowner Name',
      },
      contractor: {
        title: 'Contractor details',
        subText: 'All necessary details are already prefilled.',
        inputLabel: 'First and last name',
      },
      projectAddress: {
        header: 'Project address',
        description: 'All necessary details are already prefilled by you.',
        streetAddressLabel: 'Street address',
        cityLabel: 'City',
        stateLabel: 'State',
        zipLabel: 'Zip',
      },
      totalSalesPrice: {
        header: 'Total Sales Price *',
        totalSalesPriceLabel: 'Total Sales Price *',
        description: 'All necessary details are already prefilled by you.',
      },
      fields: {
        accountField: {
          title: 'Scope of work & Accounting sumary',
          label: 'Description of Work *',
        },
        contractsFileName: {
          header: 'Document name *',
          fileNameLabel: 'Please indicate what you would like the contract’s PDF file name to be. This name will be shared with all signing parties *',
        },
        workPriceLabel: 'Price for work above *',
        completionTimeLabel: 'Additional time to complete the project *',
        netChangeLabel: 'Net change by all prior change orders *',
        priorGrossTotal: 'Adjusted gross contract total prior to this Change Order *',
        includedGrossTotal: 'Adjusted gross contract total including this Change Order *',
      },
      listing: {
        contractHeading: 'Home Improvement Contract - Change Order',
        steps: {
          step1: '1. Preview contract',
          step1Description: 'Read the full text of the contract before signing it. After signing it you can still come back to editing it.',
          step2: '2. Sign',
          step2Description: 'Enter your signature in the box below and submit it to sign your contract.',
          step3: '3. Send',
          step3Description: 'If the contract is ready send it to the other Party.',
        },
        sendContractText: {
          heading: 'Change Order Contract',
          description: 'You\'ve signed and sent the contract to the other Party.',
        },
        deleteContractText: {
          deleteButtonLabel: 'Delete',
          header: 'Delete contract',
          description: 'Are you sure you want to delete the contract you’ve already sent to the other Party?',
        },
      },
    },
  },
  user: {
    saveHeaderFile: 'Save',
    headerFileTitle: 'Personalize your contracts by uploading your company logo/header here',
    fileUploadSuccess: 'Data updated successfully',
  },
};

export default lang;
