import lang from 'lang/en';
import { useMemo, useEffect, useState } from 'react';
import {
  CustomCardRow, NarrowInputField,
} from 'domains/project/addProject/styles';
import { useGetField } from 'domains/project/addProject/details/userSearchAndDisplayCard/useGetField';
import {
  isHomeowner, getAuthInfo, isProjectPendingState, isInspector, getUserType,
} from 'utils/helpers';
import { Button } from 'designSystem/button';
import { subcontractorUrl } from 'utils/network/endpoints';
import { projectStatus as status } from 'utils/constants';
import { ApproveCTA } from '../styles';
import { UpdateMilestones } from '../updateMilestones';
import Inspection from '../paymentInspection/inspection';
import type { SubcontractorMilestoneDataProps } from '../types';
import { useMilestoneApproval } from '../approval/useMilestoneApproval';
import Approval from '../approval/approval';

export const MilestoneData = ({
  subContractorIndex, index,
  contractorId, subcontractorId, isEscrow, projectStatus, projectId,
  milestoneData,
} : SubcontractorMilestoneDataProps) => {
  const {
    name, isCompleted, milestoneDocuments: files, id: milestoneId, townInspection, tradesmanInspection,
    isCompletedByContractor, isCompletedByHomeOwner, isCompletedByInspector,
  } = milestoneData;

  const redirectionPath = `/projects/view/subcontractor-milestones/${projectId}`;
  const userType = getUserType();
  const apiUrl = `${subcontractorUrl}${milestoneId}/${userType}/markAsCompleted`;
  const { saveMilestoneApproval, loading } = useMilestoneApproval(apiUrl, redirectionPath);

  const [townInspectionStatus, setTownInspectionStatus] = useState(townInspection);
  const [tradesmanInspectionStatus, setTradesmanInspection] = useState(tradesmanInspection);

  const { getField } = useGetField();
  const { userId } = getAuthInfo();
  const isContractor = userId === contractorId;
  const isTownInspectionAllowded = index > 0 && isContractor && isEscrow;
  const isTradesmenInspectionAllowded = index === 2 && isContractor && isEscrow;
  const isUpdateMilestoneApplicable = projectStatus !== status.draft;
  const showContractorApprovalCTA = index > 0 && isContractor && isEscrow && isCompleted && !isCompletedByContractor;
  const showHomeownerApprovalCTA = index > 0 && isHomeowner() && isEscrow && isCompleted && isCompletedByContractor && !isCompletedByHomeOwner;
  const showInspectorApprovalCTA = index > 0 && isInspector() && isEscrow && isCompleted && isCompletedByHomeOwner && !isCompletedByInspector;

  useEffect(() => {
    if (townInspection) { setTownInspectionStatus(townInspection); }
  }, [townInspection]);

  useEffect(() => {
    if (tradesmanInspection) { setTradesmanInspection(tradesmanInspection); }
  }, [tradesmanInspection]);

  const townInspectionElement = useMemo(() => {
    if (isTownInspectionAllowded) {
      return (
        <Inspection
          labelText={lang.subcontractorMilestones.inspection.townInspection}
          milestoneId={milestoneId}
          inspectionRequiredStatus={townInspectionStatus}
          fieldName="townInspection"
          setInspectionRequired={setTownInspectionStatus}
        />
      );
    }
  }, [isTownInspectionAllowded]);

  const tradesmanInspectionElement = useMemo(() => {
    if (isTradesmenInspectionAllowded) {
      return (
        <Inspection
          labelText={lang.subcontractorMilestones.inspection.tradesmanInspection}
          milestoneId={milestoneId}
          inspectionRequiredStatus={tradesmanInspectionStatus}
          fieldName="tradesmenInspection"
          setInspectionRequired={setTradesmanInspection}
        />
      );
    }
  }, [isTradesmenInspectionAllowded]);

  const updateMilestone = useMemo(() => {
    if (isUpdateMilestoneApplicable) {
      return (
        <UpdateMilestones
          milestoneId={milestoneId}
          isCompleted={isCompleted}
          files={files}
          isSubcontractor={subcontractorId === userId}
          featureAccess={isHomeowner() || isContractor || isInspector() || isProjectPendingState(projectStatus)}
          accessFileUpload={(!isHomeowner() && !isContractor && !isInspector()) && !isProjectPendingState(projectStatus)}
        />
      );
    }
  }, [isUpdateMilestoneApplicable]);

  const contractorApproveCTA = useMemo(() => {
    if (showContractorApprovalCTA) {
      return (
        <ApproveCTA>
          <Button
            label={lang.approveCTA}
            state={loading ? 'loading' : 'active'}
            width="full"
            type="button"
            onClick={() => saveMilestoneApproval({ isCompleted: true })}
          />
        </ApproveCTA>
      );
    }
  }, []);

  const homeownerApproveCTA = useMemo(() => {
    if (showHomeownerApprovalCTA) {
      return (
        <ApproveCTA>
          <Button
            label={lang.approveCTA}
            state={loading ? 'loading' : 'active'}
            width="full"
            type="button"
            onClick={() => saveMilestoneApproval({
              isCompleted: true,
            })}
          />
        </ApproveCTA>
      );
    }
  }, []);

  const inspectorApproveCTA = useMemo(() => {
    if (showInspectorApprovalCTA && tradesmanInspectionStatus) {
      return (
        <ApproveCTA>
          <Button
            label={lang.approveCTA}
            state={loading ? 'loading' : 'active'}
            width="full"
            type="button"
            onClick={() => saveMilestoneApproval({ isCompleted: true })}
          />
        </ApproveCTA>
      );
    }
  }, []);

  const homeownerApproval = useMemo(() => {
    if (isCompletedByHomeOwner && isEscrow) {
      return (
        <Approval
          labelText={lang.milestones.homeownerApproval}
          name={`homeowner-approval-${milestoneId}`}
          milestoneId={milestoneId}
          approvalStatus={isCompletedByHomeOwner}
        />
      );
    }
  }, []);

  const contractorApproval = useMemo(() => {
    if (isCompletedByContractor && isEscrow) {
      return (
        <Approval
          labelText={lang.milestones.contractorApproval}
          name={`contractor-approval-${milestoneId}`}
          milestoneId={milestoneId}
          approvalStatus={isCompletedByContractor}
        />
      );
    }
  }, []);

  const InspectorApproval = useMemo(() => {
    if (isCompletedByInspector && isEscrow) {
      return (
        <Approval
          labelText={lang.milestones.InspectorApproval}
          name={`inspector-approval-${milestoneId}`}
          milestoneId={milestoneId}
          approvalStatus={isCompletedByInspector}
        />
      );
    }
  }, []);

  return (
    <>
      <CustomCardRow>
        <NarrowInputField
          disabled
          labelText={`
                ${lang.subcontractorMilestones.milestones.milestone.startText} 
                ${index + 1}
                ${lang.subcontractorMilestones.milestones.milestone.endText} *
              `}
          field={{ ...getField(`subContractors.${subContractorIndex}.milestoneName` as const, name) }}
        />
      </CustomCardRow>
      {updateMilestone}
      {contractorApproveCTA}
      {homeownerApproveCTA}
      {homeownerApproval}
      {contractorApproval}
      {inspectorApproveCTA}
      {InspectorApproval}
      {townInspectionElement}
      {tradesmanInspectionElement}
    </>
  );
};
