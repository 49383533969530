import { ReactComponent as Calendar } from 'assets/images/calendar.svg';
import { ReactComponent as CrossIcon } from 'assets/images/errorIcon.svg';
import 'globalStyles/datepicker.css';
import moment from 'moment';
import { forwardRef } from 'react';
import Datepicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {
  ErrorMessage, ErrorText, IconPlaceholder, IconPlaceholderText, IconSpan, Input, InputContainer, InputLabel,
} from '../styles';
import type { DatepickerInputProps, LabelledDatepickerProps } from './types';

export const CustomInputField = ({
  labelText,
  placeholderText,
  field,
  onClick,
  errorMessage = '',
  errorIconPosition,
  errorIconTopPosition,
  ...rest
}: DatepickerInputProps) => {
  return (
    <InputContainer>
      <InputLabel htmlFor={field.name}>{labelText}</InputLabel>
      <Input {...rest} {...field} value={field.value || ''} $isError={errorMessage.length > 0} data-testid="test-input" readOnly={true} />
      {
        !field.value
        && (
          <IconPlaceholder onClick={onClick} isError={errorMessage.length > 0}>
            <Calendar />
            <IconPlaceholderText>{placeholderText}</IconPlaceholderText>
          </IconPlaceholder>
        )
      }
      {errorMessage.length > 0 && (
        <IconSpan
          isError={errorMessage.length > 0}
          errorIconPosition={errorIconPosition}
          errorIconTopPosition={errorIconTopPosition}
        >
          <CrossIcon />
        </IconSpan>
      )}
      {errorMessage !== ''
        && <ErrorMessage><ErrorText>{errorMessage}</ErrorText></ErrorMessage>}
    </InputContainer>
  );
};

const CustomInputFieldWithRef = forwardRef((props: DatepickerInputProps, ref) => (
  <CustomInputField {...props} />
));

export const DatepickerField = ({
  selectedDate,
  field,
  setFieldValue,
  disabled,
  minDate = moment().toDate(),
  maxDate = null,
  ...rest
}: LabelledDatepickerProps) => {
  const propsToPass = {
    field,
    ...rest,
  };

  const handleDateChange = (date: Date | null) => {
    if (date && setFieldValue) {
      setFieldValue(date);
    }
  };

  return (
    <Datepicker
      disabled={disabled}
      minDate={minDate}
      selected={selectedDate}
      maxDate={maxDate}
      onChange={(date) => handleDateChange(date)}
      useWeekdaysShort
      customInput={(
        <CustomInputFieldWithRef
          {...propsToPass}
        />
      )}
    />
  );
};

export default DatepickerField;
