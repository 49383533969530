import {
  useCallback, useEffect, useState,
} from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { ReactComponent as PlusIcon } from 'assets/images/plus-icon.svg';
import { Line } from 'rc-progress';
import lang from 'lang/en';
import { CircleButton, Button } from 'designSystem/button';
import { colors } from 'designSystem';
import { DataTable } from 'common/dataTable';
import { getTrimmedString, isContractor } from 'utils/helpers';
import { useWindowDimensions } from 'hooks/useWindowDimensions';
import { ListingEmptyState } from './listingEmptyState';
import { getRequiredColumn } from './data';
import {
  CreateProject, ProjectTitle, Listing, ProgressBar, NameText, Status, ListButton,
} from '../styles';
import type { ColumnType, SortType, ProjectListDataType } from '../types';

const DesktopListing = ({ projectListData }: ProjectListDataType) => {
  const { linkProjects, updateText } = lang;
  const [columns, setColumns] = useState<ColumnType>([]);
  const navigate = useNavigate();
  const { screenSize } = useWindowDimensions();
  useEffect(() => {
    const columListing = getRequiredColumn();
    setColumns(columListing);
  }, []);

  const trimCharSize = screenSize === 'desktop' ? 20 : 10;

  const getListing = () => {
    if (projectListData) {
      const listing = projectListData.map(({
        status, name, homeOwner, progress, userCompany, id,
      }) => {
        return {
          status: <Status status={status}><span><p>{status}</p></span></Status>,
          projectName: <NavLink to={`/projects/view/details/${id}`}><NameText>{getTrimmedString(name, trimCharSize)}</NameText></NavLink>,
          homeownerName:
  <NameText>{homeOwner?.firstName ? getTrimmedString(homeOwner?.firstName, trimCharSize) : 'N/A'}</NameText>,
          contractorName:
  <NameText>{userCompany?.name ? getTrimmedString(userCompany?.name, trimCharSize) : 'N/A'}</NameText>,
          progress:
  <ProgressBar screenSize={screenSize}>
    <Line
      percent={progress}
      strokeWidth={4}
      strokeColor={colors.green500}
      trailColor={colors.blue100}
      trailWidth={3}
    />
    <p>
      {progress}
                                &nbsp;%
    </p>
  </ProgressBar>,
          milestoneAction: <ListButton screenSize={screenSize}><Button type="button" label={updateText} variant="secondary" /></ListButton>,
        };
      });
      return listing;
    }
    return [];
  };

  const handleSorting = useCallback((data: SortType) => {}, []);

  return (
    <>

      <CreateProject>
        {isContractor()
      && (
        <>
          <ProjectTitle>{linkProjects}</ProjectTitle>
          <CircleButton icon={<PlusIcon />} size="small" onClick={() => navigate('/projects/add')} />
        </>
      )}
      </CreateProject>

      {projectListData !== null && projectListData.length > 0
            && (
              <Listing>
                <DataTable columns={columns} data={getListing() || []} onColumnSort={handleSorting} />
              </Listing>
            )}
      {projectListData !== null && projectListData.length === 0
            && <ListingEmptyState />}
    </>
  );
};

export default DesktopListing;
