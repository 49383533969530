import { Route, Routes } from 'react-router-dom';
import HomeownerDetails from './homeowner';
import HomeownerOnboardingConfirmation from './confirmation';

const Homeowner = () => {
  return (
    <Routes>
      <Route path="/" element={<HomeownerDetails />} />
      <Route path="confirmation" element={<HomeownerOnboardingConfirmation />} />
    </Routes>
  );
};

export default Homeowner;
