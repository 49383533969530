import {
  useEffect, useCallback,
} from 'react';
import { useSaveForm } from 'utils/form/useSaveForm';
import { projectBaseUrl } from 'utils/network/endpoints';
import { ChangeOrderPayload } from 'domains/project/addProject/contract/homeowner/types';
import { notifications } from 'designSystem/toastNotifications/toastNotificationEmitter';

export const useSaveChangeOrderContract = (): {
  createChangeOrderContract: (data: ChangeOrderPayload, projectId?: number | string) => Promise<void>;
  updateChangeOrderContract: (data: ChangeOrderPayload, projectId?: number | string) => Promise<void>;
  loading: boolean;
  isSaved: boolean;
  error: string;
} => {
  const {
    loading, error, save, isSaved,
  } = useSaveForm<ChangeOrderPayload>();

  useEffect(() => {
    if (error) {
      notifications.error(error);
    }
  }, [error]);

  const createChangeOrderContract = useCallback(
    async (data: ChangeOrderPayload, projectId?: number | string) => {
      if (projectId) {
        const url = `${projectBaseUrl}/${projectId}/changeOrder`;
        await save(url, data, 'post');
      }
    },
    [save],
  );

  const updateChangeOrderContract = useCallback(
    async (data: ChangeOrderPayload, contractId?: number | string) => {
      if (contractId) {
        const url = `${projectBaseUrl}/changeOrder/${contractId}`;
        await save(url, data, 'patch');
      }
    },
    [save],
  );

  return {
    loading,
    error,
    createChangeOrderContract,
    updateChangeOrderContract,
    isSaved,
  };
};
