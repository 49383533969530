import lang from 'lang/en';
import { isEmpty } from 'lodash';
import * as Yup from 'yup';

export const schema = {
  workOnThePrincipalResidence: Yup.boolean().nullable().required(lang.missingInformationLabel),
  threeDayRight: Yup.boolean().nullable().required(lang.missingInformationLabel),
  rightToInitiateArbitration: Yup.boolean().nullable().required(lang.missingInformationLabel),
  jobAndProjectStatements: Yup.array().of(Yup.string())
    .min(1, lang.missingInformationLabel).required(lang.missingInformationLabel).nullable(),
  contractorDaytimePhoneNo: Yup.string().when('jobAndProjectStatements', (jobAndProjectStatements: string) => {
    return !isEmpty(jobAndProjectStatements) && jobAndProjectStatements.includes('workExceeds1000')
    && jobAndProjectStatements.includes('ownerOccupiedResidence')
    && !jobAndProjectStatements.includes('workingUnderAgreement')
      ? Yup.string()
        .test('length', lang.validPhoneNumber, (val?: number | string) : boolean => {
          if (val) {
            const dataLength = val.toString();
            return dataLength.length === 10;
          }
          return false;
        })
        .nullable()
        .typeError(lang.missingInformationLabel)
        .required(lang.missingInformationLabel) : Yup.string().nullable();
  }),
  contractorEveningPhoneNo: Yup.string().when('jobAndProjectStatements', (jobAndProjectStatements: string) => {
    return !isEmpty(jobAndProjectStatements) && jobAndProjectStatements.includes('workExceeds1000')
    && jobAndProjectStatements.includes('ownerOccupiedResidence')
    && !jobAndProjectStatements.includes('workingUnderAgreement')
      ? Yup.string()
        .test('length', lang.validPhoneNumber, (val?: number | string) : boolean => {
          if (val) {
            const dataLength = val.toString();
            return dataLength.length === 10;
          }
          return false;
        })
        .nullable()
        .typeError(lang.missingInformationLabel)
        .required(lang.missingInformationLabel) : Yup.string().nullable();
  }),
  contractorEmployerIdOrSocialSecurityNo: Yup.string().when('jobAndProjectStatements', (jobAndProjectStatements: string) => {
    return !isEmpty(jobAndProjectStatements) && jobAndProjectStatements.includes('workExceeds1000')
    && jobAndProjectStatements.includes('ownerOccupiedResidence')
    && !jobAndProjectStatements.includes('workingUnderAgreement')
      ? Yup.string().nullable().required(lang.missingInformationLabel) : Yup.string().nullable();
  }),
  contractorHICRegisterationNo: Yup.string().when('jobAndProjectStatements', (jobAndProjectStatements: string) => {
    return !isEmpty(jobAndProjectStatements) && jobAndProjectStatements.includes('workExceeds1000')
    && jobAndProjectStatements.includes('ownerOccupiedResidence')
    && !jobAndProjectStatements.includes('workingUnderAgreement')
      ? Yup.string().nullable().required(lang.missingInformationLabel) : Yup.string().nullable();
  }),
  contractorHICExpiryDate: Yup.string().when('jobAndProjectStatements', (jobAndProjectStatements: string) => {
    return !isEmpty(jobAndProjectStatements) && jobAndProjectStatements.includes('workExceeds1000')
    && jobAndProjectStatements.includes('ownerOccupiedResidence')
    && !jobAndProjectStatements.includes('workingUnderAgreement')
      ? Yup.string().nullable().required(lang.missingInformationLabel) : Yup.string().nullable();
  }),
  salesperson: Yup.string().when('jobAndProjectStatements', (jobAndProjectStatements: string) => {
    return !isEmpty(jobAndProjectStatements) && jobAndProjectStatements.includes('workExceeds1000')
    && jobAndProjectStatements.includes('ownerOccupiedResidence')
    && !jobAndProjectStatements.includes('workingUnderAgreement')
      ? Yup.string().nullable().required(lang.missingInformationLabel) : Yup.string().nullable();
  }),
  responsibleForBuildingPermit: Yup.string().when('jobAndProjectStatements', (jobAndProjectStatements: string) => {
    return !isEmpty(jobAndProjectStatements) && jobAndProjectStatements.includes('workExceeds1000')
    && jobAndProjectStatements.includes('ownerOccupiedResidence')
    && !jobAndProjectStatements.includes('workingUnderAgreement')
      ? Yup.string().nullable().required(lang.missingInformationLabel) : Yup.string().nullable();
  }),
};

export const massachusettsStateFieldSchema = {
  extraData: Yup.object().shape(schema),
};
