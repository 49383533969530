import { useForm } from 'react-hook-form';
import lang from 'lang/en';
import { ControlledRadioButton } from 'designSystem/input/radioBox/controlledRadioButton';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from 'designSystem';
import { useEffect, useMemo, useState } from 'react';
import type { File } from 'designSystem/fileUpload/types';
import { ListFiles } from 'designSystem/fileUpload/ListFiles';
import { contractorMilestoneUrl, subcontractorUrl } from 'utils/network/endpoints';
import { UploadDocuments as UploadMilestones } from './uploaddocuments';
import {
  UpdateMilestoneSection, RadioSection, CompletedText, ButtonContainer,
  FileUploadSection, FilesSection,
} from '../styles';
import { updateMilestoneType, UpdateMilestoneProps } from './types';
import { updateMilestoneSchema } from './validationSchema';
import { useUpdateMilestone } from './useUpdateMilestone';

export const UpdateMilestones = ({
  milestoneId, isCompleted, files, isSubcontractor, featureAccess, accessFileUpload,
}:UpdateMilestoneProps) => {
  const milestoneUrl = isSubcontractor ? subcontractorUrl : contractorMilestoneUrl;
  const showFileListing = featureAccess && files && files.length !== 0;
  const [isCompletedMilestone, setCompletedMilestone] = useState(isCompleted);
  const [documents, setDocuments] = useState<Array<File>>([]);
  const { saveMilestoneData, loading } = useUpdateMilestone(milestoneUrl);

  const resolver = { resolver: yupResolver(updateMilestoneSchema) };
  const {
    control, handleSubmit, setValue, reset, trigger,
  } = useForm({
    ...resolver,
    mode: 'onChange',
    defaultValues: {
      isCompleted: false,
      milestoneDocuments: [],
    },
  });

  useEffect(() => {
    const documentsListing = files && files.length !== 0
      ? files.map((item) => ({ fileName: item.fileName, documentUrl: item.documentUrl })) : [];
    reset({ isCompleted, milestoneDocuments: documentsListing });
    setDocuments(documentsListing);
    trigger();
  }, [isCompleted, files]);

  const updateDocuments = (newDocuments: Array<File>) => {
    setValue('milestoneDocuments', newDocuments);
    setDocuments(newDocuments);
  };

  const updateCompletedStatus = (value: boolean) => {
    setValue('isCompleted', value);
    setCompletedMilestone(value);
  };

  const onSubmit = (data: updateMilestoneType) => {
    if (milestoneId) {
      saveMilestoneData(data, milestoneId);
    }
  };

  const getButtonStateValue = () => {
    if (documents.length > 0) {
      return loading ? 'loading' : 'active';
    }
    return 'disabled';
  };

  const filesSection = useMemo(() => {
    if (showFileListing) {
      return <FilesSection><ListFiles documents={files} /></FilesSection>;
    }
  }, [showFileListing]);

  const uploadMilestonFiles = (isCompletedMilestone && accessFileUpload)
    && (
      <>
        <FileUploadSection>
          <UploadMilestones
            documents={documents}
            updateDocuments={updateDocuments}
            uploadTitle={lang.milestoneUpload.uploadTitle}
            addButtonLabel={lang.milestoneUpload.addButtonLabel}
            deleteTextHeading={lang.milestoneUpload.delete.heading}
            deleteSubText={lang.milestoneUpload.delete.subText}
            loadTitleSection
          />
        </FileUploadSection>
        <ButtonContainer>
          <Button label={lang.updateText} width="full" state={getButtonStateValue()} />
        </ButtonContainer>
      </>
    );
  return (
    <UpdateMilestoneSection key={milestoneId}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <CompletedText>{lang.completed}</CompletedText>
        <RadioSection>
          <ControlledRadioButton
            name="isCompleted"
            control={control}
            labelText={lang.subcontractorMilestones.milestones.completed.yesOption}
            id={`${milestoneId}-yes`}
            isChecked={isCompletedMilestone !== undefined && isCompletedMilestone}
            isDisabled={featureAccess}
            onChange={() => updateCompletedStatus(true)}
          />
          <ControlledRadioButton
            name="isCompleted"
            control={control}
            labelText={lang.subcontractorMilestones.milestones.completed.noOption}
            id={`${milestoneId}-no`}
            isDisabled={featureAccess}
            onChange={() => updateCompletedStatus(false)}
            isChecked={isCompletedMilestone !== undefined && !isCompletedMilestone}
          />
        </RadioSection>
        {filesSection}
        {uploadMilestonFiles}
      </form>
    </UpdateMilestoneSection>
  );
};
