import * as Yup from 'yup';
import lang from 'lang/en';

const fileSchema = Yup.object().shape({
  fileName: Yup.string(),
  documentUrl: Yup.string(),
});

export const updateMilestoneSchema = Yup.object().shape({
  isCompleted: Yup.boolean(),
  milestoneDocuments: Yup.array().of(fileSchema).min(1, lang.milestoneUpload.requireFile).required(lang.milestoneUpload.requireFile),
});
