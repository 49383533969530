import { SignatureState } from 'domains/project/addProject/contract/homeowner/types';

export const initializeDrawing = (canvasId: string, updateSignatureStateCallback: (newState: SignatureState) => void, preloadedImageUrl?: string) => {
  const canvas = document.getElementById(canvasId) as HTMLCanvasElement;
  const context2D = canvas.getContext('2d') as CanvasRenderingContext2D;
  context2D.strokeStyle = '#222222';
  context2D.lineWidth = 4;

  if (preloadedImageUrl) {
    const preloadedImage = new Image();
    preloadedImage.onload = () => {
      context2D.drawImage(preloadedImage, 0, 0);
      updateSignatureStateCallback('preloaded');
    };
    preloadedImage.src = preloadedImageUrl;
  }

  let drawing = false;
  let mousePos = {
    x: 0,
    y: 0,
  };
  let lastPos = mousePos;

  canvas.addEventListener('mousedown', (event) => {
    drawing = true;
    lastPos = getMousePos(canvas, event);
  }, false);

  canvas.addEventListener('mouseup', (event) => {
    drawing = false;
    if (!isCanvasBlank()) {
      updateSignatureStateCallback('present');
    }
  }, false);

  canvas.addEventListener('mousemove', (event) => {
    mousePos = getMousePos(canvas, event);
  }, false);

  // Add touch event support for mobile
  canvas.addEventListener('touchstart', (event) => {
    drawing = true;
    lastPos = getTouchPos(canvas, event);
  }, false);

  canvas.addEventListener('touchmove', (event) => {
    const touch = event.touches[0];
    const mouseEvent = new MouseEvent('mousemove', {
      clientX: touch.clientX,
      clientY: touch.clientY,
    });
    canvas.dispatchEvent(mouseEvent);
  }, false);

  canvas.addEventListener('touchstart', (event) => {
    mousePos = getTouchPos(canvas, event);
    const touch = event.touches[0];
    const mouseEvent = new MouseEvent('mousedown', {
      clientX: touch.clientX,
      clientY: touch.clientY,
    });
    canvas.dispatchEvent(mouseEvent);
  }, false);

  canvas.addEventListener('touchend', (event) => {
    const mouseEvent = new MouseEvent('mouseup', {});
    canvas.dispatchEvent(mouseEvent);
  }, false);

  function getMousePos(canvasDom: HTMLCanvasElement, mouseEvent: MouseEvent) {
    const rect = canvasDom.getBoundingClientRect();
    return {
      x: mouseEvent.clientX - rect.left,
      y: mouseEvent.clientY - rect.top,
    };
  }

  function getTouchPos(canvasDom: HTMLCanvasElement, touchEvent: TouchEvent) {
    const rect = canvasDom.getBoundingClientRect();
    return {
      x: touchEvent.touches[0].clientX - rect.left,
      y: touchEvent.touches[0].clientY - rect.top,
    };
  }

  function renderCanvas() {
    if (drawing) {
      context2D.moveTo(lastPos.x, lastPos.y);
      context2D.lineTo(mousePos.x, mousePos.y);
      context2D.stroke();
      lastPos = mousePos;
    }
  }

  function isCanvasBlank() {
    const pixelBuffer = new Uint32Array(
      context2D.getImageData(0, 0, canvas.width, canvas.height).data.buffer,
    );

    return !pixelBuffer.some((color) => color !== 0);
  }

  (function drawLoop() {
    window.requestAnimationFrame(drawLoop);
    renderCanvas();
  }());
};
