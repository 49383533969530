import { ControlledInputField } from 'designSystem/input';
import {
  LabelWithMargin, RadioContainer, ErrorMessage, ErrorText,
} from 'domains/project/addProject/contract/styles';
import { SelectPropsWithControl } from 'domains/project/addProject/contract/subcontractor/form/types';
import lang from 'lang/en';
import { useWatch } from 'react-hook-form';
import { useEffect } from 'react';
import { useWindowDimensions } from 'hooks/useWindowDimensions';
import { getTextareaIconLeftPosition } from '../helpers';

const formStrings = lang.contract.subcontractors.modal.form;

const PaymentFrequencyOptionSelect = ({
  registrant,
  control,
  setValue,
  errorMsg,
}: SelectPropsWithControl) => {
  const optionValue = useWatch({
    control,
    name: registrant.name,
  });
  const { screenSize } = useWindowDimensions();
  useEffect(() => {
    if (optionValue !== 'other' && setValue) {
      setValue('paymentFrequencyOther', null);
    }
  }, [optionValue]);

  return (
    <RadioContainer>
      <LabelWithMargin htmlFor="paymentFrequencyWeeklyOption">
        <input
          {...registrant}
          type="radio"
          value="weekly"
          id="paymentFrequencyWeeklyOption"
        />
        {formStrings.paymentFrequencyOptions.weeklyOption}
      </LabelWithMargin>
      <LabelWithMargin htmlFor="paymentFrequencyMonthlyOption">
        <input
          {...registrant}
          type="radio"
          value="monthly"
          id="paymentFrequencyMonthlyOption"
        />
        {formStrings.paymentFrequencyOptions.monthlyOption}
      </LabelWithMargin>
      <LabelWithMargin htmlFor="paymentFrequencyQuarterlyOption">
        <input
          {...registrant}
          type="radio"
          value="quarterly"
          id="paymentFrequencyQuarterlyOption"
        />
        {formStrings.paymentFrequencyOptions.quarterlyOption}
      </LabelWithMargin>
      <LabelWithMargin htmlFor="paymentFrequencyOtherOption">
        <input
          {...registrant}
          type="radio"
          value="other"
          id="paymentFrequencyOtherOption"
        />
        {formStrings.paymentFrequencyOptions.otherOption}
      </LabelWithMargin>
      <ControlledInputField
        control={control}
        name="paymentFrequencyOther"
        labelText={formStrings.paymentFrequencyOptions.otherFieldLabel}
        disabled={optionValue !== 'other'}
        errorIconPosition={getTextareaIconLeftPosition(screenSize, '516px')}
      />
      <ErrorMessage><ErrorText>{errorMsg}</ErrorText></ErrorMessage>
    </RadioContainer>
  );
};

PaymentFrequencyOptionSelect.defaultProps = {
  errorMsg: '',
};

export default PaymentFrequencyOptionSelect;
