import {
  CustomControlledDatepicker, LabelWithMargin, RadioContainer, ErrorMessage, ErrorText,
} from 'domains/project/addProject/contract/styles';
import { CompletionDateOptionSelectProps } from 'domains/project/addProject/contract/subcontractor/form/types';
import lang from 'lang/en';
import { useWatch } from 'react-hook-form';
import { useEffect } from 'react';
import { useWindowDimensions } from 'hooks/useWindowDimensions';

const formStrings = lang.contract.subcontractors.modal.form;

const SubstantialCompletionDateOptionSelect = ({
  registrant,
  control,
  handleDateChange,
  getValues,
  setValue,
  specificDate,
  otherDate,
  errorMsg,
}: CompletionDateOptionSelectProps) => {
  const optionValue = useWatch({
    control,
    name: registrant.name,
  });
  const { screenSize } = useWindowDimensions();
  useEffect(() => {
    if (optionValue !== 'specificDate' && setValue) {
      setValue('specificSubstantialCompletionDate', null);
    }
    if (optionValue !== 'other' && setValue) {
      setValue('otherSubstantialCompletionDate', null);
    }
  }, [optionValue]);
  return (
    <RadioContainer>
      <LabelWithMargin htmlFor="specificSubstantialCompletionDateOption">
        <input
          {...registrant}
          type="radio"
          value="specificDate"
          id="specificSubstantialCompletionDateOption"
        />
        {formStrings.substantialCompletionDateOptions.specificDateOption}
      </LabelWithMargin>
      <CustomControlledDatepicker
        placeholder={formStrings.pickDatePlaceholder}
        control={control}
        name="specificSubstantialCompletionDate"
        labelText={formStrings.substantialCompletionDateOptions.completionDateSpecificFieldLabel}
        setFieldValue={handleDateChange('specificSubstantialCompletionDate', 'selectedSubstantialDateSpecific')}
        selectedDate={specificDate}
        disabled={optionValue !== 'specificDate'}
        errorIconPosition={screenSize === 'mobile' ? '91%' : '343px'}
      />
      <LabelWithMargin htmlFor="industryStandardSubstantialCompletionDateOption">
        <input
          {...registrant}
          type="radio"
          value="industryStandard"
          id="industryStandardSubstantialCompletionDateOption"
        />
        {formStrings.substantialCompletionDateOptions.industryStandardsOption}
      </LabelWithMargin>
      <LabelWithMargin htmlFor="otherSubstantialCompletionDateOption">
        <input
          {...registrant}
          type="radio"
          value="other"
          id="otherSubstantialCompletionDateOption"
        />
        {formStrings.substantialCompletionDateOptions.otherOption}
      </LabelWithMargin>
      <CustomControlledDatepicker
        placeholder={formStrings.pickDatePlaceholder}
        control={control}
        name="otherSubstantialCompletionDate"
        labelText={formStrings.substantialCompletionDateOptions.otherCompletionDateFieldLabel}
        setFieldValue={handleDateChange('otherSubstantialCompletionDate', 'selectedSubstantialDateOther')}
        selectedDate={otherDate}
        disabled={optionValue !== 'other'}
        errorIconPosition={screenSize === 'mobile' ? '91%' : '343px'}
      />
      <ErrorMessage><ErrorText>{errorMsg}</ErrorText></ErrorMessage>
    </RadioContainer>
  );
};

SubstantialCompletionDateOptionSelect.defaultProps = {
  errorMsg: '',
};

export default SubstantialCompletionDateOptionSelect;
