import { useCallback, useState } from 'react';
import { companyDataUrl } from 'utils/network/endpoints';
import { notifications } from 'designSystem/toastNotifications/toastNotificationEmitter';
import { apiClient } from 'utils/network/apiClient';
import lang from 'lang/en';
import { clearAuthInfo } from 'utils/helpers';
import { useNavigate } from 'react-router-dom';
import { CompanyDataDTO } from './types';

export const useGetCompanyData = (): {
  getCompanyData: () => Promise<CompanyDataDTO>;
  loading: boolean
} => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const getCompanyData = useCallback(
    async () => {
      setLoading(true);
      const url = companyDataUrl;
      try {
        const callResponse = await apiClient.get(url);
        if (callResponse.status === 200) {
          return callResponse.data.data;
        } else {
          if ((callResponse as any).isNotAuthenticated) {
            clearAuthInfo();
            notifications.error(lang.sessionExpiredMessage);
            navigate('/signin');
            return;
          }
          notifications.error(lang.unknownErrorTryLater);
        }
      } catch (callError: unknown) {
        notifications.error((callError as Error)?.message || lang.accountActivationFailedMessage);
      }
      setLoading(false);
    },
    [apiClient],
  );

  return {
    getCompanyData,
    loading,
  };
};
