import {
  useFieldArray,
} from 'react-hook-form';
import { useState } from 'react';
import lang from 'lang/en';
import Loader from 'designSystem/loader';
import Action from 'designSystem/action/action';
import { emptySubcontractorMilestones } from './milestones';
import DeleteItem from '../../../../../common/deleteItem';
import UserSearchAndDisplayCard from '../../details/userSearchAndDisplayCard/userSearchAndDisplayCard';
import { Subcontractor } from '../../types';
import UserFoundCardContent from './UserFoundCardContent';
import { SubcontractorFieldsProps, FormFieldType } from './types';

const SubcontractorFields = ({
  control,
  setValue,
  getValues,
  isEscrow,
}: SubcontractorFieldsProps &
FormFieldType) => {
  const [isOpen, setIsOpen] = useState(false);
  const [deleteItemIndex, setDeleteItemIndex] = useState(-1);
  const [loader, setLoader] = useState(false);

  const {
    fields,
    append,
    remove,
    update,
  } = useFieldArray({
    control,
    keyName: 'key',
    name: 'subContractors',
  });

  const handleUserFound = (user: any, index: number) => {
    if ((fields[index] as any)?.subcontractorId === '') {
      update(index as number, {
        ...fields[index],
        subcontractorId: user.id,
      });
    }
  };

  const handleUserNotFound = (index: number) => {
    const field = fields[index] as unknown as Subcontractor;
    if (field.subcontractorId === '') {
      return;
    }
    remove(index);
    handleAddSubcontractor();
  };

  const handleShowModal = (index: number) => {
    setIsOpen(true);
    setDeleteItemIndex(index);
  };

  const handleCloseModal = () => {
    setIsOpen(false);
    setDeleteItemIndex(-1);
    setLoader(false);
  };

  const handleDelete = (index: number) => {
    setLoader(true);
    remove(index);
    handleCloseModal();
  };
  const handleAddSubcontractor = () => {
    append({
      subcontractorId: '',
      cost: '',
      ...emptySubcontractorMilestones,
    });
  };

  const subcontractorFields = fields.map((item: any, index) => {
    const headerText = `${lang.subcontractorMilestones.subHeaderStartText} ${index + 1} ${lang.subcontractorMilestones.subHeaderEndText}`;
    const header = (
      <>
        {headerText}
        {index === 0
          ? null
          : <Action label={lang.subcontractorMilestones.milestones.removeSubcontractorButtonText} type="delete" onClick={() => handleShowModal(index)} />}
      </>
    );
    const userContent = (
      <UserFoundCardContent
        item={item}
        control={control}
        index={index}
        isLastElement={index === fields.length - 1}
        handleAddSubcontractor={handleAddSubcontractor}
        getValues={getValues}
        setValue={setValue}
        isEscrow={isEscrow}
      />
    );

    return (
      <>
        <DeleteItem
          heading={lang.subcontractorMilestones.milestones.removeSubcontractorModal.title}
          subText={lang.subcontractorMilestones.milestones.removeSubcontractorModal.description}
          handleCancel={() => setIsOpen(false)}
          handleDelete={() => {
            handleDelete(deleteItemIndex);
          }}
          isOpen={isOpen}
        />
        <UserSearchAndDisplayCard
          key={item.key}
          customRender={userContent}
          userType="subcontractor"
          userNotFoundMessage={lang.subcontractorMilestones.subcontractorNotFoundText}
          header={header}
          description={lang.subcontractorMilestones.description}
          userId={item.subcontractorId}
          onUserFound={(user) => handleUserFound(user, index)}
          onUserNotFound={() => handleUserNotFound(index)}
          title={lang.subcontractorMilestones.subcontractorDetailsFilledDescription}
        />
      </>
    );
  });

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {loader && <Loader />}
      {subcontractorFields}
    </>
  );
};

export default SubcontractorFields;
