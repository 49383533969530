import { Controller } from 'react-hook-form';
import { ReactElement } from 'react';
import PasswordField from './passwordField';
import type { ControlledPasswordFieldProps } from './types';

const ControlledInputField = ({
  name, control, showValidationSchema, ...propsToPass
}
  : ControlledPasswordFieldProps)
  :ReactElement => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        return (
          <PasswordField
            showValidationSchema={showValidationSchema}
            errorMessage={error?.message}
            {...propsToPass}
            field={field}
          />
        );
      }}
    />
  );
};

export default ControlledInputField;
