import { useCallback, useEffect } from 'react';
import { useSaveForm } from 'utils/form/useSaveForm';
import { companyDataUrl } from 'utils/network/endpoints';
import { notifications } from 'designSystem/toastNotifications/toastNotificationEmitter';
import { CompanyData } from '../types';

export const useSaveCompanyData = (): {
  saveCompanyData: (data: CompanyData) => Promise<void>;
  loading: boolean;
  isSaved: boolean;
  error: string;
} => {
  const {
    loading, error, save, isSaved,
  } = useSaveForm<CompanyData>();

  useEffect(() => {
    if (error) {
      notifications.error(error);
    }
  }, [error]);

  const saveCompanyData = useCallback(
    async (data: CompanyData) => {
      const url = companyDataUrl;

      await save(url, data, 'patch');
    },
    [save],
  );

  return {
    loading,
    error,
    saveCompanyData,
    isSaved,
  };
};
