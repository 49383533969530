import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useWindowDimensions } from 'hooks/useWindowDimensions';
import lang from 'lang/en';
import { useEffect, useState } from 'react';
import { ActionButtonsContainer } from 'domains/accountSetup/onboarding/styles';
import { Button } from 'designSystem';
import {
  getButtonState, isReadOnlyProjectSectionBySubcontractorAndHomeowner,
  isVisibleProjectSectionByContractor, isSubcontractorNotAuthorized, projectSubmittedByContractor, isInspector, isProjectPendingState,
} from 'utils/helpers';
import { useNavigate, useParams } from 'react-router-dom';
import type { File } from 'designSystem/fileUpload/types';
import { Header, TitleHeading } from 'domains/project/addProject/styles';
import { projectDetailsFormSchema } from 'domains/project/addProject/validationSchema';
import { NameAndAddress } from 'domains/project/addProject/details/nameAndAddress';
import { useSaveProjectDetailsData } from 'domains/project/addProject/useSaveProjectDetailsData';
import { ProjectPreloadedData, ProjectDetails } from 'domains/project/addProject/types';
import UserSearchAndDisplayCard from 'domains/project/addProject/details/userSearchAndDisplayCard/userSearchAndDisplayCard';
import { ProjectCost } from 'domains/project/addProject/details/projectCost';
import Blueprints from 'domains/project/addProject/details/blueprints/blueprints';
import { HomeownerDetails } from './homeownerDetails';
import { ListBlueprints } from './blueprints';

type DetailsProps = {
  preloadedData?: ProjectPreloadedData;
  onFormSubmitted: () => void;
  setProjectId: (id: number) => void;
  handleSubmitProjectValidation: (data: Array<{[key:string] : boolean}>) => void;
  projectStatus?: number;
  contractorId?: string;
}

const Details = ({
  preloadedData, onFormSubmitted, setProjectId, handleSubmitProjectValidation,
  projectStatus, contractorId,
}: DetailsProps) => {
  const {
    loading,
    saveProjectDetailsData,
    updateProjectDetailsData,
    isSaved,
    response,
  } = useSaveProjectDetailsData();
  const { id } = useParams<{ id: string }>();
  useEffect(() => {
    if (id) {
      setProjectId(parseInt(id, 10));
    }
  }, [id]);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [documents, setDocuments] = useState<Array<File>>([]);
  const navigate = useNavigate();
  const resolver = { resolver: yupResolver(projectDetailsFormSchema) };
  const {
    handleSubmit, control, formState, reset, trigger, setValue,
  } = useForm(
    {
      ...resolver,
      mode: 'onChange',
      defaultValues: {
        project: {
          name: '',
          country: 'United States',
          streetAddress: '',
          city: '',
          state: '',
          zip: '',
        },
        homeOwnerId: null,
        cost: null,
        projectDocuments: [],
        escrow: true,
      },
    },
  );

  const updateDocuments = (newDocuments: Array<File>) => {
    setValue('projectDocuments', newDocuments);
    setDocuments(newDocuments);
  };

  useEffect(() => {
    if (preloadedData) {
      const { id: preloadedId, projectDocuments, ...restPreloadedData } = preloadedData;
      const documentsListing = projectDocuments.map((item:any) => ({ fileName: item.fileName, documentUrl: item.documentUrl }));
      reset({ ...restPreloadedData, projectDocuments: documentsListing });
      setDocuments(documentsListing);
      setIsDataLoaded(true);
      trigger();
    }
  }, [preloadedData]);

  const onSubmit = (data: ProjectDetails) => {
    handleSubmitProjectValidation(
      [{
        homeowner: !!data.homeOwnerId,
        cost: !!data.cost,
        projectDocuments: data.projectDocuments ? data.projectDocuments.length > 0 : false,
      }],
    );
    if (isDataLoaded) {
      updateProjectDetailsData({ ...data, id: preloadedData?.id as string });
      return;
    }
    saveProjectDetailsData(data);
  };

  const handleBack = () => {
    navigate('/projects');
  };

  useEffect(() => {
    if (isSaved && response && response.id) {
      setProjectId(response.id);
      onFormSubmitted();
    }
  }, [isSaved, response]);

  const { isDirty, isValid } = formState;

  const { screenSize } = useWindowDimensions();

  const handleUserFound = (user: any) => {
    setValue('homeOwnerId', user.id);
  };

  const handleUserNotFound = () => {
    // eslint-disable-next-line no-console
    console.log('not found');
  };

  const homeownerSearch = () => {
    if (isReadOnlyProjectSectionBySubcontractorAndHomeowner(contractorId) || isInspector()) {
      return (
        <HomeownerDetails userId={preloadedData?.homeOwnerId} userType="homeowner" />
      );
    }
    return (
      <UserSearchAndDisplayCard
        userType="homeowner"
        userNotFoundMessage={lang.homeownerNotFound}
        description={lang.homeownerDetailsDescription}
        header={lang.homeownerDetailsLabel}
        userId={preloadedData?.homeOwnerId}
        onUserFound={handleUserFound}
        onUserNotFound={handleUserNotFound}
      />
    );
  };

  const projectDocuments = () => {
    if (isReadOnlyProjectSectionBySubcontractorAndHomeowner(contractorId)
     || isVisibleProjectSectionByContractor(projectStatus, contractorId) || isInspector() || isProjectPendingState(projectStatus)) {
      return <ListBlueprints documents={documents} />;
    }
    return (
      <Blueprints projectDocuments={documents} updateProjectDocuments={updateDocuments} />
    );
  };

  return (
    <>
      <TitleHeading><Header screenSize={screenSize}>{lang.projectDetailsStepLabel}</Header></TitleHeading>
      <form onSubmit={handleSubmit(onSubmit)}>
        <NameAndAddress control={control} projectStatus={projectStatus} contractorId={contractorId} />
        {homeownerSearch()}
        {projectDocuments()}
        {!isSubcontractorNotAuthorized(contractorId)
         && <ProjectCost control={control} projectStatus={projectStatus} contractorId={contractorId} />}
        <ActionButtonsContainer>
          {projectSubmittedByContractor(projectStatus, contractorId)
              && (
                <>
                  <Button data-testid="back-button" label={lang.backLabel} type="button" variant="secondary" onClick={handleBack} />
                  <Button data-testid="next-button" type="submit" variant="primary" state={getButtonState(isDirty || isDataLoaded, isValid, loading)} label={lang.nextLabel} />
                </>
              )}
        </ActionButtonsContainer>

      </form>
    </>
  );
};

export default Details;
