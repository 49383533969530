import { ReactComponent as MenuIcon } from 'assets/images/menu.svg';
import { ReactComponent as Logo } from 'assets/images/navlogo.svg';
import SignoutIcon from 'assets/images/logout.svg';
import lang from 'lang/en';
import { colors } from 'designSystem';
import { NavLink } from 'react-router-dom';
import { useState } from 'react';
import { showSettings } from 'utils/helpers';
import { otherLinks } from './content';
import type { LinkType, ActionType } from './types';
import {
  MobileNavContainer, MobileLinkSection, ToggleIcon, MobileLogoSection, MobileLinks, LinkName, OtherLinkSection,
  LinkIcon, MainLinksSection, SignoutLink,
} from './styles';

export const MobileNavigation = ({ pageLinks, handleLogout }: LinkType & ActionType) => {
  const [linkDropdownVisibility, setLinkDropdownVisibility] = useState(false);

  const toggleDropdown = () => {
    (linkDropdownVisibility)
      ? setLinkDropdownVisibility(false)
      : setLinkDropdownVisibility(true);
  };

  const activeStyle = {
    textDecoration: 'none',
    background: colors.blue900,
    width: '100%',
    padding: '11px 13px',
    marginBottom: '17px',
    borderRadius: '6px',
    opacity: '0.9',
  };

  return (
    <MobileNavContainer>
      <MobileLinkSection>
        <MobileLogoSection>
          <Logo />
        </MobileLogoSection>
        <ToggleIcon onClick={() => toggleDropdown()} linkDropdownVisibility={linkDropdownVisibility} data-testid="toggle">
          <MenuIcon />
        </ToggleIcon>
      </MobileLinkSection>
      {
        linkDropdownVisibility
        && (
          <MobileLinks>
            <MainLinksSection>
              {pageLinks.map(({
                id, icon, name, link,
              }) => {
                return (
                  <NavLink
                    to={link}
                    key={id}
                    style={
                      ({ isActive }) => (
                        isActive
                          ? activeStyle
                          : {
                            marginLeft: '12px',
                            marginBottom: '16px',
                          }
                      )
                    }
                  >
                    <LinkIcon src={icon} alt={`${name}-icon`} />
                    <LinkName>{name}</LinkName>
                  </NavLink>
                );
              })}
            </MainLinksSection>
            <OtherLinkSection>
              <>
                {
                  showSettings() && otherLinks.map(({
                    id, icon, name, link,
                  }) => {
                    return (
                      <NavLink
                        to={link}
                        key={id}
                        style={
                          ({ isActive }) => (
                            isActive
                              ? activeStyle
                              : {
                                marginLeft: '12px',
                                marginBottom: '16px',
                              }
                          )
                        }
                      >
                        <LinkIcon src={icon} alt={`${name}-icon`} />
                        <LinkName>{name}</LinkName>
                      </NavLink>
                    );
                  })
                }
                <SignoutLink onClick={handleLogout}>
                  <LinkIcon src={SignoutIcon} alt="sign-out" />
                  <LinkName>{lang.linkSignOut}</LinkName>
                </SignoutLink>
              </>
            </OtherLinkSection>
          </MobileLinks>
        )
      }
    </MobileNavContainer>
  );
};
