import styled from 'styled-components';
import { typings, colors } from 'designSystem';
import { ScreenSize } from 'hooks/useWindowDimensions';

export const HeaderLogoContainer = styled.div<{screenSize: ScreenSize}>`
  padding: ${({ screenSize }) => screenSize === 'desktop' ? '92px 170px' : '92px 18px'};
`;

export const HeaderLogoFormSection = styled.div`
 
`;

export const LogoButtonSection = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 32px;
  & button {
    width: 260px;
  }
`;

export const LogoHeadingText = styled.h2`
  ${typings.textLg}
  font-size: 24px;
  color: ${colors.black};
  margin-bottom: 24px;
`;
