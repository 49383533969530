import { useCallback, useEffect } from 'react';
import { useSaveForm } from 'utils/form/useSaveForm';
import { contractorMilestoneUrl } from 'utils/network/endpoints';
import { notifications } from 'designSystem/toastNotifications/toastNotificationEmitter';
import lang from 'lang/en';

type InspectionRequestType = {
  type: string;
  inspectionDocument?: { fileName: string; documentUrl: string; }
}

export const useInspectionRequest = (): {
  saveInspectionRequestData: (data: InspectionRequestType,
     milestoneId: number) => Promise<void>;
  loading: boolean;
  isSaved: boolean;
  error: string;
} => {
  const {
    loading, error, save, isSaved,
  } = useSaveForm<InspectionRequestType>();

  useEffect(() => {
    if (isSaved && !error) {
      notifications.success(lang.milestones.contractorInspectionSuccess);
    }
  }, [isSaved, error]);

  useEffect(() => {
    if (error) {
      notifications.error(error);
    }
  }, [error]);

  const saveInspectionRequestData = useCallback(
    async (data: InspectionRequestType, milestoneId: number) => {
      if (milestoneId) {
        const url = `${contractorMilestoneUrl}${milestoneId}/requestInspection`;
        await save(url, data, 'post');
      }
    },
    [save],
  );

  return {
    loading,
    error,
    saveInspectionRequestData,
    isSaved,
  };
};
