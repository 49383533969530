import { AccountType, getAuthInfo } from 'utils/helpers';
import { useEffect, useState, useMemo } from 'react';
import lang from 'lang/en';
import { CustomCardTitle } from 'domains/project/addProject/details/userSearchAndDisplayCard/styles';
import { UserDataDTO } from 'domains/project/addProject/details/userSearchAndDisplayCard/types';
import { useGetUserData } from 'domains/project/addProject/details/userSearchAndDisplayCard/useGetUserData';
import { NoDataFoundText } from 'domains/project/addProject/styles';
import { CardDescription } from 'domains/accountSetup/onboarding/styles';
import { DisplayUserData } from '../../displayUserDetails';
import { Margin } from '../styles';

export const UserDetails = ({
  userId, userType, index, isContractor,
}: {
  userId?: string;
  userType: AccountType;
  index: number;
  isContractor: boolean;
}) => {
  const {
    getUserDataById,
  } = useGetUserData(userType);

  const { userId: sessionUserId } = getAuthInfo();

  const [userData, setUserData] = useState<UserDataDTO>();

  useEffect(() => {
    const searchUser = async () => {
      const requestResult = await getUserDataById(userId as string);
      if (requestResult) {
        setUserData(requestResult);
      }
    };
    if (userId) {
      searchUser();
    }
  }, [userId]);

  const heading = sessionUserId === userId ? lang.detailsText
    : `${lang.subcontractorMilestones.subHeaderStartText} ${index + 1} ${lang.subcontractorMilestones.subHeaderEndText}`;

  const subcontrctorDetailsText = useMemo(() => {
    if (isContractor) {
      return (
        <CardDescription>
          {lang.subcontractorMilestones.subcontractorDetailsFilledDescription}
        </CardDescription>
      );
    }
  }, [isContractor]);

  if (userData) {
    return (
      <>
        <CustomCardTitle>
          {heading}
        </CustomCardTitle>
        {subcontrctorDetailsText}
        <Margin margin="40px 0 0 0" />
        <DisplayUserData userData={userData} />
      </>
    );
  }
  return <NoDataFoundText>{lang.subcontractorMilestones.noSubcontractorDetailsFound}</NoDataFoundText>;
};
