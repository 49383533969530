import { ControlledInputField } from 'designSystem/input';
import {
  Card, CardRow, CardTitle, CustomFieldSized,
} from 'domains/accountSetup/onboarding/styles';
import lang from 'lang/en';
import { useWindowDimensions } from 'hooks/useWindowDimensions';
import { isReadOnlyProjectSectionBySubcontractorAndHomeowner, isEditableByContractor } from 'utils/helpers';
import { PropsWithControl } from '../types';

const NameAndAdress = ({ control, projectStatus, contractorId }: PropsWithControl) => {
  const { screenSize } = useWindowDimensions();
  const isDisabled = isEditableByContractor(projectStatus, contractorId) || isReadOnlyProjectSectionBySubcontractorAndHomeowner(contractorId);
  return (
    <Card screenSize={screenSize}>
      <CardTitle>
        {`${lang.projectNameAndAddressLabel}`}
      </CardTitle>
      <CardRow>
        <CustomFieldSized
          maxWidth="372px"
          labelText={lang.projectNameLabel}
          control={control}
          name="project.name"
          disabled={isDisabled}
        />
      </CardRow>
      <CardRow>
        <CustomFieldSized disabled maxWidth="612px" labelText={lang.countryLabel} control={control} name="project.country" />
      </CardRow>
      <CardRow>
        <ControlledInputField labelText={lang.addressLabel} control={control} name="project.streetAddress" disabled={isDisabled} />
      </CardRow>
      <CardRow customFlexBasis="230px">
        <ControlledInputField labelText={lang.cityLabel} control={control} name="project.city" disabled={isDisabled} />
        <ControlledInputField labelText={lang.stateLabel} control={control} name="project.state" disabled={isDisabled} />
        <CustomFieldSized maxWidth="240px" labelText={lang.zipLabel} control={control} name="project.zip" disabled={isDisabled} />
      </CardRow>
    </Card>
  );
};

export default NameAndAdress;
