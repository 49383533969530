/* eslint-disable no-nested-ternary */
import styled, { css } from 'styled-components';
import {
  textLg, textSm, textBase, textXLg, textSLg,
} from 'designSystem/typings';
import { colors } from 'designSystem';
import type { StyleProps } from './types';

const LabelStyle = css`
  ${textSm}
  line-height: 20px;
  color: ${colors.gray500};
  `;

export const Label = styled.p`
  ${LabelStyle}
`;

export const Form = styled.form`
  margin-top: 24px;
`;

export const RadioMargin = styled.div<StyleProps>`
  margin-left: ${({ screenSize }) => screenSize === 'mobile' ? 0 : '20px'};
  margin-top: ${({ screenSize }) => screenSize === 'mobile' ? '20px' : 0}
`;

const SubTitle = css`
  ${textLg}
  font-weight: 500;
  color: ${colors.gray900};
`;

export const RadioBoxes = styled.div<StyleProps>`
  display: flex;
  flex-direction: ${({ screenSize }) => screenSize === 'mobile' ? 'column' : 'row'};
  align-items: ${({ screenSize }) => screenSize === 'mobile' ? 'center' : 'none'};
`;

export const PlanContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px 0 10px 5px;
`;

export const PlanTitle = styled.p`
  ${SubTitle}
`;

export const PlanAmount = styled.p`
  ${textBase}
  font-weight: 500;
  color: ${colors.gray500};
  margin-top: 24px;
  & span {
    ${textXLg}
    font-weight: 800;
    color: ${colors.blue700};
    vertical-align: baseline;
  }
`;

export const PlanLabel = styled.p`
  ${LabelStyle}
  text-align: left;
  margin-top: 32px;
`;

export const UpFrontAmount = styled.p`
  ${textSLg}
  color: ${colors.blue700};
  margin-top: 15px;
`;

export const CardElements = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 64px;  
`;

export const PaymentTitle = styled.p`
  ${SubTitle}
  margin-bottom: 4px;
`;

export const CardNumberBox = styled.div`
  margin: 10px 0 0 0;
  border: 1px solid gray;
  height: 48px;
  border-radius: 50px;
  padding: 12px 25px;
  font-size: 16px;
  border: 1px solid #E05959;
`;

export const InputElements = styled.div<StyleProps>`
  width: ${({ screenSize }) => screenSize === 'mobile' ? '100%' : '78%'};
  max-width: 456px;
  margin-top: 32px;
  display: flex;
  flex-direction: column;
`;

export const RowFields = styled.div`
  display: flex;
`;

export const Expiry = styled.div`
  width: 36%;
`;

export const Cvc = styled.div`
  width: 36%;
  margin-left: 10px;
`;

export const BtnSpacer = styled.div`
  margin-top: 12px;
`;

export const PaymentIcons = styled.div<StyleProps>`
 display: flex; 
 margin-top: 24px;
 width: ${({ screenSize }) => screenSize === 'mobile' ? '50%' : '29%'};
 justify-content: space-between;
`;

export const SecurePaymentLabel = styled.p`
  ${textSm}
  line-height: 20px;
  font-weight: 500;
  color: ${colors.gray700};
  margin-left: 12px;
  margin-right: 8px;
`;

export const StripeInfo = styled.div`
  display: flex;
  align-items: center;
  margin-top: 27px;
`;

export const ErrorMessage = styled.span`
  margin: 5px 0 0 12px;
`;

export const ErrorText = styled.p`
 ${textSm}
 line-height: 20px;
 color: ${colors.red500};
`;
