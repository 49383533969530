import { Button } from 'designSystem';
import { CardSection } from 'designSystem/card/styles';
import lang from 'lang/en';
import {
  Container, Heading, ButtonSpacer,
} from './styles';
import type { ConfirmationProps } from './type';

export const Confirmation = ({ handleOkClick, confirmationText } : ConfirmationProps) => {
  const {
    thankyou, okay,
  } = lang;
  return (
    <Container>
      <Heading>{thankyou}</Heading>
      <CardSection maxWidth="448px">
        {confirmationText}
      </CardSection>
      <ButtonSpacer />
      <Button variant="primary" label={okay} type="button" onClick={handleOkClick} />
    </Container>
  );
};
