import { SubcontractorContractDTO } from 'domains/project/addProject/contract/subcontractor/form/types';
import { useState } from 'react';
import { apiClient } from 'utils/network/apiClient';
import { createSubcontractorContractUrl } from 'utils/network/endpoints';

export const useCreateSubcontractorContract = () => {
  const [loading, setLoading] = useState(false);
  const [isSaved, setIsSaved] = useState(false);

  const createSubcontractorContract = async (data: SubcontractorContractDTO, subcontractorId: string) => {
    setLoading(true);
    try {
      const url = createSubcontractorContractUrl.replace('%subcontractorId%', subcontractorId);
      const result = await apiClient.post(url, data);
      if (result.status === 200) {
        setIsSaved(true);
      }
    } catch (requestError:any) {
      console.error(requestError);
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    isSaved,
    createSubcontractorContract,
  };
};
