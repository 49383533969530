import { Step, StepState } from 'designSystem/steps/types';
import lang from 'lang/en';

export const stepToRoute = (step: number, currentProjectId: number): string => {
  switch (step) {
    case 0:
      return currentProjectId ? `/projects/add/details/${currentProjectId}` : '/projects/add/details';
    case 1:
      return `/projects/add/contractor-milestones/${currentProjectId}`;
    case 2:
      return `/projects/add/subcontractor-milestones/${currentProjectId}`;
    case 3:
      return `/projects/add/contract/${currentProjectId}`;
    case 4:
      return `/projects/add/submit-save/${currentProjectId}`;
    default:
      return '/projects/add/details';
  }
};

export const initialSteps: Array<Step> = [
  {
    circleContent: '01',
    label: lang.projectDetailsStepLabel,
    stepState: 'notStarted' as StepState,
  },
  {
    circleContent: '02',
    label: lang.projectMilestonesStepLabel,
    stepState: 'notStarted' as StepState,
  },
  {
    circleContent: '03',
    label: lang.projectContractorMilestonesStepLabel,
    stepState: 'notStarted' as StepState,
  },
  {
    circleContent: '04',
    label: lang.projectContractStepLabel,
    stepState: 'notStarted' as StepState,
  },
  {
    circleContent: '05',
    label: lang.projectSubmitOrSaveStepLabel,
    stepState: 'notStarted' as StepState,
  },
];
