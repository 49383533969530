import { CardTitle, CardDescription } from 'domains/accountSetup/onboarding/styles';
import lang from 'lang/en';
import { useEffect, useMemo, useState } from 'react';
import { isEmpty } from 'lodash';
import { useWatch } from 'react-hook-form';
import { ErrorMessage, ErrorText } from 'domains/project/addProject/contract/styles';
import { CheckboxField } from '../../../common/checkboxField';
import { PointsSection, ListItems } from '../../styles';
import { points } from './content';
import { ContractorDetailsField } from './contractorDetailFields';
import { ComponentCommonTypes } from '../types';

export const ProjectStatements = ({
  getValues,
  control,
  setValue,
  errors,
  clearErrors,
  register,
}: ComponentCommonTypes) => {
  const { contract: { homeowner: { modal: { form: { massachusettsFields: { jobProjectStatements } } } } } } = lang;
  const jobProjectMultiStatement = register && register('extraData.jobAndProjectStatements');
  const [showOtherFields, setShowOtherFields] = useState(false);
  const selectedJobAndProjectStatements = jobProjectMultiStatement?.name && useWatch({
    control,
    name: jobProjectMultiStatement.name,
  });

  useEffect(() => {
    const flag = !isEmpty(selectedJobAndProjectStatements)
      && selectedJobAndProjectStatements.includes('workExceeds1000')
      && selectedJobAndProjectStatements.includes('ownerOccupiedResidence')
      && !selectedJobAndProjectStatements.includes('workingUnderAgreement') ? true : false;
    setShowOtherFields(flag);
    manageFieldsValues(selectedJobAndProjectStatements);
  }, [selectedJobAndProjectStatements]);

  const manageFieldsValues = (values: Array<string>) => {
    if ((!values?.includes('workExceeds1000') || !values?.includes('ownerOccupiedResidence'))
     || values?.includes('workingUnderAgreement')) {
      setValue('extraData.contractorDaytimePhoneNo', '');
      setValue('extraData.contractorEveningPhoneNo', '');
      setValue('extraData.contractorEmployerIdOrSocialSecurityNo', '');
      setValue('extraData.contractorHICRegisterationNo', '');
      setValue('extraData.contractorHICExpiryDate', '');
      setValue('extraData.salesperson', '');
      setValue('extraData.responsibleForBuildingPermit', '');
    }
  };

  const renderPoints = useMemo(() => {
    return (
      <PointsSection>
        {
          points.map((item, index) => {
            return (
              <ListItems>
                {`(${index + 1})`}
                    &nbsp;
                {item}
              </ListItems>
            );
          })
        }
      </PointsSection>
    );
  }, [jobProjectStatements.points]);

  const contractorDetails = useMemo(() => {
    if (!showOtherFields) { return ''; }
    if (showOtherFields) {
      return (
        <ContractorDetailsField
          control={control}
          getValues={getValues}
          setValue={setValue}
          errors={errors}
          clearErrors={clearErrors}
        />
      );
    }
  }, [showOtherFields, errors]);

  return (
    <>
      <CardTitle marginBottom="0">
        {jobProjectStatements.mainHeading}
      </CardTitle>
      <CardDescription>
        {jobProjectStatements.description}
      </CardDescription>
      <CheckboxField
        labelText={jobProjectStatements.statement1}
        fieldName="extraData.jobAndProjectStatements"
        value="workExceeds1000"
        getValues={getValues}
        registrant={jobProjectMultiStatement}
      />
      <CheckboxField
        labelText={jobProjectStatements.statement2}
        fieldName="extraData.jobAndProjectStatements"
        value="ownerOccupiedResidence"
        getValues={getValues}
        registrant={jobProjectMultiStatement}
      />
      <CheckboxField
        labelText={jobProjectStatements.statement3}
        fieldName="extraData.jobAndProjectStatements"
        value="workingUnderAgreement"
        getValues={getValues}
        registrant={jobProjectMultiStatement}
      />
      {renderPoints}
      {contractorDetails}
      <ErrorMessage margin="12px 0 0 10px">
        <ErrorText>{errors?.extraData?.jobAndProjectStatements?.message || ''}</ErrorText>
      </ErrorMessage>
    </>
  );
};
