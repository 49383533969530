import { useCallback, useEffect } from 'react';
import { notifications } from 'designSystem/toastNotifications/toastNotificationEmitter';
import { userBaseUrl } from 'utils/network/endpoints';
import { useGetData } from 'utils/form/useGetData';
import { AxiosResponse } from 'axios';
import { setAuthInfo, clearAuthInfo, getAuthInfo } from 'utils/helpers';

export const useGetUserInformation = () : {
  response: AxiosResponse | null
  getUserInfo: () => void;
} => {
  const {
    getData,
    error,
    response,
  } = useGetData<AxiosResponse>();

  useEffect(() => {
    if (error) {
      notifications.error(error);
    }
  }, [error]);

  useEffect(() => {
    if (response && !error) {
      const authUser = getAuthUserData(response);
      const userInfo = getAuthInfo();
      setAuthInfo({ ...userInfo, ...authUser });
    }
  }, [response, error]);

  const getUserInfo = useCallback(async () => {
    await getData(userBaseUrl);
  }, []);
  return {
    response,
    getUserInfo,
  };
};

const getAuthUserData = (response: any) => {
  return {
    userId: response.id,
    user: response.name,
    accountStatus: response.accountStatus,
    accountType: response.type,
  };
};
