import { ReactComponent as CrossIcon } from 'assets/images/errorIcon.svg';
import {
  ErrorMessage, ErrorText, IconSpan, InputContainer, InputLabel, NumberInput, PostfixIconSpan, PrefixIconSpan,
} from '../styles';
import type { MaskedNumberFieldProps } from './types';

//type FormatNumberProps = { floatValue: number; formattedValue: string; value: number; }

export const NumberMaskedField = ({
  labelText,
  field,
  errorMessage = '',
  maskFormat,
  className,
  placeholder,
  disabled,
  iconPrefix,
  iconPostfix,
  prefixAlignment,
  keepPostfix,
  errorIconAlignment,
  inputpadding,
  inputwidth,
  postfixAlignment,
  numberPrefix,
  fixedDecimalScale,
  errorIconPosition,
  errorIconTopPosition,
}: MaskedNumberFieldProps) => {
  const {
    value: fieldValue,
    onChange,
    ...fieldToPass
  } = field;

  const getPostFixElemement = () => {
    if ((keepPostfix && iconPostfix) || (iconPostfix && errorMessage === '' && (fieldValue === '' || fieldValue === null))) {
      return <PostfixIconSpan postfixAlignment={postfixAlignment}>{iconPostfix}</PostfixIconSpan>;
    }
  };
  const errorIconElement = errorMessage.length > 0 && (
    <IconSpan
      errorIconAlignment={errorIconAlignment}
      isError={errorMessage !== ''}
      errorIconPosition={errorIconPosition}
      errorIconTopPosition={errorIconTopPosition}
    >
      <CrossIcon />
    </IconSpan>
  );
  const errorMsgText = errorMessage !== '' && <ErrorMessage><ErrorText>{errorMessage}</ErrorText></ErrorMessage>;
  const prefixElement = iconPrefix && <PrefixIconSpan prefixAlignment={prefixAlignment}>{iconPrefix}</PrefixIconSpan>;

  const handleValueChange = (value: any) => {
    onChange(value.value);
  };

  return (
    <InputContainer className={className}>
      <InputLabel htmlFor={field.name}>{labelText}</InputLabel>
      {prefixElement}
      <NumberInput
        placeholder={placeholder}
        format={maskFormat}
        prefix={numberPrefix}
        disabled={disabled}
        fixedDecimalScale={fixedDecimalScale}
        {...fieldToPass}
        onValueChange={handleValueChange}
        $isError={errorMessage.length > 0}
        value={field.value || ''}
        data-testid="test-input"
        inputpadding={inputpadding}
        inputwidth={inputwidth}
      />
      {getPostFixElemement()}
      {errorIconElement}
      {errorMsgText}
    </InputContainer>
  );
};

export default NumberMaskedField;
