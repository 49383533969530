import { ToastContainer as ToastContainerBase } from 'react-toastify';

const ToastContainer = () => {
  return (
    <ToastContainerBase
      position="top-center"
      autoClose={5000}
      newestOnTop={false}
      rtl={false}
      pauseOnFocusLoss
    />
  );
};

export default ToastContainer;
