import { colors } from 'designSystem';
import ReactS3Uploader from 'react-s3-uploader';
import { textSm } from 'designSystem/typings';
import styled, { css } from 'styled-components';
import { ScreenSize } from 'hooks/useWindowDimensions';
import { ErrorMessage } from 'designSystem/input/styles';

export const Title = styled.h2`
  ${textSm};
  color: ${colors.gray700};
  line-height: 20px;
`;

export const Container = styled.section`
  display: flex;
  flex-direction: column;
`;

export const DottedBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  border: 2px dashed ${colors.gray300};
  box-sizing: border-box;
  border-radius: 6px;
  padding: 26px 0px;
`;

export const UploadButton = styled.button`
  margin: 0px;
  padding: 0px;
  border: none;
  background-color: transparent;
  cursor: pointer;
  outline: none;

  color: ${colors.blue700};
  ${textSm};
  line-height: 20px;
  margin-top: 12px;
  margin-bottom: 4px;
`;

export const StyledReactS3Uploader = styled(ReactS3Uploader)`
  position: absolute;
  z-index: -1;
  height: 0px;
`;

export const FileTypesLabel = styled.p`
  ${textSm};
  font-size: 12px;
  line-height: 16px;
  color: ${colors.gray500};
`;

export const FileRow = styled.div`
  width: 100%;
  height: 80px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  padding: 0px 24px;
  margin-top: 8px;
  margin-bottom: 32px;

  box-shadow: 0px 4px 6px -1px rgba(29, 78, 216, 0.1), 0px 2px 4px -1px rgba(29, 78, 216, 0.06);
  border-radius: 8px;

  font-size: 14px;
  line-height: 20px;
  color: ${colors.gray900};
`;

export const FileName = styled.p<{screenSize?: ScreenSize}>`
${({ screenSize }) => {
    switch (screenSize) {
      case 'mobile':
        return css`
        width: 35%;
        text-overflow: ellipsis;
        overflow: hidden;
        `;
      default:
        return css``;
    }
  }};
`;

export const FilesContainer = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const CustomErrorMessage = styled(ErrorMessage)`
  margin: 0px;
  & > p {
    margin-top: 4px;
  }
`;

export const ListFile = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const DownloadLink = styled.div`
  display: flex;
  align-items: center;
`;

export const DownloadText = styled.p`
  ${textSm}
  line-height: 20px;
  font-weight: 500;
  color: ${colors.blue700};
  margin-left: 11px;
`;

export const ActionItems = styled.div`
  display: flex;
  align-items: flex-end;
  & a {
    margin-left: 16px;
  }
`;
