import styled from 'styled-components';
import { colors } from 'designSystem';
import { ScreenSize } from 'hooks/useWindowDimensions';
import { textSm } from 'designSystem/typings';
import { StepState } from './types';

const stepStateToTextColor = (stepState: StepState) => {
  switch (stepState) {
    case 'started':
      return colors.blue700;
    case 'completed':
      return colors.white;
    case 'notStarted':
    default:
      return colors.gray500;
  }
};

const stepStateToBorderColor = (stepState: StepState) => {
  switch (stepState) {
    case 'started':
      return colors.blue700;
    case 'completed':
      return colors.blue700;
    case 'notStarted':
    default:
      return colors.gray500;
  }
};

const stepStateToBackgroundColor = (stepState: StepState) => {
  switch (stepState) {
    case 'completed':
      return colors.blue700;
    case 'notStarted':
    case 'started':
    default:
      return colors.white;
  }
};

const stepStateToLabelColor = (stepState: StepState) => {
  switch (stepState) {
    case 'completed':
      return colors.gray900;
    case 'started':
      return colors.blue700;
    case 'notStarted':
    default:
      return colors.gray500;
  }
};

type StepCircleProps = {
  screenSize?: ScreenSize;
  stepState: StepState;
  isClickable?: boolean;
}

export const StepContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const StepCircle = styled.div<StepCircleProps>`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 40px;
  height: 40px;

  border-radius: 50%;
  border: 2px solid ${({ stepState }) => stepStateToBorderColor(stepState)};
  box-sizing: border-box;

  color: ${({ stepState }) => stepStateToTextColor(stepState)};
  background-color: ${({ stepState }) => stepStateToBackgroundColor(stepState)};

  cursor: ${({ isClickable }) => isClickable ? 'pointer' : 'unset'};

  & svg {
    & path {
      fill: ${colors.white};
    }
  }
`;

type StepLabelProps = {
  stepState: StepState,
  isClickable?: boolean;
}

export const StepLabel = styled.p<StepLabelProps>`
  ${textSm};
  margin-left: 24px;
  color: ${({ stepState }) => stepStateToLabelColor(stepState)};
  cursor: ${({ isClickable }) => isClickable ? 'pointer' : 'unset'};
`;

export const StepsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
`;
