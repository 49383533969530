import { Button } from 'designSystem';
import { CardSection } from 'designSystem/card/styles';
import { useGetUserInformation } from 'hooks/useGetUserInformation';
import lang from 'lang/en';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  ConfirmationContainer, Heading, PlainText, TextSection, ButtonSpacer,
} from '../styles';

export const CompanyOnboardingConfirmation = () => {
  const {
    thankyou, okay, companyOnboardingConfirmationText,
  } = lang;
  const navigate = useNavigate();
  const { getUserInfo } = useGetUserInformation();

  useEffect(() => {
    getUserInfo();
  }, []);

  const handleOkClick = () => {
    navigate('/projects');
  };

  return (
    <ConfirmationContainer>
      <Heading>{thankyou}</Heading>
      <CardSection maxWidth="352px">
        <TextSection>
          <PlainText>{companyOnboardingConfirmationText}</PlainText>
        </TextSection>
      </CardSection>
      <ButtonSpacer />
      <Button variant="primary" label={okay} type="button" onClick={handleOkClick} />
    </ConfirmationContainer>
  );
};

export default CompanyOnboardingConfirmation;
