import { ReactComponent as ImageIcon } from 'assets/images/image-icon.svg';
import { ErrorMessage, ErrorText } from 'designSystem/input/styles';
import Loader from 'designSystem/loader';
import lang from 'lang/en';
import { useCallback, useRef, useState } from 'react';
import runtimeEnv from '@mars/heroku-js-runtime-env';
import {
  Container, DottedBox, FileTypesLabel, StyledReactS3Uploader, Title, UploadButton,
} from './styles';
import type { File, FileUploadProps, SigningFile } from './types';
import UploadedFiles from './uploadedFiles';

const FileUpload = ({
  title, filetypeDefinition, getSignedUrlHandler, uploadedFiles, updateUploadedFiles, maxFileSize, addButtonLabel,
  deleteItemHeading, deleteItemSubtext, variant, showDownload, showAddMore,
}: FileUploadProps) => {
  const { REACT_APP_IMAGE_URL } = runtimeEnv();
  const uploaderRef = useRef<HTMLInputElement | null>(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [loader, setLoader] = useState(false);
  const onButtonClick = useCallback(() => {
    if (uploaderRef.current) {
      uploaderRef.current.click();
    }
  }, [uploaderRef]);

  const onUploadFinished = (response: {signedUrl: any, fileKey: string}, uploadedFile: {name: string}) => {
    //const finalDocumentUrl = response.signedUrl.replaceAll(REACT_APP_IMAGE_URL, '');
    const newFile = { fileName: uploadedFile.name, documentUrl: response.fileKey };
    const newFiles = [...uploadedFiles, newFile];
    setLoader(false);
    updateUploadedFiles(newFiles);
  };

  const handleUpdateFiles = (newFiles: Array<File>) => {
    updateUploadedFiles(newFiles);
    setErrorMessage('');
  };

  const validateFileAndGetErrorMessage = (file: SigningFile): string => {
    const fileNameSplit = file.name.split('.');
    const fileType = file.type || fileNameSplit[fileNameSplit.length - 1];
    const isFileTypeCorrect = filetypeDefinition.accept?.includes(fileType);
    const isFileSizeCorrect = file.size <= maxFileSize;

    if (!isFileTypeCorrect) {
      return `${lang.fileUpload.incorrectExtensionText} ${filetypeDefinition.label}`;
    }
    if (!isFileSizeCorrect) {
      return `${lang.fileUpload.incorrectSizeText} ${filetypeDefinition.sizeLabel}`;
    }

    return '';
  };

  const onUploadStarting = (fileToUpload: SigningFile, next: (file: SigningFile) => void) => {
    const errorMessageToSet = validateFileAndGetErrorMessage(fileToUpload);

    if (errorMessageToSet) {
      setErrorMessage(errorMessageToSet);
      return;
    }
    setErrorMessage('');
    setLoader(true);
    next(fileToUpload);
  };

  return (
    <>
      {loader && <Loader />}
      <StyledReactS3Uploader
        getSignedUrl={getSignedUrlHandler}
        uploadRequestHeaders={{ 'content-type': '' }}
        contentDisposition="attachment"
        onFinish={onUploadFinished}
        preprocess={onUploadStarting}
        accept={filetypeDefinition.accept}
        inputRef={(ref: HTMLInputElement) => {
          uploaderRef.current = ref;
        }}
      />
      {uploadedFiles.length !== 0
        ? (
          <UploadedFiles
            files={uploadedFiles}
            updateFiles={handleUpdateFiles}
            errorMessage={errorMessage}
            onAddButtonClick={onButtonClick}
            addButtonLabel={addButtonLabel}
            deleteItemSubtext={deleteItemSubtext}
            deleteItemHeading={deleteItemHeading}
            variant={variant}
            showDownload={showDownload}
            showAddMore={showAddMore}
          />
        )
        : (
          <Container>
            <Title>{title}</Title>
            <DottedBox>
              <ImageIcon />
              <UploadButton type="button" onClick={onButtonClick}>{lang.fileUpload.label}</UploadButton>
              <FileTypesLabel>{`${filetypeDefinition.label} ${filetypeDefinition.sizeLabel}`}</FileTypesLabel>
            </DottedBox>
            {errorMessage && <ErrorMessage><ErrorText>{errorMessage}</ErrorText></ErrorMessage>}
          </Container>
        )}
    </>
  );
};

FileUpload.defaultProps = {
  showDownload: false,
  showAddMore: true,
  addButtonLabel: '',
};

export default FileUpload;
