import lang from 'lang/en';
import { Card } from 'domains/accountSetup/onboarding/styles';
import { useWindowDimensions } from 'hooks/useWindowDimensions';
import { RadioOptionFields } from '../../../common/radioOptionFields';
import type { ComponentCommonTypes } from '../types';
import { ProjectStatements } from './projectStatements';

export const MassachusettsStateForm = ({
  control, setValue, getValues, errors, clearErrors, register,
} : ComponentCommonTypes) => {
  const { contract: { homeowner: { modal: { form: { massachusettsFields } } } } } = lang;
  const { screenSize } = useWindowDimensions();
  return (
    <>
      <Card screenSize={screenSize}>
        <ProjectStatements
          getValues={getValues}
          control={control}
          errors={errors}
          clearErrors={clearErrors}
          setValue={setValue}
          register={register}
        />
      </Card>
      <Card screenSize={screenSize}>
        <RadioOptionFields
          control={control}
          fieldName="extraData.rightToInitiateArbitration"
          fieldText={massachusettsFields.rightToInitiateArbitration}
          setValue={setValue}
          errorMsg={errors?.extraData?.rightToInitiateArbitration?.message || ''}
          clearErrors={clearErrors}
          getValues={getValues}
        />
      </Card>
      <Card screenSize={screenSize}>
        <RadioOptionFields
          control={control}
          fieldName="extraData.workOnThePrincipalResidence"
          fieldText={massachusettsFields.workOnPrincipleResidence}
          setValue={setValue}
          errorMsg={errors?.extraData?.workOnThePrincipalResidence?.message || ''}
          clearErrors={clearErrors}
          getValues={getValues}
        />
      </Card>
      <Card screenSize={screenSize}>
        <RadioOptionFields
          control={control}
          fieldName="extraData.threeDayRight"
          fieldText={massachusettsFields.rightToRescind}
          setValue={setValue}
          errorMsg={errors?.extraData?.threeDayRight?.message || ''}
          clearErrors={clearErrors}
          getValues={getValues}
        />
      </Card>
    </>

  );
};
