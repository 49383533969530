export const projectStatus = {
  draft: 1,
  live: 2,
  underReview: 3,
  pending: 4,
  completed: 5,
  cancelled: 6,
};

export const userRole = {
  contractor: 'contractor',
  homeowner: 'homeowner',
  inspector: 'inspector',
};

export const states = {
  connecticut: 'connecticut',
  florida: 'florida',
  massachusetts: 'massachusetts',
  delaware: 'delaware',
  ohio: 'ohio',
};
