import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from 'store/store';

interface AppState {
  header?: string;
}

const initialState: AppState = {
  header: '',
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setHeader: (state, action: PayloadAction<string>) => {
      state.header = action.payload;
    },
  },
});

export const { setHeader } = appSlice.actions;
export const selectHeader = (state: RootState) => state.app.header;
export default appSlice.reducer;
