import { ReactComponent as CrossIcon } from 'assets/images/errorIcon.svg';
import { ChangeEvent, useState } from 'react';
import lang from 'lang/en';
import {
  Input, InputContainer, InputLabel,
  LabelSection, ShowText, IconSpan, ErrorMessage, ErrorText,
} from '../styles';
import passwordValidator from './passwordValidator';
import type { PasswordFieldProps } from './types';
import PasswordValidationRules from './passwordValidationRules';

export const PasswordField = ({
  labelText, field, errorMessage = '', showValidationSchema = false,
}: PasswordFieldProps) => {
  const passwordValidationSchema = {
    uppercase: true,
    lowercase: true,
    number: true,
    min: 8,
    specialCharacter: true,
  };

  const [passwordValidations, setPasswordValidations] = useState(passwordValidator('', passwordValidationSchema));
  const [passwordVisibility, setPasswordVisibility] = useState(false);
  const { onChange, onBlur, name } = field;

  const validatePassword = (value: string) => {
    const valid = passwordValidator(value, passwordValidationSchema);
    setPasswordValidations([...valid]);
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChange(event);
    validatePassword(event.target.value);
  };

  return (
    <InputContainer>
      <LabelSection>
        <InputLabel htmlFor={name}>{labelText}</InputLabel>
        <ShowText onClick={() => setPasswordVisibility(!passwordVisibility)}>{passwordVisibility ? lang.hide : lang.show}</ShowText>
      </LabelSection>
      <Input
        type={passwordVisibility ? 'text' : 'password'}
        name={name}
        onChange={handleChange}
        onBlur={onBlur}
        $isError={errorMessage.length > 0}
      />
      {errorMessage.length > 0 && <IconSpan><CrossIcon /></IconSpan>}
      {errorMessage !== ''
      && <ErrorMessage><ErrorText>{errorMessage}</ErrorText></ErrorMessage>}
      {showValidationSchema && <PasswordValidationRules messages={passwordValidations} />}
    </InputContainer>
  );
};

export default PasswordField;
