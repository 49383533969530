/* eslint-disable no-nested-ternary */
import { colors } from 'designSystem';
import { textLg } from 'designSystem/typings';
import styled, { css } from 'styled-components';
import type { StyleProps } from './subscription/types';

const commonCardStyle = css`
  background-color: ${colors.white};
  padding: 48px 200px;
  margin-top: 32px;
`;

export const Card = styled.div<StyleProps>`
${commonCardStyle}
  width: 100%;
  ${({ screenSize }) => {
    switch (screenSize) {
      case 'mobile':
        return css`
        background-color: transparent;
        padding: 0;
        margin-top: 62px;
        `;
      case 'smallTablet':
        return css`
        background-color: ${colors.white};
        padding: 12px 0;
        margin-top: 62px;
        `;
      case 'tablet':
        return css`
          background-color: ${colors.white};
          padding: 48px 88px;
          margin-top: 62px;
          `;
      case 'desktop':
        return commonCardStyle;
      default:
        return commonCardStyle;
    }
  }};
`;

export const CardRow = styled.div`
  display: flex;
  
  & > div {
    width: 100%;
    margin-right: 24px;
  }
`;

export const ActionButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 32px;
  margin-bottom: 34px;
  gap: 0px 32px;
`;

export const CardContainer = styled.section<StyleProps>`
  display: flex;
  flex-direction: column;
  padding: ${({ screenSize }) => screenSize === 'tablet' ? '57px 32px' : (screenSize === 'mobile' ? 0 : '12px 0 12px 188px')};
`;

export const CardTitle = styled.div`
  ${textLg};
  font-weight: 500;
  margin-bottom: 5px;
  color: ${colors.gray900};
`;
