import { Controller } from 'react-hook-form';
import { ReactElement } from 'react';
import { ToggleInput } from './toggleInput';
import type { ControlledToggleProps } from './types';

export const ControlledRadioButton = ({
  name, control, ...propsToPass
}: ControlledToggleProps):ReactElement => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => {
        const { error } = fieldState;
        return <ToggleInput {...propsToPass} field={field} errorMessage={error?.message} />;
      }}
    />
  );
};

export default ControlledRadioButton;
