import {
  State, SubcontractorContractDTO, SubcontractorContractFormType, SubcontractorResponsibilities,
} from 'domains/project/addProject/contract/subcontractor/form/types';
import { stateOptions } from 'domains/project/addProject/contract/subcontractor/staticData';
import { ScreenSize } from 'hooks/useWindowDimensions';
import { dateStringFormat, defaultDateFormatString } from 'utils/dateFormats';

const removeEmpty = (object: object) => {
  return Object.fromEntries(Object.entries(object).filter(([_, v]) => v != null && v !== ''));
};

const getFilteredData = (data: any) => {
  const {
    projectAddress,
    homeownerFirstLastName,
    contractorFirstLastName,
    subcontractorFirstLastName,
    escrow,
    paymentAmountHourly,
    subcontractorResponsibilities,
    otherCompletionDate,
    otherSubstantialCompletionDate,
    paymentAmountTotal,
    paymentAmountOther,
    paymentMethodOther,
    paymentFrequencyOther,
    specificCompletionDate,
    specificSubstantialCompletionDate,
    contractorCompanyName,
    subcontractorCompanyName,
    contractLog,
    responsibilities,
    otherSubcontractorResponsibilities,
    ...filteredData
  } = data;
  return filteredData;
};

export const getMappedData = (data: SubcontractorContractFormType) => {
  const mappedData = {
    ...data,
    state: data.state.value,
    paymentAmountHourlyRate: parseFloat(data.paymentAmountHourly as string),
    subcontractorResponsibility: data.subcontractorResponsibilities.map((subcontractorResponsibility: any) => {
      return { value: subcontractorResponsibility };
    }),
    commencementDate: data.commencementDate,
    completionDateSpecific: data.specificCompletionDate,
    substantialCompletionDateSpecific: data.specificSubstantialCompletionDate,
    completionDateOther: data?.otherCompletionDate,
    substantialCompletionDateOther: data?.otherSubstantialCompletionDate,
    subcontractorWorkerCompensationInsuranceOptions: data?.subcontractorWorkerCompensationInsuranceOptions,
    paymentAmountTotalAmount: data?.paymentAmountTotal ? parseFloat(data.paymentAmountTotal) : null,
    paymentAmountOtherDescription: data?.paymentAmountOther,
    paymentMethodOptionsOther: data?.paymentMethodOther,
    paymentFrequencyOptionsOther: data?.paymentFrequencyOther,
    workerCompensationInsuranceLimit: data?.workerCompensationInsuranceLimit ? parseFloat(data.workerCompensationInsuranceLimit) : null,
    subcontractorWorkerCompensationInsuranceLimit: data?.subcontractorWorkerCompensationInsuranceLimit ? parseFloat(data.subcontractorWorkerCompensationInsuranceLimit) : null,
    descriptionOfOtherResposibilities: data?.otherSubcontractorResponsibilities || '',
  };

  return getFilteredData(mappedData);
};

export const mapDataToFormValues = (
  data: SubcontractorContractDTO,
  homeownerFirstLastName: string,
  contractorFirstLastName: string,
  subcontractorFirstLastName: string,
  projectAddress: unknown,
  escrow: boolean,
  subcontractorCompanyName: string,
  contractorCompanyName: string,
): SubcontractorContractFormType => {
  const mappedFormValues = {
    ...data,
    state: stateOptions.find((stateOption) => stateOption.value === data.state) as State,
    commencementDate: dateStringFormat(data.commencementDate, defaultDateFormatString),
    specificCompletionDate: data?.completionDateSpecific && dateStringFormat(data.completionDateSpecific, defaultDateFormatString),
    specificSubstantialCompletionDate: data?.substantialCompletionDateSpecific && dateStringFormat(data.substantialCompletionDateSpecific, defaultDateFormatString),
    workerCompensationInsuranceLimit: data?.workerCompensationInsuranceLimit?.toString(),
    subcontractorResponsibilities: data?.responsibilities?.map((responsibility) => responsibility.value as SubcontractorResponsibilities),
    paymentAmountHourly: data?.paymentAmountHourlyRate?.toString(),
    paymentAmountTotal: data?.paymentAmountTotalAmount?.toString(),
    subcontractorWorkerCompensationInsuranceOptions: data?.subcontractorWorkerCompensationInsuranceOptions,
    subcontractorWorkerCompensationInsuranceLimit: data?.subcontractorWorkerCompensationInsuranceLimit?.toString(),
    homeownerFirstLastName,
    contractorFirstLastName,
    subcontractorFirstLastName,
    subcontractorCompanyName,
    contractorCompanyName,
    projectAddress,
    otherSubcontractorResponsibilities: data?.descriptionOfOtherResposibilities || '',
    otherCompletionDate: data?.completionDateOther && dateStringFormat(data.completionDateOther, defaultDateFormatString),
    otherSubstantialCompletionDate: data?.substantialCompletionDateOther && dateStringFormat(data.substantialCompletionDateOther, defaultDateFormatString),
    escrow,
    paymentAmountOther: data?.paymentAmountOtherDescription || '',
    paymentMethodOther: data?.paymentMethodOptionsOther || '',
    paymentFrequencyOther: data?.paymentFrequencyOptionsOther || '',
  };

  return removeEmpty(mappedFormValues) as SubcontractorContractFormType;
};

export const getButtonState = (isValid: boolean, loading?: boolean, updateLoading?: boolean) => {
  if (isValid) {
    return loading || updateLoading ? 'loading' : 'active';
  }

  return 'disabled';
};

export const getTopPosition = (screenSize: ScreenSize) => {
  if (screenSize === 'tablet') {
    return '45px';
  }
  return screenSize === 'mobile' ? '54%' : '32px';
};

export const getTextareaIconTopPosition = (screenSize: ScreenSize) => {
  if (screenSize === 'tablet') {
    return '68px';
  }
};

export const getTextareaIconLeftPosition = (screenSize: ScreenSize, leftPosition?: string) => {
  if (screenSize === 'tablet') {
    return leftPosition || '527px';
  }
  return screenSize === 'mobile' ? '91%' : '785px';
};
