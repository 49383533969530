import { RadioSection, CompletedText, InspectionSection as InspectionContainer } from 'domains/project/viewProject/milestones/styles';
import { ControlledRadioButton } from 'designSystem/input/radioBox/controlledRadioButton';
import { Control } from 'react-hook-form';
import lang from 'lang/en';
import { FormFieldType } from './types';

export const InspectionSection = ({
  labelText, name, labelId, control, getValues, setValue,
} : {
  labelText: string;
  name: string;
  labelId: string;
  control: Control;
} & FormFieldType) => {
  const updateInspection = (value: boolean) => {
    setValue(name, value);
  };

  return (
    <InspectionContainer margin="0 0 23px 0">
      <CompletedText>{labelText}</CompletedText>
      <RadioSection>
        <ControlledRadioButton
          control={control}
          name={name}
          labelText={lang.subcontractorMilestones.milestones.completed.yesOption}
          id={`${labelId}-yes`}
          isChecked={getValues(name)}
          onChange={() => updateInspection(true)}
        />
        <ControlledRadioButton
          control={control}
          name={name}
          labelText={lang.subcontractorMilestones.milestones.completed.noOption}
          id={`${labelId}-no`}
          onChange={() => updateInspection(false)}
          isChecked={!getValues(name)}
        />
      </RadioSection>
    </InspectionContainer>
  );
};
