import { NavBar } from 'domains/navBar';
import { useEffect, useMemo } from 'react';
import { isContractor, isUserAuthourised } from 'utils/helpers';
import Loader from 'designSystem/loader';
import { useInactiveUserRedirection } from 'hooks/useInactiveUserRedirection';
import { UploadHeaderLogo } from './uploadHeaderLogo';
import { useGetUserInfo } from './useGetUserInfo';

export const Settings = () => {
  const { getUserData, isLoading, userInfo } = useGetUserInfo();
  const { redirectInactiveUser, unAuthroisedForHomeowner } = useInactiveUserRedirection();

  useEffect(() => {
    const isActiveUser = isUserAuthourised();
    if (!isActiveUser) {
      redirectInactiveUser();
    }
  });

  useEffect(() => {
    unAuthroisedForHomeowner();
  }, []);

  useEffect(() => {
    getUserData();
  }, []);

  const uploadHeaderLogo = useMemo(() => {
    if (isContractor()) {
      return (<UploadHeaderLogo profileLogo={userInfo?.profileLogo} />);
    }
  }, [userInfo]);

  return (
    <>
      {isLoading && <Loader />}
      <NavBar />
      {uploadHeaderLogo}
    </>
  );
};
