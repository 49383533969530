import { useCallback, useEffect } from 'react';
import { useSaveForm } from 'utils/form/useSaveForm';
import { notifications } from 'designSystem/toastNotifications/toastNotificationEmitter';
import { useNavigate } from 'react-router-dom';

type MilestoneApproval = {
  [key:string]: boolean;
}

export const useMilestoneApproval = (url: string, path: string)
: {
  saveMilestoneApproval: (data: MilestoneApproval) => Promise<void>;
  loading: boolean;
  isSaved: boolean;
  error: string;
} => {
  const {
    loading, error, save, isSaved,
  } = useSaveForm();

  const navigate = useNavigate();

  useEffect(() => {
    if (isSaved && !error) {
      navigate('/projects/view/milestone/approval', { state: { path } });
    }
  }, [isSaved, error]);

  useEffect(() => {
    if (error) {
      notifications.error(error);
    }
  }, [error]);

  const saveMilestoneApproval = useCallback(
    async (data: MilestoneApproval) => {
      await save(url, data, 'patch');
    },
    [save],
  );

  return {
    loading,
    error,
    saveMilestoneApproval,
    isSaved,
  };
};
