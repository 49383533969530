import Lang from 'lang/en';

export interface CustomErrorResponseInterface extends Error {
  data: Record<string, unknown>;
}

export interface CustomErrorCallInterface extends Error {
  response: CustomErrorResponseInterface;
}
export const getErrorMessage = (error: any): string => {
  if (!error || !error.response || !error.response.message) {
    return Lang.unknownError;
  }

  return error?.response?.message;
};
