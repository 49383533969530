import lang from 'lang/en';
import { useWindowDimensions } from 'hooks/useWindowDimensions';
import { Card } from 'domains/accountSetup/onboarding/styles';
import { MaxWidthContainer } from 'domains/project/addProject/contract/styles';
import { RadioOptionFields } from '../../../common/radioOptionFields';
import { HomeSolicitation } from './homesolicitation';
//import { JobExceeds } from './jobExceeds';
import type { OhioCommonTypes } from './types';

export const OhioStateForm = ({
  control, setValue, getValues, errors, clearErrors,
} : OhioCommonTypes) => {
  const { screenSize } = useWindowDimensions();
  const { contract: { homeowner: { modal: { form: { ohioFields } } } } } = lang;

  return (
    <>
      <Card screenSize={screenSize}>
        <RadioOptionFields
          control={control}
          fieldName="extraData.contractForConstruction"
          fieldText={ohioFields.contractForConstruction}
          setValue={setValue}
          errorMsg={errors?.extraData?.contractForConstruction?.message || ''}
          clearErrors={clearErrors}
          getValues={getValues}
        />
      </Card>
      <Card screenSize={screenSize}>
        <RadioOptionFields
          control={control}
          fieldName="extraData.siteLocated"
          fieldText={ohioFields.siteLocated}
          setValue={setValue}
          errorMsg={errors?.extraData?.siteLocated?.message || ''}
          clearErrors={clearErrors}
          getValues={getValues}
        />
      </Card>
      <Card screenSize={screenSize}>
        <RadioOptionFields
          control={control}
          fieldName="extraData.contractNegotiated"
          fieldText={ohioFields.contractNegotiated}
          setValue={setValue}
          errorMsg={errors?.extraData?.contractNegotiated?.message || ''}
          clearErrors={clearErrors}
          getValues={getValues}
        />
      </Card>
      {/* <Card screenSize={screenSize}>
        <JobExceeds control={control} setValue={setValue} errors={errors} clearErrors={clearErrors} getValues={getValues} />
      </Card> */}
      <Card screenSize={screenSize}>
        <HomeSolicitation control={control} setValue={setValue} errors={errors} clearErrors={clearErrors} getValues={getValues} />
      </Card>
    </>
  );
};
