import { Route, Routes } from 'react-router-dom';
import AccountSetup from './accountSetup';
import Onboarding from './onboarding/onboarding';
import { UserTypeSelection } from './userType/userType';

export const AccountSetupRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<AccountSetup />} />
      <Route path="type" element={<UserTypeSelection />} />
      <Route path="onboarding/*" element={<Onboarding />} />
    </Routes>
  );
};

export default AccountSetupRoutes;
