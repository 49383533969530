import {
  blue700, disabledColor, gray300, gray500, gray700, green400, red300, red500, white,
} from 'designSystem/colors';
import { textSm } from 'designSystem/typings';
import NumberFormat from 'react-number-format';
import styled, { css } from 'styled-components';
import type {
  ElementPosition, InputStyleProps, ValidationStyleProps, IconSpanType,
} from './types';

const disabledStyles = css`
  color: ${disabledColor};
  border:1px solid ${disabledColor};
  background-color: ${white};
`;

export const Input = styled.input<InputStyleProps>`
  ${textSm};
  border: ${({ $isError }) => $isError ? `1px solid ${red300}` : `1px solid ${gray300}`};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  border-radius: 6px;
  outline-color:${green400};
  line-height: 20px;
  font-weight: 400;
  padding: ${({ $isWithIconPrefix }) => $isWithIconPrefix ? '9px 38px 9px 36px' : '9px 38px 9px 16px'}};

  &:disabled {
    ${disabledStyles};
  }
`;

export const TextArea = styled.textarea<InputStyleProps>`
  ${textSm};
  border: ${({ $isError }) => $isError ? `1px solid ${red300}` : `1px solid ${gray300}`};
  text-overflow: ellipsis;
  overflow-y: scroll;
  border-radius: 6px;
  outline-color:${green400};
  line-height: 20px;
  font-weight: 400;
  overflow-x: hidden;
  resize: none;
  padding: ${({ $isWithIconPrefix }) => $isWithIconPrefix ? '9px 38px 9px 36px;' : '9px 38px 9px 16px;'}};

  &:disabled {
    ${disabledStyles};
  }
  &::-webkit-scrollbar {
    width: 12px;
    height: auto;
  }
 &::-webkit-scrollbar-thumb {
  -webkit-border-radius: 6px;
  border-radius: 6px;
  background: ${green400};  
}
`;

const NumberInputInternal = styled(NumberFormat)``;

export const NumberInput = styled(NumberInputInternal)<InputStyleProps>`
  ${textSm};
  border: ${({ $isError }) => $isError ? `1px solid ${red300}` : `1px solid ${gray300}`};
  padding: ${({ inputpadding }) => inputpadding || '9px 38px 9px 16px'};
  border-radius: 6px;
  outline-color: ${green400};
  line-height: 20px;
  font-weight: 400;
  width: ${({ inputwidth }) => inputwidth || '100%'};

  &:disabled {
   ${disabledStyles};
  }
`;

const fieldContainer = css`
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;
export const InputContainer = styled.div`
  ${fieldContainer};
`;

export const InputLabel = styled.label`
  ${textSm};
  color: ${gray700};
  margin-bottom: 8px;
`;

export const LabelSection = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ShowText = styled.p`
  ${textSm};
  color: ${blue700};
  cursor: pointer;
`;

export const PasswordContainer = styled.div`
  margin-bottom: 12px;
`;

export const RulesSection = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 12px;
`;

export const Rules = styled.div`
  display: flex;
`;

const getValidationMessageColor = (colorState: string) => {
  switch (colorState) {
    case 'default':
      return gray500;
    case 'valid':
      return green400;
    case 'invalid':
      return red500;
    default:
      return gray500;
  }
};

export const ValidationMessage = styled.p<ValidationStyleProps>`
  ${textSm};
  color: ${({ colorState }) => getValidationMessageColor(colorState)};
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 5px;
`;

export const Symbol = styled.span`
  margin-right: 8px;
`;

const iconSpanBase = css`
  position: absolute;
  cursor: pointer;
  background: transparent;
`;

export const PrefixIconSpan = styled.span<{ prefixAlignment?: ElementPosition}>`
  ${iconSpanBase};
  top:${({ prefixAlignment }) => prefixAlignment?.top || '20px'};
  right: inherit;
  left: ${({ prefixAlignment }) => prefixAlignment?.left || '12px'};
`;

export const PostfixIconSpan = styled.span<{ postfixAlignment?: ElementPosition}>`
  ${iconSpanBase};
  right: 12px;
  left: ${({ postfixAlignment }) => postfixAlignment?.left || 'inherit'};
  top: ${({ postfixAlignment }) => postfixAlignment?.top || '34px'};
`;

const getIconLeftAlignment = (isError?: boolean, errorIconAlignment?: ElementPosition, errorIconPosition?: string) => {
  switch (isError) {
    case true:
      return errorIconPosition || '343px';
    default:
      return errorIconAlignment?.left || 'inherit';
  }
};

const getIconTopAlignment = ($isWithIconPrefix?: boolean, errorIconTopPosition?: string): string => {
  if (errorIconTopPosition) {
    return errorIconTopPosition;
  }
  return $isWithIconPrefix ? '18px;' : '32px;';
};

export const IconSpan = styled.span<IconSpanType>`
  ${iconSpanBase};
  top: ${({ $isWithIconPrefix, errorIconTopPosition }) => getIconTopAlignment($isWithIconPrefix, errorIconTopPosition)};
  left: ${({ isError, errorIconAlignment, errorIconPosition }) => getIconLeftAlignment(isError, errorIconAlignment, errorIconPosition)};
  right: 12px;
`;

export const ErrorMessage = styled.span`
  margin: 5px 0 0 12px;
`;

export const ErrorText = styled.p`
  ${textSm};
  line-height: 20px;
  color: ${red500};
`;

const InternalCardInput = styled.div``;

export const CardInput = styled(InternalCardInput)<InputStyleProps>`
  border: ${({ $isError }) => $isError ? `1px solid ${red500}` : `1px solid ${gray300}`};
  padding: 9px 38px 9px 16px;
  border-radius: 6px;
  outline-color: ${green400};
  ${textSm};
  line-height: 20px;
  font-weight: 400;

  &:disabled {
    ${disabledStyles};
  }
`;

export const PaymentContainer = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

export const IconPlaceholder = styled.div<{isError?: boolean}>`
  position: absolute;
  top: ${({ isError }) => isError ? 0 : '24px'};
  left: 0;
  bottom:0;
  margin-left: 12px;
  display: flex;
  align-items: center;

  & svg {
    margin-right: 12px;
  }
`;

export const IconPlaceholderText = styled.span`
  ${textSm};
  line-height: 20px;
  font-weight: 400;
  color: ${gray500};
`;
