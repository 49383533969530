import lang from 'lang/en';
import { useWindowDimensions } from 'hooks/useWindowDimensions';
import { Card } from 'domains/accountSetup/onboarding/styles';
import { RadioOptionFields } from '../../../common/radioOptionFields';
import { ComponentCommonTypes } from '../types';

export const DelawareContractFields = ({
  control, setValue, getValues, errors, clearErrors,
} : ComponentCommonTypes) => {
  const { screenSize } = useWindowDimensions();
  const { contract: { homeowner: { modal: { form: { floridaFields } } } } } = lang;

  return (
    <>
      <Card screenSize={screenSize}>
        <RadioOptionFields
          control={control}
          fieldName="extraData.workOnThePrincipalResidence"
          fieldText={floridaFields.principleResidence}
          setValue={setValue}
          errorMsg={errors?.extraData?.workOnThePrincipalResidence?.message || ''}
          clearErrors={clearErrors}
          getValues={getValues}
        />
      </Card>
      <Card screenSize={screenSize}>
        <RadioOptionFields
          control={control}
          fieldName="extraData.threeDayRight"
          fieldText={floridaFields.rightToRescind}
          setValue={setValue}
          errorMsg={errors?.extraData?.threeDayRight?.message || ''}
          clearErrors={clearErrors}
          getValues={getValues}
        />
      </Card>
    </>
  );
};
