import {
  useEffect, useContext, useCallback,
} from 'react';
import { BrowserHistory } from 'history';
import {
  // eslint-disable-next-line camelcase
  useNavigate, UNSAFE_NavigationContext,
} from 'react-router-dom';

export const useBlockBrowserBackButton = () => {
  const navigate = useNavigate();
  const navigation = useContext(UNSAFE_NavigationContext)
    .navigator as BrowserHistory;
  let currentPathname: string | null = null;
  let currentSearch: string | null = null;

  useEffect(() => {
    return () => {
      window.onpopstate = null;
    };
  }, []);

  const blockBackButton = useCallback(() => {
    if (navigation) {
      navigation.listen((locationListener) => {
        if (locationListener.action === 'PUSH') {
          if (
            locationListener.location.pathname !== currentPathname
            || locationListener.location.search !== currentSearch
          ) {
            currentPathname = locationListener.location.pathname;
            currentSearch = locationListener.location.search;
          }
        } else if (locationListener.action === 'POP' && currentPathname === '/projects/add/details') {
          navigation.go(1);
        }
      });

      return () => {
        window.onpopstate = null;
      };
    }
  }, []);

  return {
    blockBackButton,
  };
};
