import { colors } from 'designSystem';
import { ControlledInputField } from 'designSystem/input';
import ControlledNumberMaskedField from 'designSystem/input/maskedNumber/controlledMaskedNumberField';
import {
  textBase, textBaseNormal, textLg, textSLg, textSm,
} from 'designSystem/typings';
import { ScreenSize } from 'hooks/useWindowDimensions';
import styled, { css } from 'styled-components';

type WithScreenSize = {
  screenSize?: ScreenSize;
}

export const Container = styled.div<WithScreenSize>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: ${({ screenSize }) => screenSize === 'mobile' ? '296px' : '420px'};
  height: 230px;
  `;

export const Content = styled.div<WithScreenSize>`
  ${textBase};
  text-align: center;
  font-weight: 400;
  width: ${({ screenSize }) => screenSize === 'mobile' ? '244px' : '290px'};
`;

export const Header = styled.h1`
  ${textSLg};
  margin-bottom: 20px;
`;

export const ButtonRow = styled.div<WithScreenSize>`
  display: flex;
  flex-direction: row;

  margin-top: 32px;
  gap: ${({ screenSize }) => screenSize === 'mobile' ? '48px' : '32px'};
  `;

export const CardTitle = styled.div<{marginBottom?: string}>`
  ${textLg};
  display: flex; 
  justify-content: space-between;
  font-weight: 500;
  margin-bottom: ${({ marginBottom }) => marginBottom || '24px'};
`;

export const CardDescription = styled.div`
  ${textSm};
  color: ${colors.gray500};
  line-height: 20px;
  margin-bottom: 24px;
`;

export const Card = styled.div<{screenSize?: ScreenSize}>`
  width: 100%;
  margin-top: 32px;

  ${({ screenSize }) => {
    switch (screenSize) {
      case 'mobile':
        return css`
        width: 100%;
        margin-top: 32px;
        height: auto;
        padding: 8px 4px;
        background-color: transparent;
        border-radius: 8px;
        `;
      case 'desktop':
      default:
        return css`
          height: auto;
          padding: ${screenSize !== 'desktop' ? '48px 112px' : '48px 200px;'};
          background-color: ${colors.white};
          border-radius: 8px;
        `;
    }
  }};
`;

export const CardRow = styled.div < { customFlexBasis?: string } >`
  display: flex;
  flex-flow: row wrap;
  gap: 0 24px;
  
  & > div {
    flex: ${({ customFlexBasis }) => customFlexBasis ? `1 1 ${customFlexBasis}` : '1 1 278px'};
    width: 100%;
  }
`;

export const ActionButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 32px;
  margin-bottom: 34px;
  gap: 0 32px;
`;

export const CustomFieldSized = styled(ControlledInputField)<{maxWidth: string}>`
  max-width: ${({ maxWidth }) => maxWidth};
`;

export const CustomMaskedField = styled(ControlledNumberMaskedField)<{maxWidth: string}>`
  max-width: ${({ maxWidth }) => maxWidth};
`;

export const ConfirmationContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Heading = styled.h1`
  ${textLg};
  color: ${colors.black};
  text-transform: uppercase;
  font-size: 30px;
  line-height: 40px;
  margin-top: 40px;
  margin-bottom: 32px;
`;

export const TextSection = styled.div`
  & p:nth-child(2) {
    margin-top: 23px;
  }
`;

export const PlainText = styled.p`
  ${textBaseNormal};
  color: ${colors.black};
  text-align: center;
`;

export const ButtonSpacer = styled.div`
  margin-top: 32px;
`;

export const AddressNote = styled.p`
  ${textSm}
  color: ${colors.gray500};
  margin: 10px 0 22px 0;
`;
