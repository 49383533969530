import { ControlledRadioBox, ControlledInputField } from 'designSystem/input';
import lang from 'lang/en';
import { useForm, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from 'designSystem';
import { ReactComponent as Visa } from 'assets/images/visa.svg';
import { ReactComponent as MasterCard } from 'assets/images/mastercard.svg';
import { ReactComponent as Discover } from 'assets/images/discover.svg';
import { ReactComponent as Amex } from 'assets/images/amex.svg';
import { ReactComponent as Lock } from 'assets/images/lock.svg';
import { ReactComponent as StripeLogo } from 'assets/images/stripeLogo.svg';
import {
  ReactElement, useEffect, useMemo, useState,
} from 'react';
import { useWindowDimensions } from 'hooks/useWindowDimensions';
import Loader from 'designSystem/loader';
import { isEmpty } from 'lodash';
import { ControlledPaymentField } from './controlledPaymentField';
import { subscriptionValidation } from '../validationSchema';
import type { SubscriptionForm, StripeEventType } from './types';
import {
  Card, CardContainer, CardTitle, ActionButtonsContainer, /*CardRow,*/
} from '../styles';
import {
  Label, Form,
  RadioBoxes, PlanContent, PlanTitle, PlanAmount, PlanLabel, UpFrontAmount,
  RadioMargin, CardElements, PaymentTitle, InputElements, RowFields, Expiry, Cvc,
  BtnSpacer, PaymentIcons, StripeInfo, SecurePaymentLabel, ErrorMessage, ErrorText,
} from './styles';
import useSubscription from './useSubscription';

export const Subscription = ({ setPreviousStep } : {
  setPreviousStep: () => void;
}): ReactElement => {
  const resolver = { resolver: yupResolver(subscriptionValidation) };
  const {
    handleSubmit, control, setError, formState,
  } = useForm({ ...resolver, mode: 'onChange' });
  const { screenSize } = useWindowDimensions();
  const {
    useSubscribe, loading, getStripePlans, stripePlans,
  } = useSubscription();

  const [paymentElementsFilled, setPaymentElementsFilled] = useState({
    cardNumber: false,
    cardExpiry: false,
    cardCvc: false,
  });

  const {
    subscriptionTitle, subscriptionPlanLabel, subscriptionYear, subscriptionMonth,
    subscriptionAnnually,
    subscriptionMonthly, subscriptionPayment, subscriptionPaymentNote, paymentNameLabel,
    paymentCardLabel, paymentExpiryLabel, paymentCvcLabel, securePayment,
  } = lang;

  const { errors } = formState;

  useEffect(() => {
    getStripePlans();
  }, []);

  const handlePaymentField = (event: StripeEventType) => {
    setError(event.elementType, {
      type: event.elementType,
      message: event.error?.message || '',
    });
    const elementsState = { ...paymentElementsFilled };
    setPaymentElementsFilled({
      ...elementsState,
      [event.elementType]: event.complete,
    });
  };

  const onSubmit: SubmitHandler<SubscriptionForm> = (data: SubscriptionForm) => {
    useSubscribe(data);
  };

  const annualPlanContent = useMemo(() => {
    if (stripePlans?.annualPlan) {
      const { annualPlan } = stripePlans;
      return (
        <PlanContent>
          <PlanTitle>{subscriptionAnnually}</PlanTitle>
          <PlanAmount>
            <span>{annualPlan?.metadata?.priceText}</span>
             &nbsp;/
            {subscriptionYear}
          </PlanAmount>
          <br />
          <PlanLabel>{annualPlan?.metadata?.note}</PlanLabel>
        </PlanContent>
      );
    }
  }, [stripePlans]);

  const monthlyPlanContent = useMemo(() => {
    if (stripePlans?.monthlyPlan) {
      const { monthlyPlan } = stripePlans;
      return (
        <PlanContent>
          <PlanTitle>{subscriptionMonthly}</PlanTitle>
          <PlanLabel>{monthlyPlan?.description}</PlanLabel>
          <UpFrontAmount>{monthlyPlan?.metadata?.priceText}</UpFrontAmount>
          <PlanLabel>{monthlyPlan?.metadata?.note}</PlanLabel>
          <PlanAmount>
            <span>{monthlyPlan?.metadata?.additionalPriceText}</span>
            &nbsp;/
            {subscriptionMonth}
          </PlanAmount>
        </PlanContent>
      );
    }
  }, [stripePlans]);

  const showLoader = useMemo(() => {
    if (isEmpty(stripePlans) && isEmpty(stripePlans?.annualPlan) && isEmpty(stripePlans?.monthlyPlan)) {
      return <Loader />;
    }
  }, [stripePlans]);

  return (
    <Card screenSize={screenSize}>
      {showLoader}
      <Form onSubmit={handleSubmit(onSubmit)}>
        <CardContainer screenSize={screenSize}>
          <CardTitle>{subscriptionTitle}</CardTitle>
          <Label>{subscriptionPlanLabel}</Label>
          <RadioBoxes screenSize={screenSize}>
            <ControlledRadioBox
              name="planType"
              control={control}
              content={annualPlanContent}
              id="annual"
              value={stripePlans?.annualPlan?.default_price}
            />
            <RadioMargin screenSize={screenSize} />
            <ControlledRadioBox
              name="planType"
              control={control}
              content={monthlyPlanContent}
              id="montly"
              value={stripePlans?.monthlyPlan?.default_price}
            />
          </RadioBoxes>
          {errors?.planType?.message !== ''
               && <ErrorMessage><ErrorText>{errors.planType?.message}</ErrorText></ErrorMessage>}
          <CardElements>
            <InputElements screenSize={screenSize}>
              <ControlledInputField
                maxLength={10}
                placeholder={lang.referalCodePlaceHolder}
                type="text"
                labelText={lang.referalCode}
                control={control}
                name="refferalCode"
              />
            </InputElements>
            <PaymentTitle>{subscriptionPayment}</PaymentTitle>
            <Label>{subscriptionPaymentNote}</Label>
            <PaymentIcons screenSize={screenSize}>
              <Visa />
              <MasterCard />
              <Discover />
              <Amex />
            </PaymentIcons>
            <StripeInfo>
              <Lock />
              <SecurePaymentLabel>{securePayment}</SecurePaymentLabel>
              <StripeLogo width="123px" height="26px" />
            </StripeInfo>
            <InputElements screenSize={screenSize}>
              <ControlledInputField
                name="name"
                control={control}
                type="text"
                labelText={paymentNameLabel}
              />
              <ControlledPaymentField
                name="cardNumber"
                control={control}
                labelText={paymentCardLabel}
                onChange={handlePaymentField}
              />
              <RowFields>
                <Expiry>
                  <ControlledPaymentField
                    name="cardExpiry"
                    control={control}
                    labelText={paymentExpiryLabel}
                    onChange={handlePaymentField}
                  />
                </Expiry>
                <Cvc>
                  <ControlledPaymentField
                    name="cardCvc"
                    control={control}
                    labelText={paymentCvcLabel}
                    onChange={handlePaymentField}
                  />
                </Cvc>
              </RowFields>
            </InputElements>
            <BtnSpacer />
          </CardElements>
        </CardContainer>
        <ActionButtonsContainer>
          <Button type="button" variant="secondary" label={lang.backLabel} state="active" onClick={setPreviousStep} />
          <Button type="submit" variant="primary" label={lang.nextLabel} state={loading ? 'loading' : 'active'} />
        </ActionButtonsContainer>
      </Form>
    </Card>
  );
};

export default Subscription;
