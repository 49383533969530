import DeleteItem from 'common/deleteItem';
import { Button } from 'designSystem';
import { CardDescription, CardTitle } from 'domains/accountSetup/onboarding/styles';
import ContractTile from 'domains/project/addProject/contract/homeowner/contractTile';
import { ListItem, UnorderedList, ChangeOrderAction } from 'domains/project/addProject/contract/homeowner/contractView/styles';
import HomeownerContractForm from 'domains/project/addProject/contract/homeowner/form/homeownerContractForm';
import { Section } from 'domains/project/addProject/contract/homeowner/styles';
import { useDownloadContractPreview } from 'domains/project/addProject/contract/homeowner/useDownloadContractPreview';
import { UserDetailsHeader } from 'domains/project/addProject/contract/styles';
import { HomeownerContractsProps } from 'domains/project/addProject/contract/types';
import { ChangeOrderForm } from 'domains/project/addProject/contract/changerOder/form/changeOrderForm';
import { useDeleteContract } from 'domains/project/addProject/contract/useDeleteContract';
import { useGetHomeownerData } from 'domains/project/addProject/contract/useGetHomeownerData';
import lang from 'lang/en';
import { useMemo, useState, useRef } from 'react';
import { getAuthInfo } from 'utils/helpers';
import { isEmpty } from 'lodash';
import ChangerOrderContracts from '../../changerOder/listing/contracts';

const sendContractStrings = lang.contract.homeowner.sendToHomeowner;
const deleteContractStrings = lang.contract.homeowner.signFlow.deleteContract;

const SendContractView = ({
  owner,
  projectData,
  triggerDataUpdate,
}: HomeownerContractsProps) => {
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const {
    loading: deleteContractLoading,
    deleteContract,
  } = useDeleteContract();

  const changeOrderFormRef = useRef<any>();

  const {
    homeOwnerId: homeownerId,
  } = projectData?.details || {};
  const { accountType } = getAuthInfo();
  const { homeowner } = useGetHomeownerData(homeownerId as string);
  const homeownerFullName = `${homeowner?.firstName} ${homeowner?.lastName}`;
  const isContractSentByHomeowner = !!projectData?.projectContract?.homeOwnerSentAt;
  const isContractSignedByHomeowner = !!projectData?.projectContract?.homeOwnerSignedAt;

  const {
    loading,
    downloadContractPreview,
  } = useDownloadContractPreview();

  const handleEditClick = () => {
    setIsEditOpen(true);
  };

  const validateDownloadRequest = useMemo(() => {
    let isValidDownloadRequest = true;
    if (projectData?.projectContract) {
      const { projectContract } = projectData;
      isValidDownloadRequest = isEmpty(projectContract.contractCreatedAt) && isEmpty(projectContract.contractUrl);
    }
    return isValidDownloadRequest;
  }, [projectData]);

  const getHandleEditClick = () => {
    if (accountType !== 'homeowner' && !isContractSignedByHomeowner) {
      return handleEditClick;
    }
  };

  const handleDeleteClick = async () => {
    await deleteContract(projectData?.projectContract.id);
    triggerDataUpdate();
  };

  const getHandleDeleteClick = () => {
    if (accountType !== 'homeowner' && !isContractSignedByHomeowner) {
      return () => setIsDeleteOpen(true);
    }
  };

  const changeOrderActionButton = useMemo(() => {
    if (accountType !== 'homeowner' && isContractSentByHomeowner) {
      return (
        <ChangeOrderAction>
          <Button
            label={lang.contract.changeOrder.buttonActionText}
            width="full"
            onClick={() => changeOrderFormRef && changeOrderFormRef?.current.click(true)}
          />
          <ChangeOrderForm projectData={projectData} ref={changeOrderFormRef} triggerDataUpdate={triggerDataUpdate} />
        </ChangeOrderAction>
      );
    }
  }, [accountType]);

  const changeOrderContracts = useMemo(() => {
    if (projectData?.changeOrder) {
      return projectData?.changeOrder?.map((changeOrderDetails) => {
        const isHomeownerSentContract = !!changeOrderDetails?.homeOwnerSentAt;
        const isContractorSentContract = !!changeOrderDetails?.contractorSentAt;
        if ((accountType === 'homeowner' && isHomeownerSentContract) || (accountType !== 'homeowner' && isContractorSentContract)) {
          return (
            <ChangerOrderContracts
              projectData={projectData}
              triggerDataUpdate={triggerDataUpdate}
              changeOrderDetails={changeOrderDetails}
            />
          );
        }
        return true;
      });
    }
  }, [projectData?.changeOrder]);

  return (
    <>
      <HomeownerContractForm
        triggerDataUpdate={triggerDataUpdate}
        projectData={projectData}
        owner={owner}
        preloadedData={{ homeownerFullName }}
        isOpen={isEditOpen}
        setIsOpen={setIsEditOpen}
      />
      <DeleteItem
        handleCancel={() => setIsDeleteOpen(false)}
        handleDelete={handleDeleteClick}
        heading={deleteContractStrings.header}
        subText={deleteContractStrings.description}
        isOpen={isDeleteOpen}
      />
      <UserDetailsHeader>
        {homeownerFullName}
      </UserDetailsHeader>
      <Section>
        <CardTitle marginBottom="0">
          {sendContractStrings.header}
        </CardTitle>
        <UnorderedList>
          {projectData?.projectContract.contractLog.map((listItem: string) => {
            return (
              <ListItem key={listItem}>
                {listItem}
              </ListItem>
            );
          })}
        </UnorderedList>
        <CardDescription>
          {sendContractStrings.description}
        </CardDescription>
        <ContractTile
          contractFileName={projectData?.projectContract.contractFileName}
          onEditClick={getHandleEditClick()}
          downloadLoading={loading}
          onDeleteClick={getHandleDeleteClick()}
          onDownloadClick={() => downloadContractPreview(projectData?.details.id as string)}
          validateDownloadRequest={validateDownloadRequest}
          contractDocumentUrl={projectData?.projectContract?.contractUrl}
        />
      </Section>
      {changeOrderContracts}
      {changeOrderActionButton}
    </>
  );
};

export default SendContractView;
