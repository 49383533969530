import HeaderWithLogo from 'designSystem/header/headerWithLogo';
import { ReactElement, useEffect } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { getAuthInfo, isUserAuthourised } from 'utils/helpers';
import { Centerlized } from '../userType/styles';
import Company from './company';
import Homeowner from './homeowner';

const Container = styled.div`
  width: 100%;
  max-width: 1168px;
  padding-left: 20px;
  padding-right: 20px;
`;

const Onboarding = (): ReactElement => {
  const { accountType } = getAuthInfo();
  const navigate = useNavigate();
  const isActiveUser = isUserAuthourised();

  useEffect(() => {
    switch (accountType) {
      case 'homeowner':
        navigate('/account-setup/onboarding/homeowner');
        break;
      case 'contractor':
      case 'subcontractor':
        navigate('/account-setup/onboarding/company');
        break;
      default:
        navigate('/account-setup/type');
        break;
    }
  }, []);

  useEffect(() => {
    if (isActiveUser) {
      navigate('/projects');
    }
  }, [isActiveUser]);

  return (
    <Centerlized>
      <Container>
        <HeaderWithLogo />
        <Routes>
          <Route path="company/*" element={<Company />} />
          <Route path="homeowner/*" element={<Homeowner />} />
        </Routes>
      </Container>
    </Centerlized>
  );
};

export default Onboarding;
