import {
  CardRow, CardTitle, Card,
} from 'domains/accountSetup/onboarding/styles';
import lang from 'lang/en';
import { useMemo } from 'react';
import { useGetField } from 'domains/project/addProject/details/userSearchAndDisplayCard/useGetField';
import {
  CustomPrefilledInput, ViewMilestonsContainer, CustomPrefilledNumberInput, PercentageText,
} from 'domains/project/addProject/styles';
import { getAuthInfo, isHomeowner, isInspector } from 'utils/helpers';
import { contractorMilestoneUrl } from 'utils/network/endpoints';
import { Button } from 'designSystem';
import { useWindowDimensions } from 'hooks/useWindowDimensions';
import { UpdateMilestones } from '../updateMilestones';
import { InspectionRequest } from '../inspectionRequest';
import type { MilestoneDataProps } from '../types';
import { ApproveCTA } from '../styles';
import HomeownerApproval from '../approval/approval';
import { useMilestoneApproval } from '../approval/useMilestoneApproval';

export const MilestoneData = ({
  index,
  milestone,
  isEscrow,
  contractorId,
  projectId,
  isPendingProject,
}: MilestoneDataProps) => {
  const { getField } = useGetField();
  const { screenSize } = useWindowDimensions();
  const {
    id: milestoneId, name, isCompleted, percentageOverall: precentage,
    milestoneDocuments: files, inspection, isCompletedByHomeOwner, totalCost, isCompletedByInspector,
  } = milestone;

  const redirectionPath = `/projects/view/contractor-milestones/${projectId}`;
  const userType = isInspector() ? 'inspector' : 'homeOwner';
  const apiUrl = `${contractorMilestoneUrl}${milestoneId}/${userType}/markAsCompleted`;
  const { saveMilestoneApproval, loading } = useMilestoneApproval(apiUrl, redirectionPath);

  const { userId } = getAuthInfo();
  const isContractor = userId === contractorId;
  const showHomeApprovalCTA = index > 0 && isHomeowner() && isCompleted && isEscrow && !isCompletedByHomeOwner;
  const showInspectorApprovalCTA = index > 0 && isInspector() && isCompleted && isEscrow && isCompletedByHomeOwner && !isCompletedByInspector;
  const inspectionRequestSection = useMemo(() => {
    if (isEscrow && (isContractor || isInspector())) {
      return (
        <CardRow>
          <InspectionRequest milestoneId={milestoneId} inspection={inspection} isPendingProject={isPendingProject} isInspector={isInspector()} />
        </CardRow>
      );
    }
  }, [isEscrow]);

  const homeownerApproveCTA = useMemo(() => {
    if (showHomeApprovalCTA) {
      return (
        <ApproveCTA>
          <Button
            label={lang.approveCTA}
            state={loading ? 'loading' : 'active'}
            width="full"
            type="button"
            onClick={() => saveMilestoneApproval({ isCompletedByHomeOwner: true })}
          />
        </ApproveCTA>
      );
    }
  }, []);

  const InspectorApproveCTA = useMemo(() => {
    if (showInspectorApprovalCTA && inspection !== null) {
      return (
        <ApproveCTA>
          <Button
            label={lang.approveCTA}
            state={loading ? 'loading' : 'active'}
            width="full"
            type="button"
            onClick={() => saveMilestoneApproval({ isCompleted: true })}
          />
        </ApproveCTA>
      );
    }
  }, []);

  const homeownerApproval = useMemo(() => {
    if (isCompletedByHomeOwner && isEscrow) {
      return (
        <HomeownerApproval
          labelText={lang.milestones.homeownerApproval}
          name={`homeowner-approval-${milestoneId}`}
          milestoneId={milestoneId}
          approvalStatus={isCompletedByHomeOwner}
        />
      );
    }
  }, []);

  const InspectorApproval = useMemo(() => {
    if (isCompletedByInspector && isEscrow) {
      return (
        <HomeownerApproval
          labelText={lang.milestones.InspectorApproval}
          name={`inspector-approval-${milestoneId}`}
          milestoneId={milestoneId}
          approvalStatus={isCompletedByInspector}
        />
      );
    }
  }, []);

  return (
    <Card screenSize={screenSize}>
      <ViewMilestonsContainer>
        <CardTitle>
          {lang.mileStone}
          &nbsp;
          {index + 1}
        </CardTitle>
        <CardRow>
          <CustomPrefilledInput
            maxWidth="320px"
            disabled
            field={{ ...getField(`milestones.${index}.name`, name) }}
            labelText={`${lang.milestoneLabel} ${index + 1}`}
          />
        </CardRow>
        <CardRow>
          <CustomPrefilledNumberInput
            maxWidth="320px"
            disabled
            field={{ ...getField(`milestones.${index}.percentageOverall`, `${precentage} %`) }}
            labelText={lang.percentageCostOfProject}
            iconPostfix={<PercentageText>%</PercentageText>}
            postfixAlignment={{ top: '32px', left: '40px' }}
            errorIconAlignment={{ top: '32px', left: '64px' }}
            inputpadding="7px 12px"
            inputwidth="59px"
            keepPostfix
          />
        </CardRow>
        <CardRow>
          <UpdateMilestones
            milestoneId={milestoneId}
            isCompleted={isCompleted}
            files={files}
            featureAccess={isHomeowner() || isInspector() || isPendingProject}
            accessFileUpload={(!isHomeowner() && !isInspector()) && !isPendingProject}
          />
        </CardRow>
        {homeownerApproveCTA}
        {homeownerApproval}
        {inspectionRequestSection}
        {InspectorApproveCTA}
        {InspectorApproval}
      </ViewMilestonsContainer>
    </Card>
  );
};
