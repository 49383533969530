import lang from 'lang/en';

export const options = [
  {
    value: 'none',
    label: lang.subcontractorMilestones.inspection.none,
  },
  {
    value: 'start',
    label: lang.subcontractorMilestones.inspection.start,
  },
  {
    value: 'rough',
    label: lang.subcontractorMilestones.inspection.rough,
  },
  {
    value: 'final',
    label: lang.subcontractorMilestones.inspection.final,
  },
];
