import { colors } from 'designSystem';
import { textLg, textSm } from 'designSystem/typings';
import { ScreenSize } from 'hooks/useWindowDimensions';
import styled from 'styled-components';

type WithScreenSize = {
  screenSize: ScreenSize;
}

export const DesktopNavContainer = styled.section`
 padding: 12px 32px;
 display: flex;
 justify-content: space-between;
 overflow: hidden;
 background: ${colors.blue700};
`;

export const LinkSection = styled.div`
  display: flex;
`;

export const LogoSection = styled.div`
  display: flex;
  align-items: center;
`;

export const Title = styled.p`
  ${textLg};
  font-weight: 700;
  line-height: 28px;
  color: ${colors.white};
  margin-left: 12px;
`;

export const Links = styled.div<WithScreenSize>`
  display: flex;
  margin-left: ${({ screenSize }) => screenSize === 'smallTablet' ? '28px' : '26px'};
  & a {
    display: flex;
    align-items: center;
    margin-right: 24px;
  }
`;

export const IconSection = styled.div`
  display: flex;
  padding: 12px 0;
  align-items: center;
   & svg {
     cursor: pointer;
   }
`;

export const UsernameText = styled.p`
  ${textSm};
  color: ${colors.white};
  padding-right: 4px;
`;

export const LinkIcon = styled.img`
  width: 14px;
  height: 16px;
`;

export const DesktopLinkName = styled.p`
  ${textSm};
  line-height: 20px;
  color: ${colors.white};
  font-weight: 500;
  margin-left: 4px;
`;

export const LinkName = styled.p`
  ${textSm};
  line-height: 20px;
  color: ${colors.white};
  font-weight: 500;
  margin-left: 11px;
`;

export const MobileNavContainer = styled.section`
overflow: hidden;
background: ${colors.blue700};
position: relative;
`;

export const ToggleIcon = styled.div<{linkDropdownVisibility?: boolean}>`
 position: absolute;
 display: flex;
 right: 15px;
 top: ${({ linkDropdownVisibility }) => linkDropdownVisibility ? '12px' : '7px'};
 border: ${({ linkDropdownVisibility }) => linkDropdownVisibility ? `2px solid ${colors.white}` : 'none'};
 padding: ${({ linkDropdownVisibility }) => linkDropdownVisibility ? '12px 11px' : '16px 12px'};
 border-radius: ${({ linkDropdownVisibility }) => linkDropdownVisibility ? '7px' : 0};
`;

export const MobileLinks = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2px 0 27px 0;
  width: 100%;
  & a {
    display: flex;
    align-items: center;
  }
`;

export const MobileLogoSection = styled.div`
 padding: 13px 2px;
 display: flex;
  align-items: center;
`;

export const OtherLinkSection = styled.div`
 border-top: 2px solid ${colors.blue800};
 padding: 17px 12px 0 12px;
 & a {
  display: flex;
  align-items: center;
}
`;

export const MobileLinkSection = styled.div` 
  padding: 6px 16px;
`;

export const MainLinksSection = styled.div`
 padding: 2px 12px 4px 12px;
`;

export const SignoutLink = styled.div`
 display: flex;
 align-items: center;
 padding: 3px 12px;
 cursor: pointer;
`;

export const SettingsSection = styled.div`
 display: flex;
 flex-direction: column;
 position: absolute;
 top: 70px;
 right: 14px;
 background: ${colors.blue700};
 width: 229px;
 padding: 14px 19px;
 border-radius: 5px;
 & a {
  display: flex;
  align-items: center;
}
`;
