import { colors, typings } from 'designSystem/index';
import styled from 'styled-components';

export const TagContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0 10px 0 8px;
  border-radius: 10px;
  height: 20px;
`;

export const Circle = styled.span`
  width: 6px;
  height: 6px;
  border-radius: 50%;
`;

export const Label = styled.span`
  ${typings.textXSm};
  margin-left: 7px;
  text-transform: none;
`;

export const PendingTagContainer = styled(TagContainer)`
  background: ${colors.orange100};

  & > ${Circle} {
    background: ${colors.pink800};
  }

  & > ${Label} {
    color: ${colors.pink800};
  }
`;

export const CompletedTagContainer = styled(TagContainer)`
  background: ${colors.teal100};

  & > ${Circle} {
    background: ${colors.green800};
  }

  & > ${Label} {
    color: ${colors.green800};
  }
`;

export const LightCompletedContainer = styled(PendingTagContainer)`
  background: ${colors.red50};
`;
