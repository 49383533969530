import {
  Checkbox, CheckboxContainer, CheckSpan, CheckboxLabel,
} from 'domains/project/addProject/contract/styles';
import { FieldValues, UseFormGetValues, UseFormRegisterReturn } from 'react-hook-form';

type CheckboxFieldProps = {
  labelText: string;
  fieldName: string;
  value: string;
  getValues: UseFormGetValues<FieldValues>;
  registrant?: UseFormRegisterReturn;
}

export const CheckboxField = ({
  labelText,
  value,
  registrant,
}: CheckboxFieldProps) => {
  return (
    <CheckboxContainer>
      <Checkbox type="checkbox" value={value} {...registrant} />
      <CheckSpan />
      <CheckboxLabel>
        {labelText}
      </CheckboxLabel>
    </CheckboxContainer>
  );
};

export default CheckboxField;
