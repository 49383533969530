import { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, SubmitHandler } from 'react-hook-form';
import lang from 'lang/en';
import { ErrorBoundary } from 'utils/errorBoundary';
import { Button } from 'designSystem';
import { ControlledInputField, ControlledPasswordField } from 'designSystem/input';
import { getButtonState } from 'utils/helpers';
import HeaderWithLogo from 'designSystem/header/headerWithLogo';
import { CardSection } from 'designSystem/card/styles';
import { useWindowDimensions } from 'hooks/useWindowDimensions';
import { signinValidationSchema } from '../validationSchema';
import {
  Centerlized, Wrapper, FormContainer, Form, ButtonSpacer,
  BottomSection, BottomHeading, LinkText,
} from '../styles';
import useSignin from './useSignin';
import { UserFormDTO } from '../types';
import useVerifyEmail from './useVerifyEmail';

const SignIn = () => {
  const params = useParams<{ token: string }>();
  const { token } = params;

  const resolver = { resolver: yupResolver(signinValidationSchema) };
  const { handleSubmit, control, formState: { isDirty, isValid } } = useForm({ ...resolver, mode: 'onChange' });
  const {
    signinHeading, email, password,
    signinBottomHeading, createAccountText,
  } = lang;
  const { screenSize } = useWindowDimensions();

  const { loading, signIn } = useSignin();
  const { verifyEmail } = useVerifyEmail();

  const onSubmit: SubmitHandler<UserFormDTO> = (data) => {
    signIn(data);
  };

  useEffect(() => {
    if (token) {
      verifyEmail(token);
    }
  }, [token]);

  return (
    <Centerlized>
      <ErrorBoundary>
        <Wrapper>
          <HeaderWithLogo text={signinHeading} />
          <FormContainer>
            <CardSection screenSize={screenSize} maxWidth="448px" minHeight="322px">
              <Form screenSize={screenSize} onSubmit={handleSubmit(onSubmit)}>
                <ControlledInputField
                  name="email"
                  control={control}
                  type="text"
                  labelText={email}
                  errorIconPosition={screenSize === 'mobile' ? '91%' : '296px'}
                />
                <ControlledPasswordField
                  name="password"
                  control={control}
                  labelText={password}
                />
                {/* <ForgotPasswordText>{forgotPassword}</ForgotPasswordText> */}
                <ButtonSpacer />
                <Button width="full" variant="primary" label={lang.signInText} type="submit" state={getButtonState(isDirty, isValid, loading)} />
              </Form>
            </CardSection>
          </FormContainer>
          <BottomSection>
            <BottomHeading>{signinBottomHeading}</BottomHeading>
            <Link to="/signup">
              <LinkText>
                {createAccountText}
              </LinkText>
            </Link>
          </BottomSection>
        </Wrapper>
      </ErrorBoundary>

    </Centerlized>
  );
};

export default SignIn;
