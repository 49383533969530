import { Button } from 'designSystem';
import { InputField } from 'designSystem/input';
import NumberMaskedField from 'designSystem/input/maskedNumber/maskedNumberField';
import { CardTitle } from 'domains/accountSetup/onboarding/styles';
import styled from 'styled-components';

export const CustomCardTitle = styled(CardTitle)`
  margin-bottom: 4px;
`;

export const CustomInputField = styled(InputField)`
  max-width: 320px;
`;

export const CustomNumberMaskedField = styled(NumberMaskedField)`
  max-width: 240px;
`;

export const CustomButton = styled(Button)`
  width: 320px;
`;
