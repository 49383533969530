import { Modal } from 'common/modal';
import lang from 'lang/en';
import {
  Card, CardTitle, CardRow, CustomMaskedField, CustomFieldSized,
} from 'domains/accountSetup/onboarding/styles';
import { yupResolver } from '@hookform/resolvers/yup';
import { InputSpanText } from 'domains/project/addProject/details/projectCost/styles';
//import isEmpty from 'lodash/isEmpty';
import {
  useState, useImperativeHandle, forwardRef, useEffect,
} from 'react';
import { ChangeOrderType, ChangeOrderPayload } from 'domains/project/addProject/contract/homeowner/types';
import {
  GrayContainer, ButtonRow, PrefilledSection, NarrowControlledInputField, ChangeOrderFormContainer,
} from 'domains/project/addProject/contract/styles';
import { ControlledInputField } from 'designSystem/input';
import { useForm } from 'react-hook-form';
import { Button } from 'designSystem';
import { /*ScreenSize,*/ useWindowDimensions } from 'hooks/useWindowDimensions';
import { getTopPosition } from 'domains/project/addProject/contract/subcontractor/form/helpers';
import { HomeownerDetails } from '../../common/homeownerDetails';
import { ModalInfoSection } from '../../common/modalInfoSection';
import { ContractorDetails } from '../../common/contractorDetails';
import { ProjectAddress } from '../../common/projectAddress';
import { TotalSalesPrice } from '../../common/totalSalesPrice';
import { useChangeOrderActions } from '../useChangeOrderActions';
import { ChangeOrderValidationSchema } from '../validationSchema';
import { useSaveChangeOrderContract } from '../useSaveChangeOrderContract';

export const ChangeOrderForm = forwardRef((
  { projectData, triggerDataUpdate, changeOrderDetails } :
  ChangeOrderType,
  ref,
) => {
  const [isOpenModal, setOpenModal] = useState(false);
  const { screenSize } = useWindowDimensions();
  const resolver = { resolver: yupResolver(ChangeOrderValidationSchema) };
  const {
    getTotalSalesPrice, getContractorFullName, getAddressDetails, getHomeownerFullName,
  } = useChangeOrderActions(projectData);
  const {
    createChangeOrderContract, updateChangeOrderContract, isSaved, loading, error,
  } = useSaveChangeOrderContract();
  const {
    control, handleSubmit, reset, trigger,
  } = useForm({
    ...resolver,
    mode: 'onChange',
    defaultValues: {
      additionalHomeOwner: '',
      descriptionOfWork: '',
      priceOfWork: null,
      additionalTime: null,
      netChange: null,
      grossContractTotalPrior: null,
      grossContractTotal: null,
      fileName: '',
    },
  });

  useImperativeHandle(ref, () => ({
    click(value: boolean) {
      setOpenModal(value);
    },
  }));

  useEffect(() => {
    if (isSaved && !error) {
      setOpenModal(false);
    }
  }, [isSaved]);

  useEffect(() => {
    if (changeOrderDetails && isOpenModal) {
      const {
        additionalHomeOwner,
        descriptionOfWork,
        priceOfWork,
        additionalTime,
        netChange,
        grossContractTotalPrior,
        grossContractTotal,
        fileName,
      } = changeOrderDetails;
      reset({
        additionalHomeOwner,
        descriptionOfWork,
        priceOfWork,
        additionalTime,
        netChange,
        grossContractTotalPrior,
        grossContractTotal,
        fileName,
      });
      trigger();
    } else {
      reset({
        additionalHomeOwner: '',
        descriptionOfWork: '',
        priceOfWork: null,
        additionalTime: null,
        netChange: null,
        grossContractTotalPrior: null,
        grossContractTotal: null,
        fileName: '',
      });
    }
  }, [changeOrderDetails, isOpenModal]);

  const {
    contract: {
      changeOrder: {
        modal, createContractButtonLabel, homeowner, fields: {
          accountField, workPriceLabel, completionTimeLabel, netChangeLabel,
          priorGrossTotal, includedGrossTotal, contractsFileName,
        },
      },
    },
  } = lang;

  const onSubmit = (data: ChangeOrderPayload) => {
    if (changeOrderDetails) {
      updateChangeOrderContract(data, changeOrderDetails?.id);
    } else {
      createChangeOrderContract(data, projectData?.details?.id);
    }
    triggerDataUpdate();
  };

  const getTextareaIconLeftPosition = () => {
    if (screenSize === 'tablet') {
      return '516px';
    }
    return screenSize === 'mobile' ? '91%' : '742px';
  };

  return (
    <Modal isOpen={isOpenModal}>
      <ChangeOrderFormContainer screenSize={screenSize}>
        <GrayContainer>
          <ModalInfoSection
            setOpenModal={setOpenModal}
            headerText={modal.headerText}
            subHeaderText={modal.subHeaderText}
            titleText={modal.titleText}
          />
          <PrefilledSection screenSize={screenSize}>
            <HomeownerDetails fullName={getHomeownerFullName()} control={control} />
            <ContractorDetails fullName={getContractorFullName()} />
            <ProjectAddress
              addressDetails={getAddressDetails()}
            />
            <TotalSalesPrice salesPrice={getTotalSalesPrice()} />
          </PrefilledSection>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Card screenSize={screenSize}>
              <CardTitle>
                {homeowner.title}
              </CardTitle>
              <NarrowControlledInputField
                labelText={homeowner.additionalHomeowner}
                control={control}
                name="additionalHomeOwner"
              />
            </Card>
            <Card screenSize={screenSize}>
              <CardTitle>
                {accountField.title}
              </CardTitle>
              <ControlledInputField
                control={control}
                name="descriptionOfWork"
                labelText={accountField.label}
                type="textarea"
                errorIconPosition={getTextareaIconLeftPosition()}
                errorIconTopPosition={screenSize === 'mobile' ? '71px' : '68px'}
              />
              <CardRow>
                <CustomMaskedField
                  maxWidth="400px"
                  control={control}
                  name="priceOfWork"
                  labelText={workPriceLabel}
                  placeholder="0.00"
                  iconPrefix={<InputSpanText>$</InputSpanText>}
                  iconPostfix={<InputSpanText>{lang.currencyUsd}</InputSpanText>}
                  prefixAlignment={{ top: '34px', left: '12px' }}
                  inputpadding="9px 38px 9px 24px"
                  errorIconPosition={screenSize === 'mobile' ? '85%' : '366px'}
                />
              </CardRow>
              <CardRow>
                <CustomFieldSized
                  maxWidth="400px"
                  control={control}
                  name="additionalTime"
                  labelText={completionTimeLabel}
                  errorIconPosition={screenSize === 'mobile' ? '85%' : '368px'}
                />
              </CardRow>
              <CardRow>
                <CustomMaskedField
                  maxWidth="400px"
                  control={control}
                  name="netChange"
                  labelText={netChangeLabel}
                  placeholder="0.00"
                  iconPrefix={<InputSpanText>$</InputSpanText>}
                  iconPostfix={<InputSpanText>{lang.currencyUsd}</InputSpanText>}
                  prefixAlignment={{ top: '34px', left: '12px' }}
                  inputpadding="9px 38px 9px 24px"
                  errorIconPosition={screenSize === 'mobile' ? '85%' : '366px'}
                />
              </CardRow>
              <CardRow>
                <CustomMaskedField
                  maxWidth="400px"
                  control={control}
                  name="grossContractTotalPrior"
                  labelText={priorGrossTotal}
                  placeholder="0.00"
                  iconPrefix={<InputSpanText>$</InputSpanText>}
                  iconPostfix={<InputSpanText>{lang.currencyUsd}</InputSpanText>}
                  prefixAlignment={{ top: screenSize !== 'mobile' ? '34px' : '50px', left: '12px' }}
                  postfixAlignment={{ top: screenSize === 'mobile' ? '60%' : '34px', left: 'inherit' }}
                  inputpadding="9px 38px 9px 24px"
                  errorIconPosition={screenSize === 'mobile' ? '85%' : '366px'}
                  errorIconTopPosition={screenSize === 'mobile' ? '46%' : '32px'}
                />
              </CardRow>
              <CardRow>
                <CustomMaskedField
                  maxWidth="400px"
                  control={control}
                  name="grossContractTotal"
                  labelText={includedGrossTotal}
                  placeholder="0.00"
                  iconPrefix={<InputSpanText>$</InputSpanText>}
                  iconPostfix={<InputSpanText>{lang.currencyUsd}</InputSpanText>}
                  prefixAlignment={{ top: screenSize !== 'mobile' ? '34px' : '50px', left: '12px' }}
                  postfixAlignment={{ top: screenSize === 'mobile' ? '60%' : '34px', left: 'inherit' }}
                  inputpadding="9px 38px 9px 24px"
                  errorIconPosition={screenSize === 'mobile' ? '85%' : '366px'}
                  errorIconTopPosition={screenSize === 'mobile' ? '46%' : '32px'}
                />
              </CardRow>
            </Card>
            <Card screenSize={screenSize}>
              <CardTitle>
                {contractsFileName.header}
              </CardTitle>
              <NarrowControlledInputField
                control={control}
                name="fileName"
                labelText={contractsFileName.fileNameLabel}
                errorIconPosition={screenSize === 'mobile' ? '67%' : '206px'}
                errorIconTopPosition={getTopPosition(screenSize)}
              />
            </Card>
            <ButtonRow>
              <Button
                type="submit"
                variant="primary"
                label={createContractButtonLabel}
                state={loading ? 'loading' : 'active'}
              />
            </ButtonRow>
          </form>
        </GrayContainer>
      </ChangeOrderFormContainer>

    </Modal>
  );
});
