import { yupResolver } from '@hookform/resolvers/yup';
import { ReactComponent as CloseIcon } from 'assets/images/close-icon.svg';
import { Modal } from 'common/modal';
import { Button } from 'designSystem';
import { CircleButton } from 'designSystem/button';
import { ControlledInputField } from 'designSystem/input';
import { ControlledToggleButton } from 'designSystem/input/toggleInput';
import {
  Card, CardDescription, CardRow, CardTitle, CustomFieldSized,
} from 'domains/accountSetup/onboarding/styles';
import {
  ButtonRow,
  CloseButtonRow,
  CustomControlledDatepicker,
  FormHeader,
  GrayContainer,
  MediumControlledInputField,
  NarrowControlledInputField,
  NarrowControlledSelect,
  ErrorMessage,
  ErrorText,
} from 'domains/project/addProject/contract/styles';
import {
  getMappedData, mapDataToFormValues, getTopPosition, getTextareaIconLeftPosition,
} from 'domains/project/addProject/contract/subcontractor/form/helpers';
import AssignmentRightOptionSelect from 'domains/project/addProject/contract/subcontractor/form/optionSelects/assignmentRightOptionSelect';
import CompletionDateOptionSelect from 'domains/project/addProject/contract/subcontractor/form/optionSelects/completionDateOptionSelect';
import ContractorInsuranceSelect from 'domains/project/addProject/contract/subcontractor/form/optionSelects/contractorInsuranceSelect';
import PaymentAmountOptionSelect from 'domains/project/addProject/contract/subcontractor/form/optionSelects/paymentAmountOptionSelect';
import PaymentFrequencyOptionSelect from 'domains/project/addProject/contract/subcontractor/form/optionSelects/paymentFrequencyOptionSelect';
import PaymentMethodOptionSelect from 'domains/project/addProject/contract/subcontractor/form/optionSelects/paymentMethodOptionSelect';
import RightToSubcontractingOptionSelect from 'domains/project/addProject/contract/subcontractor/form/optionSelects/rightToSubcontractingOptionSelect';
import SubcontractorInsuranceSelect from 'domains/project/addProject/contract/subcontractor/form/optionSelects/subcontractorInsuranceSelect';
import SubcontractorsWorkersCompensationInsuranceSelect
  from 'domains/project/addProject/contract/subcontractor/form/optionSelects/subcontractorsWorkersCompensationInsuranceSelect';
import SubstantialCompletionDateOptionSelect from 'domains/project/addProject/contract/subcontractor/form/optionSelects/substantialCompletionDateOptionSelect';
import WorkersCompensationInsuranceSelect from 'domains/project/addProject/contract/subcontractor/form/optionSelects/workersCompensationInsuranceSelect';
import SubcontractorResponsibilitiesMultiSelect from 'domains/project/addProject/contract/subcontractor/form/subcontractorResponsibilitiesMultiSelect';
import { SubcontractorContractFormType } from 'domains/project/addProject/contract/subcontractor/form/types';
import { useCreateSubcontractorContract } from 'domains/project/addProject/contract/subcontractor/useCreateSubcontractorContract';
import { useUpdateSubcontractorContract } from 'domains/project/addProject/contract/subcontractor/useUpdateSubcontractorContract';
import { useGetHomeownerData } from 'domains/project/addProject/contract/useGetHomeownerData';
import lang from 'lang/en';
import { useEffect, useState, useMemo } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { dateStringFormat, defaultDateFormat, defaultDateFormatString } from 'utils/dateFormats';
import { useWindowDimensions } from 'hooks/useWindowDimensions';
import { stateOptions, subcontractorFormDefaultValues } from '../staticData';
import { SubcontractorContractFormProps } from '../types';
import { subcontractorContractSchema } from '../validationSchema';

const modalStrings = lang.contract.subcontractors.modal;

const SubcontractorContractForm = ({
  isOpen,
  setIsOpen,
  owner,
  subcontractorData,
  projectData,
  triggerDataUpdate,
  subcontractorDetails,
}: SubcontractorContractFormProps) => {
  const {
    loading,
    createSubcontractorContract,
    isSaved,
  } = useCreateSubcontractorContract();
  const {
    loading: updateLoading,
    updateSubcontractorContract,
    isSaved: updateIsSaved,
  } = useUpdateSubcontractorContract();
  const { screenSize } = useWindowDimensions();
  const [initialValuesSet, setInitialValuesSet] = useState(false);
  const [isDataPreloaded, setIsDataPreloaded] = useState(false);
  const [selectedDate, setSelectedDate] = useState<any>({
    selectedCommencementDate: null,
    selectedCompletionDateSpecific: null,
    selectedCompletionDateOther: null,
    selectedSubstantialDateSpecific: null,
    selectedSubstantialDateOther: null,
  });
  const resolver = { resolver: yupResolver(subcontractorContractSchema) };
  const { homeowner } = useGetHomeownerData(projectData?.details.homeOwnerId as string);
  const homeownerFullName = homeowner && `${homeowner?.firstName} ${homeowner?.lastName}`;

  const {
    control,
    reset,
    trigger,
    getValues,
    setValue,
    register,
    formState,
    handleSubmit,
  } = useForm({
    ...resolver,
    mode: 'onChange',
    defaultValues: {
      ...subcontractorFormDefaultValues,
    },
  });

  const {
    project: projectAddress,
    escrow,
  } = projectData?.details || {};

  const {
    isDirty,
    isValid,
    errors,
  } = formState;

  const contractorName = useMemo(() => {
    if (projectData?.user?.company) {
      const { user: { company: { owners: { 0: { firstName, lastName } } } } } = projectData;
      return `${firstName} ${lastName}`;
    }
  }, [projectData]);

  useEffect(() => {
    if (homeowner) {
      setValue('homeownerFirstLastName', `${homeowner?.firstName} ${homeowner?.lastName}`);
    }
  }, [homeowner]);

  useEffect(() => {
    if ((!initialValuesSet && !isDataPreloaded && !!owner && !!projectAddress) && !!homeownerFullName) {
      reset({
        ...subcontractorFormDefaultValues,
        homeownerFirstLastName: homeownerFullName,
        contractorFirstLastName: contractorName || '',
        subcontractorFirstLastName: subcontractorDetails ? `${subcontractorDetails?.firstName} ${subcontractorDetails?.lastName}` : '',
        projectAddress,
        subcontractorCompanyName: subcontractorDetails?.company?.name || '',
        contractorCompanyName: projectData?.user?.company.name || '',
        escrow,
      });
      setInitialValuesSet(true);
    }
  }, [owner, projectAddress, homeownerFullName, subcontractorDetails, projectData, isOpen]);

  useEffect(() => {
    if (subcontractorData) {
      const mappedFormData = mapDataToFormValues(
        subcontractorData,
        homeownerFullName as string,
        contractorName || '',
        subcontractorDetails ? `${subcontractorDetails?.firstName} ${subcontractorDetails?.lastName}` : '',
        projectAddress,
        escrow as boolean,
        subcontractorDetails && subcontractorDetails.company ? subcontractorDetails.company.name : '',
        projectData?.user?.company.name || '',
      );

      reset({
        ...mappedFormData,
      });
      trigger();
      setIsDataPreloaded(true);
    }
  }, [subcontractorData, subcontractorDetails, isOpen]);

  const handleDateChange = (dateField: string, selectedDateKey: string) => (date: Date | string) => {
    if (date) {
      setValue(dateField, dateStringFormat(date, defaultDateFormatString), { shouldValidate: true });
      setSelectedDate({
        ...selectedDate,
        [selectedDateKey]: defaultDateFormat(date),
      });
    }
  };

  const onSubmit = async (data: SubcontractorContractFormType) => {
    const mappedData = getMappedData(data);

    if (subcontractorData) {
      await updateSubcontractorContract(mappedData, subcontractorData.id as number);
      triggerDataUpdate();
      return;
    }

    await createSubcontractorContract(mappedData, subcontractorDetails.subcontractorId as string);
    triggerDataUpdate();
  };

  useEffect(() => {
    if ((!loading && isSaved) || (!updateLoading && updateIsSaved)) {
      setIsOpen(false);
    }
  }, [loading, isSaved, updateLoading, updateIsSaved]);

  const contractorInsuranceRegistrant = register('contractorInsuranceOption');
  const workerCompensationInsuranceRegistrant = register('workerCompensationInsuranceOption');
  const subcontractorResponsibilitiesMultiSelectRegistrant = register('subcontractorResponsibilities');
  const subcontractorInsuranceRegistrant = register('subcontractorInsuranceOption');
  const subcontractorsWorkerCompensationInsuranceRegistrant = register('subcontractorWorkerCompensationInsuranceOptions');
  const completionDateOptionRegistrant = register('completionDateOptions');
  const substantialCompletionDateOptionRegistrant = register('substantialCompletionDateOptions');
  const paymentAmountOptionRegistrant = register('paymentAmountOptions');
  const paymentMethodOptionRegistrant = register('paymentMethodOptions');
  const paymentFrequencyOptionRegistrant = register('paymentFrequencyOptions');
  const rightToSubcontractingOptionRegistrant = register('rightToSubcontractingOptions');
  const assignmentRightOptionRegistrant = register('assignmentRightOptions');

  const contractorInsuranceOptionValue = useWatch({
    control,
    name: 'contractorInsuranceOption',
  });
  const workerCompensationInsuranceLimitValue = useWatch({
    control,
    name: 'workerCompensationInsuranceOption',
  });
  const subcontractorInsuranceOptionValue = useWatch({
    control,
    name: 'subcontractorInsuranceOption',
  });
  const subcontractorsWorkerCompensationInsuranceLimitValue = useWatch({
    control,
    name: 'subcontractorWorkerCompensationInsuranceOptions',
  });

  const getLoadingState = () => {
    return loading ? 'loading' : 'active';
  };

  return (
    <Modal
      isOpen={isOpen}
    >
      <GrayContainer>
        <FormHeader screenSize={screenSize}>
          <CloseButtonRow>
            <CircleButton size="medium" icon={<CloseIcon />} onClick={() => setIsOpen(false)} />
          </CloseButtonRow>
          <CardTitle>{modalStrings.header}</CardTitle>
          <CardTitle>{subcontractorDetails ? `${subcontractorDetails?.firstName} ${subcontractorDetails?.lastName}` : ''}</CardTitle>
        </FormHeader>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Card screenSize={screenSize}>
            <CardTitle>{modalStrings.form.contractType.header}</CardTitle>
            <NarrowControlledSelect
              labelText={modalStrings.form.contractType.stateLabel}
              options={stateOptions}
              control={control}
              name="state"
            />
            <ErrorMessage margin="13px 0 0 12px"><ErrorText>{errors?.state?.message || ''}</ErrorText></ErrorMessage>
          </Card>
          <Card screenSize={screenSize}>
            <CardTitle marginBottom="0">
              {modalStrings.form.homeownerDetails.header}
            </CardTitle>
            <CardDescription>
              {modalStrings.form.homeownerDetails.description}
            </CardDescription>
            <MediumControlledInputField
              labelText={modalStrings.form.homeownerDetails.firstLastNameLabel}
              disabled
              control={control}
              name="homeownerFirstLastName"
            />
            <NarrowControlledInputField
              labelText={modalStrings.form.homeownerDetails.additionalHomeownerNameLabel}
              control={control}
              name="additionalHomeOwnerName"
            />
          </Card>
          <Card screenSize={screenSize}>
            <CardTitle marginBottom="0">
              {modalStrings.form.contractorDetails.header}
            </CardTitle>
            <CardDescription>
              {modalStrings.form.contractorDetails.description}
            </CardDescription>
            <MediumControlledInputField
              labelText={modalStrings.form.contractorDetails.firstLastNameLabel}
              disabled
              control={control}
              name="contractorFirstLastName"
            />
            <MediumControlledInputField
              labelText={modalStrings.form.contractorDetails.contractorCompanyName}
              disabled
              control={control}
              name="contractorCompanyName"
            />
          </Card>
          <Card screenSize={screenSize}>
            <CardTitle marginBottom="0">
              {modalStrings.form.subContractorDetails.header}
            </CardTitle>
            <CardDescription>
              {modalStrings.form.subContractorDetails.description}
            </CardDescription>
            <MediumControlledInputField
              labelText={modalStrings.form.subContractorDetails.firstLastNameLabel}
              disabled
              control={control}
              name="subcontractorFirstLastName"
            />
            <MediumControlledInputField
              labelText={modalStrings.form.subContractorDetails.contractorCompanyName}
              disabled
              control={control}
              name="subcontractorCompanyName"
            />
          </Card>
          <Card screenSize={screenSize}>
            <CardTitle marginBottom="0">
              {modalStrings.form.projectAddress.header}
            </CardTitle>
            <CardDescription>
              {modalStrings.form.projectAddress.description}
            </CardDescription>
            <CardRow>
              <ControlledInputField disabled labelText={modalStrings.form.projectAddress.streetAddressLabel} control={control} name="projectAddress.streetAddress" />
            </CardRow>
            <CardRow customFlexBasis="230px">
              <ControlledInputField disabled labelText={modalStrings.form.projectAddress.cityLabel} control={control} name="projectAddress.city" />
              <ControlledInputField disabled labelText={modalStrings.form.projectAddress.stateLabel} control={control} name="projectAddress.state" />
              <CustomFieldSized maxWidth="240px" disabled labelText={modalStrings.form.projectAddress.zipLabel} control={control} name="projectAddress.zip" />
            </CardRow>
          </Card>
          <Card screenSize={screenSize}>
            <CardTitle>
              {modalStrings.form.authorizedRepresentatives.header}
            </CardTitle>
            <MediumControlledInputField
              control={control}
              type="text"
              name="contractorAuthorizedRepresentative"
              labelText={modalStrings.form.authorizedRepresentatives.contractorLabel}
            />
            <MediumControlledInputField
              control={control}
              type="text"
              name="subcontractorAuthorizedRepresentative"
              labelText={modalStrings.form.authorizedRepresentatives.subcontractorLabel}
            />
          </Card>
          <Card screenSize={screenSize}>
            <CardTitle marginBottom="0">
              {modalStrings.form.contractorsInsurance.header}
            </CardTitle>
            <CardDescription>
              {modalStrings.form.contractorsInsurance.description}
            </CardDescription>
            <ContractorInsuranceSelect
              contractorInsuranceValue={contractorInsuranceOptionValue}
              registrant={contractorInsuranceRegistrant}
              control={control}
              setValue={setValue}
              errorMsg={errors?.contractorInsuranceOption?.message || ''}
            />
          </Card>
          <Card screenSize={screenSize}>
            <CardTitle marginBottom="0">
              {modalStrings.form.workersCompensationInsurance.header}
            </CardTitle>
            <CardDescription>
              {modalStrings.form.workersCompensationInsurance.description}
            </CardDescription>
            <WorkersCompensationInsuranceSelect
              workerCompensationInsuranceLimitValue={workerCompensationInsuranceLimitValue}
              registrant={workerCompensationInsuranceRegistrant}
              control={control}
              setValue={setValue}
              errorMsg={errors?.workerCompensationInsuranceOption?.message || ''}
            />
          </Card>
          <Card screenSize={screenSize}>
            <CardTitle>
              {modalStrings.form.descriptionOfServices.header}
            </CardTitle>
            <ControlledInputField
              control={control}
              name="descriptionOfServices"
              labelText={modalStrings.form.descriptionOfServices.label}
              type="textarea"
              errorIconTopPosition="68px"
              errorIconPosition={getTextareaIconLeftPosition(screenSize)}
            />
          </Card>
          <Card screenSize={screenSize}>
            <CardTitle marginBottom="0">
              {modalStrings.form.subcontractorResponsibilities.header}
            </CardTitle>
            <CardDescription>
              {modalStrings.form.subcontractorResponsibilities.description}
            </CardDescription>
            <SubcontractorResponsibilitiesMultiSelect
              control={control}
              registrant={subcontractorResponsibilitiesMultiSelectRegistrant}
              setValue={setValue}
              errorMsg={errors?.subcontractorResponsibilities?.message || ''}
            />
          </Card>
          <Card screenSize={screenSize}>
            <CardTitle>
              {modalStrings.form.commencementDate.header}
            </CardTitle>
            <CustomControlledDatepicker
              placeholder={modalStrings.form.pickDatePlaceholder}
              control={control}
              name="commencementDate"
              labelText={modalStrings.form.commencementDate.fieldLabel}
              setFieldValue={handleDateChange('commencementDate', 'selectedCommencementDate')}
              selectedDate={selectedDate.selectedCommencementDate}
              errorIconPosition={screenSize === 'mobile' ? '91%' : '343px'}
            />
          </Card>
          <Card screenSize={screenSize}>
            <CardTitle marginBottom="0">
              {modalStrings.form.completionDateOptions.header}
            </CardTitle>
            <CardDescription>
              {modalStrings.form.completionDateOptions.description}
            </CardDescription>
            <CompletionDateOptionSelect
              control={control}
              handleDateChange={handleDateChange}
              getValues={getValues}
              setValue={setValue}
              registrant={completionDateOptionRegistrant}
              specificDate={selectedDate.selectedCompletionDateSpecific}
              otherDate={selectedDate.selectedCompletionDateOther}
              errorMsg={errors?.completionDateOptions?.message || ''}
            />
          </Card>
          <Card screenSize={screenSize}>
            <CardTitle marginBottom="0">
              {modalStrings.form.substantialCompletionDateOptions.header}
            </CardTitle>
            <CardDescription>
              {modalStrings.form.substantialCompletionDateOptions.description}
            </CardDescription>
            <SubstantialCompletionDateOptionSelect
              control={control}
              handleDateChange={handleDateChange}
              getValues={getValues}
              setValue={setValue}
              registrant={substantialCompletionDateOptionRegistrant}
              specificDate={selectedDate.selectedSubstantialDateSpecific}
              otherDate={selectedDate.selectedSubstantialDateOther}
              errorMsg={errors?.substantialCompletionDateOptions?.message || ''}
            />
          </Card>
          <Card screenSize={screenSize}>
            <CardTitle>
              {modalStrings.form.escrowService.header}
            </CardTitle>
            <ControlledToggleButton control={control} name="escrow" labelText={modalStrings.form.escrowService.escrowLabel} id="escrow" disabled />
          </Card>
          <Card screenSize={screenSize}>
            <CardTitle marginBottom="0">
              {modalStrings.form.paymentAmountOptions.header}
            </CardTitle>
            <CardDescription>
              {modalStrings.form.paymentAmountOptions.description}
            </CardDescription>
            <PaymentAmountOptionSelect
              registrant={paymentAmountOptionRegistrant}
              control={control}
              setValue={setValue}
              errorMsg={errors?.paymentAmountOptions?.message || ''}
            />
          </Card>
          <Card screenSize={screenSize}>
            <CardTitle marginBottom="0">
              {modalStrings.form.paymentMethodOptions.header}
            </CardTitle>
            <CardDescription>
              {modalStrings.form.paymentMethodOptions.description}
            </CardDescription>
            <PaymentMethodOptionSelect
              control={control}
              registrant={paymentMethodOptionRegistrant}
              setValue={setValue}
              errorMsg={errors?.paymentMethodOptions?.message || ''}
            />
          </Card>
          <Card screenSize={screenSize}>
            <CardTitle marginBottom="0">
              {modalStrings.form.paymentFrequencyOptions.header}
            </CardTitle>
            <CardDescription>
              {modalStrings.form.paymentFrequencyOptions.description}
            </CardDescription>
            <PaymentFrequencyOptionSelect
              setValue={setValue}
              registrant={paymentFrequencyOptionRegistrant}
              control={control}
              errorMsg={errors?.paymentFrequencyOptions?.message || ''}
            />
          </Card>
          <Card screenSize={screenSize}>
            <CardTitle marginBottom="0">
              {modalStrings.form.rightToSubcontractingOptions.header}
            </CardTitle>
            <CardDescription>
              {modalStrings.form.rightToSubcontractingOptions.description}
            </CardDescription>
            <RightToSubcontractingOptionSelect
              registrant={rightToSubcontractingOptionRegistrant}
              errorMsg={errors?.rightToSubcontractingOptions?.message || ''}
            />
          </Card>
          <Card screenSize={screenSize}>
            <CardTitle marginBottom="0">
              {modalStrings.form.assignmentRightOptions.header}
            </CardTitle>
            <CardDescription>
              {modalStrings.form.assignmentRightOptions.description}
            </CardDescription>
            <AssignmentRightOptionSelect
              registrant={assignmentRightOptionRegistrant}
              errorMsg={errors?.assignmentRightOptions?.message || ''}
            />
          </Card>
          <Card screenSize={screenSize}>
            <CardTitle marginBottom="0">
              {modalStrings.form.optionsForSubcontractorsInsurance.header}
            </CardTitle>
            <CardDescription>
              {modalStrings.form.contractorsInsurance.description}
            </CardDescription>
            <SubcontractorInsuranceSelect
              contractorInsuranceValue={subcontractorInsuranceOptionValue}
              registrant={subcontractorInsuranceRegistrant}
              control={control}
              setValue={setValue}
              errorMsg={errors?.subcontractorInsuranceOption?.message || ''}
            />
          </Card>
          <Card screenSize={screenSize}>
            <CardTitle marginBottom="0">
              {modalStrings.form.optionsForSubcontractorsWorkersCompensationInsurance.header}
            </CardTitle>
            <CardDescription>
              {modalStrings.form.workersCompensationInsurance.description}
            </CardDescription>
            <SubcontractorsWorkersCompensationInsuranceSelect
              workerCompensationInsuranceLimitValue={subcontractorsWorkerCompensationInsuranceLimitValue}
              registrant={subcontractorsWorkerCompensationInsuranceRegistrant}
              control={control}
              setValue={setValue}
              errorMsg={errors?.subcontractorWorkerCompensationInsuranceOptions?.message || ''}
            />
          </Card>
          <Card screenSize={screenSize}>
            <CardTitle>
              {modalStrings.form.additionalProvisions.header}
            </CardTitle>
            <ControlledInputField
              control={control}
              name="additionalProvisions"
              labelText={modalStrings.form.additionalProvisions.fieldLabel}
              type="textarea"
              errorIconPosition={getTextareaIconLeftPosition(screenSize)}
              errorIconTopPosition="68px"
            />
          </Card>
          <Card screenSize={screenSize}>
            <CardTitle>
              {modalStrings.form.contractsFileName.header}
            </CardTitle>
            <NarrowControlledInputField
              control={control}
              name="fileName"
              labelText={modalStrings.form.contractsFileName.fileNameLabel}
              errorIconPosition="206px"
              errorIconTopPosition={getTopPosition(screenSize)}
            />
          </Card>
          <ButtonRow>
            <Button
              type="submit"
              variant="primary"
              label={modalStrings.form.createContractButtonLabel}
              state={getLoadingState()}
            />
          </ButtonRow>
        </form>
      </GrayContainer>
    </Modal>
  );
};

export default SubcontractorContractForm;
