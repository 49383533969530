import { Confirmation } from 'common/confirmation';
import { useNavigate } from 'react-router-dom';
import lang from 'lang/en';
import { TextSection, PlainText } from '../styles';

export const ProjectSuccess = () => {
  const navigate = useNavigate();
  return (
    <Confirmation
      handleOkClick={() => navigate('/projects')}
      confirmationText={(
        <TextSection>
          <PlainText>{lang.projectSubmitSave.successText}</PlainText>
        </TextSection>
      )}
    />
  );
};
