import { Step, StepState } from 'designSystem/steps/types';
import lang from 'lang/en';
import { userRole } from 'utils/constants';
import { getAuthInfo, projectSubmittedByContractor } from 'utils/helpers';

export const initialStepsContractor: Array<Step> = [
  {
    circleContent: '01',
    label: lang.projectDetailsStepLabel,
    stepState: 'notStarted' as StepState,
    path: '/projects/view/details/',
    step: 0,
  },
  {
    circleContent: '02',
    label: lang.projectMilestonesStepLabel,
    stepState: 'notStarted' as StepState,
    path: '/projects/view/contractor-milestones/',
    step: 1,
  },
  {
    circleContent: '03',
    label: lang.projectContractorMilestonesStepLabel,
    stepState: 'notStarted' as StepState,
    path: '/projects/view/subcontractor-milestones/',
    step: 2,
  },
  {
    circleContent: '04',
    label: lang.projectContractStepLabel,
    stepState: 'notStarted' as StepState,
    path: '/projects/view/contract/',
    step: 3,
  },
  {
    circleContent: '05',
    label: lang.projectSubmitOrSaveStepLabel,
    stepState: 'notStarted' as StepState,
    path: '/projects/view/submit-save/',
    step: 4,
  },
];

export const initialStepsHomeowner: Array<Step> = [
  {
    circleContent: '01',
    label: lang.projectDetailsStepLabel,
    stepState: 'notStarted' as StepState,
    path: '/projects/view/details/',
    step: 0,
  },
  {
    circleContent: '02',
    label: lang.projectMilestonesStepLabel,
    stepState: 'notStarted' as StepState,
    path: '/projects/view/contractor-milestones/',
    step: 1,
  },
  {
    circleContent: '03',
    label: lang.projectContractorMilestonesStepLabel,
    stepState: 'notStarted' as StepState,
    path: '/projects/view/subcontractor-milestones/',
    step: 2,
  },
  {
    circleContent: '04',
    label: lang.projectContractStepLabel,
    stepState: 'notStarted' as StepState,
    path: '/projects/view/contract/',
    step: 3,
  },
];

export const initialStepsSubcontractor: Array<Step> = [
  {
    circleContent: '01',
    label: lang.projectDetailsStepLabel,
    stepState: 'notStarted' as StepState,
    path: '/projects/view/details/',
    step: 0,
  },
  {
    circleContent: '02',
    label: lang.projectContractorMilestonesStepLabel,
    stepState: 'notStarted' as StepState,
    path: '/projects/view/subcontractor-milestones/',
    step: 1,
  },
  {
    circleContent: '03',
    label: lang.projectContractStepLabel,
    stepState: 'notStarted' as StepState,
    path: '/projects/view/contract/',
    step: 2,
  },
];

export const initialStepsInspector: Array<Step> = [
  {
    circleContent: '01',
    label: lang.projectDetailsStepLabel,
    stepState: 'notStarted' as StepState,
    path: '/projects/view/details/',
    step: 0,
  },
  {
    circleContent: '02',
    label: lang.projectMilestonesStepLabel,
    stepState: 'notStarted' as StepState,
    path: '/projects/view/contractor-milestones/',
    step: 1,
  },
  {
    circleContent: '03',
    label: lang.projectContractorMilestonesStepLabel,
    stepState: 'notStarted' as StepState,
    path: '/projects/view/subcontractor-milestones/',
    step: 2,
  },
];

export const getUserType = (contractorId: string) => {
  const { userId, accountType } = getAuthInfo();
  if (accountType === userRole.contractor) {
    return contractorId === userId ? 'contractor' : 'subcontractor';
  }

  if (accountType === userRole.inspector) {
    return 'inspector';
  }

  return 'homeowner';
};

export const filterContractorSteps = (status: number, contractorId: string) => {
  if (!projectSubmittedByContractor(status, contractorId)) {
    return initialStepsContractor.filter((item) => item.circleContent !== '05');
  }
  return initialStepsContractor;
};

export const getInitialSteps = (status: number, contractorId: string) => {
  const userType = getUserType(contractorId);
  switch (userType) {
    case 'contractor':
      return filterContractorSteps(status, contractorId);
    case 'subcontractor':
      return initialStepsSubcontractor;
    case 'homeowner':
      return initialStepsHomeowner;
    case 'inspector':
      return initialStepsInspector;
    default:
      return [];
  }
};
