import {
  Card, CardTitle, CardDescription,
} from 'domains/accountSetup/onboarding/styles';
import { useWindowDimensions } from 'hooks/useWindowDimensions';
import { CustomWidthMaskedNumberField } from 'domains/project/addProject/contract/styles';
import { lang } from 'lang/en';
import { useGetField } from 'domains/project/addProject/details/userSearchAndDisplayCard/useGetField';

export const TotalSalesPrice = ({ salesPrice }: {
  salesPrice: string
}) => {
  const { screenSize } = useWindowDimensions();
  const { getField } = useGetField();
  const { contract: { changeOrder: { totalSalesPrice } } } = lang;
  return (
    <Card screenSize={screenSize}>
      <CardTitle marginBottom="0">
        {totalSalesPrice.header}
      </CardTitle>
      <CardDescription>
        {totalSalesPrice.description}
      </CardDescription>
      <CustomWidthMaskedNumberField
        labelText={totalSalesPrice.totalSalesPriceLabel}
        disabled
        numberPrefix="$"
        fixedDecimalScale
        placeholder="$ 0.00"
        type="number"
        field={{ ...getField('contractorfirstNameLastName', salesPrice || '') }}
      />
    </Card>
  );
};
