import Rollbar from 'rollbar';
import runtimeEnv from '@mars/heroku-js-runtime-env';

export const RollbarErrorTracking = (() => {
  const { REACT_APP_ROLLBAR_ACCESS_TOKEN, REACT_APP_NODE_ENV } = runtimeEnv();
  const RollbarObj = new Rollbar({
    accessToken: REACT_APP_ROLLBAR_ACCESS_TOKEN,
    captureUncaught: true,
    captureUnhandledRejections: true,
    payload: {
      environment: REACT_APP_NODE_ENV,
    },
  });

  const logErroInfo = (info: Rollbar.LogArgument) => {
    if (REACT_APP_NODE_ENV !== 'local') {
      RollbarObj.info(info);
    }
  };

  const logErrorInRollbar = (error?: string) => { // FIXME: we don't log anything just throw
    if (REACT_APP_NODE_ENV !== 'local') {
      throw new Error(error);
    }
  };

  return {
    logErroInfo,
    logErrorInRollbar,
  };
})();

export default RollbarErrorTracking;
