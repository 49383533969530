import RollbarErrorTracking from 'utils/rollbar';
import { AxiosResponse } from 'axios';
import {
  signUpUrl,
} from './endpoints';
import { ErrorHandlerType } from './types';

const unauthorizedHandlerIgnoredUrls = [
  signUpUrl,
];

type LogLevel = 'critical' | 'error' | 'warning' | 'debug' | 'info';

export const getConsoleLogger = (level: LogLevel): ((...args: string[]) => void) => {
  switch (level) {
    case 'critical':
    case 'error':
      return console.error;
    case 'warning':
      return console.warn;
    case 'info':
      // eslint-disable-next-line no-console
      return console.info;
    default:
      // eslint-disable-next-line no-console
      return console.log;
  }
};

export const log = (level: LogLevel, ...messages: string[]): void => {
  const consoleLogger = getConsoleLogger(level);

  consoleLogger(...messages);

  RollbarErrorTracking.logErroInfo(`${level}: ${messages.join(' ')}`);
};

const getErrorLevel = (status: number) => {
  if (status >= 500) {
    return 'error';
  }
  if (status === 403) {
    return 'warning';
  }
  return 'debug';
};

const handleSpecialStatus = (response: AxiosResponse): void => {
  switch (response.status) {
    case 401:
      // eslint-disable-next-line no-param-reassign
      (response as any).isNotAuthenticated = true;
      break;
    case 403:
      // eslint-disable-next-line no-param-reassign
      (response as any).isNotAuthenticated = true;
      break;
    case 404:
      // notifications.error(lang.unknownErrorTryLater);
      break;

    default:
  }
};

const handleErrors: ErrorHandlerType = (error) => {
  const {
    request: { responseURL },
  } = error;

  if (error.response) {
    if (error.response.status
      && !unauthorizedHandlerIgnoredUrls.some(
        (ignoredUrl) => responseURL.indexOf(ignoredUrl) !== -1,
      )
    ) {
      handleSpecialStatus(error.response);
    }

    if (error.response.status !== 401 && responseURL) {
      const { status, statusText } = error.response;
      const logLevel = getErrorLevel(status);

      log(
        logLevel,
        `Status code: ${status}`,
        statusText,
        responseURL,
        JSON.stringify(error.response.data),
      );
    }
  }

  return error.response || error;
};

export default handleErrors;
