import { Button } from 'designSystem';
import { Tag } from 'designSystem/tag';
import { CompanyOwnerDTO } from 'domains/accountSetup/onboarding/company/types';
import { ActionButtonsContainer, Card } from 'domains/accountSetup/onboarding/styles';
import AddHomeownerContract from 'domains/project/addProject/contract/homeowner/addHomeownerContract';
import ContractView from 'domains/project/addProject/contract/homeowner/contractView';
import AddSubcontractorContracts from 'domains/project/addProject/contract/subcontractor/addSubcontractorContracts';
import { useWindowDimensions } from 'hooks/useWindowDimensions';
import lang from 'lang/en';
import { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { getAuthInfo } from 'utils/helpers';
import { userRole, projectStatus } from 'utils/constants';
import { Header } from '../styles';
import { SubcontractorDTO } from '../types';
import { StyledCardTitle } from './styles';
import { ContractsProps } from './types';
import { useGetSubcontractorData } from './useGetSubcontractorData';
import { SendChangeOrderContractView } from './changerOder/listing/sendChangeOrderContractView';

const Contracts = ({
  setPreviousStep,
  onFormSubmitted,
  preloadedData,
  setProjectId,
  triggerDataUpdate,
}: ContractsProps) => {
  const { screenSize } = useWindowDimensions();
  const { id } = useParams<{ id: string }>();
  useEffect(() => {
    if (id) {
      setProjectId(parseInt(id, 10));
    }
  }, [id]);

  const {
    projectData,
  } = preloadedData;
  const isContractSignedByHomeowner = !!projectData?.projectContract?.homeOwnerSignedAt;
  const isContractSentByHomeowner = !!projectData?.projectContract?.homeOwnerSentAt;
  const isContractSentByContractor = !!projectData?.projectContract?.contractorSentAt;

  const { subContractors, contractorId, status } = projectData || {};
  const { subcontractorsDetails } = useGetSubcontractorData(subContractors as Array<SubcontractorDTO>);
  const {
    owner,
    accountType,
    userId,
  } = getAuthInfo();

  const isContractor = accountType === userRole.contractor && userId === contractorId;
  const handleGoNext = () => {
    onFormSubmitted();
  };

  const hicView = useMemo(() => {
    if ((accountType === 'homeowner' && !projectData?.projectContract?.contractorSentAt)
      || projectData?.subContractors?.some(
        (subcontractor: SubcontractorDTO) => subcontractor.subContractorId === userId,
      )) {
      return null;
    }
    return projectData?.projectContract
      ? <ContractView projectData={projectData} triggerDataUpdate={triggerDataUpdate} />
      : <AddHomeownerContract triggerDataUpdate={triggerDataUpdate} owner={owner as CompanyOwnerDTO} projectData={projectData} />;
  }, [projectData, owner]);

  const actionCTA = useMemo(() => {
    if (isContractor && status === projectStatus.draft) {
      return (
        <ActionButtonsContainer>
          <Button data-testid="back-button" name="back" type="button" variant="secondary" label={lang.backLabel} state="active" onClick={setPreviousStep} />
          <Button
            data-testid="next-button"
            name="next"
            type="button"
            variant="primary"
            label={lang.nextLabel}
            state="active"
            onClick={handleGoNext}
          />
        </ActionButtonsContainer>
      );
    }
  }, [isContractor]);

  const sendChangeOrderContract = useMemo(() => {
    if (projectData?.changeOrder) {
      return projectData?.changeOrder.map((changeOrderDetails) => {
        const isHomeownerSentChangeOrderContract = !!changeOrderDetails?.homeOwnerSentAt;
        const isContractorSentChangeOrderContract = !!changeOrderDetails?.contractorSentAt;

        if ((accountType === 'homeowner' && isContractorSentChangeOrderContract && !isHomeownerSentChangeOrderContract)
        || (accountType !== 'homeowner' && !isContractorSentChangeOrderContract)
        ) {
          return (
            <SendChangeOrderContractView
              changeOrderDetails={changeOrderDetails}
              triggerDataUpdate={triggerDataUpdate}
              projectData={projectData}
            />
          );
        }
        return true;
      });
    }
  }, [projectData]);

  return (
    <>
      <Header screenSize={screenSize}>{lang.contract.headerText}</Header>
      {sendChangeOrderContract}
      {
        !subContractors?.some((subcontractor) => subcontractor.subContractorId === userId) && (
          <Card screenSize={screenSize}>
            <StyledCardTitle screenSize={screenSize}>
              {lang.contract.homeowner.headerText}
              {!isContractSignedByHomeowner && isContractSentByContractor && <Tag status="pending" label={lang.contract.homeowner.awaitingHomeownersSignatureLabel} />}
              {isContractSignedByHomeowner && !isContractSentByHomeowner && <Tag status="light-completed" label={lang.contract.homeowner.signedByBothPartiesLabel} />}
              {isContractSentByHomeowner && isContractSentByContractor && <Tag status="completed" label={lang.contract.homeowner.signedAndSentByBothPartiesLabel} />}
            </StyledCardTitle>
            {hicView}
          </Card>
        )
      }
      {
        accountType !== 'homeowner' && (
          <Card screenSize={screenSize}>
            <StyledCardTitle screenSize={screenSize}>
              {lang.contract.subcontractors.headerText}
            </StyledCardTitle>
            <AddSubcontractorContracts
              subcontractorsDetails={subcontractorsDetails}
              triggerDataUpdate={triggerDataUpdate}
              owner={owner as CompanyOwnerDTO}
              projectData={projectData}
            />
          </Card>
        )
      }
      {actionCTA}
    </>
  );
};

export default Contracts;
