import { PreloadedDataType, ValidationType } from 'domains/project/addProject/types';
import { useEffect, useState } from 'react';

export const useSubmitProjectValidation = (
  projectData: PreloadedDataType,
  validationSet: ValidationType,
) => {
  const [projectSubmitValidation, setProjectSubmitValidation] = useState(validationSet);
  useEffect(() => {
    if (projectData) {
      const {
        milestones,
        subContractors,
        details: {
          homeOwnerId,
          cost,
        },
        projectContract,
      } = projectData;

      handleSubmitProjectValidation(
        [{
          homeowner: !!homeOwnerId,
          cost: !!cost,
          contractorMilestones: milestones ? milestones.length > 0 : false,
          projectContract: !!(projectContract?.contractorSentAt && projectContract?.homeOwnerSentAt && checkSubcontractorsSignedContract()),
        }],
      );
    }
  }, [projectData]);

  const checkSubcontractorsSignedContract = () => {
    const { subContractors } = projectData || {};
    if (subContractors && subContractors.length > 0) {
      const totalSubcontractors = subContractors.length;
      const signedDataSet: Array<string> = [];
      subContractors.filter((item) => (item?.subcontractorContract?.length !== 0))
        .map((val) => val?.subcontractorContract?.map((item) => signedDataSet.push(item.subcontractorSentAt)));

      if (signedDataSet.length !== totalSubcontractors) {
        return false;
      }
      if (signedDataSet.length !== 0) {
        const isEmptySubcontractorSigned = signedDataSet.every((item) => item !== null);
        return isEmptySubcontractorSigned;
      }
      return false;
    }
    return true;
  };

  const handleSubmitProjectValidation = (data: Array<{ [key: string]: boolean }>) => {
    const { projectContract = null } = projectData || {};
    const validations = projectSubmitValidation.map((item) => {
      if (data[0][item.type] !== undefined) {
        return {
          ...item,
          isValid: data[0][item.type],
        };
      }
      return { ...item, projectContract: !!(projectContract?.contractorSentAt && projectContract?.homeOwnerSentAt && checkSubcontractorsSignedContract()) };
    });
    setProjectSubmitValidation(validations);
  };
  return {
    projectSubmitValidation,
    handleSubmitProjectValidation,
  };
};
