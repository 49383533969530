import styled from 'styled-components';
import { textXLg } from 'designSystem/typings';
import { black } from 'designSystem/colors';
import { ScreenSize } from 'hooks/useWindowDimensions';

export const LogoSection = styled.div<{screenSize?: ScreenSize}>`
  margin: ${({ screenSize }) => screenSize !== 'desktop' ? '30px 0px' : '40px 0px'};
  display: flex;
  justify-content: center;
`;

export const LogoImg = styled.img`
  height: 72px;
`;

export const MainHeading = styled.h1<{fontSize?: string, lineHeight?: string}>`
  ${textXLg};
  color: ${black};
  text-align: center;

  ${({ fontSize }) => fontSize && `font-size: ${fontSize}`};
  ${({ lineHeight }) => lineHeight && `line-height: ${lineHeight}`};
`;
