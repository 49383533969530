import { CardDescription, /*CardTitle*/ } from 'domains/accountSetup/onboarding/styles';
import ContractTile from 'domains/project/addProject/contract/homeowner/contractTile';
import { Section } from 'domains/project/addProject/contract/homeowner/styles';
import { ListItem, UnorderedList, /*ChangeOrderAction*/ } from 'domains/project/addProject/contract/homeowner/contractView/styles';
import lang from 'lang/en';
import { Tag } from 'designSystem/tag';
import { getAuthInfo } from 'utils/helpers';
import { StyledCardTitle } from 'domains/project/addProject/contract/styles';
import { ChangeOrderForm } from 'domains/project/addProject/contract/changerOder/form/changeOrderForm';
import { useMemo, useRef, useState } from 'react';
import DeleteItem from 'common/deleteItem';
import { useWindowDimensions } from 'hooks/useWindowDimensions';
import { isEmpty } from 'lodash';
import type { ChangeOrderType } from '../../homeowner/types';
import { useChangeOrderActions } from '../useChangeOrderActions';

const Contracts = ({ changeOrderDetails, projectData, triggerDataUpdate } : ChangeOrderType) => {
  const { contract: { changeOrder: { listing: { sendContractText, deleteContractText } } } } = lang;
  const { screenSize } = useWindowDimensions();
  const { accountType } = getAuthInfo();
  const changeOrderFormRef = useRef<any>();
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const isContractSignedByHomeowner = !!changeOrderDetails?.homeOwnerSignedAt;
  const isContractSentByContractor = !!changeOrderDetails?.contractorSentAt;
  const isContractSentByHomeowner = !!changeOrderDetails?.homeOwnerSentAt;

  const { deleteContract, getContract, downloadLoading } = useChangeOrderActions(projectData);
  const handleEditClick = () => {
    return changeOrderFormRef && changeOrderFormRef?.current?.click(true);
  };

  const getHandleEditClick = () => {
    if (accountType !== 'homeowner' && !isContractSignedByHomeowner) {
      return handleEditClick;
    }
  };

  const handleDeleteClick = async () => {
    await deleteContract(changeOrderDetails?.id);
    triggerDataUpdate();
  };
  const getHandleDeleteClick = () => {
    if (accountType !== 'homeowner' && !isContractSignedByHomeowner) {
      return () => setIsDeleteOpen(true);
    }
  };

  const validateDownloadRequest = useMemo(() => {
    let isValidDownloadRequest = true;
    if (changeOrderDetails) {
      isValidDownloadRequest = isEmpty(changeOrderDetails.contractCreatedAt) && isEmpty(changeOrderDetails.contractUrl);
    }
    return isValidDownloadRequest;
  }, [changeOrderDetails, projectData]);

  return (
    <Section>
      <StyledCardTitle screenSize={screenSize}>
        {sendContractText.heading}
        {!isContractSignedByHomeowner && isContractSentByContractor && <Tag status="pending" label={lang.contract.homeowner.awaitingHomeownersSignatureLabel} />}
        {isContractSignedByHomeowner && !isContractSentByHomeowner && <Tag status="light-completed" label={lang.contract.homeowner.signedByBothPartiesLabel} />}
        {isContractSentByHomeowner && isContractSentByContractor && <Tag status="completed" label={lang.contract.homeowner.signedAndSentByBothPartiesLabel} />}
      </StyledCardTitle>
      <UnorderedList>
        {changeOrderDetails?.contractLog?.map((listItem: string) => {
          return (
            <ListItem key={listItem}>
              {listItem}
            </ListItem>
          );
        })}
      </UnorderedList>
      <CardDescription>
        {sendContractText.description}
      </CardDescription>
      <ContractTile
        contractFileName={changeOrderDetails?.fileName}
        onEditClick={getHandleEditClick()}
        downloadLoading={downloadLoading}
        onDeleteClick={getHandleDeleteClick()}
        onDownloadClick={() => getContract(changeOrderDetails?.id)}
        validateDownloadRequest={validateDownloadRequest}
        contractDocumentUrl={changeOrderDetails?.contractUrl}
      />
      <ChangeOrderForm
        projectData={projectData}
        ref={changeOrderFormRef}
        triggerDataUpdate={triggerDataUpdate}
        changeOrderDetails={changeOrderDetails}
      />
      <DeleteItem
        handleCancel={() => setIsDeleteOpen(false)}
        handleDelete={handleDeleteClick}
        heading={deleteContractText.header}
        subText={deleteContractText.description}
        isOpen={isDeleteOpen}
      />
    </Section>
  );
};

export default Contracts;
