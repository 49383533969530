import {
  CardDescription, CardTitle,
} from 'domains/accountSetup/onboarding/styles';
import { useWindowDimensions } from 'hooks/useWindowDimensions';
import { Controller } from 'react-hook-form';
import {
  LabelWithMargin, RadioContainer, ErrorMessage, ErrorText,
  MarginTop, RadioOptionContainer,
} from 'domains/project/addProject/contract/styles';
import type { RadioOptionsTypes } from 'domains/project/addProject/contract/types';

export const RadioOptionFields = ({
  control, fieldText, fieldName, setValue, updateValue, errorMsg, getValues,
  marginBottom, clearErrors, setStringValue, isString,
} : RadioOptionsTypes) => {
  //const { screenSize } = useWindowDimensions();

  const handleOnChange = (value: boolean) => {
    (isString && setStringValue)
      ? setStringValue(fieldName, value)
      : setValue(fieldName, value);

    clearErrors(fieldName);
    if (updateValue) {
      updateValue(value);
    }
  };

  return (
    <RadioOptionContainer marginBottom={marginBottom}>
      <CardTitle marginBottom="0">
        {fieldText.mainHeading}
      </CardTitle>
      <CardDescription>
        {fieldText.description}
      </CardDescription>
      <RadioContainer>
        <Controller
          control={control}
          name={fieldName}
          render={() => (
            <label htmlFor={`yes-${fieldName}`}>
              <input
                name={fieldName}
                type="radio"
                id={`yes-${fieldName}`}
                onChange={() => handleOnChange(true)}
                defaultChecked={getValues(fieldName) !== null ? getValues(fieldName) : false}
              />
              {fieldText.option1}
            </label>
          )}
        />
        <MarginTop />
        <Controller
          control={control}
          name={fieldName}
          render={() => (
            <LabelWithMargin htmlFor={`no-${fieldName}`}>
              <input
                name={fieldName}
                type="radio"
                id={`no-${fieldName}`}
                onChange={() => handleOnChange(false)}
                defaultChecked={getValues(fieldName) !== null ? !getValues(fieldName) : false}
              />
              {fieldText.option2}
            </LabelWithMargin>
          )}
        />
        <ErrorMessage id={`error-${fieldName}`} margin="0 0 0 10px"><ErrorText>{errorMsg}</ErrorText></ErrorMessage>
      </RadioContainer>
    </RadioOptionContainer>
  );
};
