import { ReactElement } from 'react';
import { Navigate } from 'react-router-dom';
import { checkAuth } from 'utils/helpers';

const isAuthenticated = (): boolean => {
  return checkAuth(); // we might have logic related to based on access token and user type or role in future.
};

type RequireAuthProps = {
  children: ReactElement;
  redirectTo: string;
}

const RequireAuth = ({ children, redirectTo }: RequireAuthProps): ReactElement => {
  const isLoggedIn = isAuthenticated();
  return isLoggedIn ? children : <Navigate to={redirectTo} />;
};

export default RequireAuth;
