import { floridaStateDefaultValues } from 'domains/project/addProject/contract/homeowner/form/floridaStateForm/content';
import { ohioStateDefaultValues } from 'domains/project/addProject/contract/homeowner/form/ohioStateForm/content';
import { states } from 'utils/constants';
import { massachusettsStateDefaultValues } from './form/MassachusettsStateForm/content';
import { delawareStateDefaultValues } from './form/delaware/content';

export const stateOptions = [
  {
    value: 'alaska',
    label: 'Alaska',
  },
  {
    value: 'alabama',
    label: 'Alabama',
  },
  {
    value: 'newYork',
    label: 'New York',
  },
  {
    value: 'connecticut',
    label: 'Connecticut',
  },
];

export const getStateOptions = (): Array<{value: string; label: string}> => {
  const options = [
    {
      value: 'connecticut',
      label: 'Connecticut',
    },
    {
      value: 'florida',
      label: 'Florida',
    },
    {
      value: 'massachusetts',
      label: 'Massachusetts',
    },
    {
      value: 'delaware',
      label: 'Delaware',
    },
    {
      value: 'ohio',
      label: 'Ohio',
    },
  ];

  return options;
};

export const formDefaultValues: {[key: string]: any} = {
  additionalHomeOwnerName: '',
  startDate: '',
  completionDate: '',
  substantialCompletionDate: '',
  previousCorporation: '',
  projectCostModel: '',
  totalAllowance: '',
  workDescription: '',
  paymentSchedule: '',
  escrow: false,
  contractorAuthorizedRepresentative: '',
  homeOwnerAuthorizedRepresentative: '',
  contractorInsuranceOption: '',
  contractorInsuranceProviderName: '',
  workerCompensationInsuranceOption: '',
  workerCompensationInsuranceLimit: null,
  contractFileName: '',
  state: null,
};

export const otherStateValues = {
  [states.florida]: floridaStateDefaultValues,
  [states.ohio]: ohioStateDefaultValues,
  [states.massachusetts]: massachusettsStateDefaultValues,
  [states.delaware]: delawareStateDefaultValues,
};

export const getHicDefaultValues = (stateName?: string) => {
  let defaultValues = { ...formDefaultValues };
  if (stateName && otherStateValues[stateName]) {
    defaultValues = { ...defaultValues, extraData: { ...otherStateValues[stateName] } };
  }
  return defaultValues;
};
