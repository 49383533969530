import axios from 'axios';
import { useState } from 'react';
import { apiClient } from 'utils/network/apiClient';
import { signaturePresignedUrl } from 'utils/network/endpoints';

export const useSaveSignature = (): {
  loading: boolean,
  callError: any,
  saveSignature: (base64Data: string, filename: string) => Promise<string>,
} => {
  const [loading, setLoading] = useState(false);
  const [callError, setCallError] = useState(null);

  const saveSignature = async (base64Data: string, filename: string) => {
    setLoading(true);
    try {
      const url = `${signaturePresignedUrl}?file=${filename}`;
      const presignedResult = await apiClient.get(url);

      const {
        presignedUrl,
        key,
      } = presignedResult.data.data;
      const instance = axios.create();
      delete instance.defaults.headers.common.Authorization;
      await instance.put(presignedUrl, base64Data, {
        headers: {
          'x-amz-acl': 'public-read',
          'Content-Type': '',
          'Content-Disposition': 'attachment; filename="signature.png"',
        },
        withCredentials: false,
      });
      return key;
    } catch (error: any) {
      console.error({ error });
      setCallError(error);
    } finally {
      setLoading(false);
    }
  };

  return {
    callError,
    loading,
    saveSignature,
  };
};
