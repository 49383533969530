import Steps from 'designSystem/steps/steps';
import { ReactElement, useEffect, useState } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import lang from 'lang/en';
import { Step, StepState } from 'designSystem/steps/types';
import { useGetCompanyData } from './useGetCompanyData';
import DetailsOwners from './detailsOwners/detailsOwners';
import { License } from './license/license';
import { Subscription } from './subscription';
import type { CompanyDataDTO } from './types';
import { useStepsState } from '../../../../hooks/useStepsState';
import CompanyOnboardingConfirmation from './confirmation';

const stepToRoute = (step: number): string => {
  switch (step) {
    case 0:
      return '/account-setup/onboarding/company/details';
    case 1:
      return '/account-setup/onboarding/company/license';
    case 2:
      return '/account-setup/onboarding/company/subscription';
    default:
      return '/account-setup/onboarding/company/details';
  }
};

const initialSteps: Array<Step> = [
  {
    circleContent: '01',
    label: lang.stepCompanyDetailsText,
    stepState: 'notStarted' as StepState,
  },
  {
    circleContent: '02',
    label: lang.stepLicenseText,
    stepState: 'notStarted' as StepState,
  },
  {
    circleContent: '03',
    label: lang.stepSubscriptionText,
    stepState: 'notStarted' as StepState,
  },
];

const Company = (): ReactElement => {
  const { stepsState, currentStep, setCurrentStep } = useStepsState(initialSteps);
  const [currentRoute, setCurrentRoute] = useState('/account-setup/onboarding/company');
  const [onboardingDetails, setOnboardingDetails] = useState<CompanyDataDTO>();
  const navigate = useNavigate();
  const { getCompanyData } = useGetCompanyData();

  useEffect(() => {
    setCurrentRoute(stepToRoute(currentStep));
  }, [currentStep]);

  useEffect(() => {
    navigate(currentRoute);
  }, [currentRoute]);

  useEffect(() => {
    const fillExistingData = async () => {
      const onboardingData = await getCompanyData();
      setOnboardingDetails(onboardingData);
    };
    fillExistingData();
  }, [currentStep]);

  return (
    <>
      <Steps steps={stepsState} />
      <Routes>
        <Route path="details" element={<DetailsOwners onboardingDetails={onboardingDetails} onFormSubmitted={() => setCurrentStep(currentStep + 1)} />} />
        <Route
          path="license"
          element={(
            <License
              onboardingDetails={onboardingDetails}
              onFormSubmitted={() => setCurrentStep(currentStep + 1)}
              setPreviousStep={() => setCurrentStep(currentStep - 1)}
            />
          )}
        />
        <Route path="subscription" element={<Subscription setPreviousStep={() => setCurrentStep(currentStep - 1)} />} />
        <Route path="confirmation" element={<CompanyOnboardingConfirmation />} />
      </Routes>
    </>
  );
};

export default Company;
