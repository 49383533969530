import { ButtonWithIconContainer } from 'designSystem/button/styles';
import lang from 'lang/en';
import { ReactComponent as PlusIcon } from 'assets/images/plus-icon.svg';
import Milestones from './milestones';
import { ButtonWithTopMargin } from '../../styles';
import { UserFoundCardContentProps, FormFieldType } from './types';

const UserFoundCardContent = ({
  item,
  control,
  index,
  isLastElement,
  setValue,
  getValues,
  handleAddSubcontractor,
  isEscrow,
}: UserFoundCardContentProps & FormFieldType) => {
  return (
    <>
      {item.subcontractorId
        ? <Milestones control={control} formIndex={index} setValue={setValue} getValues={getValues} isEscrow={isEscrow} />
        : null}
      {item.subcontractorId && isLastElement
        ? (
          <ButtonWithTopMargin
            customRender={(
              <ButtonWithIconContainer>
                <PlusIcon />
                {lang.subcontractorMilestones.milestones.addSubcontractorButtonText}
              </ButtonWithIconContainer>
            )}
            name="addOwner"
            type="button"
            variant="primary"
            onClick={handleAddSubcontractor}
          />
        )
        : null}
    </>
  );
};

export default UserFoundCardContent;
