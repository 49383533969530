import ControlledSelect from 'designSystem/select/select';
import { useForm } from 'react-hook-form';
import { useEffect, useState, useMemo } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from 'designSystem';
import { CustomCardTitle } from 'domains/project/addProject/details/userSearchAndDisplayCard/styles';
import { CardDescription } from 'domains/accountSetup/onboarding/styles';
import lang from 'lang/en';
import { useWindowDimensions } from 'hooks/useWindowDimensions';
import type { File } from 'designSystem/fileUpload/types';
import { getButtonState } from 'utils/helpers';
import { inspectionPresignedUrl } from 'utils/network/endpoints';
import { ListFiles } from 'designSystem/fileUpload/ListFiles';
import UploadDocuments from '../updateMilestones/uploaddocuments';
import {
  InspectionRequestSection, SelectField, ButtonContainer, FileContainer, FilesSection, CompletedText,
} from '../styles';
import { inspectionRequestSchema } from './validationSchema';
import { useInspectionRequest } from './useInspectionRequest';
import { options } from './content';

export const InspectionRequest = ({
  milestoneId, inspection, isPendingProject, isInspector,
} : {
  milestoneId: number;
  isInspector: boolean;
  inspection: {
    type: string;
    inspectionDocument: {
      fileName: string;
      documentUrl: string;
    };
  }
  isPendingProject: boolean;
}) => {
  const { screenSize } = useWindowDimensions();
  const microsoftWordDocumentAcceptString = '.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document';
  const pdfDocumentAcceptString = '.pdf, application/pdf';
  const { saveInspectionRequestData, loading } = useInspectionRequest();
  const [documents, setDocuments] = useState<Array<File>>([]);
  const resolver = { resolver: yupResolver(inspectionRequestSchema) };

  const {
    control, handleSubmit, reset, trigger, setValue, formState: { isDirty, isValid },
  } = useForm({
    ...resolver,
    mode: 'onChange',
    defaultValues: {
      type: '',
      inspectionDocument: [],
    },
  });

  useEffect(() => {
    if (inspection) {
      const { type, inspectionDocument } = inspection;
      const typeValue = options.find((item) => item.value === type);
      const defaultValues = {
        type: typeValue,
        inspectionDocument: [inspectionDocument],
      };
      reset(defaultValues);
      setDocuments([inspectionDocument]);
      trigger();
    }
  }, [inspection]);

  const updateDocuments = (newDocuments: Array<File>) => {
    setValue('inspectionDocument', newDocuments);
    setDocuments(newDocuments);
  };

  const onSubmit = (data: { type: { value: string; label: string}, inspectionDocument: Array<File>}) => {
    const { type: { value }, inspectionDocument: documentList } = data;
    const typeData = {
      type: value,
    };
    let payload: {
      type: string;
      inspectionDocument?: { fileName: string, documentUrl: string}
    } = { ...typeData };
    if (documentList.length !== 0) {
      const inspectionDocument = {
        fileName: documentList[0].fileName,
        documentUrl: documentList[0].documentUrl,
      };
      payload = {
        ...payload,
        inspectionDocument,
      };
    }
    saveInspectionRequestData(payload, milestoneId);
  };

  const filesSection = useMemo(() => {
    if (isPendingProject || isInspector) {
      return <FilesSection><ListFiles documents={documents} /></FilesSection>;
    }
    return (
      <UploadDocuments
        documents={documents}
        updateDocuments={updateDocuments}
        uploadTitle={lang.subcontractorMilestones.inspection.uploadFileTitle}
        deleteTextHeading={lang.subcontractorMilestones.inspection.delete.heading}
        deleteSubText={lang.subcontractorMilestones.inspection.delete.subText}
        showAddMore={false}
        presignedUrl={inspectionPresignedUrl}
        accept={`image/png, image/jpeg, image/jpg, ${pdfDocumentAcceptString}, ${microsoftWordDocumentAcceptString}`}
      />
    );
  }, [isPendingProject, documents]);

  const inspectionSelect = useMemo(() => {
    if (!isInspector) {
      return (
        <SelectField>
          <ControlledSelect
            name="type"
            control={control}
            placeholder="Choose"
            options={options}
            disabled={isPendingProject}
          />
        </SelectField>
      );
    }
  }, [isInspector]);

  const inspectionCTA = useMemo(() => {
    if (!isInspector) {
      return (
        <ButtonContainer>
          <Button width="full" label={lang.subcontractorMilestones.inspection.submitRequest} state={getButtonState(isDirty, isValid, loading)} />
        </ButtonContainer>
      );
    }
  }, [isInspector, isDirty, isValid, loading]);

  const inspectionTitle = useMemo(() => {
    if (!isInspector) {
      return (
        <>
          <CustomCardTitle>
            {lang.subcontractorMilestones.inspection.title}
          </CustomCardTitle>
          <CardDescription>
            {lang.subcontractorMilestones.inspection.description}
          </CardDescription>
        </>
      );
    }
    if (inspection !== null) {
      return (
        <CompletedText>{lang.subcontractorMilestones.inspection.subTitle}</CompletedText>
      );
    }
  }, [isInspector]);

  return (
    <InspectionRequestSection>
      {inspectionTitle}
      <form onSubmit={handleSubmit(onSubmit)}>
        {inspectionSelect}
        <FileContainer screenSize={screenSize}>
          {filesSection}
        </FileContainer>
        {inspectionCTA}
      </form>
    </InspectionRequestSection>
  );
};
