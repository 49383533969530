import React from 'react';
import CatchError from './catchError';

type Props = {
  children: React.ReactNode
}

const ErrorBlock = CatchError((props: Props, error?: Error) => {
  const { children } = props;
  if (error) {
    return (
      <div>
        <h2>Error block</h2>
        <h4>{error.message}</h4>
      </div>
    );
  } else {
    return (<div>{children}</div>);
  }
});

export default ErrorBlock;
