import {
  textLg, textSm, textBaseNormal, textSLg,
} from 'designSystem/typings';
import {
  gray900, red500, green400, gray500, black,
} from 'designSystem/colors';
import { InputField, ControlledInputField } from 'designSystem/input';
import { NumberMaskedField } from 'designSystem/input/maskedNumber/maskedNumberField';
import styled from 'styled-components';
import { CardRow } from 'domains/accountSetup/onboarding/company/styles';
import { ScreenSize } from 'hooks/useWindowDimensions';
import { Button } from 'designSystem';
import ControlledNumberMaskedField from 'designSystem/input/maskedNumber/controlledMaskedNumberField';
import { EditAction } from 'designSystem/action/styles';

export const Header = styled.h1<{ screenSize: ScreenSize }>`
  ${textLg};
  padding: ${({ screenSize }) => screenSize !== 'desktop' ? '0 112px' : '0 200px;'};
  font-weight: 500;
  text-transform: uppercase;
`;

export const Container = styled.div`
  width: 100%;
  max-width: 1168px;
  padding-left: 20px;
  padding-right: 20px;
`;

export const StepsContainer = styled.div`
  max-width: 930px;
  margin: 32px auto;
`;

export const PercentageText = styled.p`
  ${textSm};
  color: ${gray900};
  line-height: 20px;
  font-weight: 400;
`;

export const ErrorState = styled.p`
  ${textSm};
  line-height: 20px;
  color: ${red500};
  margin-top: 15px;
`;

export const ValidationMessage = styled.p<{ isValid: boolean }>`
  ${textSm};
  color: ${({ isValid }) => isValid ? green400 : red500};
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 5px;
`;

export const Symbol = styled.span`
  margin-right: 8px;
`;

export const MilestonesContainer = styled.div`
  margin-top: 20px;
`;

export const CustomCardRow = styled(CardRow)`
  margin-top: 27px;
`;

export const MidScreenDescription = styled.p`
  ${textSm};
  line-height: 20px;
  color: ${gray500};
  margin: 12px 0px;
`;

export const ButtonWithTopMargin = styled(Button)`
  margin-top: 12px;
`;

export const NarrowControlledNumberMaskedInput = styled(ControlledNumberMaskedField)`
  max-width: 240px;
`;

export const MediumControlledNumberMaskedInput = styled(ControlledNumberMaskedField)`
  max-width: 320px;
`;

export const NarrowNumberMaskedFilled = styled(NumberMaskedField)`
  max-width: 240px;
 `;

export const NarrowControlledInputField = styled(ControlledInputField)`
  max-width: 504px;
`;

export const TextSection = styled.div`
  padding: 32px 31px;

  & p:nth-child(2) {
    margin-top: 23px;
  }
`;

export const PlainText = styled.p`
  ${textBaseNormal};
  color: ${black};
  text-align: center;
`;

export const NoDataFoundText = styled.p<{ marginTop?: string }>`
  ${textSm};
  color: ${black};
  text-align: center;
  margin-top: ${({ marginTop }) => marginTop || 0}
`;

export const CustomPrefilledInput = styled(InputField)<{ maxWidth: string }>`
  max-width: ${({ maxWidth }) => maxWidth};
`;

export const CustomPrefilledNumberInput = styled(NumberMaskedField)<{ maxWidth: string }>`
  max-width: ${({ maxWidth }) => maxWidth};
`;

export const ViewMilestonsContainer = styled.div<{screenSize?: ScreenSize}>`
  padding: ${({ screenSize }) => screenSize === 'desktop' ? '0 1px' : 0};
`;

export const NarrowNumberMaskedInput = styled(NumberMaskedField)`
  max-width: 240px;
`;

export const NarrowInputField = styled(InputField)`
max-width: 504px;
`;

export const TitleHeading = styled.div<{screenSize?: ScreenSize}>`
  display: flex;
  & h1 {
    padding: ${({ screenSize }) => screenSize === 'desktop' ? '0 112px' : '12px 12px'};
  }
`;

export const TitleHeadingCol = styled.div<{screenSize?: ScreenSize}>`
  display: flex;
  flex-direction: column;
  & h1 {
    padding: ${({ screenSize }) => screenSize === 'desktop' ? '0 112px' : '12px 12px 0 12px'};
  }
`;

export const HeaderDesc = styled.h1<{ screenSize: ScreenSize }>`
  ${textLg};
  padding: ${({ screenSize }) => screenSize !== 'desktop' ? '0 112px' : '0 200px;'};
  font-weight: 400;
`;
