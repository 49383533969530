import { ReactComponent as TickIcon } from 'assets/images/tick.svg';
//import { NavLink } from 'react-router-dom';
import { StepCircle, StepContainer, StepLabel } from './styles';
import { StepProps } from './types';

const Step = ({
  stepState,
  circleContent,
  label,
  screenSize,
  setCurrentStep,
}: StepProps) => {
  const circleComponent = (
    <StepCircle
      isClickable={!!setCurrentStep}
      stepState={stepState}
      onClick={setCurrentStep}
    >
      {stepState === 'completed'
        ? <TickIcon data-testid="tick-icon" />
        : circleContent}
    </StepCircle>
  );

  return (
    <StepContainer>
      {circleComponent}
      {(screenSize === 'desktop' || screenSize === 'tablet') && (
        <StepLabel
          stepState={stepState}
          isClickable={!!setCurrentStep}
          onClick={setCurrentStep}
        >
          {label}
        </StepLabel>
      )}
    </StepContainer>
  );
};

export default Step;
