import lang from 'lang/en';

export const saveAddProjectValidation = [
  {
    label: lang.homeownerDetails,
    labelMissing: lang.projectSubmitSave.homeOwnerDetailsMissing,
    link: '/projects/add/details/',
    isValid: false,
    type: 'homeowner',
    step: 0,
  },
  {
    label: lang.cost,
    labelMissing: lang.projectSubmitSave.costMissing,
    link: '/projects/add/details/',
    isValid: false,
    type: 'cost',
    step: 0,
  },
  {
    label: lang.subcontractorMilestones.milestones.header,
    labelMissing: lang.projectSubmitSave.milestoneMissing,
    link: '/projects/add/contractor-milestones/',
    isValid: false,
    type: 'contractorMilestones',
    step: 1,
  },
  {
    label: lang.projectSubmitSave.contractSuccess,
    labelMissing: lang.projectSubmitSave.contract,
    link: '/projects/add/contract/',
    isValid: false,
    type: 'projectContract',
    step: 3,
  },
];

export const saveViewProjectValidation = [
  {
    label: lang.homeownerDetails,
    labelMissing: lang.projectSubmitSave.homeOwnerDetailsMissing,
    link: '/projects/view/details/',
    isValid: false,
    type: 'homeowner',
    step: 0,
  },
  {
    label: lang.cost,
    labelMissing: lang.projectSubmitSave.costMissing,
    link: '/projects/view/details/',
    isValid: false,
    type: 'cost',
    step: 0,
  },
  {
    label: lang.subcontractorMilestones.milestones.header,
    labelMissing: lang.projectSubmitSave.milestoneMissing,
    link: '/projects/view/contractor-milestones/',
    isValid: false,
    type: 'contractorMilestones',
    step: 1,
  },
  {
    label: lang.projectSubmitSave.contractSuccess,
    labelMissing: lang.projectSubmitSave.contract,
    link: '/projects/view/contract/',
    isValid: false,
    type: 'projectContract',
    step: 3,
  },
];
