import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useWindowDimensions } from 'hooks/useWindowDimensions';
import lang from 'lang/en';
import { useEffect, useState } from 'react';
import { ActionButtonsContainer } from 'domains/accountSetup/onboarding/styles';
import { Button } from 'designSystem';
import { useParams } from 'react-router-dom';
import { Header, TitleHeadingCol, HeaderDesc } from '../../styles';
import { subcontractorsValidationSchema } from './validationSchema';
import { useSaveProjectDetailsData } from '../../useSaveProjectDetailsData';
import { Subcontractors } from '../../types';
import { emptySubcontractorMilestones } from './milestones';
import { SubcontractorMilestonesProps } from './types';
import { prepareDataToPreload, prepareDataToSubmit } from './helpers';
import SubcontractorFields from './SubcontractorFields';

const SubcontractorMilestones = ({
  preloadedData,
  onFormSubmitted,
  setProjectId,
  setPreviousStep,
  isEscrow,
}: SubcontractorMilestonesProps) => {
  const {
    loading,
    updateProjectDetailsData,
    isSaved,
    response,
  } = useSaveProjectDetailsData();
  const { id } = useParams<{ id: string }>();
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const resolver = { resolver: yupResolver(subcontractorsValidationSchema) };
  const {
    handleSubmit,
    control,
    reset,
    trigger,
    setValue,
    getValues,
  } = useForm(
    {
      ...resolver,
      mode: 'onChange',
      defaultValues: {
        subContractors: [{
          subcontractorId: '',
          cost: '',
          ...emptySubcontractorMilestones,
        }],
      },
    },
  );

  useEffect(() => {
    if (id) {
      setProjectId(parseInt(id, 10));
    }
  }, [id]);

  useEffect(() => {
    if (preloadedData && preloadedData.subContractors?.length) {
      const { subContractors } = preloadedData;
      const mappedSubContractors = prepareDataToPreload(subContractors);
      if (mappedSubContractors.length === 0) {
        return;
      }
      reset({ subContractors: mappedSubContractors });
      setIsDataLoaded(true);
      trigger();
    }
  }, [preloadedData]);

  useEffect(() => {
    if (isSaved && response && response.data) {
      setProjectId(response.data.data.id);
      onFormSubmitted();
    }
  }, [isSaved, response]);

  const { screenSize } = useWindowDimensions();

  const onSubmit = async (data: Subcontractors) => {
    const mappedData = prepareDataToSubmit(data);
    if (mappedData?.subContractors && mappedData?.subContractors[0]?.totalCost) {
      await updateProjectDetailsData({
        ...mappedData,
        id,
      });
    }
    onFormSubmitted();
  };

  return (
    <>
      <TitleHeadingCol>
        <Header screenSize={screenSize}>{lang.subcontractorMilestones.header}</Header>
        <HeaderDesc screenSize={screenSize}>{lang.subcontractorMilestones.headerDesc}</HeaderDesc>
      </TitleHeadingCol>
      <form onSubmit={handleSubmit(onSubmit)}>
        <SubcontractorFields control={control} setValue={setValue} getValues={getValues} isEscrow={isEscrow} />
        <ActionButtonsContainer>
          <Button data-testid="back-button" label={lang.backLabel} type="button" variant="secondary" onClick={setPreviousStep} />
          <Button
            data-testid="next-button"
            type="submit"
            variant="primary"
            label={lang.nextLabel}
            state={loading ? 'loading' : 'active'}
          />
        </ActionButtonsContainer>
      </form>
    </>
  );
};

export default SubcontractorMilestones;
