import { ControlledInputField } from 'designSystem/input';
import {
  LabelWithMargin, RadioContainer, ErrorMessage, ErrorText,
} from 'domains/project/addProject/contract/styles';
import { SelectPropsWithControl } from 'domains/project/addProject/contract/subcontractor/form/types';
import lang from 'lang/en';
import { useWatch } from 'react-hook-form';
import { useEffect } from 'react';
import { useWindowDimensions } from 'hooks/useWindowDimensions';
import { getTextareaIconLeftPosition } from '../helpers';

const formStrings = lang.contract.subcontractors.modal.form;

const PaymentMethodOptionSelect = ({
  registrant,
  control,
  setValue,
  errorMsg,
}: SelectPropsWithControl) => {
  const optionValue = useWatch({
    control,
    name: registrant.name,
  });
  const { screenSize } = useWindowDimensions();
  useEffect(() => {
    if (optionValue !== 'other' && setValue) {
      setValue('paymentMethodOther', null);
    }
  }, [optionValue]);

  return (
    <RadioContainer>
      <LabelWithMargin htmlFor="paymentMethodImmediatelyOption">
        <input
          {...registrant}
          type="radio"
          value="immediately"
          id="paymentMethodImmediatelyOption"
        />
        {formStrings.paymentMethodOptions.immediatelyOption}
      </LabelWithMargin>
      <LabelWithMargin htmlFor="paymentMethodWithin7DaysOption">
        <input
          {...registrant}
          type="radio"
          value="withing7days"
          id="paymentMethodWithin7DaysOption"
        />
        {formStrings.paymentMethodOptions.within7daysOption}
      </LabelWithMargin>
      <LabelWithMargin htmlFor="paymentMethodRegularOption">
        <input
          {...registrant}
          type="radio"
          value="regularPayment"
          id="paymentMethodRegularOption"
        />
        {formStrings.paymentMethodOptions.regularPaymentOption}
      </LabelWithMargin>
      <LabelWithMargin htmlFor="paymentMethodOtherOption">
        <input
          {...registrant}
          type="radio"
          value="other"
          id="paymentMethodOtherOption"
        />
        {formStrings.paymentMethodOptions.otherOption}
      </LabelWithMargin>
      <ControlledInputField
        control={control}
        name="paymentMethodOther"
        labelText={formStrings.paymentMethodOptions.otherFieldLabel}
        disabled={optionValue !== 'other'}
        errorIconPosition={getTextareaIconLeftPosition(screenSize, '516px')}
      />
      <ErrorMessage><ErrorText>{errorMsg}</ErrorText></ErrorMessage>
    </RadioContainer>
  );
};

PaymentMethodOptionSelect.defaultProps = {
  errorMsg: '',
};

export default PaymentMethodOptionSelect;
