import { useCallback, useEffect, useState } from 'react';
import { apiClient } from 'utils/network/apiClient';
import lang from 'lang/en';
import { AxiosResponse } from 'axios';
import { clearAuthInfo } from 'utils/helpers';
import { useNavigate } from 'react-router-dom';
import { notifications } from 'designSystem/toastNotifications/toastNotificationEmitter';
import { CustomErrorCallInterface, getErrorMessage } from './getErrorMessage';
import { FormHttpMethod, SaveFormInterface } from './types';

export const useSaveForm = <DataType>(): SaveFormInterface<DataType> => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [isSaved, setIsSaved] = useState(false);
  const [response, setResponse] = useState<AxiosResponse | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (error) {
      setIsSaved(false);
    }
  }, [error]);

  const save = useCallback(
    async (url: string, requestData: DataType, method: FormHttpMethod) => {
      try {
        setLoading(true);
        setError('');
        setIsSaved(false);
        setResponse(null);
        const apiResponse = await apiClient[method](url, requestData);
        if (apiResponse.status >= 400) {
          setLoading(false);
          if ((apiResponse as any).isNotAuthenticated) {
            clearAuthInfo();
            notifications.error(lang.sessionExpiredMessage);
            navigate('/signin');
            return;
          }
          if (apiResponse.status === 404) {
            notifications.error(lang.unknownErrorTryLater);
            return;
          }
          setError(apiResponse.data.message);
        }

        setIsSaved(true);
        setResponse(apiResponse);
      } catch (unhandledError) {
        const errorMessage = getErrorMessage(unhandledError as CustomErrorCallInterface);
        setError(errorMessage);
      }
      setLoading(false);
    },
    [],
  );

  return {
    loading, error, save, isSaved, setError, response,
  };
};
