export const stateOptions = [

  {
    value: 'connecticut',
    label: 'Connecticut',
  },
  {
    value: 'florida',
    label: 'Florida',
  },
  {
    value: 'massachusetts',
    label: 'Massachusetts',
  },
  {
    value: 'delaware',
    label: 'Delaware',
  },
  {
    value: 'ohio',
    label: 'Ohio',
  },
];

export const subcontractorFormDefaultValues = {
  state: null,
  homeownerFirstLastName: '',
  additionalHomeOwnerName: '',
  contractorFirstLastName: '',
  contractorCompanyName: '',
  subcontractorFirstLastName: '',
  subcontractorCompanyName: '',
  projectAddress: {
    streetAddress: '',
    city: '',
    state: '',
    zip: '',
  },
  contractorAuthorizedRepresentative: '',
  subcontractorAuthorizedRepresentative: '',
  contractorInsuranceOption: '',
  contractorInsuranceProviderName: '',
  workerCompensationInsuranceOption: '',
  workerCompensationInsuranceLimit: null,
  descriptionOfServices: '',
  subcontractorResponsibilities: [],
  otherSubcontractorResponsibilities: '',
  commencementDate: '',
  completionDateOptions: '',
  specificCompletionDate: '',
  otherCompletionDate: null,
  substantialCompletionDateOptions: '',
  specificSubstantialCompletionDate: '',
  otherSubstantialCompletionDate: null,
  escrow: false,
  paymentAmountOptions: '',
  paymentAmountTotalAmount: null,
  paymentAmountHourly: null,
  paymentAmountOther: '',
  paymentMethodOptions: '',
  paymentMethodOther: '',
  paymentFrequencyOptions: '',
  paymentFrequencyOther: '',
  rightToSubcontractingOptions: '',
  assignmentRightOptions: '',
  subcontractorInsuranceOption: '',
  subcontractorInsuranceProviderName: '',
  subcontractorWorkerCompensationInsuranceOptions: '',
  subcontractorWorkerCompensationInsuranceLimit: '',
  additionalProvisions: '',
  fileName: '',
};
