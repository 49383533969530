import { ContractorInsuranceSelectProps } from 'domains/project/addProject/contract/homeowner/types';
import {
  ErrorMessage, ErrorText, LabelWithMargin, NarrowControlledInputField, RadioContainer,
} from 'domains/project/addProject/contract/styles';
import lang from 'lang/en';
import { useEffect } from 'react';

const ContractorInsuranceSelect = ({
  registrant,
  control,
  setValue,
  contractorInsuranceValue,
  errorMsg,
}: ContractorInsuranceSelectProps) => {
  const modalStrings = lang.contract.homeowner.modal;

  useEffect(() => {
    if (contractorInsuranceValue !== 'commercialLiabilityInsurance') {
      setValue('contractorInsuranceProviderName', '');
    }
  }, [contractorInsuranceValue]);

  return (
    <RadioContainer>
      <label htmlFor="commercialLiabilityInsurance">
        <input
          {...registrant}
          type="radio"
          value="commercialLiabilityInsurance"
          id="commercialLiabilityInsurance"
        />
        {modalStrings.form.contractorsInsurance.option1}
      </label>
      <NarrowControlledInputField
        control={control}
        name="contractorInsuranceProviderName"
        labelText={modalStrings.form.contractorsInsurance.option1InputFieldLabel}
        disabled={contractorInsuranceValue !== 'commercialLiabilityInsurance'}
        errorIconPosition="206px"
      />
      <LabelWithMargin htmlFor="noCommercialLiabilityInsurance">
        <input
          {...registrant}
          type="radio"
          value="noCommercialLiabilityInsurance"
          id="noCommercialLiabilityInsurance"
        />
        {modalStrings.form.contractorsInsurance.option2}
      </LabelWithMargin>
      <label htmlFor="selfInsured">
        <input
          {...registrant}
          type="radio"
          value="selfInsured"
          id="selfInsured"
        />
        {modalStrings.form.contractorsInsurance.option3}
      </label>
      <ErrorMessage><ErrorText>{errorMsg}</ErrorText></ErrorMessage>
    </RadioContainer>
  );
};

ContractorInsuranceSelect.defaultProps = {
  errorMsg: '',
};

export default ContractorInsuranceSelect;
