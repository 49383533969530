import styled, { css } from 'styled-components';
import { typings, colors } from 'designSystem';
import {
  live, draft, underReview, pending, completed, cancelled,
} from './listing/data';

type StatusTypes = {status: string};

export const CreateProject = styled.div`
  display: flex;
  align-items: center;
  padding: 40px 30px 19px 30px;

  & button {
    cursor: pointer;
  }
`;

export const ListingEmptyStateCss = styled.div`
  align-items: center;
  text-align: center;
  padding: 40px 30px 19px 30px;

  & svg {
    padding-bottom: 40px;
  }
  
  & p {
    ${typings.textSm}
    width: 255px ;
    margin: auto;
  }
  
  
`;

export const ProjectTitle = styled.p`
  ${typings.textBase}
  font-weight: 600;
  margin-right: 34px;
`;

export const Listing = styled.section`
 padding: 0 0 12px 0;
`;

const commonStyleProgressBar = css` 
display: flex;
align-items: center;
& p {
  ${typings.textXSm}
  color: ${colors.gray900};
  line-height: 12px;
  margin-left: 8px;  
}`;

export const ProgressBar = styled.div<{screenSize : string}>`
 
  ${({ screenSize }) => {
    switch (screenSize) {
      case 'smallTablet':
        return css`
         ${commonStyleProgressBar}
         & svg {
          width: 44px;
          height: 6px;
          border-radius: 10px;
         }
         & p {
           margin-left: 13px;
         }
        `;
      case 'tablet':
        return css`
        ${commonStyleProgressBar}
        & svg {
          width: 44px;
          height: 6px;
          border-radius: 10px;
         }
         & p {
          margin-left: 13px;
        }
          `;
      case 'desktop':
        return css`
        ${commonStyleProgressBar}
        & svg {
          width: 150px;
          height: 7px;
          border-radius: 10px;
         }
          `;
      default:
        return commonStyleProgressBar;
    }
  }};
`;

export const ListButton = styled.div<{screenSize?: string}>`
 & button {
  padding: ${({ screenSize }) => screenSize === 'desktop' ? '9px 17px' : '0px 11px'};
 }
`;

export const NameText = styled.p`
  ${typings.textSm}
  line-height: 20px;
  color: ${colors.gray900};
  text-transform: capitalize;
`;

const getStatusColor = (status: string) => {
  switch (status) {
    case live:
      return {
        color: colors.green800,
        bgColor: colors.green100,
      };
    case draft:
      return {
        color: colors.purple800,
        bgColor: colors.purple100,
      };
    case underReview:
      return {
        color: colors.orange900,
        bgColor: colors.orange200,
      };
    case pending:
      return {
        color: colors.yellow700,
        bgColor: colors.yellow50,
      };
    case completed:
      return {
        color: colors.blue800,
        bgColor: colors.blue100,
      };
    case cancelled:
      return {
        color: colors.red800,
        bgColor: colors.red100,
      };
    default:
      return {};
  }
};

export const Status = styled.div<StatusTypes>`
  display: flex;
  & span {
    padding: 4px 16px;
    border-radius: 10px;
    text-transform: capitalize;
    background: ${({ status }) => getStatusColor(status).bgColor};
  }
  & p {
    ${typings.textXSm}
    color:${({ status }) => getStatusColor(status).color};
  }
`;

export const MobileCreateProject = styled.div`
 display: flex;
 justify-content: space-between;
 align-items: center;
 padding: 18px 19px 0 12px;
 & p {
   ${typings.textLg}
   line-height: 32px;
   font-weight: 600;
 }
`;

export const MobileListing = styled.div`
  padding: 0 14px 0 14px;
`;

export const ProjectSection = styled.section`
  margin-right: 36px;
`;

export const ProjectStatus = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ProjectName = styled.p`
  ${typings.textBaseNormal}
  font-weight: 500;
  color: ${colors.gray900};
  text-transform: capitalize;
`;

export const StatuSpan = styled.span<StatusTypes>`
  padding: 4px 16px;
  border-radius: 10px;
  text-transform: capitalize;
  background: ${({ status }) => getStatusColor(status).bgColor};
`;

export const StatusText = styled.p<StatusTypes>`
  ${typings.textXSm}
  color:${({ status }) => getStatusColor(status).color};
`;

export const ProgressLine = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-items: center;
  & svg {
    width: 155px;
    height: 6px;
    border-radius: 10px;
  }
`;

export const ProgressPercentage = styled.p`
  ${typings.textXSm}
  line-height: 12px;
  font-weight: 400;
  margin-right: 21px;
`;

export const UpdateCTA = styled.div`
  margin-left: 52px;
  & button {
    padding: 0 12px;
  }
`;
