import {
  Card, CardRow, CardDescription, CardTitle, CustomMaskedField,
} from 'domains/accountSetup/onboarding/styles';
import lang from 'lang/en';
import { useWindowDimensions } from 'hooks/useWindowDimensions';
import { ControlledToggleButton } from 'designSystem/input/toggleInput';
import {
  isReadOnlyProjectSectionByHomeowner, isEditableByContractor, isInspector, isProjectPendingState,
} from 'utils/helpers';
import { InputSpanText } from './styles';
import type { PropsWithControl } from '../types';

export const ProjectCost = ({ control, projectStatus, contractorId }: PropsWithControl) => {
  const { screenSize } = useWindowDimensions();
  const isDisabled = isEditableByContractor(projectStatus, contractorId)
   || isReadOnlyProjectSectionByHomeowner(contractorId) || isInspector() || isProjectPendingState(projectStatus);
  return (
    <Card screenSize={screenSize}>
      <CardTitle marginBottom="4px">
        {lang.projectCostTitle}
      </CardTitle>
      <CardDescription>{lang.projectCostDesc}</CardDescription>
      <CardRow>
        <CustomMaskedField
          maxWidth="247px"
          control={control}
          name="cost"
          labelText={lang.projectCostLabel}
          placeholder="0.00"
          iconPrefix={<InputSpanText>$</InputSpanText>}
          iconPostfix={<InputSpanText>{lang.currencyUsd}</InputSpanText>}
          prefixAlignment={{ top: '34px', left: '12px' }}
          inputpadding="9px 38px 9px 24px"
          disabled={isDisabled}
          errorIconPosition={screenSize === 'mobile' ? '85%' : '212px'}
        />
      </CardRow>
      <CardRow>
        <ControlledToggleButton control={control} name="escrow" labelText={lang.escrowText} id="escrow" disabled={isDisabled} />
      </CardRow>
    </Card>
  );
};
