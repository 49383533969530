import { Controller } from 'react-hook-form';
import { ReactElement } from 'react';
import DatePickerField from './datepicker';
import type { ControlledDatepickerFieldProps } from './types';

const ControlledDatepickerField = ({
  name, control, dateFormat, setFieldValue, ...propsToPass
}: ControlledDatepickerFieldProps): ReactElement => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => {
        const { error } = fieldState;
        return <DatePickerField dateFormat={dateFormat} {...propsToPass} field={field} errorMessage={error?.message} setFieldValue={setFieldValue} />;
      }}
    />
  );
};

export default ControlledDatepickerField;
