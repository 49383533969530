import {
  CheckboxContainer, Checkbox, CheckSpan,
} from './styles';
import type { LabelledCheckboxProps } from './types';

export const CheckboxField = ({
  labelText, field,
}: LabelledCheckboxProps) => {
  return (
    <CheckboxContainer htmlFor={field.name}>
      {labelText}
      <Checkbox type="checkbox" id={field.name} {...field} checked={field.value || false} />
      <CheckSpan />
    </CheckboxContainer>
  );
};

export default CheckboxField;
