import { CompanyOwnerDTO } from 'domains/accountSetup/onboarding/company/types';
import ContractorContractView from 'domains/project/addProject/contract/homeowner/contractView/contractorContractView';
import HomeownerContractView from 'domains/project/addProject/contract/homeowner/contractView/homeownerContractView';
import SendContractView from 'domains/project/addProject/contract/homeowner/contractView/sendContractView';
import { PreloadedDataType } from 'domains/project/addProject/types';
import { getAuthInfo } from 'utils/helpers';

type ContractViewProps = {
  projectData: PreloadedDataType,
  triggerDataUpdate: () => void;
}

const ContractView = ({
  projectData,
  triggerDataUpdate,
}: ContractViewProps) => {
  const {
    owner,
    accountType,
  } = getAuthInfo();
  const isContractSentByContractor = !!projectData?.projectContract?.contractorSentAt;
  const isContractSentByHomeowner = !!projectData?.projectContract?.homeOwnerSentAt;
  if ((accountType === 'homeowner' && isContractSentByHomeowner) || (accountType !== 'homeowner' && isContractSentByContractor)) {
    return (
      <SendContractView owner={owner as CompanyOwnerDTO} triggerDataUpdate={triggerDataUpdate} projectData={projectData} />
    );
  }
  switch (accountType) {
    case 'homeowner':
      return <HomeownerContractView triggerDataUpdate={triggerDataUpdate} owner={owner as CompanyOwnerDTO} projectData={projectData} />;
    case 'contractor':
    case 'subcontractor':
    default:
      return <ContractorContractView triggerDataUpdate={triggerDataUpdate} owner={owner as CompanyOwnerDTO} projectData={projectData} />;
  }
};

export default ContractView;
