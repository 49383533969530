import runtimeEnv from '@mars/heroku-js-runtime-env';

const { REACT_APP_API_URL } = runtimeEnv();

const apiVersion = '/v1';

const apiRootUrl = `${"https://tradesmen-api-prod-23-ee13a0d6dde3.herokuapp.com"}${apiVersion}`;

const authBaseUrl = `${apiRootUrl}/accounts/password`;
export const signUpUrl = `${authBaseUrl}/register`;
export const signInUrl = `${authBaseUrl}/authenticate`;
export const verifyEmailUrl = `${authBaseUrl}/verifyEmail`;
export const setUserUpdateUrl = `${authBaseUrl}/%userId%`;

export const userBaseUrl = `${apiRootUrl}/accounts/user`;
export const companyDataUrl = `${userBaseUrl}/company`;
export const stripeSubscriptionUrl = `${apiRootUrl}/stripe/subscribe`;
export const licenseDataUrl = `${apiRootUrl}/contractor/%userCompanyId%/addCompanyLicense`;

export const homeownerBaseUrl = `${apiRootUrl}/homeOwner`;
export const homeownerDetailsUrl = `${homeownerBaseUrl}/details`;

export const subContractorBaseUrl = `${apiRootUrl}/subContractor`;

export const projectBaseUrl = `${apiRootUrl}/projects`;

export const s3BaseUrl = `${apiRootUrl}/s3`;
export const s3PresignedUrl = `${s3BaseUrl}/presignedUrl`;
export const inspectionPresignedUrl = `${s3PresignedUrl}/inspection`;
export const signaturePresignedUrl = `${s3PresignedUrl}/signature`;

export const saveProjectUrl = `${projectBaseUrl}/%projectId%`;
export const downloadContractPreviewUrl = `${projectBaseUrl}/%projectId%/hicContract`;
export const downloadSubcontractorContract = `${apiRootUrl}/contract/%contractId%/subContractor`;
export const createSubcontractorContractUrl = `${projectBaseUrl}/%subcontractorId%/subcontractorContract`;

export const contractBaseUrl = `${apiRootUrl}/contract/%contractId%`;
export const contractUpdateUrl = `${contractBaseUrl}/updateSignature`;
export const subcontractUpdateUrl = `${apiRootUrl}/contract/subcontract/%subcontractId%/updateSignature`;
export const sendContractUrl = `${contractBaseUrl}/sendContract`;
export const sendContractAsHomeownerUrl = `${contractBaseUrl}/homeowner/sendContract`;
export const deleteContractUrl = `${contractBaseUrl}/deleteContract`;

export const downloadSubcontractorContractPreviewUrl = `${contractBaseUrl}/subContractor`;
export const deleteSubcontractorContractUrl = `${apiRootUrl}/contract/subcontract/%contractId%`;
export const subcontractorContractBaseUrl = `${apiRootUrl}/subcontract/%contractId%`;
export const updateSubcontractorContractUrl = `${apiRootUrl}/projects/subcontract/%contractId%`;
export const sendSubcontractorContractAsSubcontractorUrl = `${subcontractorContractBaseUrl}/subcontractor/sendContract`;
export const sendSubcontractorContractUrl = `${subcontractorContractBaseUrl}/sendContract`;

export const contractorMilestoneUrl = `${apiRootUrl}/milestone/`;

export const subcontractorUrl = `${apiRootUrl}/subContractor/`;
export const userHeaderFilePresignedUrl = `${s3PresignedUrl}/user`;
export const uploadHeaderFileUrl = `${userBaseUrl}/add-logo`;
export const saveChangeOrderDetails = `${projectBaseUrl}/%projectid%/changeOrder`;
export const saveChangeOrderSignature = `${apiRootUrl}/contract/changeOrder/%contractId%/updateSignature`;
export const sendChangeOrderContractByContractor = `${apiRootUrl}/changeOrder/%contractId%/sendContract`;
export const deleteChangeOrderContract = `${apiRootUrl}/contract/changeOrder/%contractId%`;
export const getChangeOrderContract = `${apiRootUrl}/changeOrder/%contractId%/contract`;
export const sendChangeOrderContractByHomeowner = `${apiRootUrl}/changeOrder/%contractId%/homeowner/sendContract`;
export const stripeGetPlans = `${apiRootUrl}/stripe/get-plans`;
