export const nonEscrowcontractorMilestones = {
  totalCost: null,
  milestones: [{
    name: '',
    percentageOverall: '',
  },
  ],
};
export const escrowcontractorMilestones = {
  totalCost: null,
  milestones: [{
    name: '',
    percentageOverall: 10,
  },
  {
    name: '',
    percentageOverall: 30,
  },
  {
    name: '',
    percentageOverall: 30,
  },
  {
    name: '',
    percentageOverall: 30,
  },
  ],
};

export const setContractorMilestoneValue = (isEscrow?: boolean) => {
  return isEscrow ? escrowcontractorMilestones : nonEscrowcontractorMilestones;
};
