import runtimeEnv from '@mars/heroku-js-runtime-env';
import { Modal } from 'common/modal';
import { useWindowDimensions } from 'hooks/useWindowDimensions';
import { CardDescription, CardTitle } from 'domains/accountSetup/onboarding/styles';
import ContractTile from 'domains/project/addProject/contract/homeowner/contractTile';
import HomeownerContractForm from 'domains/project/addProject/contract/homeowner/form/homeownerContractForm';
import SendContractModal from 'domains/project/addProject/contract/homeowner/sendContractModal';
import Signature from 'domains/project/addProject/contract/homeowner/signature/signature';
import { useUpdateContract } from 'domains/project/addProject/contract/homeowner/signature/useUpdateContract';
import { Section, WideButton } from 'domains/project/addProject/contract/homeowner/styles';
import { SignatureState } from 'domains/project/addProject/contract/homeowner/types';
import { useDownloadContractPreview } from 'domains/project/addProject/contract/homeowner/useDownloadContractPreview';
import { useSendContract } from 'domains/project/addProject/contract/homeowner/useSendContract';
import { UserDetailsHeader } from 'domains/project/addProject/contract/styles';
import { HomeownerContractsProps } from 'domains/project/addProject/contract/types';
import { useGetHomeownerData } from 'domains/project/addProject/contract/useGetHomeownerData';
import lang from 'lang/en';
import {
  useEffect, useMemo, useRef, useState,
} from 'react';
import { isEmpty } from 'lodash';

const signFlowStrings = lang.contract.homeowner.signFlow;

const ContractorContractView = ({
  owner,
  projectData,
  triggerDataUpdate,
}: HomeownerContractsProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isSendContractOpen, setIsSendContractOpen] = useState(false);
  const [signatureState, setSignatureState] = useState<SignatureState>('empty');
  //const { screenSize } = useWindowDimensions();
  const signatureRef = useRef<any>();
  const {
    homeOwnerId: homeownerId,
  } = projectData?.details || {};
  const { homeowner } = useGetHomeownerData(homeownerId as string);
  const homeownerFullName = `${homeowner?.firstName} ${homeowner?.lastName}`;
  const { REACT_APP_IMAGE_URL } = runtimeEnv();
  const {
    updateContract,
  } = useUpdateContract();

  const {
    loading,
    downloadContractPreview,
  } = useDownloadContractPreview();

  const {
    loading: sendContractLoading,
    isSend,
    sendContract,
  } = useSendContract();

  const handleSignatureUploadFinished = async (signatureUrl: string) => {
    updateContract(projectData?.projectContract.id, 'contractorSignature', signatureUrl);
    setSignatureState('submitted');
  };

  const handleEditClick = () => {
    setIsOpen(true);
  };

  const handleSendContract = async () => {
    await sendContract(projectData?.projectContract.id);
    triggerDataUpdate();
  };

  useEffect(() => {
    if (isSend && !loading) {
      setIsSendContractOpen(false);
    }
  }, [isSend]);

  useEffect(() => {
    if (isEmpty(projectData?.projectContract?.contractorSignatureUrl)) {
      setSignatureState('empty');
      signatureRef?.current?.click();
    }
  }, [projectData]);

  const preloadedImageUrl = useMemo(() => {
    if (projectData?.projectContract?.contractorSignatureUrl) {
      return `${REACT_APP_IMAGE_URL}/${projectData?.projectContract?.contractorSignatureUrl}`;
    }
    return '';
  }, [projectData]);

  return (
    <>
      <HomeownerContractForm
        triggerDataUpdate={triggerDataUpdate}
        projectData={projectData}
        owner={owner}
        preloadedData={{ homeownerFullName }}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      />
      <UserDetailsHeader>
        {homeownerFullName}
      </UserDetailsHeader>
      <Section>
        <CardTitle marginBottom="0">
          {signFlowStrings.step1.header}
        </CardTitle>
        <CardDescription>
          {signFlowStrings.step1.description}
        </CardDescription>
        <ContractTile
          contractFileName={projectData?.projectContract.contractFileName}
          onEditClick={handleEditClick}
          downloadLoading={loading}
          onDownloadClick={() => downloadContractPreview(projectData?.details.id as string)}
        />
      </Section>
      <Section>
        <CardTitle marginBottom="0">
          {signFlowStrings.step2.header}
        </CardTitle>
        <CardDescription>
          {signFlowStrings.step2.description}
        </CardDescription>
        <Signature
          signatureState={signatureState}
          setSignatureState={setSignatureState}
          onSignatureUploadFinished={handleSignatureUploadFinished}
          preloadedImageUrl={preloadedImageUrl}
          id={projectData?.projectContract.id}
          ref={signatureRef}
        />
      </Section>
      <Section>
        <CardTitle marginBottom="0">
          {signFlowStrings.step3.header}
        </CardTitle>
        <CardDescription>
          {signFlowStrings.step3.description}
        </CardDescription>
        <WideButton
          width="full"
          type="button"
          variant="primary"
          label={signFlowStrings.step3.sendContractButtonLabel}
          onClick={() => setIsSendContractOpen(true)}
          state={(signatureState === 'submitted' || signatureState === 'preloaded') ? 'active' : 'disabled'}
        />
      </Section>
      <Modal isOpen={isSendContractOpen}>
        <SendContractModal
          sendContractLoading={sendContractLoading}
          handleSend={handleSendContract}
          handleClose={() => setIsSendContractOpen(false)}
        />
      </Modal>
    </>
  );
};

export default ContractorContractView;
