import { CircleButton as StyledCircleButton } from './styles';
import type { CircleButtonProps } from './types';

const CircleButton = ({
  size = 'medium',
  onClick,
  icon,
  state = 'active',
}: CircleButtonProps) => {
  return (
    <StyledCircleButton
      type="button"
      onClick={onClick}
      state={state}
      size={size}
      disabled={state !== 'active'}
    >
      {icon}
    </StyledCircleButton>
  );
};

export default CircleButton;
