import {
  Card, CardRow, CardTitle, CardDescription,
} from 'domains/accountSetup/onboarding/styles';
import { useWindowDimensions } from 'hooks/useWindowDimensions';
import { CustomWidthInputField, NarrowControlledInputField } from 'domains/project/addProject/contract/styles';
import { lang } from 'lang/en';
import { useGetField } from 'domains/project/addProject/details/userSearchAndDisplayCard/useGetField';
import { useMemo } from 'react';
import { Control } from 'react-hook-form';

const { contract: { changeOrder: { homeowner } } } = lang;

export const HomeownerDetails = ({
  fullName, subText = homeowner.subText, showAdditionalHomeowner, additionalHomeownerLabel, control,
}: { fullName: string; subText?: string; showAdditionalHomeowner?: boolean; additionalHomeownerLabel?: string; control: Control}) => {
  const { screenSize } = useWindowDimensions();
  const { getField } = useGetField();

  const showAdditionalHomeownerField = useMemo(() => {
    if (showAdditionalHomeowner) {
      return (
        <CardRow>
          <NarrowControlledInputField
            labelText={additionalHomeownerLabel}
            control={control}
            name="additionalHomeOwnerName"
          />
        </CardRow>
      );
    }
  }, [showAdditionalHomeowner]);
  return (
    <Card screenSize={screenSize}>
      <CardTitle marginBottom="0">
        {homeowner.title}
      </CardTitle>
      <CardDescription>
        {subText}
      </CardDescription>
      <CardRow>
        <CustomWidthInputField
          labelText={homeowner.inputLabel}
          disabled
          field={{ ...getField('homeownerfirstNameLastName', fullName || '') }}
        />
      </CardRow>
      {showAdditionalHomeownerField}
    </Card>
  );
};

HomeownerDetails.defaultProps = {
  showAdditionalHomeowner: false,
  additionalHomeownerLabel: '',
};
