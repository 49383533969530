import { ReactComponent as LoaderIcon } from 'assets/images/loader.svg';
import { Loading, LoaderDiv } from './styles';

const Loader = () => {
  return (
    <Loading>
      <LoaderDiv>
        <LoaderIcon />
      </LoaderDiv>
    </Loading>
  );
};

export default Loader;
