import {
  CardDescription, CardRow,
} from 'domains/accountSetup/onboarding/styles';
import lang from 'lang/en';
import { InputField } from 'designSystem/input';
import { UserFoundDisplayProps } from './types';
import { useGetField } from './useGetField';
import { CustomInputField, CustomNumberMaskedField } from './styles';

const UserFoundDisplay = ({ userData, customRender, title }: UserFoundDisplayProps) => {
  const { getField } = useGetField();

  return (
    <>
      <CardDescription>
        {title}
      </CardDescription>
      <CardRow>
        <InputField disabled field={{ ...getField('homeowner.firstName', userData.firstName) }} labelText={lang.firstNameLabel} />
        <InputField disabled field={{ ...getField('homeowner.lastName', userData.lastName) }} labelText={lang.lastNameLabel} />
      </CardRow>
      <CardRow>
        <CustomInputField disabled field={{ ...getField('homeowner.email', userData.email) }} labelText={lang.emailLabel} />
      </CardRow>
      <CardRow>
        <CustomNumberMaskedField
          disabled
          field={{ ...getField('homeowner.phone', userData.phone) }}
          labelText={lang.phoneLabel}
          maskFormat="+1(###) ###-##-##"
        />
      </CardRow>
      {customRender}
    </>
  );
};

export default UserFoundDisplay;
