import { toast, ToastOptions } from 'react-toastify';

const notificationConfig: ToastOptions = {
  position: 'top-center',
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

const success = (message:string) => toast.success(message, notificationConfig);
const info = (message:string) => toast.info(message, notificationConfig);
const warning = (message:string) => toast.warning(message, notificationConfig);
const error = (message:string) => toast.error(message, notificationConfig);

export const notifications = {
  success,
  info,
  warning,
  error,
};
