import { useCallback, useEffect, useState } from 'react';
import { notifications } from 'designSystem/toastNotifications/toastNotificationEmitter';
import { userBaseUrl } from 'utils/network/endpoints';
import { useGetData } from 'utils/form/useGetData';
import type { UserInfoResponse } from './types';

export const useGetUserInfo = () : {
  userInfo: UserInfoResponse | null
  getUserData: () => void;
  isLoading: boolean;
} => {
  const [isLoading, setLoading] = useState(false);
  const [userInfo, setUserIfo] = useState<UserInfoResponse>(null);

  const {
    getData,
    error,
    response,
  } = useGetData<UserInfoResponse>();

  useEffect(() => {
    if (error) {
      notifications.error(error);
    }
  }, [error]);

  useEffect(() => {
    setLoading(true);
    if (response !== null) {
      setUserIfo(response);
      setLoading(false);
    }
  }, [response]);

  const getUserData = useCallback(async () => {
    await getData(userBaseUrl);
  }, []);
  return {
    userInfo,
    getUserData,
    isLoading,
  };
};
