import styled from 'styled-components';
import { textLg, textSm } from 'designSystem/typings';
import { black, blue700 } from 'designSystem/colors';
import { ScreenSize } from 'hooks/useWindowDimensions';

export const Centerlized = styled.section`
  display: flex;
  justify-content: center;
`;

export const Wrapper = styled.div`
  width: 100%;
`;

export const FormContainer = styled.div`
  margin-top: 24px;
  display: flex;
  justify-content: center;
`;

export const BottomSection = styled.div`
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const BottomHeading = styled.h1`
  ${textLg}
  color: ${black};
  text-align: center;
  margin-bottom: 16px;
`;

export const LinkText = styled.span`
  ${textSm}
  color: ${blue700};
`;

export const Form = styled.form<{screenSize?: ScreenSize}>`
  display: flex;
  flex-direction: column;
`;

export const TermsAndCondition = styled.p`
  ${textSm}
  color: ${black};
`;

export const CheckBoxContent = styled.div`
  display: flex;
  margin-left: 25px;
`;

export const ButtonSpacer = styled.div`
  margin-top: 25px;
`;

export const ForgotPasswordText = styled.p`
  ${textSm}
  color: ${blue700};
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 0px 8px;

  & > button {
    width: 96px;
  }
`;
