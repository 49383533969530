import runtimeEnv from '@mars/heroku-js-runtime-env';
import { Modal } from 'common/modal';
import { CardDescription, CardTitle } from 'domains/accountSetup/onboarding/styles';
import ContractTile from 'domains/project/addProject/contract/homeowner/contractTile';
import SendContractModal from 'domains/project/addProject/contract/homeowner/sendContractModal';
import Signature from 'domains/project/addProject/contract/homeowner/signature/signature';
import { useUpdateContract } from 'domains/project/addProject/contract/homeowner/signature/useUpdateContract';
import { Section, WideButton } from 'domains/project/addProject/contract/homeowner/styles';
import { SignatureState } from 'domains/project/addProject/contract/homeowner/types';
import { useDownloadContractPreview } from 'domains/project/addProject/contract/homeowner/useDownloadContractPreview';
import { useSendAsHomeownerContract } from 'domains/project/addProject/contract/homeowner/useSendAsHomeownerContract';
import { UserDetailsHeader } from 'domains/project/addProject/contract/styles';
import { HomeownerContractsProps } from 'domains/project/addProject/contract/types';
import { useGetHomeownerData } from 'domains/project/addProject/contract/useGetHomeownerData';
import lang from 'lang/en';
import { useEffect, useState } from 'react';

const signFlowStrings = lang.contract.homeowner.signFlow;

const HomeownerContractView = ({
  owner,
  projectData,
  triggerDataUpdate,
}: HomeownerContractsProps) => {
  const [isSendContractOpen, setIsSendContractOpen] = useState(false);
  const [signatureState, setSignatureState] = useState<SignatureState>('empty');

  const {
    homeOwnerId: homeownerId,
  } = projectData?.details || {};
  const { homeowner } = useGetHomeownerData(homeownerId as string);
  const isHomeowner = !!homeowner;
  const homeownerFullName = `${homeowner?.firstName} ${homeowner?.lastName}`;
  const { REACT_APP_IMAGE_URL } = runtimeEnv();
  const {
    loading: updateContractLoading,
    updateContract,
  } = useUpdateContract();

  const {
    loading,
    downloadContractPreview,
  } = useDownloadContractPreview();

  const {
    loading: sendContractLoading,
    isSend,
    sendAsHomeownerContract,
  } = useSendAsHomeownerContract();

  const handleSignatureUploadFinished = async (signatureUrl: string) => {
    updateContract(projectData?.projectContract.id, 'homeOwnerSignature', signatureUrl);
    setSignatureState('submitted');
  };

  const handleSendContract = async () => {
    await sendAsHomeownerContract(projectData?.projectContract.id);
    triggerDataUpdate();
  };

  useEffect(() => {
    if (isSend && !loading) {
      setIsSendContractOpen(false);
    }
  }, [isSend]);

  return (
    <>
      <UserDetailsHeader>
        {homeownerFullName}
      </UserDetailsHeader>
      <Section>
        <CardTitle marginBottom="0">
          {signFlowStrings.step1.header}
        </CardTitle>
        <CardDescription>
          {signFlowStrings.step1.description}
        </CardDescription>
        <ContractTile
          contractFileName={projectData?.projectContract.contractFileName}
          downloadLoading={loading}
          onDownloadClick={() => downloadContractPreview(projectData?.details.id as string)}
        />
      </Section>
      <Section>
        <CardTitle marginBottom="0">
          {signFlowStrings.step2.header}
        </CardTitle>
        <CardDescription>
          {signFlowStrings.step2.description}
        </CardDescription>
        <Signature
          signatureState={signatureState}
          setSignatureState={setSignatureState}
          onSignatureUploadFinished={handleSignatureUploadFinished}
          preloadedImageUrl={`${REACT_APP_IMAGE_URL}/${projectData?.projectContract.homeOwnerSignatureUrl}`}
          id={projectData?.projectContract.id}
        />
      </Section>
      <Section>
        <CardTitle marginBottom="0">
          {signFlowStrings.step3.header}
        </CardTitle>
        <CardDescription>
          {signFlowStrings.step3.description}
        </CardDescription>
        <WideButton
          width="full"
          type="button"
          variant="primary"
          label={signFlowStrings.step3.sendContractButtonLabel}
          onClick={() => setIsSendContractOpen(true)}
          state={(signatureState === 'submitted' || signatureState === 'preloaded') ? 'active' : 'disabled'}
        />
      </Section>
      <Modal isOpen={isSendContractOpen}>
        <SendContractModal
          sendContractLoading={sendContractLoading}
          handleSend={handleSendContract}
          handleClose={() => setIsSendContractOpen(false)}
        />
      </Modal>
    </>
  );
};

export default HomeownerContractView;
