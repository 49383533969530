import { SubcontractorContractDTO } from 'domains/project/addProject/contract/subcontractor/form/types';
import { useState } from 'react';
import { apiClient } from 'utils/network/apiClient';
import { createSubcontractorContractUrl, updateSubcontractorContractUrl } from 'utils/network/endpoints';

export const useUpdateSubcontractorContract = () => {
  const [loading, setLoading] = useState(false);
  const [isSaved, setIsSaved] = useState(false);

  const updateSubcontractorContract = async (data: SubcontractorContractDTO, contractId: number) => {
    setLoading(true);
    try {
      const url = updateSubcontractorContractUrl.replace('%contractId%', contractId.toString());
      const result = await apiClient.patch(url, data);
      if (result.status === 204) {
        setIsSaved(true);
      }
    } catch (requestError:any) {
      console.error(requestError);
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    isSaved,
    updateSubcontractorContract,
  };
};
