import {
  Container, Label, Input, SpanSection, Text,
} from './styles';
import {
  ErrorMessage, ErrorText,
} from '../styles';

import type { LabelledToggleProps } from './types';

export const ToggleInput = ({
  field, id, labelText, errorMessage = '',
  disabled,
}: LabelledToggleProps) => {
  const { value, ...restField } = field;
  return (
    <Container>
      <Text>{labelText}</Text>
      <Label htmlFor={id}>
        <Input type="checkbox" id={id} {...restField} checked={value} data-testid="toggle-input" disabled={disabled} />
        <SpanSection></SpanSection>
      </Label>
      {errorMessage !== ''
      && <ErrorMessage><ErrorText>{errorMessage}</ErrorText></ErrorMessage>}
    </Container>
  );
};
