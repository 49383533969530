import { colors } from 'designSystem';
import { ButtonWithIconContainer } from 'designSystem/button/styles';
import { blue700, gray300, white } from 'designSystem/colors';
import { ControlledDatepicker, ControlledInputField, InputField } from 'designSystem/input';
import { MaskedNumberField } from 'designSystem/input/maskedNumber';
import ControlledSelect from 'designSystem/select/select';
import { textLg, textSm } from 'designSystem/typings';
import { CardTitle } from 'domains/accountSetup/onboarding/styles';
import styled, { css, StyledProps } from 'styled-components';
import { ScreenSize } from 'hooks/useWindowDimensions';

export const StyledCardTitle = styled(CardTitle)<{ screenSize?: ScreenSize}>`
  text-transform: uppercase;
  margin-bottom: 0;
  justify-content: flex-start;
  gap: 40px;
  align-items: center;
  ${({ screenSize }) => {
    switch (screenSize) {
      case 'mobile':
        return css`
            display: flex;
            flex-direction: column;
            align-items: center;
            align-content: center;
            margin-top: 32px;
            margin-bottom: 15px;
          `;
      default:
        return css``;
    }
  }};
`;

export const StyledButtonWithIconContainer = styled(ButtonWithIconContainer)`
  width: 172px;
`;

export const UserDetailsHeader = styled.div`
  ${textLg};
  font-weight: 500;
  margin: 32px 0;
`;

export const CustomControlledDatepicker = styled(ControlledDatepicker)`
  max-width: 372px;
`;

export const NarrowControlledInputField = styled(ControlledInputField)`
  & > input {
    max-width: 240px;
  }
`;

export const MediumControlledInputFieldSection = styled(ControlledInputField)<{ maxWidth: string}>`
  max-width: ${({ maxWidth }) => maxWidth}
`;

export const WideLimitedControlledInputField = styled(ControlledInputField)`
  max-width: 650px;
`;

export const RadioContainer = styled.div`
  ${textSm};
  color: ${colors.gray700};
  display: flex;
  flex-direction: column;
  
  & input {
    margin-right: 8px;
  }
  
  & > ${NarrowControlledInputField} {
    margin-top: 32px;
  }
`;

export const LabelWithMargin = styled.label`
  margin-bottom: 32px;
`;

export const GrayContainer = styled.div`
  background-color: ${colors.gray50};
  max-width: calc(100vw - 2rem);
  max-height: calc(100vh - 2rem);
  overflow-y: auto;
`;

export const FormHeader = styled.div<{screenSize?: ScreenSize}>`
  margin: ${({ screenSize }) => screenSize === 'mobile' ? '0 20px' : '0 112px'};
  padding: ${({ screenSize }) => screenSize === 'mobile' ? '0 0 0 4px' : '0 0 0 86px'}; 
`;

export const CloseButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  padding: 32px 0;
`;

export const NarrowControlledSelect = styled(ControlledSelect)`
  max-width: 320px;
`;

export const MediumControlledInputField = styled(ControlledInputField)`
  & > input {
    max-width: 372px;
  }
`;

export const ButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 40px 0;
`;

export const CheckSpan = styled.span`
  width: 16px;
  height: 16px;
  position: absolute;
  left: 0;
  border: 1px solid ${gray300};
  border-radius: 4px;
  &:after {
    content: "";
    position: absolute;
    display: none;
  }
`;

export const CheckboxContainer = styled.label`
  display: flex;
  position: relative;
  cursor: pointer;
  margin-bottom: 32px;
  
  & > p {
    margin-left: 24px;
    max-width: 700px;
  }
`;

export const Checkbox = styled.input`
  &:checked + ${CheckSpan} {
    background-color: ${blue700};
    border: none;
  }
  &:checked + ${CheckSpan}:after {
    display: block;
    left: 6px;
    top: 2px;
    width: 3px;
    height: 7px;
    border: solid ${white};
    border-width: 0 2px 3px 0;
    transform: rotate(45deg);
  }
  position: absolute;
  opacity: 0;
  cursor: pointer;
`;

export const PrefilledSection = styled.div<{screenSize?: ScreenSize}>`
  padding: ${({ screenSize }) => screenSize === 'mobile' ? '0 23px 0 23px' : '0'}; 
`;

export const ChangeOrderFormContainer = styled.div<{screenSize?: ScreenSize}>`
 & form {
  padding: ${({ screenSize }) => screenSize === 'mobile' ? '0 23px 0 23px' : '0'}; 
 }
`;

export const CustomWidthInputField = styled(InputField)`
 max-width: 320px;
`;

export const CustomWidthMaskedNumberField = styled(MaskedNumberField)`
 max-width: 320px;
`;

export const SendChangerOrderSection = styled.div``;

export const ErrorMessage = styled.div<{margin?: string}>`
  margin: ${({ margin }) => margin || '22px 0 0 12px'};
`;

export const ErrorText = styled.p`
   ${textSm}
   color: ${colors.red500};
`;

export const MarginTop = styled.div<{ marginTop?: string}>`
  margin-top: ${({ marginTop }) => marginTop || '20px'}
`;

export const TitleText = styled.p`
 ${textLg}
 font-weight: 500;
 color: ${colors.black};
 margin-bottom: 20px;
`;

export const MaxWidthContainer = styled.div` 
  max-width: 783px;
`;

export const RadioOptionContainer = styled.div<{marginBottom?:string}>`
  margin-bottom: ${({ marginBottom }) => marginBottom || 0};
`;

export const CheckboxLabel = styled.p`
 ${textSm}
 color: ${colors.gray700}
`;
