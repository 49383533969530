import {
  LabelWithMargin, RadioContainer, ErrorMessage, ErrorText,
} from 'domains/project/addProject/contract/styles';
import lang from 'lang/en';
import { UseFormRegisterReturn } from 'react-hook-form';

type ProjectCostModelSelectProps = {
  registrant: UseFormRegisterReturn;
  fieldName: string;
  errorMsg?: string;
}

const ProjectCostModelSelect = ({ registrant, errorMsg }: ProjectCostModelSelectProps) => {
  const modalStrings = lang.contract.homeowner.modal;

  return (
    <RadioContainer>
      <LabelWithMargin htmlFor="totalCost">
        <input
          {...registrant}
          type="radio"
          value="totalCost"
          id="totalCost"
        />
        {modalStrings.form.projectCostModel.option1}
      </LabelWithMargin>
      <LabelWithMargin htmlFor="workPlusContractorFeeCost">
        <input
          {...registrant}
          type="radio"
          value="workPlusContractorFeeCost"
          id="workPlusContractorFeeCost"
        />
        {modalStrings.form.projectCostModel.option2}
      </LabelWithMargin>
      <label htmlFor="timeAndMaterialCost">
        <input
          {...registrant}
          type="radio"
          value="timeAndMaterialCost"
          id="timeAndMaterialCost"
        />
        {modalStrings.form.projectCostModel.option3}
      </label>
      <ErrorMessage><ErrorText>{errorMsg}</ErrorText></ErrorMessage>
    </RadioContainer>
  );
};

ProjectCostModelSelect.defaultProps = {
  errorMsg: '',
};

export default ProjectCostModelSelect;
