import { useWindowDimensions } from 'hooks/useWindowDimensions';
import lang from 'lang/en';
import { ReactComponent as TickIcon } from 'assets/images/tick.svg';
import { ReactComponent as RedLineIcon } from 'assets/images/line.svg';
import { ReactComponent as Mail } from 'assets/images/mail.svg';
import { useEffect } from 'react';
import { useNavigate, Link, useParams } from 'react-router-dom';
import {
  ActionButtonsContainer, Card, CardDescription, CardRow,
} from 'domains/accountSetup/onboarding/styles';
import { ButtonWithIconContainer } from 'designSystem/button/styles';
import { Button } from 'designSystem';
import {
  Header, Symbol, TitleHeading, ValidationMessage,
} from '../styles';
import { CustomCardTitle } from '../details/userSearchAndDisplayCard/styles';
import type { ProjectSubmitSaveProps } from '../types';
import { useSubmitProject } from './useSubmitProject';

export const Submit = ({
  setProjectId, projectSubmitValidation, updateCurrentStep,
}: ProjectSubmitSaveProps) => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const {
    submitProject, loading, isSaved, error,
  } = useSubmitProject();

  useEffect(() => {
    if (id) {
      setProjectId(parseInt(id, 10));
    }
  }, [id]);

  useEffect(() => {
    if (isSaved && !error) navigate('/projects/add/success');
  }, [isSaved, error]);

  const { screenSize } = useWindowDimensions();

  const handleSubmitProject = () => {
    if (id) {
      submitProject(parseInt(id, 10));
    }
  };

  const getSubmitBtnState = () => {
    const notValidProject = projectSubmitValidation.some((item) => item.isValid === false);
    if (!notValidProject) {
      return loading ? 'loading' : 'active';
    }
    return 'disabled';
  };

  return (
    <>
      <TitleHeading><Header screenSize={screenSize}>{lang.projectSubmitSave.header}</Header></TitleHeading>
      <form>
        <Card screenSize={screenSize}>
          <CustomCardTitle>
            {lang.projectSubmitSave.subHeaderStartText}
          </CustomCardTitle>
          <CardDescription>
            {lang.projectSubmitSave.description}
          </CardDescription>
          {projectSubmitValidation.map(({
            isValid, label, link, step, labelMissing,
          }) => {
            return (
              <CardRow key={Math.random()}>
                <ValidationMessage isValid={isValid} onClick={() => updateCurrentStep(step)}>
                  <Symbol>{(isValid) ? <TickIcon /> : <RedLineIcon width="12px" height="8px" />}</Symbol>
                  <Link to={link + id}>{isValid ? label : labelMissing}</Link>
                </ValidationMessage>
              </CardRow>
            );
          })}
          <ActionButtonsContainer>
            <Button
              data-testid="back-button"
              type="button"
              variant="primary"
              customRender={(
                <ButtonWithIconContainer>
                  <Mail />
                  {lang.submitLabel}
                </ButtonWithIconContainer>
              )}
              state={getSubmitBtnState()}
              onClick={handleSubmitProject}
            />
            <Button data-testid="next-button" type="button" variant="secondary" label={lang.saveDraftLabel} onClick={() => navigate('/projects')} />
          </ActionButtonsContainer>
        </Card>
      </form>
    </>
  );
};

export default Submit;
