import { ErrorBoundary } from 'utils/errorBoundary';
import { Button } from 'designSystem';
import {
  getAuthInfo, getButtonState, clearAuthInfo, isUserAuthourised,
} from 'utils/helpers';
import { useForm, SubmitHandler } from 'react-hook-form';
import lang from 'lang/en';
import { yupResolver } from '@hookform/resolvers/yup';
import ControlledSelect from 'designSystem/select/select';
import { useNavigate } from 'react-router-dom';
import HeaderWithLogo from 'designSystem/header/headerWithLogo';
import { useWindowDimensions } from 'hooks/useWindowDimensions';
import { useInactiveUserRedirection } from 'hooks/useInactiveUserRedirection';
import { useEffect } from 'react';
import { selectUserValidationSchema } from './validationSchema';
import type { SelectUser } from './types';
import {
  Centerlized, ButtonSection, SubHeading, FormSection, Form, Fields,
  LinkText,
} from './styles';
import { useSetUserType } from './useSetUserType';

export const UserTypeSelection = () => {
  const {
    selectUserHeading, confirm, introduceText, goBackToSignin,
  } = lang;
  const { setUserType, loading } = useSetUserType();
  const resolver = { resolver: yupResolver(selectUserValidationSchema) };
  const {
    handleSubmit, control, formState: { isDirty, isValid },
  } = useForm({ ...resolver, mode: 'onChange' });
  const navigate = useNavigate();
  const { screenSize } = useWindowDimensions();
  const { redirectInactiveUser } = useInactiveUserRedirection();
  const isActiveUser = isUserAuthourised();

  useEffect(() => {
    if (!isActiveUser) {
      redirectInactiveUser();
    }
  }, []);

  useEffect(() => {
    if (isActiveUser) {
      navigate('/projects');
    }
  }, [isActiveUser]);

  const onSubmit: SubmitHandler<SelectUser> = async (data) => {
    const { userId } = getAuthInfo();
    await setUserType(userId, data.userType.value);
    navigate('/account-setup/onboarding');
  };

  const handleBackToSignin = () => {
    clearAuthInfo();
    navigate('/signin');
  };

  return (
    <ErrorBoundary>
      <Centerlized>
        <HeaderWithLogo lineHeight={screenSize !== 'desktop' ? '24px' : ''} fontSize={screenSize !== 'desktop' ? '20px' : ''} text={selectUserHeading} />
        <SubHeading screenSize={screenSize}>{introduceText}</SubHeading>
        <FormSection>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Fields>
              <ControlledSelect
                name="userType"
                control={control}
                placeholder="Choose"
                options={[
                  {
                    value: 'contractor',
                    label: 'Contractor',
                  },
                  {
                    value: 'homeowner',
                    label: 'Homeowner',
                  },
                ]}
              />
            </Fields>
            <ButtonSection>
              <Button variant="primary" label={confirm} type="submit" state={getButtonState(isDirty, isValid, loading)} />
            </ButtonSection>
          </Form>
        </FormSection>
        <LinkText onClick={handleBackToSignin}>{goBackToSignin}</LinkText>
      </Centerlized>
    </ErrorBoundary>
  );
};

export default UserTypeSelection;
