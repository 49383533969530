import { SignIn, SignUp } from 'domains/userAuth';
import { AccountConfirmation } from 'domains/accountSetup/confirmation';
import { ReactElement, useMemo } from 'react';
import { ProjectListing } from 'domains/project/listing';
import { Route } from 'react-router-dom';
import AccountSetupRoutes from 'domains/accountSetup/routes';
import Disabled from 'domains/userAuth/disabled';
import ProjectAdd from 'domains/project/addProject';
import ProjectView from 'domains/project/viewProject';
import { Settings } from 'domains/settings';
import RequireAuth from './requireAuth';

const useAppRoutes = (): Array<ReactElement> => {
  const publicRoutes = useMemo(() => {
    const routes = [
      <Route path="/signin" key="signin" element={<SignIn />}>
        <Route path=":token" element={<SignIn />} />
        <Route path="" element={<SignIn />} />
      </Route>,
      <Route path="/login/:token" key="signin" element={<SignIn />} />,
      <Route path="/signup" key="signup" element={<SignUp />} />,
      <Route
        path="/account-confirmation"
        key="account-confirmation"
        element={<AccountConfirmation />}
      />,
      <Route
        path="/disabled"
        key="disabled"
        element={<Disabled />}
      />,
    ];

    return routes;
  }, []);

  const protectedRoutes = useMemo(() => {
    const routes = [
      <Route
        path="/projects"
        key="projects"
        element={(
          <RequireAuth redirectTo="/signin">
            <ProjectListing />
          </RequireAuth>
        )}
      />,
      <Route
        path="/projects/add/*"
        key="projects-add"
        element={(
          <ProjectAdd />
        )}
      />,
      <Route
        path="/projects/view/*"
        key="projects-view"
        element={(
          <ProjectView />
        )}
      />,
      <Route
        path="/account-setup/*"
        key="account-setup"
        element={(
          <RequireAuth redirectTo="/signin">
            <AccountSetupRoutes />
          </RequireAuth>
        )}
      />,
      <Route
        path="/settings"
        key="user-settings"
        element={(
          <RequireAuth redirectTo="/signin">
            <Settings />
          </RequireAuth>
        )}
      />,

    ];

    return routes;
  }, []);

  return [...publicRoutes, ...protectedRoutes];
};

export default useAppRoutes;
