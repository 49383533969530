import {
  LabelWithMargin, RadioContainer, ErrorMessage, ErrorText,
} from 'domains/project/addProject/contract/styles';
import { SimpleSelectProps } from 'domains/project/addProject/contract/subcontractor/form/types';
import lang from 'lang/en';

const formStrings = lang.contract.subcontractors.modal.form;

const RightToSubcontractingOptionSelect = ({
  registrant,
  errorMsg,
}: SimpleSelectProps) => {
  return (
    <RadioContainer>
      <LabelWithMargin htmlFor="hasRightsToSubcontractingOption">
        <input
          {...registrant}
          type="radio"
          value="hasRight"
          id="hasRightsToSubcontractingOption"
        />
        {formStrings.rightToSubcontractingOptions.rightOption}
      </LabelWithMargin>
      <LabelWithMargin htmlFor="hasNoRightsToSubcontractingOption">
        <input
          {...registrant}
          type="radio"
          value="hasNoRights"
          id="hasNoRightsToSubcontractingOption"
        />
        {formStrings.rightToSubcontractingOptions.noRightOption}
      </LabelWithMargin>
      <ErrorMessage><ErrorText>{errorMsg}</ErrorText></ErrorMessage>
    </RadioContainer>
  );
};

RightToSubcontractingOptionSelect.defaultProps = {
  errorMsg: '',
};

export default RightToSubcontractingOptionSelect;
