import { Controller } from 'react-hook-form';
import { ReactElement } from 'react';
import RadioBox from './radioBox';
import type { ControlledRadioboxProps } from './types';

export const ControlledRadiobox = ({
  name, content, control, ...propsToPass
}: ControlledRadioboxProps):ReactElement => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => {
        const { error } = fieldState;
        return <RadioBox {...propsToPass} field={field} content={content} errorMessage={error?.message} />;
      }}
    />
  );
};

export default ControlledRadiobox;
