import { AccountType } from 'utils/helpers';
import { Card } from 'domains/accountSetup/onboarding/styles';
import { useWindowDimensions } from 'hooks/useWindowDimensions';
import { useEffect, useState } from 'react';
import lang from 'lang/en';
import { CustomCardTitle } from 'domains/project/addProject/details/userSearchAndDisplayCard/styles';
import UserFoundDisplay from 'domains/project/addProject/details/userSearchAndDisplayCard/userFoundDisplay';
import { UserDataDTO } from 'domains/project/addProject/details/userSearchAndDisplayCard/types';
import { useGetUserData } from 'domains/project/addProject/details/userSearchAndDisplayCard/useGetUserData';
import { NoDataFoundText } from 'domains/project/addProject/styles';

export const HomeownerDetails = ({ userId, userType }: {
  userId?: string;
  userType: AccountType;
}) => {
  const {
    getUserDataById,
  } = useGetUserData(userType);

  const { screenSize } = useWindowDimensions();

  const [userData, setUserData] = useState<UserDataDTO>();

  useEffect(() => {
    const searchUser = async () => {
      const requestResult = await getUserDataById(userId as string);
      if (requestResult) {
        setUserData(requestResult);
      }
    };
    if (userId) {
      searchUser();
    }
  }, [userId]);

  return (
    <Card screenSize={screenSize}>
      {userData
        ? (
          <>
            <CustomCardTitle>
              {lang.homeownerDetailsLabel}
            </CustomCardTitle>
            <UserFoundDisplay userData={userData} />
          </>
        )
        : <NoDataFoundText>{lang.noHomeownerDetailsFound}</NoDataFoundText>}
    </Card>
  );
};
