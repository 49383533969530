import { notifications } from 'designSystem/toastNotifications/toastNotificationEmitter';
import lang from 'lang/en';
import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { clearAuthInfo } from 'utils/helpers';
import { apiClient } from 'utils/network/apiClient';
import { downloadSubcontractorContractPreviewUrl } from 'utils/network/endpoints';

export const useDownloadContractPreview = (): {
  downloadContractPreview: (contractId: string) => Promise<any>;
  loading: boolean
} => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const downloadContractPreview = useCallback(
    async (contractId: string) => {
      setLoading(true);
      const url = downloadSubcontractorContractPreviewUrl.replace('%contractId%', contractId.toString());
      try {
        const callResponse = await apiClient.get(url);
        setLoading(false);
        if (callResponse.status === 200) {
          return callResponse.data.data;
        } else {
          if ((callResponse as any).isNotAuthenticated) {
            clearAuthInfo();
            notifications.error(lang.sessionExpiredMessage);
            navigate('/signin');
            return;
          }
          notifications.error(lang.unknownErrorTryLater);
        }
      } catch (callError: unknown) {
        notifications.error((callError as Error)?.message || lang.accountActivationFailedMessage);
      }

      setLoading(false);
    },
    [apiClient],
  );

  return {
    downloadContractPreview,
    loading,
  };
};
