import { useEffect, useState } from 'react';
import { UserDataDTO } from '../details/userSearchAndDisplayCard/types';
import { useGetUserData } from '../details/userSearchAndDisplayCard/useGetUserData';

export const useGetHomeownerData = (homeownerId: string) => {
  const [homeowner, setHomeowner] = useState<UserDataDTO | null>(null);
  const { getUserDataById: getHomeownerById } = useGetUserData('homeowner');

  useEffect(() => {
    const getHomeowner = async () => {
      if (homeownerId) {
        return getHomeownerById(homeownerId);
      }
    };

    getHomeowner().then((homeownerData) => {
      if (homeownerData) {
        setHomeowner(homeownerData);
      }
    });
  }, [homeownerId]);

  return {
    homeowner,
  };
};
