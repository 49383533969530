import LoaderIcon from './loaderIcon';
import {
  LoaderButtonInnerComponent, PrimaryButton, SecondaryButton, DangerButton,
} from './styles';
import type { ButtonProps } from './types';

const Button = ({
  label,
  variant = 'primary',
  customRender,
  state = 'active',
  name,
  ...propsToPass
}: ButtonProps) => {
  const getInnerComponent = () => {
    if (customRender) {
      return customRender;
    }
    if (state === 'loading') {
      return (
        <LoaderButtonInnerComponent>
          <LoaderIcon />
          {label}
        </LoaderButtonInnerComponent>
      );
    }

    return label;
  };

  switch (variant) {
    case 'danger':
      return (
        <DangerButton
          name={name}
          disabled={state !== 'active'}
          state={state}
          {...propsToPass}
        >
          {getInnerComponent()}
        </DangerButton>
      );
    case 'secondary':
      return (
        <SecondaryButton
          name={name}
          disabled={state !== 'active'}
          state={state}
          {...propsToPass}
        >
          {getInnerComponent()}
        </SecondaryButton>
      );
    case 'primary':
    default:
      return (
        <PrimaryButton
          name={name}
          disabled={state !== 'active'}
          state={state}
          {...propsToPass}
        >
          {getInnerComponent()}
        </PrimaryButton>
      );
  }
};

export default Button;
