import * as Yup from 'yup';
import lang from 'lang/en';
import { notAllowSpecialCharcters } from 'utils/regex';
const maxCharactersAllowed = 50;

export const projectNameAndAddressSchema = Yup.object().shape({
  name: Yup.string()
    .max(maxCharactersAllowed, `${lang.maxCharactersAllowedIs} ${maxCharactersAllowed}`)
    .nullable()
    .required(lang.missingInformationLabel),
  country: Yup.string()
    .nullable()
    .required(lang.missingInformationLabel),
  streetAddress: Yup.string()
    .nullable()
    .required(lang.missingInformationLabel),
  city: Yup.string()
    .nullable()
    .required(lang.missingInformationLabel),
  state: Yup.string()
    .nullable()
    .required(lang.missingInformationLabel),
  zip: Yup.string()
    .nullable()
    .required(lang.missingInformationLabel),
});

const fileSchema = Yup.object().shape({
  fileName: Yup.string(),
  documentUrl: Yup.string(),
});

export const projectDetailsFormSchema = Yup.object().shape({
  project: projectNameAndAddressSchema,
  homeOwnerId: Yup.string().nullable(),
  projectDocuments: Yup.array().of(fileSchema),
  cost: Yup.number().transform((v, o) => o === '' ? null : v)
    .typeError(lang.onlyPositiveNumberAllowed).positive(lang.onlyPositiveNumberAllowed).nullable(true).required(lang.missingInformationLabel),
  escrow: Yup.boolean(),
});

export const contractor = Yup.object().shape({
  name: Yup.string()
    .max(maxCharactersAllowed, `${lang.maxCharactersAllowedIs} ${maxCharactersAllowed}`)
    .matches(notAllowSpecialCharcters, lang.incorrectCharactersText)
    .nullable()
    .required(lang.missingInformationLabel),
  percentageOverall: Yup.number()
    .max(100, lang.maxAllowedHundred)
    .typeError(lang.onlyPositiveNumberAllowed)
    .positive(lang.onlyPositiveNumberAllowed).nullable(),
});

export const contractorMilestone = Yup.object().shape({
  totalCost: Yup.string().required(lang.fieldRequired).nullable(),
  milestones: Yup.array().of(contractor),
});
