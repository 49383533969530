import {
  CardRow, CustomMaskedField,
} from 'domains/accountSetup/onboarding/styles';
import lang from 'lang/en';
import {
  MediumControlledInputField, CustomControlledDatepicker, ErrorMessage, ErrorText,
} from 'domains/project/addProject/contract/styles';
import { InputSpanText } from 'domains/project/addProject/details/projectCost/styles';
import { useEffect, useMemo, useState } from 'react';
import { useWindowDimensions } from 'hooks/useWindowDimensions';
import { dateStringFormat, defaultDateFormat, defaultDateFormatString } from 'utils/dateFormats';
import { RadioOptionFields } from '../../../common/radioOptionFields';
import { FloridaCommonTypes } from './types';

export const JobExceeds = ({
  control, setValue, errors, clearErrors, getValues,
} : FloridaCommonTypes) => {
  const { contract: { homeowner: { modal: { form: { floridaFields } } } } } = lang;
  const { screenSize } = useWindowDimensions();
  const [isJobExceeds, setJobExceeds] = useState<boolean | null>(null);
  const [selectedDate, setSelectedDate] = useState({
    selectedPublicLiabilityExpiryDay: null,
    selectedWorkCompensationLiabilityExpiryDay: null,
  });

  const handleDateChange = (date: Date | string, dateField: string, selectedDateKey: string) => {
    if (date) {
      setValue(dateField, dateStringFormat(date, defaultDateFormatString), { shouldValidate: true });
      setSelectedDate({
        ...selectedDate,
        [selectedDateKey]: defaultDateFormat(date),
      });
    }
  };

  useEffect(() => {
    setJobExceeds(getValues('extraData.jobExceeds5000'));
  }, [getValues('extraData.jobExceeds5000')]);

  const handleJobExceedsAction = (value: boolean) => {
    setJobExceeds(value);
    if (!value) {
      setValue('extraData.publicLiabilityInsPolicyNum', '');
      setValue('extraData.publicLiabilityInsExpirationDate', '');
      setValue('extraData.publicLiabilityInsLiabilityLimit', '');
      setValue('extraData.workerCompensationInsPolicyNum', '');
      setValue('extraData.workerCompensationInsExpirationDate', '');
      setValue('extraData.workerCompensationInsLiabilityLimit', '');
    }
  };

  const insuranceDetails = useMemo(() => {
    if (isJobExceeds) {
      return (
        <>
          <CardRow>
            <MediumControlledInputField
              control={control}
              labelText={floridaFields.jobExceeds.publicLiabilityInsurancePolicy}
              name="extraData.publicLiabilityInsPolicyNum"
              errorIconPosition={screenSize === 'mobile' ? '91%' : '343px'}
            />
          </CardRow>
          <CardRow>
            <CustomControlledDatepicker
              selectedDate={selectedDate.selectedPublicLiabilityExpiryDay}
              control={control}
              name="extraData.publicLiabilityInsExpirationDate"
              labelText={floridaFields.jobExceeds.publicLiabilityInsuranceExpiryDate}
              placeholderText={floridaFields.jobExceeds.expiryDatePlaceholderText}
              setFieldValue={(date) => { handleDateChange(date, 'extraData.publicLiabilityInsExpirationDate', 'selectedPublicLiabilityExpiryDay'); }}
              errorIconPosition={screenSize === 'mobile' ? '91%' : '343px'}
            />
          </CardRow>
          <CardRow>
            <CustomMaskedField
              maxWidth="372px"
              control={control}
              name="extraData.publicLiabilityInsLiabilityLimit"
              labelText={floridaFields.jobExceeds.publicLiabilityInsuranceLimit}
              placeholder="0.00"
              iconPrefix={<InputSpanText>$</InputSpanText>}
              iconPostfix={<InputSpanText>{lang.currencyUsd}</InputSpanText>}
              prefixAlignment={{ top: '34px', left: '12px' }}
              inputpadding="9px 38px 9px 24px"
              errorIconPosition={screenSize === 'mobile' ? '91%' : '344px'}
            />
          </CardRow>
          <CardRow>
            <MediumControlledInputField
              control={control}
              labelText={floridaFields.jobExceeds.workerCompensationInsurancePolicy}
              name="extraData.workerCompensationInsPolicyNum"
              errorIconPosition={screenSize === 'mobile' ? '91%' : '343px'}
            />
          </CardRow>
          <CardRow>
            <CustomControlledDatepicker
              selectedDate={selectedDate.selectedWorkCompensationLiabilityExpiryDay}
              control={control}
              name="extraData.workerCompensationInsExpirationDate"
              labelText={floridaFields.jobExceeds.workerCompensationInsuranceExpiryDate}
              placeholderText={floridaFields.jobExceeds.expiryDatePlaceholderText}
              setFieldValue={(date) => { handleDateChange(date, 'extraData.workerCompensationInsExpirationDate', 'selectedWorkCompensationLiabilityExpiryDay'); }}
              errorIconPosition={screenSize === 'mobile' ? '91%' : '343px'}
            />
          </CardRow>
          <CardRow>
            <CustomMaskedField
              maxWidth="372px"
              control={control}
              name="extraData.workerCompensationInsLiabilityLimit"
              labelText={floridaFields.jobExceeds.workerCompensationInsuranceLiabilityLimit}
              placeholder="0.00"
              iconPrefix={<InputSpanText>$</InputSpanText>}
              iconPostfix={<InputSpanText>{lang.currencyUsd}</InputSpanText>}
              prefixAlignment={{ top: '34px', left: '12px' }}
              inputpadding="9px 38px 9px 24px"
              errorIconPosition={screenSize === 'mobile' ? '91%' : '344px'}
            />
          </CardRow>
        </>
      );
    }
  }, [isJobExceeds]);

  return (
    <>
      <RadioOptionFields
        control={control}
        fieldName="extraData.jobExceeds5000"
        fieldText={floridaFields.jobExceeds}
        setValue={setValue}
        updateValue={(value) => handleJobExceedsAction(value)}
        clearErrors={clearErrors}
        getValues={getValues}
      />
      {insuranceDetails}
      <ErrorMessage margin="0 0 0 10px"><ErrorText>{errors?.extraData?.jobExceeds5000?.message || ''}</ErrorText></ErrorMessage>
    </>
  );
};
