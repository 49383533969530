import runtimeEnv from '@mars/heroku-js-runtime-env';
import { Modal } from 'common/modal';
import { CardDescription, CardTitle } from 'domains/accountSetup/onboarding/styles';
import ContractTile from 'domains/project/addProject/contract/homeowner/contractTile';
import SendContractModal from 'domains/project/addProject/contract/homeowner/sendContractModal';
import Signature from 'domains/project/addProject/contract/homeowner/signature/signature';
import { useUpdateSubcontractorContract } from 'domains/project/addProject/contract/homeowner/signature/useUpdateSubcontractorContract';
import { Section, WideButton } from 'domains/project/addProject/contract/homeowner/styles';
import { SignatureState } from 'domains/project/addProject/contract/homeowner/types';
import { useDownloadContractPreview } from 'domains/project/addProject/contract/subcontractor/useDownloadContractPreview';
import { UserDetailsHeader } from 'domains/project/addProject/contract/styles';
import SubcontractorContractForm from 'domains/project/addProject/contract/subcontractor/form/subcontractorContractForm';
import { useSendSubcontractorContract } from 'domains/project/addProject/contract/subcontractor/useSendSubcontractorContract';
import { SubcontractorContractProps } from 'domains/project/addProject/contract/types';
import lang from 'lang/en';
import {
  useEffect, useMemo, useRef, useState,
} from 'react';
import { isEmpty } from 'lodash';

const signFlowStrings = lang.contract.homeowner.signFlow;

const ContractorContractView = ({
  projectData,
  triggerDataUpdate,
  subcontractorDetails,
  subcontractorData,
  owner,
}: SubcontractorContractProps) => {
  const [isSendContractOpen, setIsSendContractOpen] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [signatureState, setSignatureState] = useState<SignatureState>('empty');
  const subcontractorContract = subcontractorData;
  const subcontractorFullName = `${subcontractorDetails?.firstName} ${subcontractorDetails?.lastName}`;
  const { REACT_APP_IMAGE_URL } = runtimeEnv();
  const signatureRef = useRef<any>();
  const {
    loading: updateContractLoading,
    updateSubcontractorContract,
  } = useUpdateSubcontractorContract();

  const {
    loading,
    downloadContractPreview,
  } = useDownloadContractPreview();

  const {
    loading: sendContractLoading,
    isSend,
    sendSubcontractorContract,
  } = useSendSubcontractorContract();

  const handleSignatureUploadFinished = async (signatureUrl: string) => {
    updateSubcontractorContract(subcontractorContract.id, 'contractorSignature', signatureUrl);
    setSignatureState('submitted');
  };

  const handleSendContract = async () => {
    await sendSubcontractorContract(subcontractorContract.id);
    triggerDataUpdate();
  };

  useEffect(() => {
    if (isSend && !loading) {
      setIsSendContractOpen(false);
    }
  }, [isSend]);

  useEffect(() => {
    if (isEmpty(subcontractorContract?.contractorSignatureUrl)) {
      setSignatureState('empty');
      signatureRef?.current?.click();
    }
  }, [subcontractorContract]);

  const preloadedImageUrl = useMemo(() => {
    if (!isEmpty(subcontractorContract?.contractorSignatureUrl)) {
      return `${REACT_APP_IMAGE_URL}/${subcontractorContract?.contractorSignatureUrl}`;
    }
    return '';
  }, [subcontractorContract]);

  return (
    <>
      <SubcontractorContractForm
        triggerDataUpdate={triggerDataUpdate}
        projectData={projectData}
        owner={owner}
        subcontractorData={subcontractorData}
        subcontractorDetails={subcontractorDetails}
        isOpen={isEditOpen}
        setIsOpen={setIsEditOpen}
      />
      <UserDetailsHeader>
        {subcontractorFullName}
      </UserDetailsHeader>
      <Section>
        <CardTitle marginBottom="0">
          {signFlowStrings.step1.header}
        </CardTitle>
        <CardDescription>
          {signFlowStrings.step1.description}
        </CardDescription>
        <ContractTile
          contractFileName={subcontractorContract?.fileName}
          downloadLoading={loading}
          onEditClick={() => setIsEditOpen(true)}
          onDownloadClick={() => downloadContractPreview(subcontractorContract?.id as string)}
        />
      </Section>
      <Section>
        <CardTitle marginBottom="0">
          {signFlowStrings.step2.header}
        </CardTitle>
        <CardDescription>
          {signFlowStrings.step2.description}
        </CardDescription>
        <Signature
          signatureState={signatureState}
          setSignatureState={setSignatureState}
          onSignatureUploadFinished={handleSignatureUploadFinished}
          preloadedImageUrl={preloadedImageUrl}
          ref={signatureRef}
        />
      </Section>
      <Section>
        <CardTitle marginBottom="0">
          {signFlowStrings.step3.header}
        </CardTitle>
        <CardDescription>
          {signFlowStrings.step3.description}
        </CardDescription>
        <WideButton
          width="full"
          type="button"
          variant="primary"
          label={signFlowStrings.step3.sendContractButtonLabel}
          onClick={() => setIsSendContractOpen(true)}
          state={(signatureState === 'submitted' || signatureState === 'preloaded') ? 'active' : 'disabled'}
        />
      </Section>
      <Modal isOpen={isSendContractOpen}>
        <SendContractModal
          sendContractLoading={sendContractLoading}
          handleSend={handleSendContract}
          handleClose={() => setIsSendContractOpen(false)}
        />
      </Modal>
    </>
  );
};

export default ContractorContractView;
