import { MarginTop, MaxWidthContainer, TitleText } from 'domains/project/addProject/contract/styles';
import lang from 'lang/en';
import { RadioOptionFields } from '../../../common/radioOptionFields';
import type { FloridaCommonTypes } from './types';

export const HomeSolicitation = ({
  control, setValue, errors, clearErrors, getValues,
} :FloridaCommonTypes) => {
  const { contract: { homeowner: { modal: { form: { floridaFields: { homesolicitation } } } } } } = lang;
  return (
    <MaxWidthContainer>
      <TitleText>{homesolicitation.heading1}</TitleText>
      <TitleText>{homesolicitation.heading2}</TitleText>
      <MarginTop marginTop="25px" />
      <RadioOptionFields
        control={control}
        fieldName="extraData.placeOfBusinessOfContractor"
        fieldText={homesolicitation.contractorBusinessSales}
        setValue={setValue}
        errorMsg={errors?.extraData?.placeOfBusinessOfContractor?.message || ''}
        marginBottom="20px"
        clearErrors={clearErrors}
        getValues={getValues}
      />
      <RadioOptionFields
        control={control}
        fieldName="extraData.stateFairOrCommercialExhibit"
        fieldText={homesolicitation.commericalExhibit}
        setValue={setValue}
        errorMsg={errors?.extraData?.stateFairOrCommercialExhibit?.message || ''}
        marginBottom="20px"
        clearErrors={clearErrors}
        getValues={getValues}
      />
      <RadioOptionFields
        control={control}
        fieldName="extraData.residenceOfOwner"
        fieldText={homesolicitation.residenceVisitInvitation}
        setValue={setValue}
        errorMsg={errors?.extraData?.residenceOfOwner?.message || ''}
        marginBottom="20px"
        clearErrors={clearErrors}
        getValues={getValues}
      />
    </MaxWidthContainer>
  );
};
