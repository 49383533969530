import DeleteItem from 'common/deleteItem';
import { CompanyOwnerDTO } from 'domains/accountSetup/onboarding/company/types';
import { CardDescription, CardTitle } from 'domains/accountSetup/onboarding/styles';
import ContractTile from 'domains/project/addProject/contract/homeowner/contractTile';
import { Section } from 'domains/project/addProject/contract/homeowner/styles';
import { UserDetailsHeader } from 'domains/project/addProject/contract/styles';
import { ListItem, UnorderedList } from 'domains/project/addProject/contract/subcontractor/contractView/styles';
import SubcontractorContractForm from 'domains/project/addProject/contract/subcontractor/form/subcontractorContractForm';
import { useDeleteSubcontractorContract } from 'domains/project/addProject/contract/subcontractor/useDeleteSubcontractorContract';
import { useDownloadContractPreview } from 'domains/project/addProject/contract/subcontractor/useDownloadContractPreview';
import { SubcontractorDataDTO } from 'domains/project/addProject/details/userSearchAndDisplayCard/types';
import { PreloadedDataType } from 'domains/project/addProject/types';
import lang from 'lang/en';
import { isEmpty } from 'lodash';
import { useMemo, useState } from 'react';

const sendContractStrings = lang.contract.subcontractors.sendToSubcontractor;
const deleteContractStrings = lang.contract.subcontractors.signFlow.deleteContract;

export type SubcontractorSendContractView = {
  subcontractorData?: any;
  projectData?: PreloadedDataType;
  owner: CompanyOwnerDTO;
  triggerDataUpdate: () => void;
  subcontractorDetails: SubcontractorDataDTO;
}

const SendContractView = ({
  owner,
  subcontractorData,
  triggerDataUpdate,
  projectData,
  subcontractorDetails,
}: SubcontractorSendContractView) => {
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const subcontractorContract = subcontractorData;
  const isContractSentByContractor = !!subcontractorContract?.contractorSentAt;
  const isContractSignedBySubcontractor = !!subcontractorContract?.subcontractorSignedAt;
  const isContractSentBySubcontractor = !!subcontractorContract?.subcontractorSentAt;
  const {
    loading: deleteContractLoading,
    deleteSubcontractorContract,
  } = useDeleteSubcontractorContract();

  const subcontractorFullName = `${subcontractorDetails?.firstName} ${subcontractorDetails?.lastName}`;

  const {
    loading,
    downloadContractPreview,
  } = useDownloadContractPreview();

  const handleDeleteClick = async () => {
    await deleteSubcontractorContract(subcontractorContract?.id);
    triggerDataUpdate();
  };

  const validateDownloadRequest = useMemo(() => {
    let isValidDownloadRequest = true;
    if (subcontractorContract) {
      isValidDownloadRequest = isEmpty(subcontractorContract.contractCreatedAt) && isEmpty(subcontractorContract.contractUrl);
    }
    return isValidDownloadRequest;
  }, [subcontractorContract]);

  const getOnDeleteClick = () => {
    if (!isContractSignedBySubcontractor) {
      return () => setIsDeleteOpen(true);
    }
  };

  const getOnEditClick = () => {
    if (!isContractSignedBySubcontractor) {
      return () => setIsEditOpen(true);
    }
  };

  return (
    <>
      <SubcontractorContractForm
        triggerDataUpdate={triggerDataUpdate}
        projectData={projectData}
        subcontractorData={subcontractorData}
        owner={owner}
        subcontractorDetails={subcontractorDetails}
        isOpen={isEditOpen}
        setIsOpen={setIsEditOpen}
      />
      <DeleteItem
        handleCancel={() => setIsDeleteOpen(false)}
        handleDelete={handleDeleteClick}
        heading={deleteContractStrings.header}
        subText={deleteContractStrings.description}
        isOpen={isDeleteOpen}
      />
      <UserDetailsHeader>
        {subcontractorFullName}
      </UserDetailsHeader>
      <Section>
        <CardTitle marginBottom="0">
          {sendContractStrings.header}
        </CardTitle>
        <UnorderedList>
          {subcontractorContract?.contractLog.map((listItem: string) => {
            return (
              <ListItem key={listItem}>
                {listItem}
              </ListItem>
            );
          })}
        </UnorderedList>
        <CardDescription>
          {sendContractStrings.description}
        </CardDescription>
        <ContractTile
          contractFileName={subcontractorContract?.fileName}
          onEditClick={getOnEditClick()}
          downloadLoading={loading}
          onDeleteClick={getOnDeleteClick()}
          onDownloadClick={() => downloadContractPreview(subcontractorContract?.id as string)}
          validateDownloadRequest={validateDownloadRequest}
          contractDocumentUrl={subcontractorContract?.contractUrl}
        />
      </Section>
    </>
  );
};

export default SendContractView;
