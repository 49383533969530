import * as Yup from 'yup';
import lang from 'lang/en';

const milestoneValidationSchema = Yup.object().shape({
  name: Yup.string().nullable(),
  completed: Yup.boolean().nullable(),
});

const subcontractorIdIsValidValidation = (subcontractorId: string) => {
  return subcontractorId !== '' ? Yup.string().required(lang.fieldRequired) : Yup.string().nullable();
};

const subcontractorValidationSchema = Yup.object().shape({
  subcontractorId: Yup.string(),
  cost: Yup.string().nullable().when('subcontractorId', subcontractorIdIsValidValidation),
  milestone0Name: Yup.string().nullable().when('subcontractorId', subcontractorIdIsValidValidation),
  milestone0Completed: Yup.boolean().nullable(),
  milestone1Name: Yup.string().nullable().when('subcontractorId', subcontractorIdIsValidValidation),
  milestone1Completed: Yup.boolean().nullable(),
  milestone2Name: Yup.string().nullable().when('subcontractorId', subcontractorIdIsValidValidation),
  milestone2Completed: Yup.boolean().nullable(),
});

export const subcontractorsValidationSchema = Yup.object().shape({
  subContractors: Yup.array().of(subcontractorValidationSchema),
});
