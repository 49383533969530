import type { LabelledRadioboxProps } from './types';
import {
  RadioBoxContainer, RadioBoxInput, RadioBoxLabel, RadioBoxContentSection,
  RadioBoxBtnSection, RadioBoxSpan,
} from './styles';

export const RadioBox = ({
  field, value, content, id, errorMessage = '',
}: LabelledRadioboxProps) => {
  const isError = errorMessage.length > 0;
  return (
    <RadioBoxContainer>
      <RadioBoxInput type="radio" id={id} {...field} value={value} $isError={isError} />
      <RadioBoxLabel htmlFor={id}>
        <RadioBoxContentSection $isError={isError}>
          {content}
        </RadioBoxContentSection>
        <RadioBoxBtnSection $isError={isError}>
          <RadioBoxSpan $isError={isError}></RadioBoxSpan>
        </RadioBoxBtnSection>
      </RadioBoxLabel>
    </RadioBoxContainer>
  );
};

export default RadioBox;
