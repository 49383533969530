import { MarginTop, MaxWidthContainer, TitleText } from 'domains/project/addProject/contract/styles';
import lang from 'lang/en';
import { RadioOptionFields } from '../../../common/radioOptionFields';
import type { OhioCommonTypes } from './types';

export const HomeSolicitation = ({
  control, setValue, errors, clearErrors, getValues,
} :OhioCommonTypes) => {
  const { contract: { homeowner: { modal: { form: { ohioFields: { ohioStatuteLimitations } } } } } } = lang;
  return (
    <MaxWidthContainer>
      <TitleText>{ohioStatuteLimitations.heading1}</TitleText>
      <TitleText>{ohioStatuteLimitations.heading2}</TitleText>
      <MarginTop marginTop="25px" />
      <RadioOptionFields
        control={control}
        fieldName="extraData.contractWorkOnPrincipal"
        fieldText={ohioStatuteLimitations.contractWorkOnPrincipal}
        setValue={setValue}
        errorMsg={errors?.extraData?.contractWorkOnPrincipal?.message || ''}
        marginBottom="20px"
        clearErrors={clearErrors}
        getValues={getValues}
      />
      <RadioOptionFields
        control={control}
        fieldName="extraData.rightToRescind"
        fieldText={ohioStatuteLimitations.rightToRescind}
        setValue={setValue}
        errorMsg={errors?.extraData?.rightToRescind?.message || ''}
        marginBottom="20px"
        clearErrors={clearErrors}
        getValues={getValues}
      />
    </MaxWidthContainer>
  );
};
