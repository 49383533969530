import { useEffect } from 'react';
import { SortingRule, useSortBy, useTable } from 'react-table';
import { TableHeadSection } from './tableHeadSection';
import { TableBodySection } from './tableBodySection';
import { Table } from './styles';

type DataTableType = {
  columns: Array<{ Header?: string; width?: number; accessor: string}> | [];
  data?: any | [];
  onColumnSort?: (data: Array<SortingRule<{id:string; desc:boolean}>> | []) => void;
  isTransparentBg?: boolean;
  bodyPadding?: string;
}

export const DataTable = ({
  columns, data, onColumnSort, isTransparentBg, bodyPadding,
}: DataTableType) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state: { sortBy },
  } = useTable({
    columns,
    data,
    manualSortBy: true,
  }, useSortBy);

  useEffect(() => {
    if (onColumnSort) {
      onColumnSort(sortBy);
    }
  }, [onColumnSort, sortBy]);
  return (
    <Table {...getTableProps()}>
      <TableHeadSection headerGroups={headerGroups} isTransparentBg={isTransparentBg} />
      <TableBodySection bodyPadding={bodyPadding} getTableBodyProps={getTableBodyProps} rows={rows} prepareRow={prepareRow} />
    </Table>
  );
};

DataTable.defaultProps = {
  isTransparentBg: false,
  bodyPadding: '15px 70px',
};

export default DataTable;
