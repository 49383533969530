import {
  CardTitle, Card, CardRow,
} from 'domains/accountSetup/onboarding/styles';
import lang from 'lang/en';
import { NarrowNumberMaskedFilled, ViewMilestonsContainer } from 'domains/project/addProject/styles';
import { useGetField } from 'domains/project/addProject/details/userSearchAndDisplayCard/useGetField';
import { useWindowDimensions } from 'hooks/useWindowDimensions';
import { MilestoneContainer } from '../styles';
import { MilestoneData } from './milestoneData';
import type { ViewMilestonesProps } from '../types';

export const ViewMilestones = ({
  preloadedData, isEscrow, contractorId, projectId, isPendingProject,
} : ViewMilestonesProps) => {
  const { getField } = useGetField();
  const { screenSize } = useWindowDimensions();
  const milestoneData = preloadedData?.milestones?.map((item, index) => {
    return (
      <MilestoneData
        index={index}
        milestone={item}
        isEscrow={isEscrow}
        contractorId={contractorId}
        projectId={projectId}
        isPendingProject={isPendingProject}
      />
    );
  });
  return (
    <MilestoneContainer>
      <Card screenSize={screenSize}>
        <ViewMilestonsContainer screenSize={screenSize}>
          <CardTitle>{lang.milestones.contractorTotalMilestone}</CardTitle>
          <CardRow>
            <NarrowNumberMaskedFilled
              disabled
              numberPrefix="$"
              labelText={lang.milestones.contractorTotalCost}
              field={{ ...getField('totalCost', preloadedData?.totalCost) }}
            />
          </CardRow>
        </ViewMilestonsContainer>
      </Card>
      {milestoneData}
    </MilestoneContainer>

  );
};
