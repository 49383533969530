import { Button } from 'designSystem';
import { CardDescription } from 'domains/accountSetup/onboarding/styles';
import ContractView from 'domains/project/addProject/contract/subcontractor/contractView';
import { PreloadedDataType, SubcontractorDTO } from 'domains/project/addProject/types';
import lang from 'lang/en';
import { getAuthInfo } from 'utils/helpers';
import AddContractorButton from '../addContractorButton';
import { SubcontractorContractsProps } from '../types';
import SubcontractorContract from './subcontractorContract';

const AddSubcontractorContracts = ({
  subcontractorsDetails,
  owner,
  projectData,
  triggerDataUpdate,
}: SubcontractorContractsProps) => {
  const {
    userId,
  } = getAuthInfo();
  const isSubcontractors = subcontractorsDetails.length !== 0;
  return isSubcontractors
    ? (
      <>
        {subcontractorsDetails
          .map((subcontractorDetails) => {
            const subcontractorToRender = projectData?.subContractors?.find((subcontractor) => subcontractor.id === subcontractorDetails.subcontractorId);
            const isContractCreated = subcontractorToRender?.subcontractorContract?.length !== 0;
            if ((userId === projectData?.contractorId || subcontractorDetails?.id === userId) && isContractCreated) {
              return (
                <ContractView
                  projectData={projectData as PreloadedDataType}
                  key={subcontractorDetails.subcontractorId}
                  subcontractorDetails={subcontractorDetails}
                  triggerDataUpdate={triggerDataUpdate}
                  subcontractorData={subcontractorToRender as SubcontractorDTO}
                />
              );
            }
            if (userId === projectData?.contractorId && !isContractCreated) {
              return (
                <SubcontractorContract
                  key={subcontractorDetails.subcontractorId}
                  subcontractorDetails={subcontractorDetails}
                  owner={owner}
                  projectData={projectData}
                  subcontractorData={subcontractorToRender}
                  triggerDataUpdate={triggerDataUpdate}
                />
              );
            }
            return null;
          })}
      </>
    )
    : (
      <>
        <CardDescription>
          {lang.contract.subcontractors.noSubcontractorsDescriptionText}
        </CardDescription>
        <Button type="button" variant="primary" customRender={<AddContractorButton />} state={isSubcontractors ? 'active' : 'disabled'} />
      </>
    );
};

export default AddSubcontractorContracts;
