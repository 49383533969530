import { Modal } from 'common/modal';
import { Button } from 'designSystem';
import {
  ButtonRow, Container, Content, Header,
} from 'domains/accountSetup/onboarding/styles';
import { useWindowDimensions } from 'hooks/useWindowDimensions';
import lang from 'lang/en';
import type { DeleteItemProps } from './types';

export const DeleteItem = ({
  heading, subText, handleCancel, handleDelete, isOpen,
}: DeleteItemProps) => {
  const { screenSize } = useWindowDimensions();
  return (
    <Modal isOpen={isOpen}>
      <Container screenSize={screenSize}>
        <Header>
          {heading}
        </Header>
        <Content screenSize={screenSize}>
          {subText}
        </Content>
        <ButtonRow screenSize={screenSize}>
          <Button type="button" variant="secondary" label={lang.cancelButtonLabel} onClick={handleCancel} />
          <Button name="deleteButton" type="button" variant="danger" label={lang.deleteButtonLabel} onClick={handleDelete} />
        </ButtonRow>
      </Container>
    </Modal>
  );
};

export default DeleteItem;
