import styled from 'styled-components';
import { textSm } from 'designSystem/typings';
import { gray700 } from 'designSystem/colors';
import { ScreenSize } from 'hooks/useWindowDimensions';

type WithScreenSize = {
  screenSize?: ScreenSize;
}
export const UpdateMilestoneSection = styled.div``;

export const RadioSection = styled.div`
  display: flex;
`;

export const CompletedText = styled.p`
 ${textSm} 
 line-height: 20px;
 font-weight: 400;
 color: ${gray700};
 margin-bottom: 8px;
`;

export const MilestoneContainer = styled.div`
 margin-bottom: 30px;
`;

export const ButtonContainer = styled.div`
 width: 320px;
 margin-top: 40px;
`;

export const FileUploadSection = styled.div`
 margin-top: 37px;
`;

export const FilesSection = styled.div`
 margin-top: 20px;
`;

export const Margin = styled.div<{margin:string}>`
 margin: ${({ margin }) => margin}
`;

export const InspectionSection = styled.div<{margin?: string}>`
 margin: ${({ margin }) => margin};
`;

export const InspectionRequestSection = styled.div`
  margin-top: 30px;
  & form {
    width: 320px;
  }
`;

export const SelectField = styled.div``;

export const FileContainer = styled.div<WithScreenSize>`
 min-width: ${({ screenSize }) => screenSize === 'desktop' ? '730px' : '340px'};
 margin-top: 24px;
`;

export const ApproveCTA = styled.div`
 max-width: 320px;
 margin-top: 23px;
`;

export const ApprovalSection = styled.div<{margin?: string}>`
 margin: ${({ margin }) => margin};
`;
