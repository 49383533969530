import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useWindowDimensions } from 'hooks/useWindowDimensions';
import lang from 'lang/en';
import { useEffect, useState } from 'react';
import { ActionButtonsContainer } from 'domains/accountSetup/onboarding/styles';
import { Button } from 'designSystem';
import {
  getButtonState,
} from 'utils/helpers';
import { useNavigate, useParams } from 'react-router-dom';
import type { File } from 'designSystem/fileUpload/types';
import { Header, TitleHeading } from '../styles';
import { projectDetailsFormSchema } from '../validationSchema';
import { NameAndAddress } from './nameAndAddress';
import { ProjectPreloadedData, ProjectDetails } from '../types';
import { useSaveProjectDetailsData } from '../useSaveProjectDetailsData';
import UserSearchAndDisplayCard from './userSearchAndDisplayCard/userSearchAndDisplayCard';
import { ProjectCost } from './projectCost';
import Blueprints from './blueprints/blueprints';

type DetailsProps = {
  preloadedData?: ProjectPreloadedData;
  onFormSubmitted: () => void;
  setProjectId: (id: number) => void;
  handleSubmitProjectValidation: (data: Array<{[key:string] : boolean}>) => void;
  projectStatus?: number;
  contractorId?: string;
}

const Details = ({
  preloadedData, onFormSubmitted, setProjectId, handleSubmitProjectValidation,
  projectStatus, contractorId,
}: DetailsProps) => {
  const {
    loading,
    saveProjectDetailsData,
    updateProjectDetailsData,
    isSaved,
    response,
  } = useSaveProjectDetailsData();
  const { id } = useParams<{ id: string }>();
  useEffect(() => {
    if (id) {
      setProjectId(parseInt(id, 10));
    }
  }, [id]);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [documents, setDocuments] = useState<Array<File>>([]);
  const navigate = useNavigate();
  const resolver = { resolver: yupResolver(projectDetailsFormSchema) };
  const {
    handleSubmit, control, formState, reset, trigger, setValue, getValues,
  } = useForm(
    {
      ...resolver,
      mode: 'onChange',
      defaultValues: {
        project: {
          name: '',
          country: 'United States',
          streetAddress: '',
          city: '',
          state: '',
          zip: '',
        },
        homeOwnerId: null,
        cost: null,
        projectDocuments: [],
        escrow: true,
      },
    },
  );

  const updateDocuments = (newDocuments: Array<File>) => {
    setValue('projectDocuments', newDocuments);
    setDocuments(newDocuments);
  };

  useEffect(() => {
    if (preloadedData) {
      const { id: preloadedId, projectDocuments, ...restPreloadedData } = preloadedData;
      const documentsListing = projectDocuments.map((item:any) => ({ fileName: item.fileName, documentUrl: item.documentUrl }));
      reset({ ...restPreloadedData, projectDocuments: documentsListing });
      setDocuments(documentsListing);
      setIsDataLoaded(true);
      trigger();
    }
  }, [preloadedData]);

  const onSubmit = (data: ProjectDetails) => {
    handleSubmitProjectValidation(
      [{
        homeowner: !!data.homeOwnerId,
        cost: !!data.cost,
      }],
    );
    if (isDataLoaded) {
      updateProjectDetailsData({ ...data, id: preloadedData?.id as string });
      return;
    }
    saveProjectDetailsData(data);
  };

  const handleBack = () => {
    navigate('/projects');
  };

  useEffect(() => {
    if (isSaved && response && response.id) {
      setProjectId(response.id);
      onFormSubmitted();
    }
  }, [isSaved, response]);

  const { isDirty, isValid } = formState;

  const { screenSize } = useWindowDimensions();

  const handleUserFound = (user: any) => {
    setValue('homeOwnerId', user.id);
  };
  useEffect(() => {
    if (getValues('cost') === '') {
      setValue('cost', null);
    }
  }, [getValues('cost')]);

  const handleUserNotFound = () => {
    // eslint-disable-next-line no-console
    console.log('not found');
  };

  return (
    <>
      <TitleHeading><Header screenSize={screenSize}>{lang.projectDetailsStepLabel}</Header></TitleHeading>
      <form onSubmit={handleSubmit(onSubmit)}>
        <NameAndAddress control={control} projectStatus={projectStatus} contractorId={contractorId} />
        <UserSearchAndDisplayCard
          userType="homeowner"
          userNotFoundMessage={lang.homeownerNotFound}
          description={lang.homeownerDetailsDescription}
          header={lang.homeownerDetailsLabel}
          userId={preloadedData?.homeOwnerId}
          onUserFound={handleUserFound}
          onUserNotFound={handleUserNotFound}
        />
        <Blueprints projectDocuments={documents} updateProjectDocuments={updateDocuments} />
        <ProjectCost control={control} projectStatus={projectStatus} contractorId={contractorId} />
        <ActionButtonsContainer>
          <Button data-testid="back-button" label={lang.backLabel} type="button" variant="secondary" onClick={handleBack} />
          <Button data-testid="next-button" type="submit" variant="primary" state={getButtonState(isDirty || isDataLoaded, isValid, loading)} label={lang.nextLabel} />
        </ActionButtonsContainer>
      </form>
    </>
  );
};

export default Details;
