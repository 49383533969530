import { useCallback, useEffect } from 'react';
import { useSaveForm } from 'utils/form/useSaveForm';
import { signUpUrl } from 'utils/network/endpoints';
import { notifications } from 'designSystem/toastNotifications/toastNotificationEmitter';
import { useNavigate } from 'react-router-dom';
import { RegisterFormData, UserFormDTO } from '../types';

const useSignup = (): {
  signUp: (data: RegisterFormData) => Promise<void>;
  loading: boolean;
  isSaved: boolean;
  error: string;
} => {
  const {
    loading, error, save, isSaved,
  } = useSaveForm<UserFormDTO>();
  const navigate = useNavigate();

  useEffect(() => {
    if (error) {
      notifications.error(error);
    }
  }, [error]);

  useEffect(() => {
    if (isSaved && !error) {
      navigate('/account-confirmation');
    }
  }, [isSaved, error]);

  const signUp = useCallback(
    async (data: UserFormDTO) => {
      const url = signUpUrl;

      await save(url, data, 'post');
    },
    [save],
  );

  return {
    loading,
    error,
    signUp,
    isSaved,
  };
};

export default useSignup;
