import styled from 'styled-components';
//import { typings, colors } from 'designSystem';

export const Loading = styled.div`
  position: fixed;
  background: rgba(255, 255, 255, 0.6);
  width: 100%;
  top: 0;
  left: 0;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999999;
`;

export const LoaderDiv = styled.div`
  width: 60px;
  & svg {
    width: 100%;
  }
`;
