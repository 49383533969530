import { Card, CardTitle } from 'domains/accountSetup/onboarding/styles';
import { useWindowDimensions } from 'hooks/useWindowDimensions';
import lang from 'lang/en';
import {
  CustomCardRow, MilestonesContainer, NarrowNumberMaskedInput,
} from 'domains/project/addProject/styles';
import { useGetField } from 'domains/project/addProject/details/userSearchAndDisplayCard/useGetField';
import { getAuthInfo, isHomeowner, isInspector } from 'utils/helpers';
import { UserDetails } from './userDetails';
import type { SubContractorViewMilestones } from '../types';
import { MilestoneData } from './milestoneData';

export const ViewMilestones = ({
  subContractorMilestone, index: subContractorIndex, projectStatus, contractorId, isEscrow, projectId,
}: SubContractorViewMilestones) => {
  const { screenSize } = useWindowDimensions();
  const { getField } = useGetField();
  const { userId } = getAuthInfo();
  const isContractor = userId === contractorId;
  const milestoneData = subContractorMilestone?.milestones.map((item, index) => {
    return (
      <MilestoneData
        subContractorIndex={subContractorIndex}
        index={index}
        projectStatus={projectStatus}
        contractorId={contractorId}
        subcontractorId={subContractorMilestone?.subContractorId}
        isEscrow={isEscrow}
        projectId={projectId}
        milestoneData={item}
      />
    );
  });

  return (
    <Card screenSize={screenSize}>
      <UserDetails
        userType="subcontractor"
        userId={subContractorMilestone.subContractorId}
        index={subContractorIndex}
        isContractor={isContractor}
      />
      <MilestonesContainer>
        <CardTitle>
          {lang.subcontractorMilestones.milestones.header}
        </CardTitle>
        <CustomCardRow>
          {
            (!isHomeowner() || !isInspector())
              && (
                <NarrowNumberMaskedInput
                  disabled
                  numberPrefix="$"
                  field={{ ...getField(`subContractors.${subContractorIndex}.cost` as const, subContractorMilestone?.totalCost) }}
                  labelText={lang.subcontractorMilestones.milestones.contractorCost}
                />
              )
          }

        </CustomCardRow>
        {milestoneData}
      </MilestonesContainer>
    </Card>
  );
};
