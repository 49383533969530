import Loader from 'designSystem/loader';
import Steps from 'designSystem/steps/steps';
import { Step } from 'designSystem/steps/types';
import { NavBar } from 'domains/navBar';
import Contracts from 'domains/project/addProject/contract';
import { Container, StepsContainer } from 'domains/project/addProject/styles';
import { SubmitProject } from 'domains/project/addProject/submit';
import { ProjectSuccess } from 'domains/project/addProject/success';
import { PreloadedDataType, ValidationType } from 'domains/project/addProject/types';
import { useFillExistingData } from 'domains/project/useFillExistingData';
import { useSubmitProjectValidation } from 'domains/project/useSubmitProjectValidation';
import { saveViewProjectValidation } from 'domains/project/addProject/data';
import { Centerlized } from 'domains/userAuth/styles';
import useScrollTop from 'hooks/scrollTop';
import { useStepsState } from 'hooks/useStepsState';
import { useBlockBrowserBackButton } from 'hooks/useBlockBrowserBackButton';

import { ReactElement, useEffect, useState } from 'react';
import {
  Route, Routes, useLocation, useNavigate, useParams,
} from 'react-router-dom';
import { useInactiveUserRedirection } from 'hooks/useInactiveUserRedirection';
import { isUserAuthourised } from 'utils/helpers';
import Details from './details';
import { getInitialSteps } from './helper';
import { ContractorMilestones, SubcontractorMilestones } from './milestones';
import ApproveMilestoneSuccess from './milestones/success/success';

const ProjectView = (): ReactElement => {
  const [initialSteps, setInitialStep] = useState<Array<Step>>([]);
  const {
    stepsState,
    currentStep,
    setCurrentStep,
  } = useStepsState(initialSteps);
  const {
    projectData,
    loader,
    setTriggerDataUpdate,
    setCurrentProjectId,
    currentProjectId,
  } = useFillExistingData(currentStep);
  const { blockBackButton } = useBlockBrowserBackButton();
  const { redirectInactiveUser } = useInactiveUserRedirection();
  const isActiveUser = isUserAuthourised();

  const { id: projectId } = useParams<{ id: string }>();

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!isActiveUser) {
      redirectInactiveUser();
    }
  });

  useEffect(() => {
    if (projectId) {
      setCurrentProjectId(parseInt(projectId, 10));
    }
  }, [projectId]);

  useEffect(() => {
    blockBackButton();
  }, []);

  useEffect(() => {
    if (currentProjectId && initialSteps) {
      const currentPath = initialSteps.find((item) => (`${item.path}${currentProjectId}` === location.pathname));
      setCurrentStep(currentPath?.step || 0);
    }
  }, [currentProjectId, initialSteps]);

  useEffect(() => {
    if (currentProjectId) {
      useScrollTop();
      const currentPath = initialSteps.find((item) => (item.step === currentStep));
      if (currentPath) {
        return navigate(`${currentPath.path}${currentProjectId}`);
      }
    }
  }, [currentStep, currentProjectId]);

  useEffect(() => {
    if (projectData) {
      const steps = getInitialSteps(projectData?.status, projectData?.contractorId);
      setInitialStep(steps);
    }
  }, [projectData?.contractorId, currentProjectId]);

  const {
    projectSubmitValidation,
    handleSubmitProjectValidation,
  } = useSubmitProjectValidation(projectData as PreloadedDataType, saveViewProjectValidation as ValidationType);

  return (
    <>
      {loader && <Loader />}
      <NavBar />
      <Centerlized>
        <Container>
          {location.pathname !== '/projects/view/success' && location.pathname !== '/projects/view/milestone/approval' && loader === false
            && (
              <StepsContainer>
                <Steps
                  setCurrentStep={setCurrentStep}
                  steps={stepsState}
                />
              </StepsContainer>
            )}

          <Routes>
            <Route path="details">
              <Route
                path=""
                element={(
                  <Details
                    setProjectId={(id) => setCurrentProjectId(id)}
                    onFormSubmitted={() => setCurrentStep(currentStep + 1)}
                    preloadedData={projectData?.details}
                    handleSubmitProjectValidation={handleSubmitProjectValidation}
                    projectStatus={projectData?.status}
                    contractorId={projectData?.contractorId}
                  />
                )}
              />
              <Route
                path=":id"
                element={(
                  <Details
                    setProjectId={(id) => setCurrentProjectId(id)}
                    onFormSubmitted={() => setCurrentStep(currentStep + 1)}
                    preloadedData={projectData?.details}
                    handleSubmitProjectValidation={handleSubmitProjectValidation}
                    projectStatus={projectData?.status}
                    contractorId={projectData?.contractorId}
                  />
                )}
              />
            </Route>
            <Route
              path="contractor-milestones/:id"
              element={(
                <ContractorMilestones
                  setProjectId={(id) => setCurrentProjectId(id)}
                  onFormSubmitted={() => setCurrentStep(currentStep + 1)}
                  setPreviousStep={() => setCurrentStep(currentStep - 1)}
                  handleSubmitProjectValidation={handleSubmitProjectValidation}
                  milestoneData={{
                    projectStatus: projectData?.status,
                    contractorId: projectData?.contractorId,
                    isEscrow: projectData?.details?.escrow,
                    preloadedData: {
                      milestones: projectData?.milestones,
                      totalCost: projectData?.totalCost,
                    },
                    projectId: currentProjectId,
                  }}
                />
              )}
            />
            <Route
              path="subcontractor-milestones/:id"
              element={(
                <SubcontractorMilestones
                  setProjectId={(id) => setCurrentProjectId(id)}
                  onFormSubmitted={() => setCurrentStep(currentStep + 1)}
                  setPreviousStep={() => setCurrentStep(currentStep - 1)}
                  milestoneData={{
                    projectStatus: projectData?.status,
                    contractorId: projectData?.contractorId,
                    isEscrow: projectData?.details?.escrow,
                    preloadedData: { subContractors: projectData?.subContractors },
                    projectId: currentProjectId,
                  }}
                />
              )}
            />
            <Route
              path="contract/:id"
              element={(
                <Contracts
                  setProjectId={(id) => setCurrentProjectId(id)}
                  onFormSubmitted={() => setCurrentStep(currentStep + 1)}
                  setPreviousStep={() => setCurrentStep(currentStep - 1)}
                  preloadedData={{
                    contract: {},
                    projectData,
                  }}
                  triggerDataUpdate={() => setTriggerDataUpdate(true)}
                />
              )}
            />
            <Route
              path="submit-save/:id"
              element={(
                <SubmitProject
                  setProjectId={(id) => setCurrentProjectId(id)}
                  projectSubmitValidation={projectSubmitValidation}
                  updateCurrentStep={(step) => setCurrentStep(step)}
                />
              )}
            />
            <Route
              path="success"
              element={(
                <ProjectSuccess />
              )}
            />
            <Route
              path="milestone/approval"
              element={(
                <ApproveMilestoneSuccess />
              )}
            />
          </Routes>
        </Container>
      </Centerlized>
    </>
  );
};

export default ProjectView;
