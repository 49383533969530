import { ReactComponent as PlusIcon } from 'assets/images/plus-icon.svg';
import lang from 'lang/en';
import { StyledButtonWithIconContainer } from './styles';

type AddContractorButtonProps = {
  onClick?: () => void;
}

const AddContractorButton = ({ onClick }: AddContractorButtonProps) => {
  return (
    <StyledButtonWithIconContainer onClick={onClick}>
      <PlusIcon />
      {lang.contract.addContractButtonLabel}
    </StyledButtonWithIconContainer>
  );
};

export default AddContractorButton;
