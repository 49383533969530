import { ReactElement } from 'react';
import { Navigate } from 'react-router-dom';
import { getAuthInfo } from 'utils/helpers';

const AccountSetup = (): ReactElement => {
  const authInfo = getAuthInfo();

  switch (authInfo.accountStatus) {
    case 'active':
      return <Navigate to="/dashboard" />;
    case 'disabled':
      return <Navigate to="/disabled" />;
    case 'missingAccountType':
      return <Navigate to="/account-setup/type" />;
    case 'notOnboarded':
      return <Navigate to="/account-setup/onboarding" />;
    default:
      return <Navigate to="/signin" />;
  }
};

export default AccountSetup;
