import { Card, CardTitle } from 'domains/accountSetup/onboarding/styles';
import { WideLimitedControlledInputField } from 'domains/project/addProject/contract/styles';
import { useWindowDimensions } from 'hooks/useWindowDimensions';
import lang from 'lang/en';
import { Control, FieldValues } from 'react-hook-form';

export const Connecticut = ({ control }: {
  control: Control<FieldValues>;
}) => {
  const modalStrings = lang.contract.homeowner.modal;
  const { screenSize } = useWindowDimensions();
  return (
    <Card screenSize={screenSize}>
      <CardTitle>
        {modalStrings.form.previousCorporations.header}
      </CardTitle>
      <WideLimitedControlledInputField
        control={control}
        name="previousCorporation"
        labelText={modalStrings.form.previousCorporations.label}
      />
    </Card>
  );
};
