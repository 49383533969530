import { useCallback, useEffect } from 'react';
import { useSaveForm } from 'utils/form/useSaveForm';
import { notifications } from 'designSystem/toastNotifications/toastNotificationEmitter';
import lang from 'lang/en';
import { updateMilestoneType } from './types';

export const useUpdateMilestone = (milestoneUrl: string): {
  saveMilestoneData: (data: updateMilestoneType, milestoneId: number) => Promise<void>;
  loading: boolean;
  isSaved: boolean;
  error: string;
} => {
  const {
    loading, error, save, isSaved,
  } = useSaveForm<updateMilestoneType>();

  useEffect(() => {
    if (isSaved && !error) {
      notifications.success(lang.milestones.milestoneUpdateSuccessMsg);
    }
  }, [error, isSaved]);

  useEffect(() => {
    if (error) {
      notifications.error(error);
    }
  }, [error]);

  const saveMilestoneData = useCallback(
    async (data: updateMilestoneType, milestoneId) => {
      const url = `${milestoneUrl}${milestoneId}/markAsCompleted`;
      await save(url, data, 'patch');
    },
    [save],
  );

  return {
    loading,
    error,
    saveMilestoneData,
    isSaved,
  };
};
