import FileUpload from 'designSystem/fileUpload/fileUpload';
import { useGetS3PresignedUrl } from 'designSystem/fileUpload/useGetS3PresignedUrl';
import { CardDescription } from 'domains/accountSetup/onboarding/styles';
import { useMemo } from 'react';
import lang from 'lang/en';
import { CustomCardTitle } from 'domains/project/addProject/details/userSearchAndDisplayCard/styles';
import { FileUploadProps } from './types';

const maxFileSize = 10000000;

export const UploadDocuments = ({
  documents, updateDocuments, loadTitleSection,
  uploadTitle, addButtonLabel, deleteTextHeading, deleteSubText,
  showAddMore, presignedUrl, accept,
}: FileUploadProps) => {
  const { getSignedUrl } = useGetS3PresignedUrl(presignedUrl);
  const filetypeDefinition = {
    label: lang.milestoneUpload.fileTypesLabel,
    sizeLabel: lang.milestoneUpload.fileSizeLabel,
    accept,
  };

  const titleSection = useMemo(() => {
    if (loadTitleSection) {
      return (
        <>
          <CustomCardTitle>
            {`${lang.milestoneUpload.titleLabel}`}
          </CustomCardTitle>
          <CardDescription>
            {lang.milestoneUpload.subtitleDescription}
          </CardDescription>
        </>
      );
    }
  }, [loadTitleSection]);

  return (
    <>
      {titleSection}
      <FileUpload
        uploadedFiles={documents}
        updateUploadedFiles={updateDocuments}
        getSignedUrlHandler={getSignedUrl}
        title={uploadTitle}
        filetypeDefinition={filetypeDefinition}
        maxFileSize={maxFileSize}
        addButtonLabel={addButtonLabel}
        deleteItemHeading={deleteTextHeading}
        deleteItemSubtext={deleteSubText}
        variant="secondary"
        showAddMore={showAddMore}
        showDownload
      />
    </>
  );
};

UploadDocuments.defaultProps = {
  loadTitleSection: false,
  showAddMore: true,
  uploadTitle: '',
  addButtonLabel: '',
  deleteTextHeading: '',
  deleteSubText: '',
  presignedUrl: null,
  accept: 'image/png, image/jpeg, image/jpg',
};

export default UploadDocuments;
