import FileUpload from 'designSystem/fileUpload/fileUpload';
import { BlueprintsProps } from 'designSystem/fileUpload/types';
import { useGetS3PresignedUrl } from 'designSystem/fileUpload/useGetS3PresignedUrl';
import { Card, CardDescription } from 'domains/accountSetup/onboarding/styles';
import { useWindowDimensions } from 'hooks/useWindowDimensions';
import lang from 'lang/en';
import { CustomCardTitle } from '../userSearchAndDisplayCard/styles';

const maxFileSize = 10000000;

const Blueprints = ({ projectDocuments, updateProjectDocuments }: BlueprintsProps) => {
  const { screenSize } = useWindowDimensions();
  const { getSignedUrl } = useGetS3PresignedUrl();
  const microsoftWordDocumentAcceptString = '.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document';
  const pdfDocumentAcceptString = '.pdf, application/pdf';
  const filetypeDefinition = {
    label: lang.blueprint.fileTypesLabel,
    sizeLabel: lang.blueprint.fileSizeLabel,
    accept: `image/png, image/jpeg, image/jpg, ${pdfDocumentAcceptString}, ${microsoftWordDocumentAcceptString}`,
  };

  return (
    <Card screenSize={screenSize}>
      <CustomCardTitle>
        {`${lang.blueprint.titleLabel}`}
      </CustomCardTitle>
      <CardDescription>
        {lang.blueprint.subtitleDescription}
      </CardDescription>
      <FileUpload
        uploadedFiles={projectDocuments}
        updateUploadedFiles={updateProjectDocuments}
        getSignedUrlHandler={getSignedUrl}
        title={lang.blueprint.uploadTitle}
        filetypeDefinition={filetypeDefinition}
        maxFileSize={maxFileSize}
        addButtonLabel={lang.blueprint.addButtonLabel}
        deleteItemHeading={lang.blueprint.delete.heading}
        deleteItemSubtext={lang.blueprint.delete.subText}
        variant="primary"
      />
    </Card>
  );
};

export default Blueprints;
