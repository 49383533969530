import { PreloadedDataType } from 'domains/project/addProject/types';
import { useGetProjectData } from 'domains/project/addProject/useGetProjectData';
import { useEffect, useState } from 'react';

export const useFillExistingData = (currentStep: number) => {
  const [projectData, setProjectData] = useState<PreloadedDataType>();
  const [loader, setLoader] = useState(false);
  const [triggerDataUpdate, setTriggerDataUpdate] = useState<boolean>(false);
  const [currentProjectId, setCurrentProjectId] = useState<number>();

  const { getProjectData } = useGetProjectData();

  useEffect(() => {
    const fillExistingData = async () => {
      setLoader(true);
      const {
        id,
        name,
        country,
        streetAddress,
        city,
        state,
        zip,
        projectDocuments,
        cost,
        escrow,
        status,
        userId,
        homeOwnerId,
        ...loadedProjectData
      } = await getProjectData(currentProjectId as number);
      setProjectData({
        ...loadedProjectData,
        details: {
          id,
          project: {
            name,
            country,
            streetAddress,
            city,
            state,
            zip,
          },
          projectDocuments,
          homeOwnerId,
          cost,
          escrow,
        },
        status,
        contractorId: userId,
      });
      setLoader(false);
    };
    if (currentProjectId && triggerDataUpdate) {
      fillExistingData();
      setTriggerDataUpdate(false);
      return;
    }
    if (currentProjectId) {
      fillExistingData();
    }
  }, [currentStep, currentProjectId, triggerDataUpdate]);

  return {
    projectData,
    loader,
    setTriggerDataUpdate,
    setCurrentProjectId,
    currentProjectId,
  };
};
