import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from 'designSystem';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import {
  clearAuthInfo, getAuthInfo, getButtonState,
} from 'utils/helpers';
import lang from 'lang/en';
import HomeownerForm from './homeownerForm';
import { ActionButtonsContainer } from '../styles';
import type { HomeownerData } from './types';
import { useSaveHomeownerData } from './useSaveHomeownerData';
import { useGetHomeownerData } from './useGetHomeownerData';
import { homeownerValidationSchema } from './validationSchema';

const HomeownerDetails = () => {
  const {
    loading,
    saveHomeownerData,
    isSaved,
  } = useSaveHomeownerData();
  const { getHomeownerData } = useGetHomeownerData();
  const { user } = getAuthInfo();
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const navigate = useNavigate();
  const resolver = { resolver: yupResolver(homeownerValidationSchema) };
  const {
    handleSubmit, control, formState, reset, trigger,
  } = useForm(
    {
      ...resolver,
      mode: 'onChange',
      defaultValues: {
        homeowner: {
          firstName: '',
          lastName: '',
          email: user,
          country: 'United States',
          streetAddress: '',
          city: '',
          state: '',
          zip: '',
          phone: null,
        },
      },
    },
  );

  const { isDirty, isValid } = formState;

  const onSubmit = async (data: HomeownerData) => {
    if (isDirty) {
      await saveHomeownerData(data);
    } else {
      navigate('/account-setup/onboarding/homeowner/confirmation');
    }
  };

  useEffect(() => {
    const setData = async () => {
      const loadedData = await getHomeownerData();
      if (loadedData) {
        const { id, ...providedData } = loadedData;
        reset({ homeowner: providedData });
        setIsDataLoaded(true);
        trigger();
      }
    };
    setData();
  }, []);

  useEffect(() => {
    if (isSaved) {
      navigate('/account-setup/onboarding/homeowner/confirmation');
    }
  }, [isSaved]);

  const handleBackToSignin = () => {
    clearAuthInfo();
    navigate('/signin');
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <HomeownerForm control={control} />
      <ActionButtonsContainer>
        <Button name="back" type="button" variant="secondary" label={lang.backLabel} state="active" onClick={handleBackToSignin} />
        <Button name="next" type="submit" variant="primary" label={lang.nextLabel} state={getButtonState(isDirty || isDataLoaded, isValid, loading)} />
      </ActionButtonsContainer>
    </form>
  );
};

export default HomeownerDetails;
