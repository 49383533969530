import { ReactComponent as CrossIcon } from 'assets/images/errorIcon.svg';
import {
  ErrorMessage, ErrorText, IconSpan, Input, InputContainer, InputLabel, PostfixIconSpan, PrefixIconSpan, TextArea,
} from './styles';
import type { LabelledInputFieldProps } from './types';

export const InputField = ({
  type,
  labelText,
  maxLength,
  field,
  errorMessage = '',
  className,
  disabled,
  placeholder,
  iconPrefix,
  iconPostfix,
  prefixAlignment,
  errorIconPosition,
  errorIconTopPosition,
}: LabelledInputFieldProps) => {
  return (
    <InputContainer className={className}>
      <InputLabel htmlFor={field.name}>{labelText}</InputLabel>
      {iconPrefix && <PrefixIconSpan prefixAlignment={prefixAlignment}>{iconPrefix}</PrefixIconSpan>}
      {type === 'textarea'
        ? (
          <TextArea
            disabled={disabled}
            maxLength={maxLength}
            {...field}
            $isError={errorMessage.length > 0}
            data-testid="test-textarea"
            $isWithIconPrefix={!!iconPrefix}
            placeholder={placeholder}
            cols={50}
            rows={5}
          >
            {field.value || ''}
          </TextArea>
        )
        : (
          <Input
            disabled={disabled}
            maxLength={maxLength}
            type={type}
            {...field}
            value={field.value || ''}
            $isError={errorMessage.length > 0}
            data-testid="test-input"
            $isWithIconPrefix={!!iconPrefix}
            placeholder={placeholder}
            autoComplete="off"
          />
        )}
      {errorMessage.length > 0 && (
        <IconSpan
          $isWithIconPrefix={!!iconPrefix}
          errorIconPosition={errorIconPosition}
          errorIconTopPosition={errorIconTopPosition}
          isError={errorMessage !== ''}
        >
          <CrossIcon />
        </IconSpan>
      )}
      {iconPostfix && <PostfixIconSpan>{iconPostfix}</PostfixIconSpan>}
      {errorMessage !== ''
        && <ErrorMessage><ErrorText>{errorMessage}</ErrorText></ErrorMessage>}
    </InputContainer>
  );
};

export default InputField;
