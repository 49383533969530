import { textBase, textLg, textSm } from 'designSystem/typings';
import { black, blue700 } from 'designSystem/colors';
import styled from 'styled-components';
import { ScreenSize } from 'hooks/useWindowDimensions';

export const Centerlized = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 23px;
`;

export const Heading = styled.h1`
  ${textLg}
  color: ${black}
  text-transform: uppercase;
  margin-top: 40px;
  margin-bottom: 32px;
`;

export const ButtonSection = styled.div`
  margin-top: 32px;
`;

export const SubHeading = styled.p<{screenSize: ScreenSize}>`
  ${({ screenSize }) => screenSize !== 'desktop' ? textBase : textLg}
  font-weight: ${({ screenSize }) => screenSize !== 'desktop' ? 400 : 500};
  color: ${black};
  margin-top: 32px;
  margin-bottom: 16px;
`;

export const FormSection = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  margin-bottom: 32px;
  align-items: center;
  width: 100%;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const LinkText = styled.p`
  ${textSm}
  font-weight: 500;
  color: ${blue700};
  cursor: pointer;
`;

export const Fields = styled.div`
  width: 100%;
  max-width: 320px;
`;
