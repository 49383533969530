import { Stripe, StripeElements } from '@stripe/stripe-js';
import { CardNumberElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { useCallback, useEffect, useState } from 'react';
import { stripeSubscriptionUrl, stripeGetPlans } from 'utils/network/endpoints';
import { useSaveForm } from 'utils/form/useSaveForm';
import { useNavigate } from 'react-router-dom';
import { getAuthInfo } from 'utils/helpers';
import { notifications } from 'designSystem/toastNotifications/toastNotificationEmitter';
import { AxiosResponse } from 'axios';
import { useGetData } from 'utils/form/useGetData';
import runtimeEnv from '@mars/heroku-js-runtime-env';
import type { SubscriptionForm, useSubscriptType, SubscribeApiProp } from './types';

export const useSubscription = () : useSubscriptType => {
  const { REACT_APP_ANNUAL_PROD_ID, REACT_APP_MONTHLY_PROD_ID } = runtimeEnv();

  const stripe = useStripe();
  const elements = useElements();
  const {
    loading, error, save, isSaved, response,
  } = useSaveForm<SubscribeApiProp>();

  const {
    getData,
    error: stripePlanApiError,
    response: stripePlanResponse,
  } = useGetData<AxiosResponse>();

  const [paymentError, setPaymentError] = useState('');
  const [stripePlans, setStripeResponse] = useState<{[key:string]: any} | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (error) {
      notifications.error(error);
    }
  }, [error]);

  useEffect(() => {
    if (stripePlanApiError) {
      notifications.error(stripePlanApiError);
    }
  }, [stripePlanApiError]);

  useEffect(() => {
    if (stripePlanResponse?.data) {
      const annualPlan = stripePlanResponse?.data.find((item: { id: string; }) => item.id === REACT_APP_ANNUAL_PROD_ID);
      const monthlyPlan = stripePlanResponse?.data.find((item: { id: string }) => item.id === REACT_APP_MONTHLY_PROD_ID);
      setStripeResponse({
        annualPlan,
        monthlyPlan,
      });
    }
  }, [stripePlanResponse]);

  useEffect(() => {
    if (isSaved && !error && !paymentError) {
      navigate('/account-setup/onboarding/company/confirmation');
    }
  }, [isSaved, error, paymentError]);

  useEffect(() => {
    if (response) {
      confirmCardPayment(response, stripe);
    }
  }, [response, stripe, elements]);

  const getUserEmail = (): string => {
    return getAuthInfo().user;
  };

  const getPaymentMethod = async (
    stripeObject: Stripe,
    stripeElement: StripeElements,
    name: string,
  ) : Promise<{ paymentMethod?: string | undefined; errorMessage?: string | undefined; }> => {
    const { getElement } = stripeElement;
    const cardNumberElement = getElement(CardNumberElement);
    if (cardNumberElement) {
      const payment = await stripeObject.createPaymentMethod({
        type: 'card',
        card: cardNumberElement,
        billing_details: {
          name,
          email: getUserEmail(),
        },
      });
      return {
        paymentMethod: payment.paymentMethod?.id,
        errorMessage: payment?.error?.message,
      };
    }
    return {
      paymentMethod: '',
      errorMessage: '',
    };
  };

  const confirmCardPayment = async (responseData: AxiosResponse | null, stripeObject: Stripe | null) => {
    if (stripeObject) {
      const clientSecret = response?.data?.clientSecret;
      stripeObject.confirmCardPayment(clientSecret).then((stripeResponse) => {
        if (stripeResponse.error) {
          notifications.error(stripeResponse.error.message || 'Something went wrong');
        } else {
          navigate('/account-setup/onboarding/company/confirmation');
        }
      });
    }
  };

  const useSubscribe = useCallback(async (data: SubscriptionForm) => {
    if (!stripe || !elements) { return true; }
    const { paymentMethod, errorMessage } = await getPaymentMethod(stripe, elements, data.name);
    setPaymentError('');
    if (errorMessage) {
      setPaymentError(errorMessage);
      notifications.error(errorMessage);
      return false;
    }
    if (paymentMethod) {
      const postData = {
        email: getUserEmail(),
        paymentMethod,
        planType: data.planType,
        refferalCode: data.refferalCode,
      };
      await save(stripeSubscriptionUrl, postData, 'post');
    }
  }, [save, stripe, elements]);

  const getStripePlans = useCallback(async () => {
    await getData(stripeGetPlans);
  }, []);
  return {
    useSubscribe,
    loading,
    getStripePlans,
    stripePlans,
  };
};

export default useSubscription;
