import { ContractorInsuranceSelectProps } from 'domains/project/addProject/contract/homeowner/types';
import {
  LabelWithMargin, NarrowControlledInputField, RadioContainer, ErrorMessage, ErrorText,
} from 'domains/project/addProject/contract/styles';
import lang from 'lang/en';
import { useEffect } from 'react';

const SubcontractorInsuranceSelect = ({
  registrant,
  control,
  contractorInsuranceValue,
  setValue,
  errorMsg,
}: ContractorInsuranceSelectProps) => {
  const modalStrings = lang.contract.subcontractors.modal;

  useEffect(() => {
    if (contractorInsuranceValue !== 'commercialLiabilityInsurance') {
      setValue('subcontractorInsuranceProviderName', null);
    }
  }, [contractorInsuranceValue]);
  return (
    <RadioContainer>
      <label htmlFor="subcontractorCommercialLiabilityInsurance">
        <input
          {...registrant}
          type="radio"
          value="commercialLiabilityInsurance"
          id="subcontractorCommercialLiabilityInsurance"
        />
        {modalStrings.form.optionsForSubcontractorsInsurance.option1}
      </label>
      <NarrowControlledInputField
        control={control}
        name="subcontractorInsuranceProviderName"
        labelText={modalStrings.form.optionsForSubcontractorsInsurance.option1InputFieldLabel}
        disabled={contractorInsuranceValue !== 'commercialLiabilityInsurance'}
        errorIconPosition="206px"
      />
      <LabelWithMargin htmlFor="subcontractorNoCommercialLiabilityInsurance">
        <input
          {...registrant}
          type="radio"
          value="noCommercialLiabilityInsurance"
          id="subcontractorNoCommercialLiabilityInsurance"
        />
        {modalStrings.form.optionsForSubcontractorsInsurance.option2}
      </LabelWithMargin>
      <label htmlFor="subcontractorSelfInsured">
        <input
          {...registrant}
          type="radio"
          value="selfInsured"
          id="subcontractorSelfInsured"
        />
        {modalStrings.form.optionsForSubcontractorsInsurance.option3}
      </label>
      <ErrorMessage margin="10px 0 0 12px"><ErrorText>{errorMsg}</ErrorText></ErrorMessage>
    </RadioContainer>
  );
};

SubcontractorInsuranceSelect.defaultProps = {
  errorMsg: '',
};

export default SubcontractorInsuranceSelect;
