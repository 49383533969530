import {
  Card, CardRow, CardTitle, CardDescription,
} from 'domains/accountSetup/onboarding/styles';
import { useWindowDimensions } from 'hooks/useWindowDimensions';
import { CustomWidthInputField } from 'domains/project/addProject/contract/styles';
import { lang } from 'lang/en';
import { useGetField } from 'domains/project/addProject/details/userSearchAndDisplayCard/useGetField';

export const ContractorDetails = ({ fullName }: { fullName: string}) => {
  const { screenSize } = useWindowDimensions();
  const { getField } = useGetField();
  const { contract: { changeOrder: { contractor } } } = lang;
  return (
    <Card screenSize={screenSize}>
      <CardTitle marginBottom="0">
        {contractor.title}
      </CardTitle>
      <CardDescription>
        {contractor.subText}
      </CardDescription>
      <CardRow>
        <CustomWidthInputField
          labelText={contractor.inputLabel}
          disabled
          field={{ ...getField('contractorfirstNameLastName', fullName || '') }}
        />
      </CardRow>
    </Card>
  );
};
