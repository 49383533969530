import { WorkersCompensationInsuranceSelectProps } from 'domains/project/addProject/contract/homeowner/types';
import {
  LabelWithMargin, RadioContainer, ErrorMessage, ErrorText,
} from 'domains/project/addProject/contract/styles';
import { MediumControlledNumberMaskedInput } from 'domains/project/addProject/styles';
import lang from 'lang/en';
import { useEffect } from 'react';

const WorkersCompensationInsuranceSelect = ({
  registrant,
  control,
  workerCompensationInsuranceLimitValue,
  setValue,
  errorMsg,
}: WorkersCompensationInsuranceSelectProps) => {
  const modalStrings = lang.contract.homeowner.modal;

  useEffect(() => {
    if (workerCompensationInsuranceLimitValue !== 'hasCompensationInsurance') {
      setValue('workerCompensationInsuranceLimit', null);
    }
  }, [workerCompensationInsuranceLimitValue]);

  return (
    <RadioContainer>
      <LabelWithMargin htmlFor="exemptFromInsurance">
        <input
          {...registrant}
          type="radio"
          value="exemptFromInsurance"
          id="exemptFromInsurance"
        />
        {modalStrings.form.workersCompensationInsurance.option1}
      </LabelWithMargin>
      <label htmlFor="hasCompensationInsurance">
        <input
          {...registrant}
          type="radio"
          value="hasCompensationInsurance"
          id="hasCompensationInsurance"
        />
        {modalStrings.form.workersCompensationInsurance.option2}
      </label>
      <MediumControlledNumberMaskedInput
        placeholder="$ 0.00"
        numberPrefix="$"
        fixedDecimalScale
        control={control}
        name="workerCompensationInsuranceLimit"
        labelText={modalStrings.form.workersCompensationInsurance.option2InputFieldLabel}
        disabled={workerCompensationInsuranceLimitValue !== 'hasCompensationInsurance'}
        errorIconPosition="286px"
      />
      <ErrorMessage margin="0 0 0 10px"><ErrorText>{errorMsg}</ErrorText></ErrorMessage>
    </RadioContainer>
  );
};

WorkersCompensationInsuranceSelect.defaultProps = {
  errorMsg: '',
};

export default WorkersCompensationInsuranceSelect;
