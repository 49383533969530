import { useCallback, useEffect } from 'react';
import { useSaveForm } from 'utils/form/useSaveForm';
import { notifications } from 'designSystem/toastNotifications/toastNotificationEmitter';

type updateInspectionType = {
  [key: string] : boolean;
}

export const useInspectionSaveData = (inspectionUrl: string): {
  saveInspectionData: (keyName: string, value: boolean, milestoneId: number) => Promise<void>;
  loading: boolean;
  isSaved: boolean;
  error: string;
} => {
  const {
    loading, error, save, isSaved,
  } = useSaveForm<updateInspectionType>();

  useEffect(() => {
    if (error) {
      notifications.error(error);
    }
  }, [error]);

  const saveInspectionData = useCallback(
    async (keyName: string, value: boolean, milestoneId: number) => {
      if (milestoneId) {
        const data = {
          [keyName]: value,
        };
        const url = `${inspectionUrl}${milestoneId}/markInspectionRequired`;
        await save(url, data, 'patch');
      }
    },
    [save],
  );

  return {
    loading,
    error,
    saveInspectionData,
    isSaved,
  };
};
