import {
  Card, CardRow, CardTitle, CardDescription,
} from 'domains/accountSetup/onboarding/styles';
import { useWindowDimensions } from 'hooks/useWindowDimensions';
import { InputField } from 'designSystem/input';
import { lang } from 'lang/en';
import { useGetField } from 'domains/project/addProject/details/userSearchAndDisplayCard/useGetField';

export const ProjectAddress = ({
  addressDetails,
} : {
  addressDetails: {
    streetAddress: string;
    city: string;
    state: string;
    zip: string;
  }
}) => {
  const { screenSize } = useWindowDimensions();
  const { getField } = useGetField();
  const { contract: { changeOrder: { projectAddress } } } = lang;
  const {
    streetAddress, city, state, zip,
  } = addressDetails;
  return (
    <Card screenSize={screenSize}>
      <CardTitle marginBottom="0">
        {projectAddress.header}
      </CardTitle>
      <CardDescription>
        {projectAddress.description}
      </CardDescription>
      <CardRow>
        <InputField
          disabled
          labelText={projectAddress.streetAddressLabel}
          field={{ ...getField('projectAddress.streetAddress', streetAddress) }}
        />
      </CardRow>
      <CardRow customFlexBasis="230px">
        <InputField
          disabled
          labelText={projectAddress.cityLabel}
          field={{ ...getField('projectAddress.city', city) }}
        />
        <InputField
          disabled
          labelText={projectAddress.stateLabel}
          field={{ ...getField('projectAddress.state', state) }}
        />
        <InputField
          disabled
          labelText={projectAddress.zipLabel}
          field={{ ...getField('projectAddress.zip', zip) }}
        />
      </CardRow>
    </Card>
  );
};
