import lang from 'lang/en';

const { contract: { homeowner: { modal: { form: { massachusettsFields: { jobProjectStatements: { points: listItem } } } } } } } = lang;

export const points = [
  listItem.point1,
  listItem.point2,
  listItem.point3,
  listItem.point4,
  listItem.point5,
  listItem.point6,
  listItem.point7,
  listItem.point8,
  listItem.point9,
  listItem.point10,
  listItem.point11,
  listItem.point12,
];

export const massachusettsStateDefaultValues = {
  rightToInitiateArbitration: null,
  workOnThePrincipalResidence: null,
  threeDayRight: null,
  jobAndProjectStatements: null,
  contractorDaytimePhoneNo: '',
  contractorEveningPhoneNo: '',
  contractorEmployerIdOrSocialSecurityNo: '',
  contractorHICRegisterationNo: '',
  contractorHICExpiryDate: '',
  salesperson: '',
  responsibleForBuildingPermit: null,
};

export const iconPositionStyle = {
  customField: {
    left: {
      mobile: '85%',
      desktop: '216px',
    },
  },
  mediumField: {
    left: {
      mobile: '91%',
      desktop: '343px',
    },
    top: {
      mobile: '46%',
      desktop: '32px',
    },
  },
  smallField: {
    left: {
      mobile: '89%',
      desktop: '248px',
    },
    top: {
      mobile: '39%',
      desktop: '32px',
    },
  },

};
