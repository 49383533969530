import {
  CardDescription, CardTitle,
} from 'domains/accountSetup/onboarding/styles';
import { useWindowDimensions } from 'hooks/useWindowDimensions';
import { Controller } from 'react-hook-form';
import {
  LabelWithMargin, RadioContainer, ErrorMessage, ErrorText,
  MarginTop, RadioOptionContainer,
} from 'domains/project/addProject/contract/styles';
import type { RadioStringOptionsTypes } from 'domains/project/addProject/contract/types';

export const RadioStringOptions = ({
  control, fieldText, fieldName, setValue, errorMsg, value1, value2, getValues,
  marginBottom, clearErrors,
} : RadioStringOptionsTypes) => {
  const { screenSize } = useWindowDimensions();
  const handleOnChange = (value: string) => {
    setValue(fieldName, value);
    clearErrors(fieldName);
  };

  return (
    <RadioOptionContainer marginBottom={marginBottom}>
      <CardTitle marginBottom="0">
        {fieldText.mainHeading}
      </CardTitle>
      <CardDescription>
        {fieldText.description}
      </CardDescription>
      <RadioContainer>
        <Controller
          control={control}
          name={fieldName}
          render={() => (
            <label htmlFor={`yes-${fieldName}`}>
              <input
                name={fieldName}
                type="radio"
                id={`yes-${fieldName}`}
                value={value1}
                onChange={() => handleOnChange(value1)}
                defaultChecked={getValues(fieldName) !== null ? getValues(fieldName) === value1 : false}
              />
              {fieldText.option1}
            </label>
          )}
        />
        <MarginTop />
        <Controller
          control={control}
          name={fieldName}
          render={() => (
            <LabelWithMargin htmlFor={`no-${fieldName}`}>
              <input
                name={fieldName}
                type="radio"
                id={`no-${fieldName}`}
                value={value2}
                onChange={() => handleOnChange(value2)}
                defaultChecked={getValues(fieldName) !== null ? getValues(fieldName) === value2 : false}
              />
              {fieldText.option2}
            </LabelWithMargin>
          )}
        />
        <ErrorMessage id={`error-${fieldName}`} margin="0 0 0 10px"><ErrorText>{errorMsg}</ErrorText></ErrorMessage>
      </RadioContainer>
    </RadioOptionContainer>
  );
};
