import { Button } from 'designSystem';
import { Content, Header } from 'domains/accountSetup/onboarding/styles';
import { ModalContainer, SendContractButtonRow, WiderNoButton } from 'domains/project/addProject/contract/homeowner/styles';
import lang from 'lang/en';

type SendContractModal = {
  handleClose: () => void;
  handleSend: () => void;
  sendContractLoading?: boolean;
}

const SendContractModal = ({
  handleClose,
  handleSend,
  sendContractLoading,
}: SendContractModal) => {
  return (
    <>
      <ModalContainer>
        <Header>
          {lang.contract.homeowner.signFlow.step3.modal.header}
        </Header>
        <Content>
          {lang.contract.homeowner.signFlow.step3.modal.description}
        </Content>
      </ModalContainer>
      <SendContractButtonRow>
        <WiderNoButton
          width="full"
          type="button"
          variant="secondary"
          label={lang.contract.homeowner.signFlow.step3.modal.noButtonLabel}
          onClick={handleClose}
        />
        <Button
          type="button"
          variant="primary"
          label={lang.contract.homeowner.signFlow.step3.modal.yesButtonLabel}
          onClick={handleSend}
          state={sendContractLoading ? 'loading' : 'active'}
        />
      </SendContractButtonRow>
    </>
  );
};

export default SendContractModal;
