import { Controller } from 'react-hook-form';
import { ReactElement } from 'react';
import Checkbox from './checkbox';
import type { ControlledCheckboxProps } from './types';

const ControlledCheckbox = ({
  name, control, ...propsToPass
}: ControlledCheckboxProps):ReactElement => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        return <Checkbox {...propsToPass} field={field} />;
      }}
    />
  );
};

export default ControlledCheckbox;
