import { ControlledInputField } from 'designSystem/input';
import { useWindowDimensions } from 'hooks/useWindowDimensions';
import { RadioContainer, ErrorMessage, ErrorText } from 'domains/project/addProject/contract/styles';
import RegisteredCheckbox from 'domains/project/addProject/contract/subcontractor/form/registeredCheckbox';
import { SelectPropsWithControl } from 'domains/project/addProject/contract/subcontractor/form/types';
import lang from 'lang/en';
import { useMemo, useEffect } from 'react';
import { useWatch } from 'react-hook-form';
import { getTextareaIconLeftPosition } from './helpers';

const formStrings = lang.contract.subcontractors.modal.form;

const SubcontractorResponsibilitiesMultiSelect = ({
  registrant,
  control,
  setValue,
  errorMsg,
}: SelectPropsWithControl) => {
  const optionValue = useWatch({
    control,
    name: registrant.name,
  });
  const { screenSize } = useWindowDimensions();

  useEffect(() => {
    if (optionValue && !optionValue.includes('other') && setValue) {
      setValue('otherSubcontractorResponsibilities', '');
    }
  }, [optionValue]);

  const isOtherOptionSelected = useMemo(() => {
    return optionValue?.includes && optionValue.includes('other');
  }, [optionValue]);

  return (
    <RadioContainer>
      <RegisteredCheckbox labelText={formStrings.subcontractorResponsibilities.laborOption} registrant={registrant} value="labor" />
      <RegisteredCheckbox labelText={formStrings.subcontractorResponsibilities.materialsOption} registrant={registrant} value="materials" />
      <RegisteredCheckbox labelText={formStrings.subcontractorResponsibilities.equipmentOption} registrant={registrant} value="equipment" />
      <RegisteredCheckbox labelText={formStrings.subcontractorResponsibilities.travelOption} registrant={registrant} value="travel" />
      <RegisteredCheckbox labelText={formStrings.subcontractorResponsibilities.otherOption} registrant={registrant} value="other" />
      <ControlledInputField
        control={control}
        name="otherSubcontractorResponsibilities"
        labelText={formStrings.subcontractorResponsibilities.fieldLabel}
        type="textarea"
        disabled={!isOtherOptionSelected}
        errorIconPosition={getTextareaIconLeftPosition(screenSize)}
        errorIconTopPosition="68px"
      />
      <ErrorMessage margin="0 0 0 12px"><ErrorText>{errorMsg}</ErrorText></ErrorMessage>
    </RadioContainer>
  );
};

SubcontractorResponsibilitiesMultiSelect.defaultProps = {
  errorMsg: '',
};

export default SubcontractorResponsibilitiesMultiSelect;
