import DeleteItem from 'common/deleteItem';
import { useState } from 'react';
import { ReactComponent as PlusIcon } from 'assets/images/plus-icon.svg';
import { ReactComponent as SecondaryPlusIcon } from 'assets/images/blue-plus.svg';
import { ErrorText } from 'designSystem/input/styles';
import { Button } from 'designSystem';
import { ButtonWithIconContainer } from 'designSystem/button/styles';
import { UploadedFilesProps } from './types';
import UploadedFile from './uploadedFile';
import { CustomErrorMessage, FilesContainer } from './styles';

const UploadedFiles = ({
  files, updateFiles, onAddButtonClick, errorMessage, addButtonLabel, deleteItemHeading, deleteItemSubtext,
  variant, showDownload, showAddMore,
}: UploadedFilesProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [fileToDeleteIndex, setFileToDeleteIndex] = useState(-1);
  const onDeleteAction = (index: number) => {
    const newFiles = files.filter((file, i) => i !== index);
    updateFiles(newFiles);
  };

  const onDeleteCancel = () => {
    setIsOpen(false);
  };

  const handleDeleteActionClick = (index: number) => {
    setIsOpen(true);
    setFileToDeleteIndex(index);
  };

  const onDeleteConfirm = () => {
    if (fileToDeleteIndex !== -1) {
      onDeleteAction(fileToDeleteIndex);
    }
    setIsOpen(false);
    setFileToDeleteIndex(-1);
  };

  const plusIcon = variant === 'secondary' ? <SecondaryPlusIcon /> : <PlusIcon />;

  return (
    <>
      <DeleteItem
        heading={deleteItemHeading}
        subText={deleteItemSubtext}
        handleCancel={onDeleteCancel}
        handleDelete={onDeleteConfirm}
        isOpen={isOpen}
      />
      <FilesContainer>
        {files.filter((item) => item.documentUrl !== null && item.fileName !== null)
          .map((file, index) => (
            <UploadedFile
              key={file.documentUrl}
              fileName={file.fileName}
              documentUrl={file.documentUrl}
              onDeleteAction={() => handleDeleteActionClick(index)}
              showDownload={showDownload}
            />
          ))}
      </FilesContainer>
      {
        showAddMore
        && (
          <Button
            variant={variant}
            type="button"
            onClick={onAddButtonClick}
            customRender={(
              <ButtonWithIconContainer>
                {plusIcon}
                {addButtonLabel}
              </ButtonWithIconContainer>
            )}
          />
        )
      }

      {errorMessage && <CustomErrorMessage><ErrorText>{errorMessage}</ErrorText></CustomErrorMessage>}
    </>
  );
};

UploadedFiles.defaultProps = {
  showDownload: false,
  showAddMore: true,
};

export default UploadedFiles;
