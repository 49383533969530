import { ReactComponent as LoadingIcon } from 'assets/images/loader.svg';
import { colors } from 'designSystem';
import { textSm } from 'designSystem/typings';
import styled from 'styled-components';

const BaseAction = styled.button`
  margin: 0;
  padding: 0;
  border: none;
  background-color: transparent;
  cursor: pointer;
  outline: none;

  display: flex;
  justify-content: center;
  align-items: center;
  ${textSm};
  color: ${colors.blue700};
  line-height: 20px;

  & > p {
    margin-top: 2px;
  }

  & > svg {
    margin-right: 11px;
  }
`;

export const DeleteAction = styled(BaseAction)`
  color: ${colors.red500};
`;

export const EditAction = styled(BaseAction)``;

export const DownloadAction = styled(BaseAction)``;

export const StyledLoading = styled(LoadingIcon)`
  width: 20px;
  height: 20px;
`;
