import { ReactComponent as Logo } from 'assets/images/navlogo.svg';
import { ReactComponent as UserIcon } from 'assets/images/user.svg';
import { useWindowDimensions } from 'hooks/useWindowDimensions';
import { NavLink } from 'react-router-dom';
import { useState, useRef, useEffect } from 'react';
import SignoutIcon from 'assets/images/logout.svg';
import lang from 'lang/en';
import { getAuthInfo, showSettings } from 'utils/helpers';
import {
  DesktopLinkName, DesktopNavContainer, IconSection,
  LinkIcon, Links, LinkSection, LogoSection, UsernameText,
  SettingsSection, LinkName, SignoutLink,
} from './styles';
import { desktopOtherLinks } from './content';

import type { LinkType, ActionType } from './types';

export const activeStyle = {
  textDecoration: 'underline',
};

export const DesktopNavigation = ({ pageLinks, handleLogout }: LinkType & ActionType) => {
  const [linkDropdownVisibility, setLinkDropdownVisibility] = useState(false);
  const dropdownRef = useRef<any>(null);
  const { screenSize } = useWindowDimensions();
  const { user } = getAuthInfo();

  const toggleDropdown = () => {
    (linkDropdownVisibility)
      ? setLinkDropdownVisibility(false)
      : setLinkDropdownVisibility(true);
  };

  useEffect(() => {
    window.addEventListener('click', handleDropdownVisibility, true);
    return () => {
      window.removeEventListener('click', handleDropdownVisibility, true);
    };
  }, []);

  const handleDropdownVisibility = (e: any) => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
      setLinkDropdownVisibility(false);
    }
  };

  return (
    <DesktopNavContainer>
      <LinkSection>
        <LogoSection>
          <Logo />
        </LogoSection>
        <Links screenSize={screenSize}>
          {pageLinks.map(({
            id,
            icon,
            name,
            link,
          }) => {
            return (
              <NavLink
                to={link}
                key={id}
                style={
                  ({ isActive }) => (
                    isActive
                      ? {
                        textDecoration: 'none',
                        background: '#1E3A8A',
                        width: '118px',
                        padding: '6px 14px',
                        borderRadius: '6px',
                        opacity: '0.9',
                      }
                      : {}
                  )
                }
              >
                <LinkIcon src={icon} alt={`${name}-icon`} />
                <DesktopLinkName>{name}</DesktopLinkName>
              </NavLink>
            );
          })}

        </Links>
      </LinkSection>
      <IconSection>
        <UsernameText>
          {user}
        </UsernameText>
        <UserIcon onClick={() => toggleDropdown()} id="settings" />
      </IconSection>
      {
        linkDropdownVisibility
          && (
            <SettingsSection ref={dropdownRef}>
              <>
                {
                  showSettings() && desktopOtherLinks.map(({
                    id, icon, name, link,
                  }) => {
                    return (
                      <NavLink
                        to={link}
                        key={id}
                        style={
                          ({ isActive }) => (
                            isActive
                              ? {
                                textDecoration: 'none',
                                background: '#1E3A8A',
                                width: '175px',
                                padding: '9px 14px',
                                borderRadius: '6px',
                                opacity: '0.9',
                                marginBottom: '13px',
                                marginTop: '12px',
                              }
                              : {
                                marginLeft: '12px',
                                marginBottom: '16px',
                                marginTop: '9px',
                              }
                          )
                        }
                      >
                        <LinkIcon src={icon} alt={`${name}-icon`} />
                        <LinkName>{name}</LinkName>
                      </NavLink>
                    );
                  })
                }
                <SignoutLink onClick={handleLogout}>
                  <LinkIcon src={SignoutIcon} alt="sign-out" />
                  <LinkName>{lang.linkSignOut}</LinkName>
                </SignoutLink>
              </>
            </SettingsSection>
          )
      }
    </DesktopNavContainer>
  );
};
