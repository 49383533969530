export const ohioStateDefaultValues = {
  workOnThePrincipalResidence: null,
  threeDayRight: null,
  miamiDadeCountry: null,
  jobExceeds5000: null,
  publicLiabilityInsPolicyNum: '',
  publicLiabilityInsExpirationDate: '',
  publicLiabilityInsLiabilityLimit: '',
  workerCompensationInsPolicyNum: '',
  workerCompensationInsExpirationDate: '',
  workerCompensationInsLiabilityLimit: '',
  placeOfBusinessOfContractor: null,
  stateFairOrCommercialExhibit: null,
  residenceOfOwner: null,
  isAmountGreaterThan2500: null,
  isContractForConstructionRapairAndImprovement: null,
  isContractForRepairRestorationAndImprovement: null,
  isContractWaivedFloridaNoticeAndRight: null,
};
