import 'react-toastify/dist/ReactToastify.css';
import { ReactElement, useEffect } from 'react';
import ToastContainer from 'designSystem/toastNotifications/toastContainer';
import {
  BrowserRouter, Routes, Route, Navigate,
} from 'react-router-dom';
import useAppRoutes from 'routes/routes';
import { updateAuthToken } from 'utils/helpers';
import runtimeEnv from '@mars/heroku-js-runtime-env';

const App = (): ReactElement => {
  const { REACT_APP_TEST_APP } = runtimeEnv();
  const routes = useAppRoutes();
  // If user navigates with address bar we need to reload token to new instance of apiClient
  useEffect(() => {
    updateAuthToken();
  }, []);
  // eslint-disable-next-line no-console
  console.log(REACT_APP_TEST_APP);
  return (
    <BrowserRouter>
      <ToastContainer />
      <Routes>
        {routes}
        <Route path="/" element={<Navigate to="/signin" />} />
      </Routes>
    </BrowserRouter>
  );
};
export default App;
